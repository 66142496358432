import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  Autocomplete,
  Box,
  Button,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { ErrorMessage, Formik } from "formik";
import "../NivelesEnsenanzaCss.css";
import Chip from "@mui/material/Chip";
import {
  GetListaCompletaEndPoint,
  selectNivelesEnsenanza,
} from "store/nivelesEnsenanza/nivelesEnsenanza.slice";
import { GetListaCompleta } from "store/nivelesEnsenanza/nivelesEnsenanza.api";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  getProyectosConNiveles,
  selectProyectosConNiveles,
} from "store/proyectos/proyectos.slice";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function NivelesEnsenanzaForm({
  itemProy,
  onSubmitResp,
  handleClose,
}) {
  // Variables recién definidas, revisar su uso!.

  const dispatch = useDispatch();
  const ListaNiveles = useSelector(selectNivelesEnsenanza);
  const Nivelesxproyecto = useSelector(selectProyectosConNiveles);
  const TraerNiveles = Nivelesxproyecto.data;
  const TraerNiveles2 = ListaNiveles.records;
  const [NExProyecto, setNExproyecto] = useState();

  useEffect(() => {
    let listaNxproy = Nivelesxproyecto?.data?.map((x) => ({
      labelxNivel: x.nivelEnsenanza,
      idxNivel: x.idNivelEnsenanza,
    }));
    setNExproyecto(listaNxproy);
  }, [ListaNiveles, Nivelesxproyecto]);

  useEffect(() => {
    dispatch(GetListaCompletaEndPoint());
  }, [dispatch]);

  //Logica para enviar formulario al backend
  const handleSubmit = (values) => {
    values.idProyecto = itemProy?.idProyecto;
    onSubmitResp(values); // Dato a enviar.
    console.log("values submit", values);
  };

  //Campos del formulario
  const initialValue = {
    idProyecto: itemProy?.idProyecto,
    idNiveles: [],
  };

  return (
    <>
      <Formik
        initialValues={initialValue}
        /* Aqui se obtienen los datos que se ingresó en el formulario */
        onSubmit={handleSubmit}
        /* Configuracion de Formik para agregar las validaciones */
        //validationSchema={validationSchema}
      >
        {(formikProps) => (
          <Box style={{ borderRadius: "5px" }}>
            <div
              style={{
                marginBottom: "10px",
              }}
            >
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={TraerNiveles2 || []}
                disableCloseOnSelect
                defaultValue={TraerNiveles || []}
                isOptionEqualToValue={(option, value) =>
                  option.idNivelEnsenanza === value.idNivelEnsenanza
                }
                getOptionLabel={(option) => option.nivelEnsenanza}
                onChange={(event, newValue) => {
                  formikProps.setFieldValue(
                    "idNiveles",
                    newValue !== null
                      ? newValue.map((option) => option.idNivelEnsenanza)
                      : []
                  );
                }}
                name="idNiveles"
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.nivelEnsenanza}
                  </li>
                )}
                style={{ width: 500, marginTop: "0.5rem" }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Niveles de Enseñanza" />
                )}
                PaperComponent={({ children }) => <Paper>{children}</Paper>}
              />
            </div>
            <Stack
              spacing={2}
              direction="row"
              sx={{ display: "flex", justifyContent: "flex-end", mt: 3, mb: 2 }}
            >
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleClose()}
                spacing={2}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                onClick={() => formikProps.submitForm()}
              >
                Guardar
              </Button>
            </Stack>
          </Box>
        )}
      </Formik>
    </>
  );
}
