import { useContext } from "react";
import {
  FormControl,
  Autocomplete,
  Box,
  Button,
  TextField,
  Stack,
  Alert,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProyectos } from "store/inicioProyectos/IProyectos.slice";
import "../ActividadesCss.css";
import { myPageProyectos } from "../ProyectosIPage/ProyectosIPage";
import {
  editProyect,
  selectProyectoById,
} from "store/inicioProyectos/IProyectos.slice";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  getInstitucionesTodas,
  selectInstitucionTodas,
} from "store/Institucion/institucion.slice";
import {
  getTiposProyecto,
  selectTiposProyecto,
} from "store/tiposProyecto/tiposProyectos.slice";
import {
  getModalidadesProyecto,
  selectModalidadesProyecto,
} from "store/modalidadesProyecto/modalidadesProyecto.slice";
import {
  getEstadosProyecto,
  selectEstadosProyecto,
} from "store/estadosProyecto/estadosProyecto.slice";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { proyectoss } from "views/validaciones/validaciones";
import { showSnackbar } from "store/mensajeria/mensajeriaSlice";
import dayjs from "dayjs";
import "dayjs/locale/es";

export default function ProyectosIForm({ itemProy, onClose }) {

  // console.log('itemProy en ProyectosIForm', itemProy)

  const dispatch = useDispatch();
  const page = useContext(myPageProyectos);
  const proyect = useSelector(selectProyectoById);

  //--------------------------------Insituciones--------------------------------------------//

  const [institucionesHook, setInstitucionesHook] = useState([]);
  const GetInstituciones = useSelector(selectInstitucionTodas);
  const [valorinstitucionesHook, setvalorinstitucionesHook] = useState(
    itemProy?.nombreInstitucion
  );

  //--------------------------------TiposProyecto-------------------------------------------//
  const [tiposProyectosHook, setTiposProyectosHook] = useState([]);
  const GetTiposProyecto = useSelector(selectTiposProyecto);
  const [valorTipoProyectoHook, setvalorTipoProyectoHook] = useState(
    itemProy?.tipoProyecto
  );
  //--------------------------------ModalidadProyecto---------------------------------------//

  const [modalidadProyectosHook, setModalidadProyectosHook] = useState([]);
  const GetModalidadProyecto = useSelector(selectModalidadesProyecto);
  const [valorModalidaProyectoHook, setvalorModalidaProyectoHook] = useState(
    itemProy?.modalidadProyecto
  );
  //--------------------------------EstadosProyecto-----------------------------------------//

  const [estadosProyectosHook, setEstadosProyectosHook] = useState([]);
  const GetEstadosProyecto = useSelector(selectEstadosProyecto);
  const [valorEstadoProyectosHook, setvalorEstadoProyectosHook] = useState(itemProy?.estadoProyecto);
  const [banderaNoAprobado, setBanderaNoAprobado] = useState(false)

  useEffect(() => {
    let ListaInstitucion = GetInstituciones?.map((x) => ({
      label: x.nombre,
      id: x.idInstitucion,
    }));
    setInstitucionesHook(ListaInstitucion);

    let ListaTipoProyectos = GetTiposProyecto.map((x) => ({
      label: x.denominacion,
      id: x.idTipoProyecto,
    }));
    setTiposProyectosHook(ListaTipoProyectos);

    let ListaModalidadProyectos = GetModalidadProyecto?.map((x) => ({
      label: x.denominacion,
      id: x.idModalidadProyecto,
    }));
    setModalidadProyectosHook(ListaModalidadProyectos);

    let ListaEstadosProyectos = GetEstadosProyecto.map((x) => ({
      label: x.descripcion,
      id: x.idEstadoProyecto,
    }));
    setEstadosProyectosHook(ListaEstadosProyectos);
  }, [
    GetInstituciones,
    GetTiposProyecto,
    GetModalidadProyecto,
    GetEstadosProyecto,
  ]);
  /////-----------------------------------------------------///
  useEffect(() => {
    dispatch(getInstitucionesTodas());
    // llamar tipo de proyecto
    dispatch(getTiposProyecto());
    // llamar modalidad de proyecto
    dispatch(getModalidadesProyecto());
    //llamar estdado de proyecto
    dispatch(getEstadosProyecto());
    //dispatch(getProyectoById(itemProy.idProyecto));
  }, [dispatch]);

  //Logica para obtener la resolucion segun la denominacion

  //Logica para enviar formulario al backend
  const handleSubmit = (value) => {
    value.idInstitucion = value.idInstitucion ?? 0;
    value.idModalidadProyecto = value.idModalidadProyecto ?? 0;
    value.idTipoProyecto = value.idTipoProyecto ?? 0;
    value.idEstadoProyecto = value.idEstadoProyecto ?? 0;
    value.fechaInicio = value.fechaInicio || proyect?.fechaInicio;
    value.fechaFin = value.fechaFin || proyect?.fechaFin;
    value.horasReloj =
      ((value.horasCatedra * 2) / 3).toFixed(2) ??
      0; /* La Hora Reloj es 2/3 de una Hora Cátedra. */

    if (value.conEvaluacion === "Si") {
      value.conEvaluacion = "S";
    } else if (value.conEvaluacion === "No") {
      value.conEvaluacion = "N";
    }

    dispatch(editProyect(value)).then((resultAction) => {
      if (editProyect.fulfilled.match(resultAction))
        dispatch(showSnackbar(resultAction.payload.message));
    });

    setTimeout(() => {
      dispatch(getProyectos(page ? page : 1));
    }, 400);
  };

  const handleClose = (event, reason) => {
    onClose();
  };

  const Evaluacion = [{ label: "Si" }, { label: "No" }];

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <Formik
        initialValues={{
          idProyecto: itemProy?.idProyecto ?? "",
          idInstitucion: itemProy?.idInstitucion ?? 0,
          denominacion: itemProy?.denominacion ?? "",
          idModalidadProyecto: itemProy?.idModalidadProyecto ?? 0,
          fechaInicio: itemProy?.fechaInicio
            ? dayjs(
              new Date(itemProy?.fechaInicio).toLocaleDateString("es-ES", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              }),
              "DD/MM/YYYY"
            )
            : null,
          fechaFin: itemProy?.fechaFin
            ? dayjs(
              new Date(itemProy?.fechaFin).toLocaleDateString("es-ES", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              }),
              "DD/MM/YYYY"
            )
            : null,
          idTipoProyecto: itemProy?.idTipoProyecto ?? 0,
          destinadoA: itemProy?.destinadoA ?? "",
          conEvaluacion: itemProy?.conEvaluacion === "S" ? "Si" : "No",
          horasCatedra: itemProy?.horasCatedra ?? "",
          horasReloj: itemProy?.horasReloj ?? "",
          idEstadoProyecto: itemProy?.idEstadoProyecto ?? 0,
          deshabilitado: itemProy?.deshabilitado ?? "",
        }}
        /* Aqui se obtienen los datos que se ingresó en el formulario */
        onSubmit={handleSubmit}
        /* Configuracion de Formik para agregar las validaciones */
        validationSchema={proyectoss}
      >
        {({
          values,
          errors,
          handleChange,
          touched,
          setFieldValue,
          submitForm,
          dirty,
        }) => (
          <Box component="form">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    value={valorinstitucionesHook}
                    onChange={(event, newValue) => {
                      setFieldValue(
                        "idInstitucion",
                        newValue !== null ? newValue.id : ""
                      );
                      setvalorinstitucionesHook(newValue?.label);
                    }}
                    id="idInstitucion"
                    options={institucionesHook}
                    name="idInstitucion"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Institución"
                        margin="normal"
                        variant="outlined"
                        autoComplete="off"
                        InputProps={{ ...params.InputProps, type: "search" }}
                        error={
                          Boolean(errors.idInstitucion) && touched.idInstitucion
                        }
                        sx={{
                          "input[type='search']::-webkit-search-cancel-button":
                          {
                            display: "none",
                          },
                        }}
                        helperText={
                          Boolean(errors.idInstitucion) &&
                          touched.idInstitucion &&
                          errors.idInstitucion
                        }
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    label="Denominación"
                    type="text"
                    name="denominacion"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    value={values.denominacion}
                    error={Boolean(errors.denominacion && touched.denominacion)}
                    helperText={
                      Boolean(errors.denominacion) &&
                      touched.denominacion &&
                      errors.denominacion
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "-7px" }}>
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    value={valorTipoProyectoHook ?? ""}
                    onChange={(event, newValue) => {
                      setFieldValue(
                        "idTipoProyecto",
                        newValue !== null ? newValue.id : ""
                      );
                      setvalorTipoProyectoHook(newValue?.label);
                    }}
                    id="idTipoProyecto"
                    options={tiposProyectosHook}
                    name="idTipoProyecto"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Tipo de proyecto"
                        margin="normal"
                        variant="outlined"
                        autoComplete="off"
                        InputProps={{ ...params.InputProps, type: "search" }}
                        error={
                          Boolean(errors.idTipoProyecto) &&
                          touched.idTipoProyecto
                        }
                        sx={{
                          "input[type='search']::-webkit-search-cancel-button":
                          {
                            display: "none",
                          },
                        }}
                        helperText={
                          Boolean(errors.idTipoProyecto) &&
                          touched.idTipoProyecto &&
                          errors.idTipoProyecto
                        }
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth style={{ marginTop: "-16px" }}>
                  <Autocomplete
                    disablePortal
                    value={valorModalidaProyectoHook}
                    onChange={(event, newValue) => {
                      setFieldValue(
                        "idModalidadProyecto",
                        newValue !== null ? newValue.id : ""
                      );
                      setvalorModalidaProyectoHook(newValue?.label);
                    }}
                    id="idModalidadProyecto"
                    options={modalidadProyectosHook}
                    name="idModalidadProyecto"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Modalidad de proyecto"
                        margin="normal"
                        variant="outlined"
                        autoComplete="off"
                        InputProps={{ ...params.InputProps, type: "search" }}
                        error={
                          Boolean(errors.idModalidadProyecto) &&
                          touched.idModalidadProyecto
                        }
                        sx={{
                          "input[type='search']::-webkit-search-cancel-button":
                          {
                            display: "none",
                          },
                        }}
                        helperText={
                          Boolean(errors.idModalidadProyecto) &&
                          touched.idModalidadProyecto &&
                          errors.idModalidadProyecto
                        }
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <DatePicker
                    label="Fecha de inicio"
                    name="fechaInicio"
                    value={values.fechaInicio}
                    onChange={(value) => setFieldValue("fechaInicio", value)}
                    slotProps={{
                      textField: {
                        helperText:
                          Boolean(errors.fechaInicio) &&
                          touched.fechaInicio &&
                          errors.fechaInicio,
                        error:
                          Boolean(errors.fechaInicio) &&
                          Boolean(touched.fechaInicio),
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <DatePicker
                    label="Fecha de fin"
                    name="fechaFin"
                    value={values.fechaFin}
                    onChange={(value) => setFieldValue("fechaFin", value)}
                    slotProps={{
                      textField: {
                        helperText:
                          Boolean(errors.fechaFin) &&
                          touched.fechaFin &&
                          errors.fechaFin,
                        error:
                          Boolean(errors.fechaFin) && Boolean(touched.fechaFin),
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  sx={{
                    mt: 1,
                  }}
                >
                  <TextField
                    fullWidth
                    id="destinadoA"
                    name="destinadoA"
                    label="Destinado a"
                    multiline
                    rows={3}
                    value={values.destinadoA}
                    onChange={handleChange}
                    helperText={
                      Boolean(errors.destinadoA) &&
                      touched.destinadoA &&
                      errors.destinadoA
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth style={{ marginTop: "-7px" }}>
                  <Autocomplete
                    disablePortal
                    onChange={(event, newValue) => {
                      setFieldValue(
                        "conEvaluacion",
                        newValue !== null ? newValue.label : ""
                      );
                    }}
                    id="conEvaluacion"
                    value={values.conEvaluacion}
                    options={Evaluacion}
                    name="conEvaluacion"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Evaluación"
                        margin="normal"
                        variant="outlined"
                        autoComplete="off"
                        InputProps={{ ...params.InputProps, type: "search" }}
                        error={
                          Boolean(errors.conEvaluacion) && touched.conEvaluacion
                        }
                        sx={{
                          "input[type='search']::-webkit-search-cancel-button":
                          {
                            display: "none",
                          },
                        }}
                        helperText={
                          Boolean(errors.conEvaluacion) &&
                          touched.conEvaluacion &&
                          errors.conEvaluacion
                        }
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    label="Horas cátedra"
                    type="number"
                    name="horasCatedra"
                    variant="outlined"
                    fullWidth
                    value={values.horasCatedra}
                    onChange={handleChange}
                    error={Boolean(errors.horasCatedra && touched.horasCatedra)}
                    helperText={
                      Boolean(errors.horasCatedra) &&
                      touched.horasCatedra &&
                      errors.horasCatedra
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth style={{ marginTop: "-7px" }}>
                  <Autocomplete
                    disablePortal
                    value={valorEstadoProyectosHook}
                    onChange={(event, newValue) => {
                      if (!(newValue?.id === 1 || newValue?.id === 2)) {
                        setFieldValue("idEstadoProyecto", newValue !== null ? newValue.id : "");
                        setvalorEstadoProyectosHook(newValue?.label);
                        setBanderaNoAprobado(false);
                      }
                      else {
                        if ((itemProy?.cantNiveles > 0 && itemProy?.cantResponsables > 0)) {
                          setFieldValue("idEstadoProyecto", newValue !== null ? newValue.id : "");
                          setvalorEstadoProyectosHook(newValue?.label);
                          setBanderaNoAprobado(false);
                        } else {
                          setBanderaNoAprobado(true);
                          setFieldValue("idEstadoProyecto", "");
                          setvalorEstadoProyectosHook(newValue?.label);
                        }
                      }
                    }}
                    id="idEstadoProyecto"
                    options={estadosProyectosHook}
                    name="idEstadoProyecto"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Estado de proyecto"
                        margin="normal"
                        variant="outlined"
                        autoComplete="off"
                        InputProps={{ ...params.InputProps, type: "search" }}
                        error={
                          Boolean(errors.idEstadoProyecto) &&
                          touched.idEstadoProyecto
                        }
                        sx={{
                          "input[type='search']::-webkit-search-cancel-button":
                          {
                            display: "none",
                          },
                        }}
                        helperText={
                          Boolean(errors.idEstadoProyecto) &&
                          touched.idEstadoProyecto &&
                          errors.idEstadoProyecto
                        }
                      />
                    )}
                  />
                </FormControl>
                {banderaNoAprobado &&
                  <Alert severity="warning" style={{ margin: "7px 0px" }}>
                    ¡No se puede aprobar un proyecto sin Responsables y sin Niveles de Enseñanza!
                  </Alert>}
                <Alert severity="info">
                  ¡Sólo los proyectos "APROBADOS" pueden generar Actividades!
                </Alert>
              </Grid>
              {/* {console.log('values', values)} */}

            </Grid>

            <Stack
              spacing={2}
              direction="row"
              sx={{ display: "flex", justifyContent: "flex-end", mt: 3, mb: 2 }}
            >
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleClose()}
                spacing={2}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                disabled={!dirty}
                onClick={() => submitForm()}
              >
                Guardar
              </Button>
            </Stack>
          </Box>
        )}
      </Formik>
    </LocalizationProvider>
  );
}
