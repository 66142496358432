import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  GetResponsabilidad,
} from "./responsabilidades.api";

const initialState = {
  entities: [],
  message: {},
  response: {},
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
};

export const GetResponsabilidadesEndPoint = createAsyncThunk(
  "responsabilidades/GetResponsabilidad", // Get Paginado para Pestaña "Actividades".
  async () => {
    const r = await GetResponsabilidad();
    return r;
  }
);

export const responsabilidadesSlice = createSlice({
  name: "responsabilidades",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // GetResposabilidadesEndPoint

      .addCase(GetResponsabilidadesEndPoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetResponsabilidadesEndPoint.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(GetResponsabilidadesEndPoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});

export const selectResponsabilidades = (state) => state.responsabilidades.entities;
export const selectActividadesResponse = (state) => state.responsabilidades.response;
export const selectIsLoading = (state) => state.responsabilidades.loading;
export const selectIsCreating = (state) => state.responsabilidades.creating;
export const selectIsEditing = (state) => state.responsabilidades.editing;
export const selectIsDeleting = (state) => state.responsabilidades.deleting;
export const selectError = (state) => state.responsabilidades.error;

export default responsabilidadesSlice.reducer;
