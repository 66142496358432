import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import logo from "./Logo.png";
import {
  getProyectosConNiveles,
  selectProyectos,
} from "store/proyectos/proyectos.slice";
import { GetObservacionEndPoint } from "store/observaciones/observaciones.slice";
import ObservacionesNuevo from "../ProyectosPage/ObservacionesTable/ObservacionesNuevo";
import ObservacionesTable from "../ProyectosPage/ObservacionesTable/ObservacionesTable";
import {
  Autocomplete,
  Box,
  Button,
  Paper,
  TableContainer,
  TextField,
  Grid,
  FormControl,
  Hidden,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  GetIdResoluciones,
  selectResolucionesXProyecto,
} from "store/Resoluciones/resoluciones.slice";
import Proy_ResponsablesPage from "../Proy_responsablePage/Proy_ResponsablesPage";
import Proy_NivelesEnsenanzaPage from "../Proy_NivelesEnsenanzaPage/NivelesEnsenanzaPage";
import { GetResponsablesEndPoint } from "store/capacitadores/capacitadores.slice";
import { formatDDMMYY } from "utils/helpers/dateFormat";

const TablaProyecto = () => {
  const dispatch = useDispatch();
  const [listaDenominacionesHook, setListaDenominacionesHook] = useState([]);
  const [denominacion, setDenominacion] = useState(null);
  const listaDenominaciones = useSelector(selectProyectos);
  const [mostrarInfo, setMostrarInfo] = useState(false);
  const resoluciones = useSelector(selectResolucionesXProyecto);
  const [resolucion, setResolucion] = useState([]);
  const [itemProy, SetItemProy] = useState([]);
  const validationSchema = yup.object({
    denominacion: yup.object().required("Debe seleccionar una denominación"),
  });

  const formik = useFormik({
    initialValues: {
      denominacion: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleFormSubmit(values.denominacion);
    },
  });

  function handleFormSubmit(value) {
    if (value !== null) {
      let objeto = listaDenominaciones.find((x) => x.idProyecto === value.id);
      dispatch(GetObservacionEndPoint(objeto.idProyecto));
      dispatch(GetIdResoluciones(objeto.idProyecto)); //
      SetItemProy(objeto);

      setDenominacion(objeto);
    } else {
      setDenominacion(null);
    }
  }

  useEffect(() => {
    resoluciones && Array.isArray(resoluciones.data)
      ? setResolucion(resoluciones.data[0])
      : setResolucion("Vacio");
  }, [resoluciones]);

  useEffect(() => {
    let lista = listaDenominaciones.map((x) => ({
      label: x.denominacion + "- FIni: " + formatDDMMYY(x.fechaInicio),
      id: x.idProyecto,
    }));

    setListaDenominacionesHook(lista);
  }, [listaDenominaciones]);

  const handleClickOpen = () => {
    dispatch(GetResponsablesEndPoint(itemProy?.idProyecto || 0));
    dispatch(getProyectosConNiveles(itemProy?.idProyecto || 0));
  };

  return (
    <TableContainer component={Paper}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        mt={4}
      >
        {/* <Grid item>
          <Hidden xsUp>
            <img src={logo} alt="Logo Dir Planeamiento" />{" "}
          </Hidden>
          <Hidden mdDown>
            <img src={logo} alt="Logo Dir Planeamiento" />
          </Hidden>
        </Grid> */}
      </Grid>

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={12} md={8}>
            <label style={{ fontWeight: "bold" }}>
              Ingrese la Denominación:
            </label>
            <Autocomplete
              disablePortal
              inputProps={{ style: { textAlign: "center" } }}
              sx={{ textAlign: "center", marginTop: "0.5rem" }}
              id="combo-box-demo"
              multiline
              options={listaDenominacionesHook}
              getOptionLabel={(option) => option.label}
              value={formik.values.denominacion}
              onChange={(event, newValue) => {
                formik.setFieldValue("denominacion", newValue);
                handleFormSubmit(newValue);
                setMostrarInfo(false);
              }}
              onBlur={formik.handleBlur}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Denominación"
                  error={
                    formik.touched.denominacion &&
                    Boolean(formik.errors.denominacion)
                  }
                  helperText={
                    formik.touched.denominacion && formik.errors.denominacion
                  }
                />
              )}
            />
            <Box sx={{ textAlign: "right" }}>
              <Button
                sx={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}
                variant="contained"
                type="submit"
                onClick={() => {
                  setMostrarInfo(true);
                  handleClickOpen();
                }}
              >
                Buscar
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>

      {denominacion !== null && mostrarInfo ? (
        <form style={{ marginTop: "1rem" }}>
          <Grid container spacing={0.5} justifyContent="center">
            <Grid item md={10}>
              <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <p
                      htmlFor="institucion-label"
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      Institución:
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={6.7}>
                    <TextField
                      id="institucion-label"
                      value={denominacion?.nombreInstitucion}
                      readOnly
                      multiline
                      style={{
                        textAlign: "center !important",
                        width: "100%",
                      }}
                    />
                  </Grid>
                </Grid>
              </FormControl>

              <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <p
                      htmlFor="institucion-label"
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      Tipo de Proyecto:
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={6.7}>
                    <TextField
                      id="tipoProyecto-label"
                      value={denominacion?.tipoProyecto}
                      readOnly
                      multiline
                      style={{ textAlign: "right", width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </FormControl>

              <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <p
                      htmlFor="institucion-label"
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      Modalidad de Proyecto:
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={6.7}>
                    <TextField
                      id="modalidadProyecto-label"
                      value={denominacion?.modalidadProyecto}
                      readOnly
                      multiline
                      style={{ textAlign: "right", width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </FormControl>

              <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <p
                      htmlFor="institucion-label"
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      Fecha Inicio:
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={6.7}>
                    <TextField
                      id="fechaInicio-label"
                      value={
                        denominacion?.fechaInicio
                          ? new Date(
                              denominacion.fechaInicio
                            ).toLocaleDateString("es-ES", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })
                          : ""
                      }
                      readOnly
                      style={{ textAlign: "right", width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </FormControl>

              <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <p
                      htmlFor="institucion-label"
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      Fecha Fin:
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={6.7}>
                    <TextField
                      id="fechaFin-label"
                      value={
                        denominacion?.fechaFin
                          ? new Date(denominacion.fechaFin).toLocaleDateString(
                              "es-ES",
                              {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              }
                            )
                          : ""
                      }
                      readOnly
                      style={{ textAlign: "right", width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </FormControl>

              <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <p
                      htmlFor="institucion-label"
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      Destinado a:
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={6.7}>
                    <TextField
                      id="destinadoA-label"
                      multiline
                      value={denominacion?.destinadoA || "NO FUE ASIGNADA"}
                      readOnly
                      style={{ textAlign: "right", width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </FormControl>

              <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <p
                      htmlFor="institucion-label"
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      Horas Cátedra:
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={6.7}>
                    <TextField
                      id="horasCatedra-label"
                      value={denominacion?.horasCatedra}
                      readOnly
                      multiline
                      style={{ textAlign: "right", width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </FormControl>

              <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <p
                      htmlFor="institucion-label"
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      Estado de Proyecto:
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={6.7}>
                    <TextField
                      id="estadoProyecto-label"
                      value={denominacion?.estadoProyecto}
                      readOnly
                      multiline
                      style={{ textAlign: "right", width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </FormControl>

              <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={4}>
                    <p
                      htmlFor="institucion-label"
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      Resolución:
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={6.7}>
                    <TextField
                      id="resolucion-label"
                      value={
                        resolucion.descripcion ||
                        "SIN RESOLUCIÓN MOMENTANEAMENTE"
                      }
                      readOnly
                      multiline
                      style={{ textAlign: "right", width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>

            <Grid item xs={12} style={{ margin: "15px 0px" }}>
              <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
                Equipo Responsable:
              </h3>
              <Proy_ResponsablesPage itemProy={itemProy} />
            </Grid>

            <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
              Niveles de Enseñanza involucrados:
            </h3>
            <Grid container spacing={0.5} justifyContent="center">
              <Grid item md={5}>
                <Proy_NivelesEnsenanzaPage itemProy={itemProy} />
              </Grid>
            </Grid>

            <Grid item md={9}>
              <h3 style={{ textAlign: "center" }}>
                <u>Observaciones</u>
              </h3>
            </Grid>
            <Grid item xs={12} style={{ margin: "5px 0px 15px 0px" }}>
              <ObservacionesNuevo itemProy={itemProy} />
            </Grid>
            <Grid item xs={12} style={{ margin: "15px 0px" }}>
              <ObservacionesTable IdProyecto={denominacion.idProyecto} />
            </Grid>
          </Grid>
        </form>
      ) : null}
    </TableContainer>
  );
};

export default TablaProyecto;
