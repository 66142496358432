import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import {
  FiltrarActividadEndPoint,
  SaveActividadEndpoint,
  selectActividadesResponse,
} from "store/actividades/actividades.slice";
import ActividadesForm from "./ActividadesForm";
import CustomModal from "components/customModal/CustomModal";

export default function ActividadNuevo() {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const sActividadesResponse = useSelector(selectActividadesResponse);

  useEffect(() => {
    if (sActividadesResponse?.data?.success) {
      setOpen(false);
      dispatch(FiltrarActividadEndPoint());
    }
  }, [dispatch, sActividadesResponse]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmitAct = (data) => {
    dispatch(SaveActividadEndpoint(data));
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen}>
        Nuevo
      </Button>
      <CustomModal
        title={"Agregar Actividad"}
        open={open}
        setOpen={handleClose}
        maxWidth="sm"
      >
        <DialogContent>
          <ActividadesForm onSubmit={handleSubmitAct} onClose={handleClose} />
        </DialogContent>
      </CustomModal>
    </React.Fragment>
  );
}
