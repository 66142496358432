import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SnackBarUtils from "utils/MUI/SnackbarUtils";
import GroupIcon from '@mui/icons-material/Group';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import {
  GetCapacitadorEndPoint,
  //estadoInicial,
  selectCapacitadores,
  selectCapacitadoresResponse,
} from "store/capacitadores/capacitadores.slice";
import CapacitadoresNuevo from "../CapacitadoresNuevo/CapacitadoresNuevo";
import CapacitadoresTable from "../CapacitadoresTable/CapacitadoresTable";
import CapacitadoresTituloModal from "../CapacitadoresTituloModal";
import { SnackBarMessageResponse } from "utils/Response/SnackBarMessageResponse";
import { useIdRol } from "utils/roles/IdRolContext";

const CapacitadoresPage = ({ itemAct, colorCap }) => {

  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const GetCapacitadores = useSelector(selectCapacitadores);
  const { rolName } = useIdRol();

  const [message, setMessage] = useState("");
  const [view, setView] = useState(false);
  const [viewMessage, setViewMessage] = useState(false);
  const capacitador = useSelector(selectCapacitadoresResponse);
  
  const handleClickOpen = () => {
    setOpen(true);
    dispatch(GetCapacitadorEndPoint(itemAct?.idActividad || 0));             // No lleva paginación.
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (Array.isArray(capacitador.message) && viewMessage) {
      setView(true);
      setMessage(SnackBarMessageResponse(capacitador));
    }

    setViewMessage(true);
  }, [capacitador]);
  const muestroTabla = () => {
    if (GetCapacitadores?.data?.length !== 0) {                  // Capacitadores para una determinada Actividad.
      return <CapacitadoresTable itemAct={itemAct} datosCap={GetCapacitadores?.data || []} />   
    }
    else {
      return <p style={{
        textAlign: 'center',
        marginBottom: '30px',
      }}> Sin Capacitadores asignados. </p>
    }
  }

  return (
    <React.Fragment>
      <IconButton variant="outlined" onClick={handleClickOpen}>
        <GroupIcon style={{color: colorCap}}/>
      </IconButton>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle >
          <b style={{ marginLeft: "12px" }}>Capacitadores</b>
        </DialogTitle>
        <DialogContent>
          <CapacitadoresTituloModal itemAct={itemAct || []} />
          {rolName !== "institucionOferente" && rolName !== "capacitadores" && rolName !== "docente" && 
          <CapacitadoresNuevo itemAct={itemAct} />}
          <div style={{ marginBottom: "15px" }} />
          {muestroTabla()}                                     {/* Muestro la Tabla sólo si hay Capacitadores asignados. */}
        </DialogContent>
      </Dialog>
      <SnackBarUtils
        closed={(value) => setView(value)}
        message={message}
        view={view}
      />
    </React.Fragment>
  );
};

export default CapacitadoresPage;
