import { request } from "utils/helpers/request.helpers";

const proyectosUrl = `${process.env.REACT_APP_API_URL_DEV}/api/Proyecto`;

export function fetchProyectos(page, search) {
  const url = `${proyectosUrl}/FiltrarProyectoPaginado?pageNumber=${page}&pBusqueda=${search}`;
  return request(url, { method: "GET" });
}

export function fetchProyectoById(id) {
  return request(`${proyectosUrl}/ObtenerProyecto_ByID?id=${id}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
}

export function createProyecto(data) {
  return request(`${proyectosUrl}/InsertarProyecto`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function editProyecto(data) {
  return request(`${proyectosUrl}/ModificarProyecto`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

export async function deleteProyecto(id) {
  return request(`${proyectosUrl}/Deshabilitar?idProyecto=${id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
  });
}

export function ListarProyectos() {
  return request(`${proyectosUrl}/ListarProyectos`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
}

export function createNivelesProy(data) {
  return request(`${proyectosUrl}/CreateRelProyectoNivel`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function deleteNivelesProy([id, idnivel]) {
  return request(
    `${proyectosUrl}/DeleteRelProyectoNivel?idProyecto=${id}&idNivel=${idnivel}`,
    {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify([id, idnivel]),
    }
  );
}

export function FiltrarProyecto(data) {
  return request(`${proyectosUrl}/FiltrarProyectos?pBusqueda=${data}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
}
