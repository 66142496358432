import React, { useContext } from "react";
import { useDispatch } from "react-redux";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

import {
  GetCapacitadorEndPoint,
  RemoveCapacitadorEndPoint,
} from "store/capacitadores/capacitadores.slice";
import { GetActividadEndPoint } from "store/actividades/actividades.slice";
import { ActividadesContext } from "views/Actividades/ActividadesPage/ActividadesPage";

export default function CapacitadorEliminar({ itemAct, itemCap }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const page = useContext(ActividadesContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    setOpen(false);
    dispatch(RemoveCapacitadorEndPoint(itemCap.idCapacitador));

    setTimeout(() => {
      //Actualizamos Tabla de Capacitadores, para
      dispatch(GetCapacitadorEndPoint(itemAct?.idActividad || 0)); //una determinada Actividad.
    }, 400);

    setTimeout(() => {
      //Actualizamos Tabla de Actividades
      dispatch(GetActividadEndPoint(page ? page : 1)); //Sabemos en tiempo real la cantidad de Capacitadores
    }, 400); //para una determinada Actividad.
  };

  return (
    <React.Fragment>
      <IconButton variant="outlined" onClick={handleClickOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog open={open}>
        <DialogTitle> Eliminar Capacitador </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Estás seguro que deseas desestimar al capacitador{" "}
            <b>"{itemCap?.persona?.apellidoyNombre}"</b> de esta Actividad?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}> Cancelar </Button>
          <Button onClick={handleSubmit} autoFocus>
            {" "}
            Aceptar{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
