// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container {
    overflow-x: auto;
  }
  
  table {
    border-collapse: collapse;
    width: 100%;
  }
  
  th {
    background-color: #f2f2f2;
    font-weight: bold;
    text-align: left;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  tr:nth-child(odd) {
    background-color: #fff;
  }
  
  td {
    padding: 8px;
    border: 1px solid #dddddd;
    text-align: left;
  }
  
  @media (max-width: 768px) {
    table {
      border-collapse: collapse;
      width: 100%;
    }
    th, td {
      padding: 6px;
    }
  }
  
  @media (max-width: 576px) {
    th, td {
      padding: 4px;
      font-size: 12px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/template/styles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;IACzB,WAAW;EACb;;EAEA;IACE,yBAAyB;IACzB,iBAAiB;IACjB,gBAAgB;IAChB,YAAY;EACd;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,YAAY;IACZ,yBAAyB;IACzB,gBAAgB;EAClB;;EAEA;IACE;MACE,yBAAyB;MACzB,WAAW;IACb;IACA;MACE,YAAY;IACd;EACF;;EAEA;IACE;MACE,YAAY;MACZ,eAAe;IACjB;EACF","sourcesContent":[".table-container {\n    overflow-x: auto;\n  }\n  \n  table {\n    border-collapse: collapse;\n    width: 100%;\n  }\n  \n  th {\n    background-color: #f2f2f2;\n    font-weight: bold;\n    text-align: left;\n    padding: 8px;\n  }\n  \n  tr:nth-child(even) {\n    background-color: #f2f2f2;\n  }\n  \n  tr:nth-child(odd) {\n    background-color: #fff;\n  }\n  \n  td {\n    padding: 8px;\n    border: 1px solid #dddddd;\n    text-align: left;\n  }\n  \n  @media (max-width: 768px) {\n    table {\n      border-collapse: collapse;\n      width: 100%;\n    }\n    th, td {\n      padding: 6px;\n    }\n  }\n  \n  @media (max-width: 576px) {\n    th, td {\n      padding: 4px;\n      font-size: 12px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
