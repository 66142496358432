import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  fetchInstituciones,
  fetchProyectos,
  fetchActividades,
  cargaExcel,
  envioCargaExtra,
} from "../../store/cargaExtra/cargaExterna.slice";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Button,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import "./styles.css";
import { toast } from "react-toastify";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function CargaExternaPage() {
  const dispatch = useDispatch();
  const [instituciones, setInstituciones] = useState([]);
  const [proyectos, setProyectos] = useState([]);
  const [actividades, setActividades] = useState([]);
  const [institucionId, setInstitucionId] = useState(null);
  const [proyectoId, setProyectoId] = useState(null);
  const [archivoExcel, setArchivoExcel] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [actividadId, setActividadId] = useState(null);
  const [registrosFallidos, setRegistrosFallidos] = useState([]);

  useEffect(() => {
    dispatch(fetchInstituciones())
      .then((response) => {
        setInstituciones(response.payload);
      })
      .catch((error) => {
        console.error("Error al obtener instituciones:", error);
      });
  }, []);

  useEffect(() => {
    if (institucionId) {
      dispatch(fetchProyectos(institucionId)).then((response) => {
        setProyectos(response.payload);
      });
    }
  }, [institucionId]);

  useEffect(() => {
    if (proyectoId) {
      dispatch(fetchActividades(proyectoId)).then((response) => {
        setActividades(response.payload);
      });
    }
  }, [proyectoId]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const fileName = selectedFile.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();

      if (fileExtension === "xlsx" || fileExtension === "xls") {
        setArchivoExcel(selectedFile);
        console.log("Archivo Excel válido:", fileName);
      } else {
        console.error("El archivo seleccionado no es un archivo Excel válido.");
      }
    }
  };

  const enviarArchivo = () => {
    if (archivoExcel && proyectoId && actividadId) {
      setRegistrosFallidos([]);
      console.log("dataaa a enviar", archivoExcel, proyectoId, actividadId);
      dispatch(
        cargaExcel({
          idProyecto: proyectoId,
          idActividad: actividadId,
          archivoExcel: archivoExcel,
        })
      )
        .then((response) => {
          // Aquí puedes hacer cualquier otra cosa que necesites con los datos devueltos por el endpoint
          if (
            response.payload.message &&
            response.payload.message[0].status === 400
          ) {
            toast.warning(
              "Los datos no van a procesar porque el archivo presenta inconsistencias, por favor chequear"
            );
            setRegistrosFallidos(
              response.payload.data.dataList.registrosfailed
            );
          } else {
            toast.success("Exito al cargar el archivo");
          }
        })
        .catch((error) => {
          console.error("Error al enviar archivo:", error);
        });

      setArchivoExcel(null);
      // setShowSuccessAlert(true);
      // setShowErrorAlert(false);
    } else {
      console.error(
        "Por favor, selecciona un archivo Excel y asegúrate de elegir un proyecto y actividad."
      );
      setShowSuccessAlert(false);
      setShowErrorAlert(true);
    }
  };

  return (
    <div className="container">
      <div style={{ display: "flex", alignItems: "left" }}>
        <h1 style={{ margin: "10px 0" }}>Carga Externa</h1>
      </div>
      <div className="component-container">
        <div className="text-placeholder">Selecciona una Institución:</div>
        <Autocomplete
          disablePortal
          id="combo-box-institucion"
          options={instituciones || []}
          getOptionLabel={(option) => option.nombre || ""}
          getOptionSelected={(option, value) =>
            option.idInstitucion === value.idInstitucion
          }
          renderInput={(params) => (
            <TextField {...params} label="Instituciones" />
          )}
          onChange={(event, newValue) => {
            setInstitucionId(newValue ? newValue.idInstitucion : null);
            setProyectoId(null);
            setActividadId(null);
          }}
        />

        <div className="text-placeholder">Selecciona un Proyecto:</div>
        <Autocomplete
          disablePortal
          id="combo-box-proyecto"
          options={proyectos || []}
          getOptionLabel={(option) => option.denominacion || ""}
          value={
            proyectos && proyectoId !== null
              ? proyectos.find((option) => option.idProyecto === proyectoId)
              : null
          }
          renderInput={(params) => <TextField {...params} label="Proyectos" />}
          onChange={(event, newValue) => {
            setProyectoId(newValue ? newValue.idProyecto : null);
            setActividadId(null);
            setActividades([]);
          }}
          disabled={!institucionId}
        />

        <div className="text-placeholder">Selecciona una Actividad:</div>
        <Autocomplete
          disablePortal
          id="combo-box-actividad"
          options={
            actividades
              ? [
                  { descripcion: "Seleccionar si no encuentra su actividad" },
                  ...actividades,
                ]
              : [{ descripcion: "Seleccionar si no encuentra su actividad" }]
          }
          getOptionLabel={(option) => option.descripcion || ""}
          value={
            actividades && actividadId !== null
              ? actividades.find((option) => option.idActividad === actividadId)
              : null
          }
          renderInput={(params) => (
            <TextField {...params} label="Actividades" />
          )}
          onChange={(event, newValue) => {
            setActividadId(newValue ? newValue.idActividad : null);
          }}
          disabled={!proyectoId}
        />

        <Typography variant="h5" gutterBottom style={{ marginTop: "30px" }}>
          Importar Excel de Aprobados:
        </Typography>
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
          disabled={actividadId === null}
        >
          Importar Archivo
          <VisuallyHiddenInput
            type="file"
            onChange={handleFileChange}
            accept=".xlsx, .xls"
          />
        </Button>
        <Typography variant="h5" gutterBottom style={{ marginTop: "30px" }}>
          Subir Notas Aprobados
        </Typography>
        <Button
          style={{ marginTop: "5px" }}
          component="label"
          variant="contained"
          onClick={enviarArchivo}
          disabled={archivoExcel === null}
        >
          Enviar
        </Button>
      </div>

      {showSuccessAlert && (
        <div className="alert alert-success">La carga fue exitosa.</div>
      )}

      {showErrorAlert && (
        <div className="alert alert-error">La carga falló.</div>
      )}

      {registrosFallidos.length > 0 && (
        <div className="component-container" style={{ marginTop: "20px" }}>
          <div className="text-placeholder">Registros a revisar:</div>
          <TableContainer style={{ marginTop: "20px" }} component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Documento
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>CUIL</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Nombres</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Apellidos
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Sexo</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Tipo Documento
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Fecha Nacimiento
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Dato a chequear
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {registrosFallidos.map((registro) => (
                  <TableRow key={registro.documento}>
                    <TableCell>{registro.documento}</TableCell>
                    <TableCell>{registro.cuit}</TableCell>
                    <TableCell>{registro.nombres}</TableCell>
                    <TableCell>{registro.apellidos}</TableCell>
                    <TableCell>{registro.sexo}</TableCell>
                    <TableCell>{registro.tipoDocumento}</TableCell>
                    <TableCell>{registro.fechaNacimiento}</TableCell>
                    <TableCell>{registro.message}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
}
