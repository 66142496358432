import React, { createContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {
  getInstituciones,
  removeInstitucion,
  selectIsLoadingInstitucion,
  selectInstituciones,
  getSearchInstituciones,
  selectSearchResults,
  selectResponse,
} from "store/Institucion/institucion.slice";
import { GenericTemplate } from "template/GenericTemplate";
import InstitucionesForm from "./InstitucionesForm";
import Loader from "components/Loader/Loader";

export const myPageInstitucion = createContext(1);

export default function InstitucionPage() {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoadingInstitucion);
  const response = useSelector(selectResponse);
  const searchResults = useSelector(selectSearchResults);
  const [page, setPage] = useState(1);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    if (!isSearching) {
      dispatch(getInstituciones(page));
    }
  }, [dispatch, page, isSearching, response]);

  const handleSearch = (e) => {
    if (e.code !== "Enter") return;
    if (e.target.value.trim() === "") {
      setIsSearching(false);
      window.location.reload(true);
    } else {
      dispatch(getSearchInstituciones(e.target.value));
      setIsSearching(true);
    }
  };

  const fetchInstituciones = useSelector(selectInstituciones);

  let tablaInstituciones =
    isSearching && searchResults
      ? searchResults
      : fetchInstituciones.records || [];

  const convertInstituciones = tablaInstituciones.map((institucion) => ({
    ID: institucion.idInstitucion,
    Nombre: institucion.nombre,
    Carácter: institucion.caracteres?.descripcion,
    Dependencia: institucion.dependencias?.descripcion,
    "Dirección Legal": institucion.domicilioLegal,
    "Cod. Postal": institucion.codigoPostal,
    Teléfono: institucion.telefono,
    Email: institucion.correoElectronico,
  }));

  const dataEmpty = [
    {
      ID: "",
      Nombre: "",
      Carácter: "",
      Dependencia: "",
      "Dirección Legal": "",
      "Cod. Postal": "",
      Teléfono: "",
      Email: "",
    },
  ];

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <myPageInstitucion.Provider value={page}>
        <React.Fragment>
          {isLoading && <Loader />}
          <GenericTemplate
            titleName="Instituciones"
            handleSearch={handleSearch}
            dataTable={
              convertInstituciones.length === 0
                ? dataEmpty
                : convertInstituciones
            }
            Form={InstitucionesForm}
            deleteFuncion={removeInstitucion}
            siEdit={convertInstituciones.length !== 0}
            siDelete={convertInstituciones.length !== 0}
            siTable
          />
          {!isSearching && (
            <Stack spacing={2} style={{ margin: "20px 0px 30px 0px" }}>
              <Pagination
                count={fetchInstituciones?.pages || 1}
                page={page}
                onChange={handleChange}
                color="primary"
              />
            </Stack>
          )}
        </React.Fragment>
      </myPageInstitucion.Provider>
    </>
  );
}
