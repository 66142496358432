import { request } from 'utils/helpers/request.helpers';

const resolucionesUrl = `${process.env.REACT_APP_API_URL_DEV}/api/Resolucion`;

/* export function GetResoluciones(page) {
  const url = `${resolucionesUrl}/Resolucion/Listar?page=${page}`;
  return request(url, { method: 'GET' });
} */

export function GetResoluciones(page) {
  return request(`${resolucionesUrl}/Listar?page=${page}`, {
    method: 'GET',
    headers: { 'content-type': 'application/json' },
    page: JSON.stringify(page),
  });
}

export function SaveResoluciones(body) {
  return request(`${resolucionesUrl}/InsertarResolucion`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(body),
  });
}

export function GetResolucionesId(id) {
  return request(`${resolucionesUrl}/GetResolucion?idProyecto=${id}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
}

export function UpdateResoluciones({ data }) {
  return request(`${resolucionesUrl}/ModificarResolucion`, {
    method: 'PUT',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(data),
  });
}

  export function DeleteResoluciones(id) {
    return request(`${resolucionesUrl}/EliminarResolucion/${id}`, {
    method: 'DELETE',
  });
}
