import React from "react";
import DialogContent from "@mui/material/DialogContent";
import {
  FormControl,
  Autocomplete,
  Box,
  Button,
  TextField,
  Stack,
} from "@mui/material";
import { Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  createNewProyect,
  getProyectos,
} from "store/inicioProyectos/IProyectos.slice";
import "../ActividadesCss.css";
import { myPageProyectos } from "../ProyectosIPage/ProyectosIPage";
import Grid from "@mui/material/Grid";
import {
  getInstitucionesTodas,
  selectInstitucionTodas,
} from "store/Institucion/institucion.slice";

import {
  getEstadosProyecto,
  selectEstadosProyecto,
} from "store/estadosProyecto/estadosProyecto.slice";
import {
  getModalidadesProyecto,
  selectModalidadesProyecto,
} from "store/modalidadesProyecto/modalidadesProyecto.slice";
import {
  getTiposProyecto,
  selectTiposProyecto,
} from "store/tiposProyecto/tiposProyectos.slice";
import { proyectoss } from "views/validaciones/validaciones";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { showSnackbar } from "store/mensajeria/mensajeriaSlice";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/es";
import CustomModal from "components/customModal/CustomModal";
import "dayjs/locale/es";

const CONST_ESTADO_INICIAL_NUEVO_PROYECTO = 3; //"EN ANÁLISIS/ TRÁMITE"

export default function ProyectosINuevo() {
  const dispatch = useDispatch();
  const page = useContext(myPageProyectos);
  const [open, setOpen] = React.useState(false);

  const [institucionesTodasHook, setinstitucionesTodasHook] = useState([]);
  const GetInstTodas = useSelector(selectInstitucionTodas);

  const [tiposProyectosHook, setTiposProyectosHook] = useState([]);
  const GetTiposProyecto = useSelector(selectTiposProyecto);

  const [modalidadProyectosHook, setModalidadProyectosHook] = useState([]);
  const GetModalidadProyecto = useSelector(selectModalidadesProyecto);

  const [estadosProyectosHook, setEstadosProyectosHook] = useState([]);
  const GetEstadosProyecto = useSelector(selectEstadosProyecto);

  useEffect(() => {
    let ListaInstitucionTodas = GetInstTodas.map((x) => ({
      label: x.nombre,
      id: x.idInstitucion,
    }));
    setinstitucionesTodasHook(ListaInstitucionTodas);

    //UseEffect de Tipo de proyectos
    let ListaTipoProyectos = GetTiposProyecto.map((x) => ({
      label: x.denominacion,
      id: x.idTipoProyecto,
    }));
    setTiposProyectosHook(ListaTipoProyectos);

    //UseEffect de Modalidad de Proyectos
    let ListaModalidadProyectos = GetModalidadProyecto?.map((x) => ({
      label: x.denominacion,
      id: x.idModalidadProyecto,
    }));
    setModalidadProyectosHook(ListaModalidadProyectos);
    //UsEffect de estados de Proyecto
    let ListaEstadosProyectos = GetEstadosProyecto.map((x) => ({
      label: x.descripcion,
      id: x.idEstadoProyecto,
    }));
    setEstadosProyectosHook(ListaEstadosProyectos);
  }, [
    GetTiposProyecto,
    GetModalidadProyecto,
    GetEstadosProyecto,
    GetInstTodas,
  ]);
  useEffect(() => {
    dispatch(getInstitucionesTodas());
    // llamar tipo de proyecto
    dispatch(getTiposProyecto(page ? page : 1));
    // llamar modalidad de proyecto
    dispatch(getModalidadesProyecto(page ? page : 1));
    //llamar estdado de proyecto
    dispatch(getEstadosProyecto(page ? page : 1));
  }, [page, dispatch]);

  const Evaluacion = [
    { id: 1, label: "Si" },
    { id: 2, label: "No" },
  ];

  const handleSubmit = (value) => {
    //Define si tiene evaluacion o no
    if (value.conEvaluacion === "Si") {
      value.conEvaluacion = "S";
    } else if (value.conEvaluacion === "No") {
      value.conEvaluacion = "N";
    }
    value.horasReloj = ((value.horasCatedra * 2) / 3).toFixed(
      2
    ); /* La Hora Reloj es 2/3 de una Hora Cátedra. */
    value.destinadoA = value.destinadoA ?? "No especificado.";

    //Le pego al backend
    dispatch(createNewProyect(value)).then((resultAction) => {
      if (createNewProyect.fulfilled.match(resultAction))
        dispatch(showSnackbar(resultAction.payload.message));
    });

    setTimeout(() => {
      dispatch(getProyectos(page ? page : 1));
    }, 400);

    setOpen(false);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick" && "escapeKeyDown") return;
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleOpen}>
        Nuevo
      </Button>
      <CustomModal title={"Agregar Proyecto"} open={open} setOpen={handleClose}>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <Formik
              initialValues={{
                idInstitucion: null,
                denominacion: "",
                idModalidadProyecto: null,
                fechaInicio: null,
                fechaFin: null,
                idTipoProyecto: null,
                destinadoA: null,
                conEvaluacion: "",
                horasCatedra: null,
                horasReloj: null,
                idEstadoProyecto: CONST_ESTADO_INICIAL_NUEVO_PROYECTO,
                deshabilitado: 0,
              }}
              /* Aqui se obtienen los datos que se ingresó en el formulario */
              onSubmit={handleSubmit}
              /* Configuracion de Formik para agregar las validaciones */
              validationSchema={proyectoss}
            >
              {({
                values,
                errors,
                handleChange,
                touched,
                setFieldValue,
                submitForm,
                dirty,
              }) => (
                <Box component="form">
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <Autocomplete
                          disablePortal
                          onChange={(event, newValue) => {
                            setFieldValue(
                              "idInstitucion",
                              newValue !== null ? newValue.id : ""
                            );
                          }}
                          value={
                            institucionesTodasHook?.find(
                              (item) => item.id === values.idInstitucion
                            )?.label || null
                          }
                          id="idInstitucion"
                          options={institucionesTodasHook}
                          name="idInstitucion"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Institución"
                              margin="normal"
                              variant="outlined"
                              autoComplete="off"
                              InputProps={{
                                ...params.InputProps,
                                type: "search",
                              }}
                              sx={{
                                "input[type='search']::-webkit-search-cancel-button":
                                  {
                                    display: "none",
                                  },
                              }}
                              error={
                                Boolean(errors.idInstitucion) &&
                                touched.idInstitucion
                              }
                              helperText={
                                Boolean(errors.idInstitucion) &&
                                touched.idInstitucion &&
                                errors.idInstitucion
                              }
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          label="Denominación"
                          type="text"
                          name="denominacion"
                          variant="outlined"
                          fullWidth
                          onChange={handleChange}
                          value={values.denominacion}
                          error={Boolean(
                            errors.denominacion && touched.denominacion
                          )}
                          helperText={
                            Boolean(errors.denominacion) &&
                            touched.denominacion &&
                            errors.denominacion
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth style={{ marginTop: "-7px" }}>
                        <Autocomplete
                          disablePortal
                          value={
                            tiposProyectosHook?.find(
                              (item) => item.id === values.idTipoProyecto
                            )?.label || null
                          }
                          onChange={(event, newValue) => {
                            setFieldValue(
                              "idTipoProyecto",
                              newValue !== null ? newValue.id : ""
                            );
                          }}
                          id="idTipoProyecto"
                          options={tiposProyectosHook}
                          name="idTipoProyecto"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Tipo de proyecto"
                              margin="normal"
                              variant="outlined"
                              autoComplete="off"
                              InputProps={{
                                ...params.InputProps,
                                type: "search",
                              }}
                              error={
                                Boolean(errors.idTipoProyecto) &&
                                touched.idTipoProyecto
                              }
                              sx={{
                                "input[type='search']::-webkit-search-cancel-button":
                                  {
                                    display: "none",
                                  },
                              }}
                              helperText={
                                Boolean(errors.idTipoProyecto) &&
                                touched.idTipoProyecto &&
                                errors.idTipoProyecto
                              }
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth style={{ marginTop: "-16px" }}>
                        <Autocomplete
                          disablePortal
                          value={
                            modalidadProyectosHook?.find(
                              (item) => item.id === values.idModalidadProyecto
                            )?.label || null
                          }
                          onChange={(event, newValue) => {
                            setFieldValue(
                              "idModalidadProyecto",
                              newValue !== null ? newValue.id : ""
                            );
                          }}
                          id="idModalidadProyecto"
                          options={modalidadProyectosHook}
                          name="idModalidadProyecto"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Modalidad de proyecto"
                              margin="normal"
                              variant="outlined"
                              autoComplete="off"
                              InputProps={{
                                ...params.InputProps,
                                type: "search",
                              }}
                              error={
                                Boolean(errors.idModalidadProyecto) &&
                                touched.idModalidadProyecto
                              }
                              sx={{
                                "input[type='search']::-webkit-search-cancel-button":
                                  {
                                    display: "none",
                                  },
                              }}
                              helperText={
                                Boolean(errors.idModalidadProyecto) &&
                                touched.idModalidadProyecto &&
                                errors.idModalidadProyecto
                              }
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <DatePicker
                          label="Fecha de inicio"
                          name="fechaInicio"
                          value={values.fechaInicio}
                          onChange={(value) =>
                            setFieldValue("fechaInicio", value)
                          }
                          slotProps={{
                            textField: {
                              helperText:
                                Boolean(errors.fechaInicio) &&
                                touched.fechaInicio &&
                                errors.fechaInicio,
                              error:
                                Boolean(errors.fechaInicio) &&
                                Boolean(touched.fechaInicio),
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <DatePicker
                          label="Fecha de fin"
                          name="fechaFin"
                          value={values.fechaFin}
                          onChange={(value) => setFieldValue("fechaFin", value)}
                          slotProps={{
                            textField: {
                              helperText:
                                Boolean(errors.fechaFin) &&
                                touched.fechaFin &&
                                errors.fechaFin,
                              error:
                                Boolean(errors.fechaFin) &&
                                Boolean(touched.fechaFin),
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        fullWidth
                        sx={{
                          mt: 1,
                        }}
                      >
                        <TextField
                          fullWidth
                          id="destinadoA"
                          name="destinadoA"
                          label="Destinado a"
                          multiline
                          rows={3}
                          value={values.destinadoA}
                          onChange={handleChange}
                          helperText={
                            Boolean(errors.destinadoA) &&
                            touched.destinadoA &&
                            errors.destinadoA
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth style={{ marginTop: "-7px" }}>
                        <Autocomplete
                          disablePortal
                          onChange={(event, newValue) => {
                            setFieldValue(
                              "conEvaluacion",
                              newValue !== null ? newValue.label : ""
                            );
                          }}
                          id="conEvaluacion"
                          value={
                            Evaluacion?.find(
                              (item) => item.label === values.conEvaluacion
                            )?.label || null
                          }
                          options={Evaluacion}
                          name="conEvaluacion"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Evaluación"
                              margin="normal"
                              variant="outlined"
                              autoComplete="off"
                              InputProps={{
                                ...params.InputProps,
                                type: "search",
                              }}
                              sx={{
                                "input[type='search']::-webkit-search-cancel-button":
                                  {
                                    display: "none",
                                  },
                              }}
                              error={
                                Boolean(errors.conEvaluacion) &&
                                touched.conEvaluacion
                              }
                              helperText={
                                Boolean(errors.conEvaluacion) &&
                                touched.conEvaluacion &&
                                errors.conEvaluacion
                              }
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          label="Horas cátedra"
                          type="number"
                          name="horasCatedra"
                          variant="outlined"
                          fullWidth
                          value={values.horasCatedra}
                          onChange={handleChange}
                          error={Boolean(
                            errors.horasCatedra && touched.horasCatedra
                          )}
                          helperText={
                            Boolean(errors.horasCatedra) &&
                            touched.horasCatedra &&
                            errors.horasCatedra
                          }
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl fullWidth style={{ marginTop: "-7px" }}>
                        <Autocomplete
                          disablePortal
                          disabled
                          value={
                            estadosProyectosHook?.find(
                              (item) => item.id === values.idEstadoProyecto
                            )?.label || null
                          }
                          onChange={(event, newValue) => {
                            setFieldValue(
                              "idEstadoProyecto",
                              newValue !== null ? newValue.id : ""
                            );
                          }}
                          id="idEstadoProyecto"
                          options={estadosProyectosHook}
                          name="idEstadoProyecto"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Estado de proyecto"
                              margin="normal"
                              variant="outlined"
                              autoComplete="off"
                              InputProps={{
                                ...params.InputProps,
                                type: "search",
                              }}
                              sx={{
                                "input[type='search']::-webkit-search-cancel-button":
                                  {
                                    display: "none",
                                  },
                              }}
                              error={
                                Boolean(errors.idEstadoProyecto) &&
                                touched.idEstadoProyecto
                              }
                              helperText={
                                Boolean(errors.idEstadoProyecto) &&
                                touched.idEstadoProyecto &&
                                errors.idEstadoProyecto
                              }
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Stack
                    spacing={2}
                    direction="row"
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      mt: 3,
                      mb: 2,
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleClose()}
                      spacing={2}
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => submitForm()}
                      disabled={!dirty}
                    >
                      Guardar
                    </Button>
                  </Stack>
                </Box>
              )}
            </Formik>
          </LocalizationProvider>
        </DialogContent>
      </CustomModal>
    </React.Fragment>
  );
}
