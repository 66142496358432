import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  GetListaCompleta
} from "./nivelesEnsenanza.api";

const initialState = {
  entities: [],
  response: {},
  message: {},
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
};

export const GetListaCompletaEndPoint = createAsyncThunk(
  "nivelesEnsenanza/GetListaCompletaEndPoint",
  async () => await GetListaCompleta()

);

/* export const GetCapacitadorEndPoint = createAsyncThunk(
  "capacitadores/GetCapacitadorXActividad",
  async (idActividad) => {
    const r = await GetCapacitadorXActividad(idActividad);
    return r;
  }
); */



export const NivelesEnsenanzaSlice = createSlice({
  name: "nivelesEnsenanza",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

       //GetListaCompletaEndPoint

      .addCase(GetListaCompletaEndPoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetListaCompletaEndPoint.fulfilled, (state, action) => {
        state.loading = false;
        state.entities  = action.payload;
      })
      .addCase(GetListaCompletaEndPoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

  },
});

export const selectNivelesEnsenanza = (state) => state.nivelesEnsenanza.entities;
export const selectIsLoading = (state) => state.nivelesEnsenanza.loading;
export const selectIsCreating = (state) => state.nivelesEnsenanza.creating;
export const selectIsEditing = (state) => state.nivelesEnsenanza.editing;
export const selectIsDeleting = (state) => state.nivelesEnsenanza.deleting;
export const selectError = (state) => state.nivelesEnsenanza.error;

export default NivelesEnsenanzaSlice.reducer;
