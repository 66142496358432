import React, { createContext, useEffect, useState } from "react";
import {
  selectActividadesResponse,
  FiltrarActividadEndPoint,
  selectError,
  selectActividadeFilter,
} from "store/actividades/actividades.slice";
import ActividadesNuevo from "../ActividadesNuevo/ActividadesNuevo";
import ActividadesTable from "../ActividadesTable/ActividadesTable";
import { useDispatch, useSelector } from "react-redux";
import GenericSearch from "utils/search/GenericSearch";
import SnackBarUtils from "utils/MUI/SnackbarUtils";
import { SnackBarMessageResponse } from "utils/Response/SnackBarMessageResponse";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useIdRol } from "utils/roles/IdRolContext";
import { Grid } from "@mui/material";

export const ActividadesContext = React.createContext(1);

const ActividadesPage = () => {
  const [message, setMessage] = useState("");
  const [view, setView] = useState(false);
  const dispatch = useDispatch();
  const GetActividades = useSelector(selectActividadeFilter);
  const GetResponse = useSelector(selectActividadesResponse);
  const sError = useSelector(selectError);
  let [page, setPage] = useState(1);
  const { rolName } = useIdRol();

  useEffect(() => {
    dispatch(FiltrarActividadEndPoint({ page: page ? page : 1 }));
  }, [page, dispatch]);

  useEffect(() => {
    if (sError) {
      setView(true);
      setMessage(SnackBarMessageResponse(GetResponse));
    }
  }, [GetResponse, sError]);

  const handleChange = (event, values) => {
    setPage(values);
  };

  const handleSearch = (e) => {
    if (e.code !== "Enter") return;
    dispatch(FiltrarActividadEndPoint({ page: 1, data: e.target.value }));
  };

  return (
    <ActividadesContext.Provider value={page}>
      <React.Fragment>
        <div style={{ display: "flex", alignItems: "left" }}>
          <h1 style={{ margin: "10px 0" }}>Actividades</h1>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {rolName !== "docente" && <ActividadesNuevo />}
          </Grid>
          <Grid item xs={8}>
            <GenericSearch handleSearch={handleSearch} />
          </Grid>
        </Grid>
        <ActividadesTable datosAct={GetActividades?.data} />

        <SnackBarUtils
          closed={(value) => setView(value)}
          message={message}
          view={view}
        />
        <Stack spacing={2} style={{ margin: "20px 0px 30px 0px" }}>
          <Pagination
            count={GetActividades?.data?.records?.pages}
            page={page}
            onChange={handleChange}
            color="primary"
          />
        </Stack>
      </React.Fragment>
    </ActividadesContext.Provider>
  );
};

export default ActividadesPage;
