import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {
  getAllCertificate,
  getAllTypeCertificate,
  selectAllCertificados,
  selectCreating,
  selectDeleting,
} from "store/certificados/certificado.slice";
import Loader from "components/Loader/Loader";
import { GenericTemplate } from "template/GenericTemplate";
import {
  getAlumnosByACT,
  loadingAlumno,
  selectByAct,
  clearAlumnosByAct,
} from "store/Alumnos/alumnos.slice";
import {
  GetActividadesXProyectoEndPoint,
  selectActividadesXProyecto,
} from "store/actividades/actividades.slice";
import {
  getProyectosConResol,
  selectProyectosConResol,
} from "store/proyectos/proyectos.slice";
import { FilterFields } from "./FilterFields";
import { useLocation } from "react-router-dom";
import { Box, Grid } from "@mui/material";

export const myPageCurso = React.createContext(1);

export default function CursoPage() {
  const dispatch = useDispatch();
  let [page, setPage] = React.useState(1);
  const [cahngeCerto, setCahngeCerto] = useState(false);
  const [certificateState, setCertificateState] = useState([]);

  const [actividadData, setActividadData] = useState(null);
  const [convertCertificado, setConvertCertificado] = useState(null);
  const [proyectoSelected, setProyectoSelected] = useState(null);
  const [actividadSelected, setActividadSelected] = useState(null);

  const location = useLocation();

  const path = location.pathname;

  const isAlumnno = path === "/certificados/certificados";

  //Loadings
  const creating = useSelector(selectCreating);
  const deleting = useSelector(selectDeleting);

  const loadding = creating || deleting;

  //finLoadings
  useEffect(() => {
    dispatch(getAlumnosByACT({ actividadData, page }));
    dispatch(getAllCertificate());
  }, [page, dispatch, creating, deleting, actividadData]);

  useEffect(() => {
    dispatch(getAllTypeCertificate());
    dispatch(getProyectosConResol());
    dispatch(getAllCertificate());
    //dispatch(clearAlumnosByAct());
  }, []);

  const handleChangeProyectos = (idProyecto) => {
    if (!idProyecto) return;
    // setProyectoSelected(idProyecto);
    dispatch(GetActividadesXProyectoEndPoint(idProyecto));
  };

  const handleGetAlumnos = (actividadData) => {
    setActividadData(actividadData);
    //dispatch(clearAlumnosByAct());
    dispatch(getAlumnosByACT({ actividadData, page }));
  };

  //Trae alumnos por proyecto
  const fetchAlumnos = useSelector(selectByAct);

  const loadingAlumnos = useSelector(loadingAlumno);
  //Trae proyectos
  const fetchProyecto = useSelector(selectProyectosConResol);
  //trae todos los certificados disponibles
  const fetchAllCertificate = useSelector(selectAllCertificados);
  const loadingCertificado = useSelector(loadingAlumno);
  const alumnos = fetchAlumnos.records ? fetchAlumnos.records : [];

  useEffect(() => {
    if (alumnos.length !== 0) {
      const convertCertificad = alumnos.map((alumno) => {
        const findCertificado = fetchAllCertificate?.data?.find(
          (certificado) => certificado.idAlumno === alumno.idAlumno
        );

        const findUser = certificateState.find(
          (user) => user.alumno === alumno.idAlumno
        );

        if (!findUser) {
          certificateState.push({
            selectValue: findCertificado?.idTipoCertificado ?? 0,
            alumno: alumno.idAlumno,
          });
        }

        let certificadoConvert = {
          ID: alumno.idAlumno ?? 0,
          NombreAlumno: alumno.persona.apellidoyNombre,
          CuitAlumno: alumno.persona.cuit,
          Actividad: alumno.actividad.descripcion,
        };

        return certificadoConvert;
      });

      setConvertCertificado(convertCertificad);
    }
  }, [alumnos]);

  if (loadingCertificado || loadingAlumnos) {
    return <Loader />;
  }

  const handleChange = (event, values) => {
    setPage(values);
  };

  return (
    <div className="container-cer">
      <myPageCurso.Provider value={page}>
        <React.Fragment>
          <div style={{ display: "flex", alignItems: "left" }}>
            <h1 style={{ margin: "10px 0" }}>Curso</h1>
          </div>
          <div className="component-container-cer">
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <FilterFields
                dataProyecto={fetchProyecto}
                handleChangeProyectos={handleChangeProyectos}
                handleGetAlumnos={handleGetAlumnos}
                proyectoSelected={proyectoSelected}
                actividadSelected={actividadSelected}
                setProyectoSelected={setProyectoSelected}
                setActividadSelected={setActividadSelected}
                setActividadData={setActividadData}
                setPage={setPage}
              />
            </div>
          </div>
          {alumnos.length !== 0 &&
            convertCertificado &&
            proyectoSelected &&
            actividadSelected && (
              <div>
                <h2 style={{ marginTop: "20px", marginLeft: "15px" }}>
                  {" "}
                  - Lista de Alumnos -{" "}
                </h2>
                <GenericTemplate
                  dataTable={convertCertificado}
                  siEdit={false}
                  siDelete={false}
                  siTable={true}
                />
              </div>
            )}
          {!alumnos.length && actividadSelected && (
            <p
              style={{
                textAlign: "center",
                marginTop: "4rem",
                fontSize: "25px",
              }}
            >
              No hay <b>Inscripciones Confirmadas</b> en esta Actividad.
              Inquietudes, consulta al Capacitador.
            </p>
          )}

          {actividadSelected && alumnos.length !== 0 && proyectoSelected && (
            <Stack spacing={2} style={{ margin: "20px 0px 30px 0px" }}>
              <Pagination
                count={fetchAlumnos?.pages}
                page={page}
                onChange={handleChange}
                color="primary"
              />
            </Stack>
          )}
        </React.Fragment>
      </myPageCurso.Provider>
    </div>
  );
}
