import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { getAlumnosApi, getAlumnosByActApi } from "./alumnos.api";

const initialState = {
  entities: null,
  entitiesXProyecto: {},
  alumnosByAct: [],
  entitiesTodas: {},
  response: {},
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
};

export const getAlumnos = createAsyncThunk(
  "alumnos/getAlumnos",
  async (page) => {
    const r = await getAlumnosApi(page);
    return r;
  }
);
export const getAlumnosByACT = createAsyncThunk(
  "alumnos/getAlumnosByACT",
  async ({actividadData, page}) => {
    const r = await getAlumnosByActApi(actividadData, page);
    return r;
  }
);

export const clearAlumnosByAct = createAction("alumnos/clearAlumnosByAct");

export const alumnosSlice = createSlice({
  name: "alumnos",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getAlumnos.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAlumnos.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(getAlumnos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getAlumnosByACT.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAlumnosByACT.fulfilled, (state, action) => {
        state.loading = false;
        state.alumnosByAct = action.payload.data;
      })
      .addCase(getAlumnosByACT.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      //#region clearAlumnosByAct
      .addCase(clearAlumnosByAct, (state) => {
        state.alumnosByAct = []; // Vacía el array
      });
      //#endregion
  },
});

export const selectAlumnos = (state) => state.alumnos.entities; // Paginado.
export const selectByAct = (state) => state.alumnos.alumnosByAct; // Paginado.
export const loadingAlumno = (state) => state.alumnos.loading; // Paginado.

export default alumnosSlice.reducer;
