//import Toolbar from '@mui/material/Toolbar';
import Loader from "components/Loader/Loader";
import React, { useContext } from "react";
import PreInsList from "../PreInsList";
import { useNavigate } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import { TokenContext } from "context/TokenContext";

export default function PreInsPage() {
  
    const navigate = useNavigate();
    const { token, decodedToken, saveToken, removeToken } = useContext(TokenContext);

  return (
    <React.Fragment>
      {/* {showLoader && <Loader />} */}
      <div style={{ display: "flex", alignItems: "left", flexDirection: "column" }}>
        <h1 style={{ margin: "10px 0" }}>Mis Inscripciones</h1>
      
      
        {/* <ProyectosTable style={{ margin: "25px 0px" }} /> */}
        <PreInsList />
        <Button
                sx={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}
                variant="contained"
                type="submit"
                onClick={() => {
                  navigate('/preinscripcion/nueva');
                }}
              >
                Nueva pre inscripción
              </Button>
      
      </div>
    </React.Fragment>
  );
}
