import axios from 'axios';
import {jwtDecode} from 'jwt-decode';

// export class ResponseError extends Error {
//   constructor(response) {
//     super(response.statusText);
//     this.response = response;
//   }
// }

// Clase de error personalizada para manejar errores
export class ResponseError extends Error {
  constructor(message, response) {
    super(message);
    this.response = response;
  }
}

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new ResponseError(response);
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
// export async function request(url, options) {
//   const fetchResponse = await fetch(url, options);
//   const response = checkStatus(fetchResponse);
//   return parseJSON(response);
// }

// Función para verificar si el token ha expirado
function isTokenExpired(token) {
  if (!token) return true;

  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Tiempo actual en segundos
    return decodedToken.exp < currentTime; // Compara el tiempo de expiración con el tiempo actual
  } catch (error) {
    return true; // Si hay un error decodificando el token, lo consideramos expirado
  }
}

// Función para obtener un nuevo access_token usando el refresh_token
async function refreshToken() {
  console.log("entra al refreshToken")
  const refresh_token = localStorage.getItem('refresh_token');
  const access_token = localStorage.getItem('token');

  if (!refresh_token) {
    throw new Error('No hay refresh_token disponible');
  }

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL_LOGIN_DEV}/api/v1/Authentication/RefreshToken`, {
      refresh_token,
      access_token
    });

    const newToken = response.data.access_token;

    // Guarda el nuevo access_token en localStorage
    localStorage.setItem('token', response.data.access_token);
    localStorage.setItem('refresh_token', response.data.refresh_token);
    localStorage.setItem('id_token', response.data.id_token);

    return newToken;
  } catch (error) {
    throw new Error('Error al obtener un nuevo token.');
  }
}

export async function request(url, options) {
  try {
    const token = localStorage.getItem('token');

     // Verificar si el token ha expirado
     if (isTokenExpired(token)) {
      // Si el token ha expirado, intenta renovarlo
      try {
        token = await refreshToken(); // Obtener nuevo token con refresh_token
      } catch (error) {
        throw new ResponseError('Error al refrescar el token.', error.response);
      }
    }

    // Prepara los headers, incluyendo el token si existe
    const headers = {
      ...options.headers, // Agrega cualquier header ya especificado
      ...(token ? { Authorization: `Bearer ${token}` } : {}), // Agrega el token si existe
    };
    // Hacer la solicitud con axios (se encarga de parsear JSON automáticamente)
    const response = await axios({
      url,
      method: options.method || 'GET', // Usa GET por defecto si no se especifica método
      // headers: options.headers || {},
      headers, // Incluye los headers con el token
      data: options.body || null, // En caso de que haya un cuerpo (POST, PUT, etc.)
    });

    return response.data; // Retorna directamente los datos
  } catch (error) {
    console.log("error axios",error)
    // Si ocurre un error, lo manejamos y lanzamos un ResponseError personalizado
    // const response = error.response;
    // const message = response?.data?.message || response?.statusText || 'Error desconocido';

    // throw new ResponseError(message, response);
    const message = error.response?.data?.message || error.response?.data?.description || error.message || 'Error desconocido';
    throw new Error(message); 
  }
}