export const tableHeadCell = {
  textAlign: "center",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontWeight: 600,
  borderTop: "1px solid rgba(224, 224, 224, 1)",
};

export const tableBodyCell = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export const tableBodyCenterCell = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textAlign: "center",
  verticalAlign: "middle",
};

const getTableHeight = () => {
  return window.innerWidth <= 768 ? '370px' : '560px';
};

// Objeto con maxHeight basado en el tamaño de la ventana (valor inicial)
export const tableHeightDefault = {
  maxHeight: getTableHeight(),
};

// Función que actualiza el maxHeight cuando se redimensiona la ventana
export const updateTableHeight = () => {
  tableHeightDefault.maxHeight = getTableHeight();
};
