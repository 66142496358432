import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import GenericEdit from "./GenericEdit";
import GenericDelete from "./GenericDelete";
import "./styles.css";
import {
  tableBodyCell,
  tableHeadCell,
} from "components/customTable/StylesTable";
import CustomTableContainer from "components/customTable/CustomTableContainer";

const useStyles = {
  tableContainer: {
    maxWidth: "100%",
    overflowX: "auto",
  },
};

const GenericTable = ({
  dataTable,
  title,
  Form,
  deleteFuncion,
  customActions,
  siEdit,
  siDelete,
}) => {
  let dataTotal = Object.keys(dataTable[0]);

  deleteFuncion && dataTotal.push("Acciones");

  return (
    <CustomTableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead
          sx={{
            borderTop: "1px solid", // Agregar borde superior
          }}
        >
          <TableRow>
            {/* Renderizar los encabezados de tabla con las claves */}
            {dataTotal.map((key) => (
              <TableCell sx={{ ...tableHeadCell }} key={key}>
                {key.toString()}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataTable.length > 0 ? (
            dataTable.map((row, index) => (
              <TableRow key={index}>
                {dataTotal.map((key) =>
                  key === "Acciones" ? (
                    <TableCell key={key} sx={{ ...tableBodyCell }}>
                      {siEdit && (
                        <GenericEdit data={row} title={title} Form={Form} />
                      )}{" "}
                      {/* siEdit "true" si quiero poder Editar */}
                      {/* Se agrega condicional en caso de no tener Delete */}
                      {siDelete &&
                        deleteFuncion && ( // siDelete "true" si quiero poder Eliminar.
                          <GenericDelete
                            idToDelete={row}
                            deleteFuncion={deleteFuncion}
                            title={title}
                          />
                        )}
                      {customActions && customActions(key, row)}
                    </TableCell>
                  ) : (
                    <TableCell key={key}>{row[key]}</TableCell>
                  )
                )}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={15} sx={{ textAlign: "center" }}>
                No se encontraron resultados
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </CustomTableContainer>
  );
};

export default GenericTable;
