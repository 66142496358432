import React from 'react';

import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Typography } from '@mui/material';

export default function ActividadesInfoAdicional({ itemAct }) {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <IconButton variant="outlined" onClick={handleClickOpen}>
        <VisibilityIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> 
          <b> Información Adicional: </b>
        </DialogTitle>
        <DialogContent style={{textAlign: "justify", marginBottom: "15px", whiteSpace: "pre-wrap"}}>
          { <Typography variant='body1' component="div">
          {itemAct.infoAdicional ?? "Sin Información Adicional."}
          </Typography> }
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
