// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../images/MarcoCertificado.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PrintView.css */

.printable-content {
  margin-top: 8px;
  font-family: Arial, sans-serif;
  background-color: withe;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  height: 100%;
  width: 100%;
  background-size: cover;
}

.printable-content h1 {
  text-align: center;
}
.printable-content p {
  padding-left: 50px;
  padding-right: 50px;
}

.divImg {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 4.5rem;
}

.divImg img {
  text-align: center;
  width: 50%;
}

.Logos {
  width: 100%;
}

.logosDiv {
  display: flex;
  justify-content: space-between;
  margin: auto;
  padding: 3rem;
}

.logosDiv img {
  width: 12%;
}

.printable-content p {
  margin: 5px 0;
  text-align: center;
  width: 100%;
  margin: auto;
}

/* Agrega más estilos personalizados aquí */
`, "",{"version":3,"sources":["webpack://./src/views/certificados/tableActions/PrintView.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB;EACE,eAAe;EACf,8BAA8B;EAC9B,uBAAuB;EACvB,yDAA6D;EAC7D,YAAY;EACZ,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA,2CAA2C","sourcesContent":["/* PrintView.css */\n\n.printable-content {\n  margin-top: 8px;\n  font-family: Arial, sans-serif;\n  background-color: withe;\n  background-image: url(\"../../../images/MarcoCertificado.png\");\n  height: 100%;\n  width: 100%;\n  background-size: cover;\n}\n\n.printable-content h1 {\n  text-align: center;\n}\n.printable-content p {\n  padding-left: 50px;\n  padding-right: 50px;\n}\n\n.divImg {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  padding-top: 4.5rem;\n}\n\n.divImg img {\n  text-align: center;\n  width: 50%;\n}\n\n.Logos {\n  width: 100%;\n}\n\n.logosDiv {\n  display: flex;\n  justify-content: space-between;\n  margin: auto;\n  padding: 3rem;\n}\n\n.logosDiv img {\n  width: 12%;\n}\n\n.printable-content p {\n  margin: 5px 0;\n  text-align: center;\n  width: 100%;\n  margin: auto;\n}\n\n/* Agrega más estilos personalizados aquí */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
