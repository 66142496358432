import * as Yup from "yup";

export const vsResolucion = Yup.object({
  orgEmisor: Yup.string().required("Este campo es obligatorio"),
  nroResolucion: Yup.string()
    .required("Este campo es obligatorio")
    .matches(
      /^[0-9]{0,4}$/,
      "El código debe tener como máximo 4 dígitos numéricos"
    ),
  anio: Yup.string()
    .required("Este campo es obligatorio")
    .matches(
      /^[0-9]{4}$/,
      "El código debe tener exactamente 4 dígitos numéricos"
    ),
  fechaResolucion: Yup.date()
    .typeError("Ingrese una fecha valida")
    .max(new Date(), "Este campo no puede ser posterior a la fecha actual")
    .required("Este campo es obligatorio"),
});
