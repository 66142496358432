import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  GetResoluciones,
  SaveResoluciones,
  UpdateResoluciones,
  DeleteResoluciones,
  GetResolucionesId,
} from "./resoluciones.api";

const initialState = {
  entities: [],
  entitiesXProyecto: {},
  response: {},
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
};

export const GetListarResoluciones = createAsyncThunk(
  "resoluciones/GetResoluciones",
  async (page) => {
    const r = await GetResoluciones(page);
    return r;
  }
);

export const GetIdResoluciones = createAsyncThunk(
  "resoluciones/GetResolucionesId",
  async (id) => {
    const r = await GetResolucionesId(id);
    return r;
  }
);

export const SaveResolucionesEndpoint = createAsyncThunk(
  "resoluciones/SaveResoluciones",
  async (data) => {
    const r = await SaveResoluciones(data);
    return r;
  }
);

export const EditResolucionesEndPoint = createAsyncThunk(
  "resoluciones/UpdateResoluciones",
  async ({ data }) => {
    const r = await UpdateResoluciones({ data });
    return r;
  }
);

export const RemoveResolucionesEndPoint = createAsyncThunk(
  "resoluciones/DeleteResoluciones",
  async (id) => {
    const r = await DeleteResoluciones(id);
    return r;
  }
);

export const resolucionesSlice = createSlice({
  name: "resoluciones",
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder

      // GetListarResoluciones

      .addCase(GetListarResoluciones.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetListarResoluciones.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(GetListarResoluciones.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // GetIdResoluciones

      .addCase(GetIdResoluciones.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetIdResoluciones.fulfilled, (state, action) => {
        state.loading = false;
        state.entitiesXProyecto = action.payload;
      })
      .addCase(GetIdResoluciones.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // SaveResolucionesEndpoint

      .addCase(SaveResolucionesEndpoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(SaveResolucionesEndpoint.fulfilled, (state, action) => {
        state.loading = false;
        state.response = action.payload;
      })
      .addCase(SaveResolucionesEndpoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // EditResolucionesEndPoint

      .addCase(EditResolucionesEndPoint.pending, (state) => {
        state.editing = true;
        state.error = null;
      })
      .addCase(EditResolucionesEndPoint.fulfilled, (state, action) => {
        state.loading = false;
        state.editing = false;
        state.entities = action.payload;
      })
      .addCase(EditResolucionesEndPoint.rejected, (state, action) => {
        state.editing = false;
        state.error = action.error.message;
      })

      // RemoveActividadEndPoint

      .addCase(RemoveResolucionesEndPoint.pending, (state) => {
        state.loading = true;
        state.deleting = true;
        state.error = null;
      })
      .addCase(RemoveResolucionesEndPoint.fulfilled, (state, action) => {
        state.loading = false;
        state.deleting = false;
        // Actualiza el estado después de eliminar la resolucion si es necesario
      })
      .addCase(RemoveResolucionesEndPoint.rejected, (state, action) => {
        state.loading = false;
        state.deleting = false;
        state.error = action.error.message;
      });
  },
});

export const selectResoluciones = (state) => state.resoluciones.entities;
export const selectResolucionesXProyecto = (state) =>
  state.resoluciones.entitiesXProyecto;
export const selectResolucionesResponse = (state) =>
  state.resoluciones.response;
export const selectIsLoading = (state) => state.resoluciones.loading;
export const selectIsCreating = (state) => state.resoluciones.creating;
export const selectIsEditing = (state) => state.resoluciones.editing;
export const selectIsDeleting = (state) => state.resoluciones.deleting;
export const selectError = (state) => state.resoluciones.error;

export default resolucionesSlice.reducer;
