import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import "dayjs/locale/es";
import Box from "@mui/material/Box";
import { FormControl, Stack } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { Autocomplete } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Formik } from "formik";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  getTiposDePeriodoById,
  stpError,
  sTiposDePeriodo,
} from "store/tiposDePeriodo/tiposDePeriodo.slice";

import {
  ActividadesAprobadasAll,
  ActividadesAprobadasSinPeriodo,
  selectActAprobadasSinPeriodo,
  selectActividadesAprobadasAll,
  selectError,
} from "store/actividades/actividades.slice";

import { vsPeriodo } from "./YupPeriodo";

export default function PeriodoForm({ data, onSubmit, onClose }) {
  //console.log('data en PeriodoForm ', data)

  dayjs.extend(customParseFormat);
  const dispatch = useDispatch();
  const tiposDePeriodo = useSelector(sTiposDePeriodo);
  const tpError = useSelector(stpError);
  const { data: actividades } = useSelector(selectActAprobadasSinPeriodo);
  const { data: actividadInicioEdit } = useSelector(
    selectActividadesAprobadasAll
  );
  const actividadesError = useSelector(selectError);

  useEffect(() => {
    dispatch(getTiposDePeriodoById(1));
    dispatch(ActividadesAprobadasSinPeriodo());
    dispatch(ActividadesAprobadasAll());
  }, [dispatch]);

  const handleSubmit = (e) => {
    onSubmit(e);
  };

  const handleClose = (event, reason) => {
    onClose();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <Formik
        initialValues={{
          id: data?.idPeriodo || null,
          idActividad: data?.actividad?.idActividad || null,
          idTipoPeriodo: 1 || "",
          fechaInicio: data?.fechaInicio
            ? dayjs(data?.fechaInicio, "DD/MM/YYYY")
            : null,
          fechaFin: data?.fechaFin ? dayjs(data?.fechaFin, "DD/MM/YYYY") : null,
        }}
        validationSchema={vsPeriodo}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          handleChange,
          touched,
          setFieldValue,
          submitForm,
          dirty,
        }) => (
          <Box component="form">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={Boolean(errors.idActividad) && touched.idActividad}
                >
                  <Autocomplete
                    onChange={(e, value) => {
                      setFieldValue("idActividad", value ? value.id : null);
                    }}
                    options={
                      (actividades &&
                        actividades.map((option) => ({
                          id: option.idActividad,
                          label: option.descripcion,
                        }))) ||
                      []
                    }
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    name="idActividad"
                    value={
                      actividadInicioEdit?.find(
                        (item) => item.idActividad === values.idActividad
                      )?.descripcion || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Actividades"
                        margin="normal"
                        variant="outlined"
                        autoComplete="off"
                        InputProps={{ ...params.InputProps, type: "search" }}
                        error={
                          Boolean(errors.idActividad) && touched.idActividad
                        }
                        sx={{
                          "input[type='search']::-webkit-search-cancel-button":
                            {
                              display: "none",
                            },
                        }}
                        helperText={
                          Boolean(errors.idActividad) &&
                          touched.idActividad &&
                          errors.idActividad
                        }
                      />
                    )}
                  />
                  {actividadesError && (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      Ocurrió un problema al recuperar este dato
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={Boolean(errors.idTipoPeriodo) && touched.idTipoPeriodo}
                >
                  <InputLabel id="periodo">Pre-Inscripción Cursado</InputLabel>
                  <Select
                    disabled
                    //labelId="periodo"
                    id="idTipoPeriodo"
                    //label="Tipo de período"
                    name="idTipoPeriodo"
                    fullWidth
                    //value={values?.idTipoPeriodo}
                    //onChange={handleChange}
                    //onSelect={handleChange}
                    error={
                      Boolean(errors.idTipoPeriodo) && touched.idTipoPeriodo
                    }
                  >
                    {tiposDePeriodo.map((option) => (
                      <MenuItem
                        key={option.idTipoPeriodo}
                        value={option.idTipoPeriodo}
                      >
                        {option.descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                  {Boolean(errors.idTipoPeriodo) && touched.idTipoPeriodo && (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      Este campo es obligatorio
                    </FormHelperText>
                  )}
                  {tpError && (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      Ocurrió un problema al recuperar este dato
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <DatePicker
                    label="Fecha de inicio"
                    name="fechaInicio"
                    value={values.fechaInicio}
                    onChange={(value) => setFieldValue("fechaInicio", value)}
                    slotProps={{
                      textField: {
                        helperText:
                          Boolean(errors.fechaInicio) &&
                          touched.fechaInicio &&
                          errors.fechaInicio,
                        error:
                          Boolean(errors.fechaInicio) &&
                          Boolean(touched.fechaInicio),
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <DatePicker
                    label="Fecha de fin"
                    name="fechaFin"
                    value={values.fechaFin}
                    onChange={(value) => setFieldValue("fechaFin", value)}
                    slotProps={{
                      textField: {
                        helperText:
                          Boolean(errors.fechaFin) &&
                          touched.fechaFin &&
                          errors.fechaFin,
                        error:
                          Boolean(errors.fechaFin) && Boolean(touched.fechaFin),
                      },
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Stack
              spacing={2}
              direction="row"
              sx={{ display: "flex", justifyContent: "flex-end", mt: 3, mb: 2 }}
            >
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleClose()}
                spacing={2}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                onClick={() => submitForm()}
                disabled={!dirty}
              >
                Guardar
              </Button>
            </Stack>
          </Box>
        )}
      </Formik>
    </LocalizationProvider>
  );
}
