import React from "react";
import { useSelector } from "react-redux";

import Proy_NivelesEnsenanzaTable from "../Proy_NivelesEnsenanzaTable/NivelesEnsenanzaTable";
import {selectProyectosConNiveles} from "store/proyectos/proyectos.slice"

const NivelesEnsenanzaPage = ({ itemProy}) => {
  const GetCapacitadores = useSelector(selectProyectosConNiveles);

  return (
    <React.Fragment>
      <Proy_NivelesEnsenanzaTable
        itemProy={itemProy}
        datosResp={GetCapacitadores?.data || []}
      />
    </React.Fragment>
  );
};

export default NivelesEnsenanzaPage;
