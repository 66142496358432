import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box,
  Grid,
} from "@mui/material";

export default function NivelesEnsenanzaTable({ itemProy, datosResp }) {
  if (datosResp != 0) {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12} md={12} lg={12} xl={12} sm={12}>
          <TableContainer component={Paper} style={{ width: "80%", margin: "auto" }}>
            <Table>
              <TableBody>
                {datosResp?.map((itemResp) => (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell style={{ textAlign: "center" }}>{itemResp.nivelEnsenanza}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <div>
        <Box
          style={{ padding: "15px", marginTop: "10px", textAlign: "center" }}
        >
          <p>Sin Niveles asignados.</p>
        </Box>
      </div>
    );
  }
}

