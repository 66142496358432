export const source_Actividades = [
  {
    label: "APROBADA",
    id: 1,
  },
  {
    label: "EN ANÁLISIS/ TRÁMITE",
    id: 2,
  },
  {
    label: "PENDIENTE",
    id: 3,
  },
  {
    label: "DESAPROBADA",
    id: 4,
  },
];
