import React from "react";

import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Tooltip } from "@mui/material";

export default function ProyectoDestinadoA({ itemProy }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Tooltip title="Destinado a">
        <IconButton variant="outlined" onClick={handleClickOpen}>
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <b> Destinado a: </b>
        </DialogTitle>
        <DialogContent style={{ textAlign: "justify" }}>
          {itemProy.destinadoA ?? "Sin Leyenda generada."}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
