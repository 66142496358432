import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import {
  GetActividad,
  GetActividadesXProyecto,
  SaveActividad,
  UpdateActividad,
  DeleteActividad,
  GetDenominaciones,
  GetActividadById,
  FiltrarActividades,
  GetActividadesAprobadasAll,
  GetActividadesAprobadasSinPeriodo,
} from "./actividades.api";

const initialState = {
  entities: [],
  entitiesXProyecto: [],
  entitiesTodas: [],
  entitiesAprobadasAll: [],
  entitiesAprobadasSinPer: [],
  entitiesFilter: [],
  message: {},
  response: {},
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
};

const errorMessage = {
  message: [
    {
      status: 500,
      description: "Se ha producido un error inesperado",
    },
  ],
};

export const GetActividadEndPoint = createAsyncThunk(
  "actividades/getActividad",
  async (page) => {
    const r = await GetActividad(page);
    return r;
  }
);

export const GetActividadesXProyectoEndPoint = createAsyncThunk(
  "actividades/GetActividadesXProyecto",
  async (idProyecto) => {
    const r = await GetActividadesXProyecto(idProyecto);
    return r;
  }
);

export const SaveActividadEndpoint = createAsyncThunk(
  "actividades/SaveActividad",
  async (body) => {
    const r = await SaveActividad(body);
    return r;
  }
);

export const EditActividadEndPoint = createAsyncThunk(
  "actividades/UpdateActividad",
  async ({ id, data }) => {
    const r = await UpdateActividad({ data });
    return r;
  }
);

export const FiltrarActividadEndPoint = createAsyncThunk(
  "actividades/FiltrarActividades",
  async ({ page = 1, data = null }) => {
    const r = await FiltrarActividades(page, data);
    return r;
  }
);

export const RemoveActividadEndPoint = createAsyncThunk(
  "actividades/DeleteActividad",
  async (id) => {
    const r = await DeleteActividad(id);
    return r;
  }
);

export const GetDenominacionesEndpoint = createAsyncThunk(
  "actividades/GetDenominaciones",
  async (page) => {
    const r = await GetDenominaciones(page);
    console.log("GetDenominaciones", r);
    return r;
  }
);

export const ActividadesAprobadasAll = createAsyncThunk(
  "actividades/GetActividadesAprobadasAll",
  async () => {
    const r = await GetActividadesAprobadasAll();
    return r;
  }
);

export const ActividadesAprobadasSinPeriodo = createAsyncThunk(
  "actividades/GetActividadesAprobadasSinPeriodo",
  async () => {
    const r = await GetActividadesAprobadasSinPeriodo();
    return r;
  }
);

export const GetActividadByIdEndpoint = createAsyncThunk(
  "actividades/GetActividadById",
  async (idActividad) => {
    const r = await GetActividadById(idActividad);
    return r;
  }
);

export const clearActividadesSearchData = createAction(
  "actividadSearch/clearData"
);

export const actividadesSlice = createSlice({
  name: "actividades",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // GetActividadEndPoint
      .addCase(GetActividadEndPoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetActividadEndPoint.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(GetActividadEndPoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // GetActividadesXProyectoEndPoint
      .addCase(GetActividadesXProyectoEndPoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetActividadesXProyectoEndPoint.fulfilled, (state, action) => {
        state.loading = false;
        state.entitiesXProyecto = action.payload;
      })
      .addCase(GetActividadesXProyectoEndPoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // SaveActividadEndpoint
      .addCase(SaveActividadEndpoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(SaveActividadEndpoint.fulfilled, (state, action) => {
        state.loading = false;
        state.response = action.payload;
      })
      .addCase(SaveActividadEndpoint.rejected, (state, action) => {
        state.loading = false;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })

      // EditActividadEndPoint
      .addCase(EditActividadEndPoint.pending, (state) => {
        state.editing = true;
        state.error = null;
      })
      .addCase(EditActividadEndPoint.fulfilled, (state, action) => {
        state.editing = false;
        state.response = action.payload;
        state.message = action.payload;
      })
      .addCase(EditActividadEndPoint.rejected, (state, action) => {
        state.editing = false;
        state.error = true;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })

      // RemoveActividadEndPoint
      .addCase(RemoveActividadEndPoint.pending, (state) => {
        state.deleting = true;
        state.error = null;
      })
      .addCase(RemoveActividadEndPoint.fulfilled, (state, action) => {
        state.deleting = false;
        state.message = action.payload;
      })
      .addCase(RemoveActividadEndPoint.rejected, (state, action) => {
        state.deleting = false;
        state.error = true;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })

      // GetDenominacionesEndpoint
      .addCase(GetDenominacionesEndpoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetDenominacionesEndpoint.fulfilled, (state, action) => {
        state.loading = false;
        state.entitiesTodas = action.payload;
      })
      .addCase(GetDenominacionesEndpoint.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })

      // ActividadesAprobadasAll
      .addCase(ActividadesAprobadasAll.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(ActividadesAprobadasAll.fulfilled, (state, action) => {
        state.loading = false;
        state.entitiesAprobadasAll = action.payload;
      })
      .addCase(ActividadesAprobadasAll.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })

      // ActividadesAprobadasSinPeriodo
      .addCase(ActividadesAprobadasSinPeriodo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(ActividadesAprobadasSinPeriodo.fulfilled, (state, action) => {
        state.loading = false;
        state.entitiesAprobadasSinPer = action.payload;
      })
      .addCase(ActividadesAprobadasSinPeriodo.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })

      // FiltrosEndpoint
      .addCase(FiltrarActividadEndPoint.pending, (state) => {
        state.error = null;
      })
      .addCase(FiltrarActividadEndPoint.fulfilled, (state, action) => {
        state.entitiesFilter = action.payload;
        state.entities = action.payload;
      })
      .addCase(FiltrarActividadEndPoint.rejected, (state, action) => {
        state.error = true;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })
      // GetActividadByIdEndpoint
      .addCase(GetActividadByIdEndpoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetActividadByIdEndpoint.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(GetActividadByIdEndpoint.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })
      //#region clearPersonaSearchData
      .addCase(clearActividadesSearchData, (state) => {
        state.entities = null; // Vacía el array entitiesFilter
        state.error = null;
        state.response = null;
      });
    //#endregion
  },
});

export const selectActividades = (state) => state.actividades.entities; // Paginado.
export const selectActividadeFilter = (state) =>
  state.actividades.entitiesFilter; // Paginado.
export const selectActividadesXProyecto = (state) =>
  state.actividades.entitiesXProyecto; // Por Proyecto.
export const selectActividadesTodas = (state) =>
  state.actividades.entitiesTodas; // Todas las Actividades.
export const selectActividadesAprobadasAll = (state) =>
  state.actividades.entitiesAprobadasAll; // Actividades Aprobadas.
export const selectActAprobadasSinPeriodo = (state) =>
  state.actividades.entitiesAprobadasSinPer; // Actividades sin Periodo.
export const selectActividadesResponse = (state) => state.actividades.response;
export const selectIsLoading = (state) => state.actividades.loading;
export const selectIsCreating = (state) => state.actividades.creating;
export const selectIsEditing = (state) => state.actividades.editing;
export const selectIsDeleting = (state) => state.actividades.deleting;
export const selectError = (state) => state.actividades.error;

export default actividadesSlice.reducer;
