import React from "react";
import ListSubheader from "@mui/material/ListSubheader";
import { styled } from "@mui/material/styles";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PeopleIcon from "@mui/icons-material/People";
import AssignmentIcon from "@mui/icons-material/Assignment";
import LayersIcon from "@mui/icons-material/Layers";
import ListItemLink from "components/ListItemLink/ListItemLink";
import RedeemIcon from "@mui/icons-material/Redeem";
import Divider from "@mui/material/Divider";

const StyledListSubheader = styled(ListSubheader)({
  display: "flex",
  alignItems: "center",
});

const StyledDiv = styled("div")({
  marginLeft: "70px",
});

const iconStyles = {
  marginRight: "32px",
  color: "#004d40",
};

export const firstListItems = ({ rolName }) =>
  rolName !== "capacitadores" &&
  rolName !== "institucionOferente" &&
  rolName !== "docente" && (
    <React.Fragment>
      <StyledListSubheader component="div">
        <PeopleIcon style={iconStyles} />
        ENTIDADES
      </StyledListSubheader>
      <StyledDiv>
        <ListItemLink to="entidades/personas" text="Personas" />
      </StyledDiv>
      <StyledDiv>
        <ListItemLink to="entidades/instituciones" text="Instituciones" />
      </StyledDiv>
      <Divider />
    </React.Fragment>
  );

export const secondListItems = ({ rolName }) =>
  rolName !== "docente" && (
    <React.Fragment>
      <StyledListSubheader component="div">
        <LayersIcon style={iconStyles} />
        PROYECTOS
      </StyledListSubheader>
      <StyledDiv>
        {rolName !== "capacitadores" && rolName !== "institucionOferente" && (
          <ListItemLink to="proyectos/proyectos" text="Proyectos" />
        )}
      </StyledDiv>
      <StyledDiv>
        <ListItemLink to="proyectos/estado" text="Estado" />
      </StyledDiv>
      <Divider />
    </React.Fragment>
  );

export const thirdListItems = ({ rolName }) => (
  <React.Fragment>
    <StyledListSubheader component="div">
      <BorderColorIcon style={iconStyles} />
      ACTIVIDADES
    </StyledListSubheader>
    <StyledDiv>
      <ListItemLink to="actividades/actividades" text="Actividades" />
    </StyledDiv>
    {rolName !== "docente" && (
      <>
        <StyledListSubheader component="div">
          <CalendarMonthIcon style={iconStyles} />
          PERIODOS
        </StyledListSubheader>
        <StyledDiv>
          <ListItemLink to="periodos/periodos" text="Períodos" />
        </StyledDiv>
      </>
    )}
    <Divider />
  </React.Fragment>
);

export const fourthListItems = ({ rolName }) => (
  <React.Fragment>
    <StyledListSubheader component="div">
      <AssignmentIcon style={iconStyles} />
      Mis Inscripciones
    </StyledListSubheader>
    <StyledDiv>
      <ListItemLink to="inscripcion/preinscripcionlista" text="Inscripciones" />
    </StyledDiv>
    <StyledDiv>
      <ListItemLink to="certificados/certificaciones" text="Mis Certificados" />
    </StyledDiv>
    <Divider />
  </React.Fragment>
);

export const fifthListItems = ({ rolName }) =>
  rolName !== "docente" && (
    <React.Fragment>
      <StyledListSubheader component="div">
        <RedeemIcon style={iconStyles} />
        CERTIFICADOS
      </StyledListSubheader>
      {/* <StyledDiv>
        {rolName !== "institucionOferente" && rolName !== "capacitadores" && (
          <ListItemLink to="certificados/cargaExterna" text="Carga Externa" />
        )}
      </StyledDiv>
      <StyledDiv onClick={(e) => window.location.reload()}>
        <ListItemLink
          to="certificados/certificaciones"
          text="Certificaciones"
        />
      </StyledDiv> */}
      <StyledDiv onClick={(e) => window.location.reload()}>
        <ListItemLink to="certificados/certificados" text="Certificados" />
      </StyledDiv>
    </React.Fragment>
  );
