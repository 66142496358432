import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, FormHelperText } from '@mui/material';
import { GetDepartamento, GetLocalidad, GetProvincias } from 'store/Localidad/Localidad.api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SavePreInscripcionExterna } from 'store/preInscripcion/preInscripcion.api';
import { useNavigate } from 'react-router-dom';
import Loader from 'components/Loader/Loader';
import { TokenContext } from 'context/TokenContext';

const PreInsForm = ({ proyectoId, actividadId }) => {

    const [selectedProvincia, setSelectedProvincia] = useState('');
    const [selectedDepartamento, setSelectedDepartamento] = useState('');
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { token, decodedToken, saveToken, removeToken } = useContext(TokenContext);

    // Query para provincias
    const { data: provincias = [] } = useQuery({ queryKey: ['provincias'], queryFn: GetProvincias });

    // Query para departamentos, depende de la provincia seleccionada
    const { data: departamentos = [] } = useQuery({
        queryKey: ['departamentos', selectedProvincia], queryFn: () => GetDepartamento(selectedProvincia, ""),
        enabled: !!selectedProvincia, // Ejecuta la query solo si hay una provincia seleccionada
    });

    // Query para localidades, depende del departamento seleccionado
    const { data: localidades = [] } = useQuery({
        queryKey: ['localidades', selectedDepartamento], queryFn: () => GetLocalidad(selectedDepartamento, ""),
        enabled: !!selectedDepartamento, // Ejecuta la query solo si hay un departamento seleccionado
    });

    const mutation = useMutation({
        mutationFn: SavePreInscripcionExterna,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['inscripciones'] });
        },
        onError: (error) => {
            Swal.fire({
                icon: 'error',
                title: '¡Error!',
                text: `${error}`,
            });
        }
    });

    const formik = useFormik({
        initialValues: {
            documento: decodedToken?.nickname,
            // cuit: '',
            nombres: decodedToken?.given_name,
            apellidos: decodedToken?.family_name,
            estado_civil: '',
            correo: decodedToken?.email,
            proyecto: '',
            // sexo: '',
            telefono: '',
            domicilio: '',
            provincia: '',
            Localidad: '',
            Departamento: '',
            // fechaNacimiento: '',
        },
        validationSchema: Yup.object({
            documento: Yup.string().required('Campo obligatorio'),
            // fechanacimiento: Yup.string().required('Campo obligatorio'),
            // cuit: Yup.string().required('Campo obligatorio'),
            nombres: Yup.string().required('Campo obligatorio'),
            apellidos: Yup.string().required('Campo obligatorio'),
            estado_civil: Yup.string().required('Campo obligatorio'),
            // sexo: Yup.string().required('Campo obligatorio'),
            correo: Yup.string().email('Correo electrónico no válido').required('Campo obligatorio'),
            Departamento: Yup.string().required('Campo obligatorio'),
            Localidad: Yup.string().required('Campo obligatorio'),
            provincia: Yup.string().required('Campo obligatorio'),
            domicilio: Yup.string().required('Campo obligatorio'),
            telefono: Yup.string().required('Campo obligatorio'),
        }),
        onSubmit: (values) => {
            values.proyecto = proyectoId;
            values.actividad = actividadId;
            const data = {
                estadoCivil: values.estado_civil,
                correo: values.correo,
                proyecto: proyectoId,
                actividad: actividadId,
                domicilio: values.domicilio,
                localidad: values.Localidad,
                departamento: values.Departamento,
                telefono: values.telefono
            }
            mutation.mutate(data, {
                onSuccess: (data) => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Preinscripción exitosa!',
                        text: '¡Preinscripción realizada con éxito!',
                    });
                    navigate('/inscripcion/preinscripcionlista');

                },
                onError: (error) => {
                    Swal.fire({
                        icon: 'error',
                        title: '¡Error!',
                        text: `${error}`,
                    });
                },
            });

            // Enviar datos a la API
            // fetch('https://api.tu-dominio.com/crearPreInscripcion', {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify(values),
            // })
            //     .then((response) => response.json())
            //     .then((data) => {
            //         if (data.success) {
            //             Swal.fire({
            //                 icon: 'success',
            //                 title: '¡Preinscripción exitosa!',
            //                 text: '¡Preinscripción realizada con éxito!',
            //             });
            //             formik.resetForm();
            //         } else {
            //             Swal.fire({
            //                 icon: 'error',
            //                 title: 'Error',
            //                 text: 'Hubo un error al realizar la preinscripción.',
            //             });
            //         }
            //     })
            //     .catch((error) => {
            //         console.error('Error:', error);
            //         Swal.fire({
            //             icon: 'error',
            //             title: 'Error de conexión',
            //             text: 'Error al conectar con el servidor.',
            //         });
            //     });
        },
    });

    return (
        <>
            {mutation.isPending && !mutation.isError && <Loader />}

            <form onSubmit={formik.handleSubmit}>
                <TextField
                    fullWidth
                    margin="normal"
                    id="documento"
                    name="documento"
                    label="Documento"
                    value={formik.values.documento}
                    onChange={formik.handleChange}
                    error={formik.touched.documento && Boolean(formik.errors.documento)}
                    helperText={formik.touched.documento && formik.errors.documento}
                    disabled
                />
                {/* <TextField
                fullWidth
                margin="normal"
                id="cuit"
                name="cuit"
                label="CUIL"
                value={formik.values.cuit}
                onChange={formik.handleChange}
                error={formik.touched.cuit && Boolean(formik.errors.cuit)}
                helperText={formik.touched.cuit && formik.errors.cuit}
            /> */}
                <TextField
                    fullWidth
                    margin="normal"
                    id="nombres"
                    name="nombres"
                    label="Nombres"
                    value={formik.values.nombres}
                    onChange={formik.handleChange}
                    error={formik.touched.nombres && Boolean(formik.errors.nombres)}
                    helperText={formik.touched.nombres && formik.errors.nombres}
                    disabled
                />
                <TextField
                    fullWidth
                    margin="normal"
                    id="apellidos"
                    name="apellidos"
                    label="Apellidos"
                    value={formik.values.apellidos}
                    onChange={formik.handleChange}
                    error={formik.touched.apellidos && Boolean(formik.errors.apellidos)}
                    helperText={formik.touched.apellidos && formik.errors.apellidos}
                    disabled
                />
                {/* <TextField
                fullWidth
                margin="normal"
                id="fechanacimiento"
                name="fechanacimiento"
                label="Fecha de Nacimiento"
                type="date"  // Especifica que el campo es de tipo fecha
                InputLabelProps={{ shrink: true }}  // Asegura que la etiqueta se mantenga arriba del campo
                value={formik.values.fechanacimiento}
                onChange={formik.handleChange}
                error={formik.touched.fechanacimiento && Boolean(formik.errors.fechanacimiento)}
                helperText={formik.touched.fechanacimiento && formik.errors.fechanacimiento}
            /> */}
                <FormControl fullWidth margin="normal" error={formik.touched.estado_civil && Boolean(formik.errors.estado_civil)}>
                    <InputLabel id="EstadoCivil-label">Estado Civil</InputLabel>
                    <Select
                        labelId="EstadoCivil-label"
                        id="estado_civil"
                        name="estado_civil"
                        value={formik.values.estado_civil}
                        onChange={formik.handleChange}
                        error={formik.touched.estado_civil && Boolean(formik.errors.estado_civil)}
                        sx={{ textAlign: 'left' }}
                        label="Estado Civil"
                    >
                        <MenuItem value={2}>SOLTERO/A</MenuItem>
                        <MenuItem value={3}>CASADO/A</MenuItem>
                        <MenuItem value={4}>VIUDO/A</MenuItem>
                        <MenuItem value={5}>UNION DE HECHO</MenuItem>
                        <MenuItem value={6}>DIVORCIADO/A</MenuItem>
                        <MenuItem value={7}>SEPARADO/A DE HECHO</MenuItem>
                        <MenuItem value={8}>SEPARADO/A</MenuItem>
                    </Select>
                    {formik.touched.estado_civil && formik.errors.estado_civil && (
                        <FormHelperText>{formik.errors.estado_civil}</FormHelperText>
                    )}
                </FormControl>
                <TextField
                    fullWidth
                    margin="normal"
                    id="correo"
                    name="correo"
                    label="Correo"
                    value={formik.values.correo}
                    onChange={formik.handleChange}
                    error={formik.touched.correo && Boolean(formik.errors.correo)}
                    helperText={formik.touched.correo && formik.errors.correo}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    id="telefono"
                    name="telefono"
                    label="Telefono"
                    value={formik.values.telefono}
                    onChange={formik.handleChange}
                    error={formik.touched.telefono && Boolean(formik.errors.telefono)}
                    helperText={formik.touched.telefono && formik.errors.telefono}
                />
                {/* Select para Provincias */}
                <FormControl fullWidth margin="normal" error={formik.touched.provincia && Boolean(formik.errors.provincia)}>
                    <InputLabel id="provincia-label">Provincia</InputLabel>
                    <Select
                        labelId="provincia-label"
                        id="provincia"
                        name="provincia"
                        value={formik.values.provincia}
                        onChange={(e) => {
                            const provinciaId = e.target.value;
                            formik.setFieldValue('provincia', provinciaId);
                            setSelectedProvincia(provinciaId); // Actualiza la provincia seleccionada
                            formik.setFieldValue('Departamento', ''); // Reinicia el departamento
                            formik.setFieldValue('Localidad', ''); // Reinicia la localidad
                        }}
                        label="provincia"
                        sx={{ textAlign: 'left' }}
                        error={formik.touched.provincia && Boolean(formik.errors.provincia)}
                    >
                        {provincias?.data?.map((provincia) => (
                            <MenuItem key={provincia.idProvincia} value={provincia.idProvincia}>
                                {provincia.nombre}
                            </MenuItem>
                        ))}
                    </Select>
                    {formik.touched.provincia && formik.errors.provincia && (
                        <FormHelperText>{formik.errors.provincia}</FormHelperText>
                    )}
                </FormControl>

                {/* Select para Departamentos */}
                <FormControl fullWidth margin="normal" disabled={!selectedProvincia} error={formik.touched.Departamento && Boolean(formik.errors.Departamento)}>
                    <InputLabel id="departamento-label">Departamento</InputLabel>
                    <Select
                        labelId="departamento-label"
                        id="Departamento"
                        name="Departamento"
                        value={formik.values.Departamento}
                        onChange={(e) => {
                            const departamentoId = e.target.value;
                            formik.setFieldValue('Departamento', departamentoId);
                            setSelectedDepartamento(departamentoId); // Actualiza el departamento seleccionado
                            formik.setFieldValue('Localidad', ''); // Reinicia la localidad
                        }}
                        label="Departamento"
                        sx={{ textAlign: 'left' }}
                        error={formik.touched.Departamento && Boolean(formik.errors.Departamento)}
                    >
                        {departamentos?.data?.map((departamento) => (
                            <MenuItem key={departamento.idDepartamento} value={departamento.idDepartamento}>
                                {departamento.nombre}
                            </MenuItem>
                        ))}
                    </Select>
                    {formik.touched.Departamento && formik.errors.Departamento && (
                        <FormHelperText>{formik.errors.Departamento}</FormHelperText>
                    )}
                </FormControl>

                {/* Select para Localidades */}
                <FormControl fullWidth margin="normal" disabled={!selectedDepartamento} error={formik.touched.Localidad && Boolean(formik.errors.Localidad)}
                >
                    <InputLabel id="localidad-label">Localidad</InputLabel>
                    <Select
                        labelId="localidad-label"
                        id="Localidad"
                        name="Localidad"
                        value={formik.values.Localidad}
                        onChange={formik.handleChange}
                        label="Localidad"
                        sx={{ textAlign: 'left' }}
                    >
                        {localidades?.data?.map((localidad) => (
                            <MenuItem key={localidad.idLocalidad} value={localidad.idLocalidad}> 
                                {localidad.localidad}
                            </MenuItem>
                        ))}
                    </Select>
                    {formik.touched.Localidad && formik.errors.Localidad && (
                        <FormHelperText>{formik.errors.Localidad}</FormHelperText>
                    )}
                </FormControl>

                <TextField
                    fullWidth
                    margin="normal"
                    id="domicilio"
                    name="domicilio"
                    label="Domicilio"
                    value={formik.values.domicilio}
                    onChange={formik.handleChange}
                    error={formik.touched.domicilio && Boolean(formik.errors.domicilio)}
                    helperText={formik.touched.domicilio && formik.errors.domicilio}
                />
                {/* <FormControl fullWidth margin="normal">
                <InputLabel id="Sexo-label">Sexo</InputLabel>
                <Select
                    labelId="Sexo-label"
                    id="sexo"
                    name="sexo"
                    value={formik.values.sexo}
                    onChange={formik.handleChange}
                    error={formik.touched.sexo && Boolean(formik.errors.sexo)}
                    sx={{ textAlign: 'left' }}
                    label="Sexo"
                >
                    <MenuItem value={2}>MASCULINO</MenuItem>
                    <MenuItem value={1}>FEMENINO</MenuItem>
                    <MenuItem value={4}>NO BINARIO</MenuItem>
                </Select>
            </FormControl> */}
                <Button color="primary" variant="contained" fullWidth type="submit">
                    Enviar
                </Button>
            </form>
        </>
    );
};

export default PreInsForm;
