import { request } from "utils/helpers/request.helpers";

const alumnosURL = `${process.env.REACT_APP_API_URL_DEV}/api/Alumno`;

export function getAlumnosApi(page) {
  const url = `${alumnosURL}/Listar`; // ENDPOINT Paginado.
  return request(url, { method: "GET" });
}

export function getAlumnosByActApi(actividadData, page) {
  const url = `${alumnosURL}/getAlumnos_ByActividad?pIdActividad=${actividadData}&page=${page}`;
  return request(url, { method: "GET" });
}
