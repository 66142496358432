import * as Yup from "yup";

export const vsResponsable = Yup.object({
  fechaInicioServ: Yup.date().required("Este campo es requerido"),
  fechaFinServ: Yup.date()
    .required("Este campo es requerido")
    .min(
      Yup.ref("fechaInicioServ"),
      "La fecha de fin debe ser mayor o igual a la fecha de inicio"
    ),
  idResponsabilidad: Yup.string().required("Este campo es requerido"),
  cuit: Yup.string().required("Este campo es requerido"),
  idPersona: Yup.string().required("Este campo es requerido"),
});
