import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

import {
  GetResponsablesEndPoint,
  RemoveCapacitadorEndPoint,
} from "store/capacitadores/capacitadores.slice";
import { myPageProyectos } from "views/ProyectosInicio/ProyectosIPage/ProyectosIPage";
import {
  deleteNiveles,
  getProyectos,
} from "store/inicioProyectos/IProyectos.slice";
import {
  getProyectosConNiveles,
  selectProyectosConNiveles,
} from "store/proyectos/proyectos.slice";

export default function NivelesEnsenanzaEliminar({ itemProy, itemResp }) {
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const page = useContext(myPageProyectos);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {

    dispatch(deleteNiveles([itemProy.idProyecto, itemResp.idNivelEnsenanza]));
    setOpen(false);

    setTimeout(() => {
      dispatch(getProyectos(page ? page : 1));
    }, 400);

    setTimeout(() => {
      dispatch(getProyectosConNiveles(itemProy.idProyecto));
    }, 400);
  };

  return (
    <React.Fragment>
      <IconButton variant="outlined" onClick={handleClickOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog open={open}>
        <DialogTitle>Eliminar Nivel de Enseñanza</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que deseas eliminar{" "}
            <b>"{itemResp?.nivelEnsenanza}"</b> como nivel de enseñanza?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleSubmit} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
