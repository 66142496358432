export const initialValues = {
  idPersona: null,
  tipoDocumento: "",
  documento: null,
  cuit: null,
  apellidos: null,
  nombres: null,
  sexo: "",
  fechaNacimiento: null,
  telefono: null,
  email: null,
};

export const source_tiposDeDocumentos = [
  {
    id: 0,
    value: "DNI",
  },
  {
    id: 1,
    value: "LC",
  },
  {
    id: 2,
    value: "LE",
  },
  {
    id: 4,
    value: "PAS",
  },
];

export const source_sexos = [
  {
    id: 1,
    value: "FEMENINO",
  },
  {
    id: 2,
    value: "MASCULINO",
  },
  {
    id: 4,
    value: "NO BINARIO",
  },
];

export const mensaje =
  "Ya existe una persona con este CUIL.";
