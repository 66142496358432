import { request } from "utils/helpers/request.helpers";

const institucionUrl = `${process.env.REACT_APP_API_URL_DEV}/api/Institucion`;
const proyectosUrl = `${process.env.REACT_APP_API_URL_DEV}/api/Proyecto`;
const ActividadUrl = `${process.env.REACT_APP_API_URL_DEV}/api/Actividad`;
const ResolucionUrl = `${process.env.REACT_APP_API_URL_DEV}/api/Resolucion`;

export function ListarTodastitucion() {
  try {
    return request(`${institucionUrl}/ListarTodas`, { 
      method: 'GET',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(),
    });
  } catch (error) {
    console.log("error", error.response);
  }
}

export function ListarProyecto(id) {
  try {
    return request(`${proyectosUrl}/ListarProyectosInstitucion/${id}`, { 
      method: 'GET',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(),
    });
  } catch (error) {
    console.log("error", error.response)
  }
}

export function ListarActividad(id) {
  try {
    return request(`${ActividadUrl}/GetActividad_DeUnProyecto?idProyecto=${id}`, { 
      method: 'GET',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(),
    });
  } catch (error) {
    console.log("error", error.response)
  }
}

export function PostCargaExtra({ idProyecto, idActividad, archivoExcel }) {
  const formData = new FormData();
  formData.append('file', archivoExcel);
  
  return request(`${ResolucionUrl}/ImportExcel?idProyecto=${idProyecto}&idActividad=${idActividad}`, {
    method: 'POST',
    body: formData,
  });
}
