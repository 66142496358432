import React from "react";
import Grid from "@mui/material/Grid";
import 'dayjs/locale/es';
import Box from "@mui/material/Box";
import { FormControl, Stack } from "@mui/material";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Formik } from "formik";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { vsResolucion } from "./YupResolucion";

export default function ResolucionForm({ data, onSubmit, onClose }) {

  console.log('data en ResolucionForm', data)

  dayjs.extend(customParseFormat);
  const OrgEmiso = [
    { id: 1, label: "S.P.C.E.(ME)" },
    { id: 2, label: "M.E." },
  ];

  const handleSubmit = (e) => {
    onSubmit(e);
  };

  const handleClose = (event, reason) => {
    onClose();
  };

  const findOrgEmisor = (label) => {
    return OrgEmiso.find((org) => org.label === label)?.id || null;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <Formik
        initialValues={{
          idResolucion: data?.idResolucion || 0,
          descripcion: data?.descripcion || "",
          idProyecto: data?.idProyecto || null,
          orgEmisor: data?.orgEmisor || null,
          nroResolucion: data?.nroResolucion || "",
          anio: data?.anio || null,
          fechaResolucion: data?.fechaResolucion ? dayjs(data?.fechaResolucion) : null,
        }}
        validationSchema={vsResolucion}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          handleChange,
          touched,
          setFieldValue,
          submitForm,
          setFieldTouched,
        }) => (
          <Box component="form">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={Boolean(errors.orgEmisor) && touched.orgEmisor}
                >
                  <Autocomplete
                    onChange={(e, value) => {
                      setFieldValue("orgEmisor", value ? value.label : null);
                    }}
                    options={OrgEmiso}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    name="orgEmisor"
                    value={values.orgEmisor}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Organismo Emisor"
                        margin="normal"
                        variant="outlined"
                        autoComplete="off"
                        InputProps={{ ...params.InputProps, type: "search" }}
                        error={Boolean(errors.orgEmisor) && touched.orgEmisor}
                        sx={{
                          "input[type='search']::-webkit-search-cancel-button":
                            {
                              display: "none",
                            },
                        }}
                        helperText={
                          Boolean(errors.orgEmisor) &&
                          touched.orgEmisor &&
                          errors.orgEmisor
                        }
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Nº de resolución"
                    type="text"
                    name="nroResolucion"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      const onlyNumbers = e.target.value.replace(/[^\d]/g, ""); // Eliminar todo excepto los números
                      setFieldValue("nroResolucion", onlyNumbers);
                    }}
                    autoComplete="off"
                    value={values.nroResolucion}
                    error={Boolean(
                      errors.nroResolucion && touched.nroResolucion
                    )}
                    helperText={
                      Boolean(errors.nroResolucion) &&
                      touched.nroResolucion &&
                      errors.nroResolucion
                    }
                    inputProps={{ maxLength: 4 }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Año"
                    type="text"
                    name="anio"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      const onlyNumbers = e.target.value.replace(/[^\d]/g, ""); // Eliminar todo excepto los números
                      setFieldValue("anio", onlyNumbers);
                    }}
                    autoComplete="off"
                    value={values.anio}
                    error={Boolean(errors.anio && touched.anio)}
                    helperText={
                      Boolean(errors.anio) && touched.anio && errors.anio
                    }
                    inputProps={{ maxLength: 4 }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <DatePicker
                    label="Fecha de Resolución"
                    name="fechaResolucion"
                    value={values.fechaResolucion}
                    onChange={(value) => setFieldValue("fechaResolucion", value)}
                    slotProps={{
                      textField: {
                        helperText:
                          Boolean(errors.fechaResolucion) &&
                          touched.fechaResolucion &&
                          errors.fechaResolucion,
                        error:
                          Boolean(errors.fechaResolucion) &&
                          Boolean(touched.fechaResolucion),
                      },
                    }}
                  />
                </FormControl>
              </Grid>

            </Grid>
            <Stack
              spacing={2}
              direction="row"
              sx={{ display: "flex", justifyContent: "flex-end", mt: 3, mb: 2 }}
            >
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleClose()}
                spacing={2}
              >
                Cancelar
              </Button>
              <Button variant="contained" onClick={() => submitForm()}>
                Guardar
              </Button>
            </Stack>
          </Box>
        )}
      </Formik>
    </LocalizationProvider>
  );
}
