import { request } from "utils/helpers/request.helpers";

const actividadesUrl = `${process.env.REACT_APP_API_URL_LOGIN_DEV}`;

export function GetActividad(page) {
  // Todas las Actividades, para pestaña "Actividades".
  const url = `${actividadesUrl}/GetActividad?page=${page}`; // ENDPOINT Paginado.
  return request(url, { method: "GET" });
}

export function GetActividadesXProyecto(idProyecto) {
  console.log("GetActividadesXProyecto was")
  // Actividades por Proyecto, no paginado.
  const url = `${actividadesUrl}/Inscripcion/GetActividadByProyecto?idProyecto=${idProyecto}`;
  return request(url, { method: "GET" });
}

export function SaveActividad(body) {
  return request(`${actividadesUrl}/SaveActividad`, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(body),
  });
}

export function UpdateActividad({ data }) {
  return request(`${actividadesUrl}/EditActividad`, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function DeleteActividad(id) {
  return request(`${actividadesUrl}/Deshabilitar?idActividad=${id}`, {
    method: "DELETE",
  });
}

export function GetDenominaciones(page) {
  const url = `${actividadesUrl}/GetDenominaciones`; // Muestra todas las Actividades con deshabilitado = 0.
  return request(url, { method: "GET" });
}

export function GetActividadesAprobadasAll() {
  const url = `${actividadesUrl}/ActividadesAprobadasAll`; // Muestra todas las Actividades con deshabilitado = 0.
  return request(url, { method: "GET" });
}

export function GetActividadesAprobadasSinPeriodo() {
  // Muestra todas las Actividades aprobadas y sin Período asignado.
  const url = `${actividadesUrl}/ActividadesAprobadasSinPeriodo`;
  return request(url, { method: "GET" });
}

export function GetActividadById(idActividad) {
  // Muestra todas las Actividades con deshabilitado = 0.
  const url = `${actividadesUrl}/GetActividadById?idActividad=${idActividad}`;
  return request(url, { method: "GET" });
}

export function FiltrarActividades(page, data) {
  const queryParams = new URLSearchParams();
  if (data) queryParams.append("pBusqueda", data);
  if (page) queryParams.append("page", page);

  const url = `${actividadesUrl}/FiltrarActividades?${queryParams.toString()}`;
  return request(url, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
}
