import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ResponsableEliminar from "../ResponsablesEliminar/ResponsablesEliminar";
import ResponsableEditar from "../ResponsablesEditar/ResponsablesEditar";
import { formatDDMMYY } from "utils/helpers/dateFormat";
import {
  tableBodyCell,
  tableBodyCenterCell,
  tableHeadCell,
} from "components/customTable/StylesTable";

export default function ResponsablesTable({ itemProy, datosResp }) {
  return (
    <TableContainer sx={{ maxHeight: 350 }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead
          sx={{
            borderTop: "1px solid", // Agregar borde superior
          }}
        >
          <TableRow>
            <TableCell sx={{ ...tableHeadCell }}>ID</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Apellido y Nombre</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Responsabilidad</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>E-mail</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Teléfono</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Inicio de Servicio</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Fin de Servicio</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Causa Baja</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {datosResp?.map((itemResp) => (
            <TableRow key={itemResp.idCapacitador}>
              <TableCell sx={{ ...tableBodyCell }}>
                {itemResp.idCapacitador}
              </TableCell>
              <TableCell sx={{ ...tableBodyCell }}>
                {itemResp.persona.apellidoyNombre}
              </TableCell>
              <TableCell sx={{ ...tableBodyCell }}>
                {itemResp.responsabilidad.descripcion}
              </TableCell>
              <TableCell sx={{ ...tableBodyCell }}>
                {itemResp.persona.email}
              </TableCell>
              <TableCell sx={{ ...tableBodyCell }}>
                {itemResp.persona.telefono}
              </TableCell>
              <TableCell sx={{ ...tableBodyCell }}>
                {formatDDMMYY(itemResp.fechaInicioServ)}
              </TableCell>
              <TableCell sx={{ ...tableBodyCell }}>
                {formatDDMMYY(itemResp.fechaFinServ)}
              </TableCell>
              <TableCell sx={{ ...tableBodyCell }}>
                {itemResp?.causasBaja?.descripcion || "SIN BAJA"}
              </TableCell>
              <TableCell sx={{ ...tableBodyCenterCell }}>
                <ResponsableEditar itemProy={itemProy} itemResp={itemResp} />
                <ResponsableEliminar itemProy={itemProy} itemResp={itemResp} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
