import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
} from "@mui/material";
import Loader from "components/Loader/Loader";
import {
  GetObservacionEndPoint,
  PostObservacionEndpoint,
  selectIsLoading,
} from "store/observaciones/observaciones.slice";

export default function ObservacionesNuevo({ itemProy }) {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [descripcion, setDescripcion] = useState("");

  const loadingEddit = useSelector(selectIsLoading);

  const handleDescripcionChange = (event) => {
    setDescripcion(event.target.value);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSaveChanges = () => {
    const dataToUpdate = {
      idProyecto: itemProy.idProyecto,
      fechaObservacion: itemProy.fechaInicio,
      fechaUltMod: itemProy.fechaFin,
      descripcion,
    };
    dispatch(PostObservacionEndpoint(dataToUpdate))
      .then(() => dispatch(GetObservacionEndPoint(dataToUpdate.idProyecto)))
      .catch((error) => console.error("Error en la eliminación: ", error));
    setDescripcion("");
    setOpenModal(false);
  };

  if (loadingEddit) return <Loader />;

  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={12} md={8}>
          <Button variant="contained" onClick={() => setOpenModal(true)}>
            Nuevo
          </Button>
        </Grid>
      </Grid>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Agregar Observación</DialogTitle>
        <DialogContent>
          <TextField
            multiline
            fullWidth
            variant="outlined"
            style={{ marginTop: "10px" }}
            label="Descripción"
            rows={5}
            placeholder="Descripción"
            value={descripcion}
            onChange={handleDescripcionChange}
            inputProps={{ maxLength: 1000 }}
          />
          <div style={{ marginTop: "5px", marginLeft: "10px" }}>
            {descripcion.length} / 1000 caracteres
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseModal} color="primary">
            Cancelar
          </Button>
          <Button variant="contained" onClick={handleSaveChanges} color="primary" disabled={!descripcion.length}>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
