import React, { useContext } from "react";
import { useDispatch } from "react-redux";

import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import CapacitadorForm from "../CapacitadoresForm/CapacitadoresForm";
import { EditCapacitadorEndPoint } from "store/capacitadores/capacitadores.slice";
import { GetActividadEndPoint } from "store/actividades/actividades.slice";
import { GetCapacitadorEndPoint } from "store/capacitadores/capacitadores.slice";
import { showSnackbar } from "store/mensajeria/mensajeriaSlice";
import { ActividadesContext } from "views/Actividades/ActividadesPage/ActividadesPage";

export default function CapacitadorEditar({ itemAct, itemCap }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const page = useContext(ActividadesContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmitAct = (data) => {
    setOpen(false);
    dispatch(EditCapacitadorEndPoint({ data })).then((resultAction) => {
      if (EditCapacitadorEndPoint.fulfilled.match(resultAction))
        dispatch(showSnackbar(resultAction.payload.message));
    });

    setTimeout(() => {
      dispatch(GetCapacitadorEndPoint(itemAct?.idActividad || 0)); //Actualizamos Tabla de Capacitadores.
    }, 400);

    setTimeout(() => {
      //Actualizamos Tabla de Actividades
      dispatch(GetActividadEndPoint(page ? page : 1)); //Sabemos en tiempo real la cantidad de Capacitadores
    }, 400); //para una determinada Actividad.
  };

  return (
    <React.Fragment>
      <IconButton variant="outlined" onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> Editar Capacitador </DialogTitle>
        <DialogContent>
          <CapacitadorForm
            itemAct={itemAct}
            itemCap={itemCap}
            onSubmitCap={handleSubmitAct}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
