import React from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';


export default function CapacitadoresTituloModal({ itemAct }) {                // Actividad del Capacitador.

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
    fontSize: '15px',
    marginBottom: '15px',
  }));

  return (
    <Box component="form" sx={{ mt: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={14}>
          <FormControl fullWidth >
            <Item>
              <b> Descripción de Actividad: </b>
              {itemAct?.descripcion ?? 'Sin Información'}
            </Item>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
}
