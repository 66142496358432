import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ResolucionesEditar from "../ResolucionesEditar/ResolucionesEditar";
import ResolucionesEliminar from "../ResolucionesEliminar/ResolucionesEliminar";
import {
  tableBodyCell,
  tableBodyCenterCell,
  tableHeadCell,
} from "components/customTable/StylesTable";
import { formatDDMMYY } from "utils/helpers/dateFormat";
export default function ResolucionesTable({ itemAct, datosCap }) {
  return (
    <TableContainer sx={{ maxHeight: 350 }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead
          sx={{
            borderTop: "1px solid", // Agregar borde superior
          }}
        >
          <TableRow>
            <TableCell sx={{ ...tableHeadCell }}>ID</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Descripción</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Org Emisor</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>N Resol</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Año</TableCell>
            {/* <TableCell sx={{ ...tableHeadCell }}>Superior Autorizante</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Elaborador</TableCell> */}
            <TableCell sx={{ ...tableHeadCell }}>
              <div style={{ textAlign: "center" }}>Fecha</div>
              <div style={{ textAlign: "center" }}>Resolución</div>
            </TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {datosCap?.map((itemCap) => (
            <TableRow key={itemCap.idResolucion}>
              <TableCell sx={{ ...tableBodyCell }}>
                {itemCap.idResolucion}
              </TableCell>
              <TableCell sx={{ ...tableBodyCell }}>
                {itemCap.descripcion}
              </TableCell>
              <TableCell sx={{ ...tableBodyCell }}>
                {itemCap.orgEmisor}
              </TableCell>
              <TableCell sx={{ ...tableBodyCell }}>
                {itemCap.nroResolucion}
              </TableCell>
              <TableCell sx={{ ...tableBodyCell }}>{itemCap.anio}</TableCell>
              {/* <TableCell ssx={{ ...tableBodyCell }}>
                {itemCap.supAutorizante}
              </TableCell>
              <TableCell sx={{ ...tableBodyCell }}>
                {itemCap.elaboradorResol}
              </TableCell> */}
              <TableCell sx={{ ...tableBodyCell }} style={{ textAlign: "center" }}>
                {formatDDMMYY(itemCap.fechaResolucion)}
              </TableCell>
              <TableCell sx={{ ...tableBodyCenterCell }}>
                <ResolucionesEditar itemAct={itemAct} itemCap={itemCap} />
                <ResolucionesEliminar itemAct={itemAct} itemCap={itemCap} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
