import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchProyectos,
  createProyecto,
  editProyecto,
  fetchProyectoById,
  deleteProyecto,
  ListarProyectos,
  createNivelesProy,
  deleteNivelesProy,
} from "./IProyectos.api";

const initialState = {
  entities: [],
  entitiesAll: [],
  entitiesFilter: [],
  newNiveles: [],
  deletexNiveles: {},
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
};

export const getProyectos = createAsyncThunk(
  "IProyectos/fetchProyectos",
  async ({ page = 1, data = "" }) => {
    const r = await fetchProyectos(page, data);
    return r;
  }
);

export const createNewProyect = createAsyncThunk(
  "IProyectos/createProyecto",
  async (data) => {
    const r = await createProyecto(data);
    return r;
  }
);

export const editProyect = createAsyncThunk(
  "IProyectos/editProyecto",
  async (data) => {
    const r = await editProyecto(data);
    return r;
  }
);

export const getProyectoById = createAsyncThunk(
  "IProyectos/fetchProyectoById",
  async (id) => {
    const r = await fetchProyectoById(id);
    return r;
  }
);

export const deleteProyectos = createAsyncThunk(
  "IProyectos/deleteProyectos",
  async (id) => {
    const r = await deleteProyecto(id);
    return r;
  }
);
export const deleteNiveles = createAsyncThunk(
  "IProyectos/deleteNiveles",
  async ([id, idnivel]) => {
    const r = await deleteNivelesProy([id, idnivel]);
    return r;
  }
);

export const newNivelesProy = createAsyncThunk(
  "IProyectos/newNivelesProy",
  async (data) => {
    const r = await createNivelesProy(data);
    return r;
  }
);

export const ListarProyectosEndpoint = createAsyncThunk(
  "IProyectos/ListarProyectos",
  async () => {
    const r = await ListarProyectos();
    return r;
  }
);

export const IProyectosSlice = createSlice({
  name: "IProyectos",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProyectos.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProyectos.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(getProyectos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getProyectoById.pending, (state) => {
        // pendiente.
        state.loading = false;
        state.error = null;
      })

      .addCase(getProyectoById.fulfilled, (state, action) => {
        // cumplida.
        state.loading = false;
        state.proyectoById = action.payload;
      })
      .addCase(getProyectoById.rejected, (state, action) => {
        // rechazado.
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(createNewProyect.pending, (state) => {
        state.creating = true;
        state.error = null;
      })
      .addCase(createNewProyect.fulfilled, (state, action) => {
        state.creating = false;
        state.messageResponse = action.payload.message;
      })
      .addCase(createNewProyect.rejected, (state, action) => {
        state.creating = false;
        state.error = action.error.message;
      })

      .addCase(newNivelesProy.pending, (state) => {
        state.creating = true;
        state.error = null;
      })
      .addCase(newNivelesProy.fulfilled, (state, action) => {
        state.creating = false;
        state.newNiveles = action.payload.data;
      })
      .addCase(newNivelesProy.rejected, (state, action) => {
        state.creating = false;
        state.error = action.error.message;
      })

      .addCase(editProyect.pending, (state) => {
        state.editing = true;
        state.error = null;
      })
      .addCase(editProyect.fulfilled, (state, action) => {
        state.editing = false;
        state.entities = action.payload;
      })
      .addCase(editProyect.rejected, (state, action) => {
        state.editing = false;
        state.error = action.error.message;
      })

      .addCase(deleteProyectos.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteProyectos.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(deleteProyectos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      //DeleteNiveles
      .addCase(deleteNiveles.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteNiveles.fulfilled, (state, action) => {
        state.loading = false;
        state.deletexNiveles = action.payload;
      })
      .addCase(deleteNiveles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      /* ListarProyectosEndpoint */
      .addCase(ListarProyectosEndpoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(ListarProyectosEndpoint.fulfilled, (state, action) => {
        state.loading = false;
        state.entitiesAll = action.payload;
      })
      .addCase(ListarProyectosEndpoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const selectProyectos = (state) => state.IProyectos.entities;
export const selectProyectosAll = (state) => state.IProyectos.entitiesAll;
export const selecNewNivelProy = (state) => state.IProyectos.newNiveles;
export const selecEliminarNivel = (state) => state.IProyectos.deletexNiveles;
export const selectProyectoById = (state) => state.IProyectos.proyectoById;
export const selectProyectosResponse = (state) => state.IProyectos.response;
export const selectIsLoadingProyecto = (state) => state.IProyectos.loading;

export const selectIsLoading = (state) => state.IProyectos.loading;
export const selectIsCreating = (state) => state.IProyectos.creating;
export const selectIsEditing = (state) => state.IProyectos.editing;
export const selectIsDeleting = (state) => state.IProyectos.deleting;
export const selectError = (state) => state.IProyectos.error;

export default IProyectosSlice.reducer;
