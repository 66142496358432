import React, { useContext } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { formatDDMMYY } from "utils/helpers/dateFormat";
import EditIcon from "@mui/icons-material/Edit";
import EliminarPersona from "../Modal/Eliminar/EliminarPersona";
import { IconButton, Tooltip } from "@mui/material";
import { PersonaContext } from "../Modal/Common/PersonaProvider";
import {
  tableBodyCell,
  tableBodyCenterCell,
  tableHeadCell,
} from "components/customTable/StylesTable";

const PersonaTable = ({ personas, onSelect }) => {
  const { setPersona } = useContext(PersonaContext);
  const handleModificarPersona = (persona) => {
    setPersona(persona);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: 0 }}>
      <TableContainer sx={{ maxHeight: 450 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            sx={{
              borderTop: "1px solid", // Agregar borde superior
            }}
          >
            <TableRow>
              <TableCell sx={{ ...tableHeadCell }}>ID</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Apellido y nombre</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Sexo</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Tipo de documento</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Documento</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>CUIL</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>
                Fecha de nacimiento
              </TableCell>
              <TableCell sx={{ ...tableHeadCell }}>
                Lugar de nacimiento
              </TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Estado civil</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Domicilio</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Localidad</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Departamento</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Telefono</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Email</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {personas?.length > 0 ? (
              personas?.map((persona) => (
                <TableRow key={persona.idPersona}>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {persona.idPersona}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {persona.nombres} {persona.apellidos}
                  </TableCell>
                  <TableCell ssx={{ ...tableBodyCell }}>
                    {persona?.sexos.descripcion}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {persona?.tipo_documento.descripcion}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {persona.documento}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {persona.cuit}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {formatDDMMYY(persona.fechaNacimiento)}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {persona.lugarNacimiento}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {persona?.estadoCiviles?.estadoCivil}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {persona.domicilio}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {persona?.departamentos?.nombre}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {persona?.localidadResidencia?.localidad}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {persona.telefono}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {persona.email}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCenterCell }}>
                    <Tooltip title="Modificar">
                      <IconButton
                        variant="outlined"
                        onClick={() => {
                          handleModificarPersona(persona);
                          onSelect(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    {<EliminarPersona persona={persona} />}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={15} sx={{ textAlign: "center" }}>
                  No se encontraron resultados
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default PersonaTable;
