import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchListarProyectos } from './listarProyectos.api';

const initialState = {
  entities: [],
  loading: false,
  error: null,
};

export const getListarProyectos = createAsyncThunk(
  'ListarProyectos/getListarProyectos',
  async () => {
    const data = await fetchListarProyectos();
    return data;
  }
);

export const ListarProyectosSlice = createSlice({
  name: 'listarProyectos',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListarProyectos.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getListarProyectos.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(getListarProyectos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});

export const selectListarProyectos = (state) => state.listarProyectos.entities;
export const filterProyectosLoading = (state) => state.listarProyectos.entities;
export const selectListarProyectosError = (state) => state.listarProyectos.error;

export default ListarProyectosSlice.reducer;
