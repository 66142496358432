import React, { createContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {
  getInstituciones,
  removeInstitucion,
  selectIsLoadingInstitucion,
  selectInstituciones,
  getSearchInstituciones,
  selectSearchResults,
  selectResponse,
} from "store/Institucion/institucion.slice";
import { GenericTemplate } from "template/GenericTemplate";
import Loader from "components/Loader/Loader";
import { getMisPreinscripciones } from "store/preInscripcion/preInscripcion.api";
import { useQuery } from "@tanstack/react-query";

export const myPageInstitucion = createContext(1);

export default function PreInsList() {
//   const dispatch = useDispatch();
//   const isLoading = useSelector(selectIsLoadingInstitucion);
//   const response = useSelector(selectResponse);
//   const searchResults = useSelector(selectSearchResults);
//   const [page, setPage] = useState(1);
//   const [isSearching, setIsSearching] = useState(false);

//   useEffect(() => {
//     if (!isSearching) {
//       dispatch(getInstituciones(page));
//     }
//   }, [dispatch, page, isSearching, response]);

//   const handleSearch = (e) => {
//     if (e.code !== "Enter") return;
//     if (e.target.value.trim() === "") {
//       setIsSearching(false);
//       window.location.reload(true);
//     } else {
//       dispatch(getSearchInstituciones(e.target.value));
//       setIsSearching(true);
//     }
//   };

//   const fetchInstituciones = useSelector(selectInstituciones);

//   let tablaInstituciones =
//     isSearching && searchResults
//       ? searchResults
//       : fetchInstituciones.records || [];

const { data: inscripciones = [], isLoading } = useQuery({
    queryKey: ['inscripciones'], queryFn: () => getMisPreinscripciones(),
    //enabled: !!dni, // Ejecuta la query solo si hay un departamento seleccionado
});

  const convertInstituciones = inscripciones.map((ins) => ({
    ID: ins.idPreinscripcion,
    "Fecha PreInscripcion": new Date(ins.fechapreInscripcion).toLocaleDateString('es-AR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }),
    Proyecto: ins.proyecto,
    Actividad: ins.actividad,
    Estado: ins.estado,
  }));

  const dataEmpty = [
    {
    },
  ];

//   const handleChange = (event, value) => {
//     setPage(value);
//   };

  return (
    <>
        <React.Fragment>
          {isLoading && <Loader />}
          { convertInstituciones.length !== 0  ? 
          
          <GenericTemplate
            titleName=""
            // handleSearch={handleSearch}
            dataTable={
              convertInstituciones.length === 0
                ? dataEmpty
                : convertInstituciones
            }
            // Form={InstitucionesForm}
            // deleteFuncion={removeInstitucion}
            siEdit={""}
            siDelete={""}
            siTable
          />

          : <>No existen Inscripciones actualmente</>
          }
          {/* {!isSearching && (
            <Stack spacing={2} style={{ margin: "20px 0px 30px 0px" }}>
              <Pagination
                count={fetchInstituciones?.pages || 1}
                page={page}
                onChange={handleChange}
                color="primary"
              />
            </Stack>
          )} */}
        </React.Fragment>
    </>
  );
}
