import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchTiposProyecto } from './tiposProyecto.api';

const initialState = {
  entities: [],
  loading: false,
  error: null,
};
//Es necesario actualizar la URL de .API
export const getTiposProyecto = createAsyncThunk(
  'tiposProyecto/getTiposProyecto',
  async () => 
  { 
    const data = await fetchTiposProyecto()

    return data;
  }
);

export const TiposProyectoSlice = createSlice({
  name: 'tiposProyecto',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTiposProyecto.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTiposProyecto.fulfilled, (state, action) => {
        state.loading = true;
        state.entities = action.payload;
      })
      .addCase(getTiposProyecto.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const selectTiposProyecto = (state) => state.tiposProyecto.entities;
export const selectTiposProyectoIsLoading = (state) =>
  state.tiposProyecto.loading;
export const selectTiposProyectoError = (state) => state.tiposProyecto.error;

export default TiposProyectoSlice.reducer;
