import { request } from 'utils/helpers/request.helpers';

const capacitadoresUrl = `${process.env.REACT_APP_API_URL_DEV}/api/capacitador`;

//-------- Responsables de Proyecto.

export function GetResponsablesXProyecto(idProyecto) {
  const url = `${capacitadoresUrl}/GetResponsables_ByID_Proyecto?pIdProyecto=${idProyecto}`;
  return request(url, { method: 'GET' });
}

//-------- Capacitadores de Actividad (Disertantes).

export function GetCapacitadorXActividad(idActividad) {
  const url = `${capacitadoresUrl}/GetCapacitadores_ByID_Actividad?pIdActividad=${idActividad}`;
  return request(url, { method: 'GET' });
}

//-------- CREATE, UPDATE y DELETE para Proyectos y para Actividades.

export function SaveCapacitador(body) {
  return request(`${capacitadoresUrl}/InsertarCapacitador`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(body),
  });
}

export function UpdateCapacitador({ data }) {
  return request(`${capacitadoresUrl}/ModificarCapacitador`, {
    method: 'PUT',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(data),
  });
}

  export function DeleteCapacitador(id) {
    return request(`${capacitadoresUrl}/Deshabilitar?pIdCapacitador=${id}`, {
    method: 'PUT',
  });
}
