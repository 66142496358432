import {
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PutPreInscribirEndpoint,
  RemovePreInscripcionEndpoint,
  selectPreInscribir,
} from "store/preInscripcion/preInscripcion.slice";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { showSnackbar } from "store/mensajeria/mensajeriaSlice";
import { useIdRol } from "utils/roles/IdRolContext";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import CustomTableContainer from "components/customTable/CustomTableContainer";
import {
  tableBodyCell,
  tableBodyCenterCell,
  tableHeadCell,
} from "components/customTable/StylesTable";

export default function PreInscripcionTable({
  preInscripcion,
  handleSubmit,
  openModalEdit,
}) {
  const Dispatch = useDispatch();
  const preInscribir = useSelector(selectPreInscribir);
  const { rolName } = useIdRol();
  const [openConfirModal, setOpenConfirModal] = useState(false);
  const [selectedIdPreinscripcion, setSelectedIdPreinscripcion] = useState();

  const handleClickOpenConfirModal = (preinscripcion) => {
    setSelectedIdPreinscripcion(preinscripcion);
    setOpenConfirModal(true);
  };

  const handleInscribir = async (idPreinscripcion) => {
    Dispatch(PutPreInscribirEndpoint({ idPreinscripcion, page: 1 })).then(
      (resultAction) => {
        if (PutPreInscribirEndpoint.fulfilled.match(resultAction))
          Dispatch(showSnackbar(resultAction.payload.message));
      }
    );
  };

  const handleRemove = () => {
    Dispatch(
      RemovePreInscripcionEndpoint(selectedIdPreinscripcion.idPreinscripcion)
    ).then((resultAction) => {
      if (RemovePreInscripcionEndpoint.fulfilled.match(resultAction))
        Dispatch(showSnackbar(resultAction.payload.message));
    });
    setOpenConfirModal(false);
  };

  useEffect(() => {
    handleSubmit();
  }, [preInscribir]);

  return (
    <>
      <ConfirmationModal
        title={"¿Desea remover esta preinscripción?"}
        open={openConfirModal}
        setOpen={setOpenConfirModal}
        onHandleOk={() => handleRemove()}
      >
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", flexDirection: "row" }}
        >
          <Grid item xs={8}>
            idPreinscripcion: {selectedIdPreinscripcion?.idPreinscripcion}
          </Grid>
          <Grid item xs={8}>
            actividad: {selectedIdPreinscripcion?.actividad?.descripcion}
          </Grid>
          <Grid item xs={8}>
            persona: {selectedIdPreinscripcion?.persona?.fullName}
          </Grid>
        </Grid>
      </ConfirmationModal>
      <CustomTableContainer style={{ maxHeight: 500 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            sx={{
              borderTop: "1px solid", // Agregar borde superior
            }}
          >
            <TableRow>
              <TableCell sx={{ ...tableHeadCell }}>ID</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Actividad</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>ApellidoyNombre</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>FechaYHora</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Inscribir</TableCell>
              {rolName !== "institucionOferente" &&
                rolName !== "capacitadores" && (
                  <TableCell sx={{ ...tableHeadCell }}>Acciones</TableCell>
                )}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(preInscripcion) && preInscripcion?.length > 0 ? (
              preInscripcion.map((preinscrip) => (
                <TableRow key={preinscrip.idPreinscripcion}>
                  <TableCell sx={{ ...tableBodyCell, textAlign: "center" }}>
                    {preinscrip.idPreinscripcion}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {preinscrip.actividad.descripcion}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {preinscrip.persona.fullName}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {preinscrip.fechaPreinscripcion}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCenterCell }}>
                    <Checkbox
                      name="inscribir"
                      checked={preinscrip.inscribir === 0 ? false : true}
                      onChange={(e) => {
                        handleInscribir(preinscrip.idPreinscripcion);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </TableCell>

                  {rolName !== "institucionOferente" &&
                    rolName !== "capacitadores" && (
                      <TableCell sx={{ ...tableBodyCenterCell }}>
                        {/* <IconButton
                          variant="outlined"             Funciona pero hace muy desprolija la funcionalidad del BACK.
                          onClick={() => openModalEdit(preinscrip.idPreinscripcion)}
                        >
                          <EditIcon />
                        </IconButton> */}
                        <IconButton
                          variant="outlined"
                          onClick={() => handleClickOpenConfirModal(preinscrip)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={15} sx={{ textAlign: "center" }}>
                  No se encontraron resultados
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CustomTableContainer>
    </>
  );
}
