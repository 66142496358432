import { request } from "utils/helpers/request.helpers";

const periodoJuntasUrl = `${process.env.REACT_APP_API_URL_DEV}/api/Periodo`;

export function fetchTiposDePeriodo() {
  return request(`${periodoJuntasUrl}/ListarTiposPeriodo`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
}

export function fetchTiposDePeriodoById(id) {
  return request(`${periodoJuntasUrl}/ObtenerTipoPeriodoxID/${id}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
}