import React from "react";
import { Autocomplete, InputLabel } from "@mui/material";
import TextField from "@mui/material/TextField";
import { selectActividadesXProyecto } from "store/actividades/actividades.slice";
import { useSelector } from "react-redux";

export const FilterFields = ({
  dataProyecto,
  handleChangeProyectos,
  handleGetAlumnos,
  proyectoSelected,
  actividadSelected,
  setProyectoSelected,
  setActividadSelected,
  setActividadData,
  setPage,
}) => {
  const fetchActividad = useSelector(selectActividadesXProyecto);

  return (
    <>
      <div>
        <Autocomplete
          value={proyectoSelected}
          onChange={(event, value) => {
            handleChangeProyectos(value?.idProyecto);
            setProyectoSelected(value);
            setActividadSelected(null);
            setActividadData(null)
          }
          }
          style={{ width: "40rem" }}
          options={dataProyecto?.data ?? ""}
          getOptionLabel={(option) => option.denominacion ?? ""}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Seleccione un  Proyecto"
              variant="outlined"
            />
          )}
        />
        {!fetchActividad.data && proyectoSelected && (
          <h1 style={{ color: "red", fontSize: "18px", textAlign: "center" }}>
            Este Proyecto no tiene ninguna Actividad asociada por el momento.
          </h1>
        )}
        <Autocomplete
          value={actividadSelected}
          style={{ marginTop: "20px", width: "40rem" }}
          disabled={!fetchActividad.data || !proyectoSelected}
          onChange={(event, value) => {
            handleGetAlumnos(value?.idActividad);
            setActividadSelected(value);
            setPage(1)
          }
          }
          options={fetchActividad?.data ?? ""}
          getOptionLabel={(option) => option.descripcion ?? ""}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Seleccione una Actividad"
              variant="outlined"
            />
          )}
        />
      </div>
    </>
  );
};
