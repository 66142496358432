import * as Yup from "yup";

export const vsActividad = Yup.object({
  idProyecto: Yup.string().required("Este campo es requerido"),
  idEstadoActividad: Yup.string().required("Este campo es requerido"),
  fechaInicio: Yup.date().required("Este campo es requerido"),
  sinCupo: Yup.boolean(),
  cupo: Yup.number()
    .test(
      "is-required-when-no-sinCupo",
      "Este campo es requerido",
      function (value) {
        const { sinCupo } = this.parent;
        if (!sinCupo) {
          return value !== undefined && value !== null;
        }
        return true;
      }
    )
    .test(
      "is-positive-when-required",
      "El campo debe ser mayor a 0",
      function (value) {
        const { sinCupo } = this.parent;
        if (!sinCupo) {
          return value > 0;
        }
        return true;
      }
    ),
  fechaFin: Yup.date()
    .required("Este campo es requerido")
    .min(
      Yup.ref("fechaInicio"),
      "La fecha de fin debe ser mayor o igual a la fecha de inicio"
    ),
  idLocalidad: Yup.string().required("Este campo es requerido"),
});
