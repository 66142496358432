import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import CustomModal from "components/customModal/CustomModal";
import React from "react";

export const GenericNew = ({ Form, title }) => {
  const [open, setOpen] = React.useState(false);
  const tituloOriginal = title; // Evitamos modificar la variable "title".

  const ultTitulo = tituloOriginal
    .split("")
    .reverse()
    .slice(0, 1)
    .join("")
    .toLowerCase();
  const dosUltTitulo = tituloOriginal
    .split("")
    .reverse()
    .slice(0, 2)
    .join("")
    .toLowerCase();
  const cuatroUltTitulo = tituloOriginal
    .split("")
    .reverse()
    .slice(0, 4)
    .join("")
    .toLowerCase();
  const seisUltTitulo = tituloOriginal
    .split("")
    .reverse()
    .slice(0, 6)
    .join("")
    .toLowerCase();

  function generoTit() {
    return "Nuevo";
  }

  function singularTit(tituloOriginal) {
    if (seisUltTitulo === "senoic") {
      return tituloOriginal.substring(0, tituloOriginal.length - 6) + "ción";
    } else if (dosUltTitulo === "se") {
      return tituloOriginal.substring(0, tituloOriginal.length - 2);
    } else if (ultTitulo === "s") {
      return tituloOriginal.substring(0, tituloOriginal.length - 1);
    } else {
      return tituloOriginal;
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen}>
        {generoTit()}
      </Button>
      <CustomModal
        title={"Agregar " + singularTit(tituloOriginal)}
        open={open}
        setOpen={handleClose}
      >
        <DialogContent>
          <Form handleClose={handleClose} />
        </DialogContent>
      </CustomModal>
    </React.Fragment>
  );
};
