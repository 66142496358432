import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import { myPageProyectos } from "../../ProyectosInicio/ProyectosIPage/ProyectosIPage";
import {RemoveResolucionesEndPoint,GetIdResoluciones} from "store/Resoluciones/resoluciones.slice";
import {getProyectos}from "store/inicioProyectos/IProyectos.slice";
import { showSnackbar } from 'store/mensajeria/mensajeriaSlice';

export default function ResolucionesEliminar({ itemAct, itemCap }) {

  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const page = useContext(myPageProyectos);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);                                    
  };

  const handleSubmit = () => {
    setOpen(false);
    dispatch(RemoveResolucionesEndPoint(itemCap.idResolucion))
    .then((resultAction) => {
      if (RemoveResolucionesEndPoint.fulfilled.match(resultAction))
        dispatch(showSnackbar(resultAction.payload.message));
    });   

    setTimeout(() => {
      dispatch(GetIdResoluciones(itemAct?.idProyecto || 0));          //Actualizamos Tabla de Capacitadores.
    }, 400);

    setTimeout(() => {                                                 //Actualizamos Tabla de Actividades
      dispatch(getProyectos(page? page : 1));                  //Sabemos en tiempo real la cantidad de Capacitadores              
    }, 400);                                                           //para una determinada Actividad. 
  };
  return (
    <React.Fragment>
      <IconButton variant="outlined" onClick={handleClickOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog open={open}>
        <DialogTitle> Eliminar Resolución </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Estás seguro que deseas desestimar la Resolucion <b>"{itemCap?.descripcion}"</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}> Cancelar </Button>
          <Button onClick={handleSubmit} autoFocus> Aceptar </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
