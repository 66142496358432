import React from "react";
import GenericTable from "./GenericTable";
import Toolbar from "@mui/material/Toolbar";
import GenericSearch from "utils/search/GenericSearch";
import { GenericNew } from "./GenericNew";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

export const GenericTemplate = ({
  titleName,
  handleSearch,
  dataTable,
  Form,
  deleteFuncion,
  customActions,
  siEdit = { siEdit },
  siDelete = { siDelete },
  siTable = { siTable },
}) => {
  if (!dataTable) return <></>;

  return (
    <>
      <div style={{ display: "flex", alignItems: "left" }}>
        <h1 style={{ margin: "10px 0" }}>{titleName}</h1>
      </div>
      <Grid container spacing={2}>
        {Form && (
          <Grid item xs={4}>
            <Toolbar disableGutters>
              <GenericNew Form={Form} title={titleName} />
            </Toolbar>
          </Grid>
        )}

        {handleSearch && (
          <Grid item xs={8}>
            <GenericSearch handleSearch={handleSearch} />
          </Grid>
        )}
      </Grid>
      {siTable && (
        <GenericTable
          dataTable={dataTable}
          title={titleName}
          Form={Form}
          deleteFuncion={deleteFuncion}
          customActions={customActions}
          siEdit={siEdit}
          siDelete={siDelete}
        />
      )}
    </>
  );
};
