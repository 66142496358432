import { request } from "utils/helpers/request.helpers";

const periodosUrl = `${process.env.REACT_APP_API_URL_DEV}/api/Periodo`;

export function fetchPeriodo_ByTipoPeriodo(pIdTipoPeriodo) {
  return request(
    `${periodosUrl}/GetPeriodo_ByTipoPeriodo?pIdTipoPeriodo=${pIdTipoPeriodo}`,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );
}

export function fetchPeriodos(page) {
  return request(`${periodosUrl}/Listar?page=${page}&tipoPeriodoIds=1`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
}

export function fetchPeriodoById(id) {
  return request(`${periodosUrl}/GetPeriodo_ByID?pId=${id}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
}

export function createPeriodo(data) {
  return request(`${periodosUrl}/InsertarPeriodo`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function updatePeriodo(data) {
  return request(`${periodosUrl}/ModificarPeriodo?pId=${data.id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

export async function disabledPeriodo(id) {
  return request(`${periodosUrl}/Deshabilitar?pIdPeriodo=${id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
  });
}

export function searchPeriodo(page, search) {
  return request(
    `${periodosUrl}/FiltrarPeriodosAppV?page=${page}&pBusqueda=${search}`,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );
}
