import React, { useContext } from "react";
import { useDispatch } from "react-redux";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import {
  getProyectos,
  deleteProyectos,
} from "store/inicioProyectos/IProyectos.slice";
import { myPageProyectos } from "../ProyectosIPage/ProyectosIPage";
import { showSnackbar } from "store/mensajeria/mensajeriaSlice";
import { Tooltip } from "@mui/material";

export default function ProyectosIEliminar({ itemProy }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const page = useContext(myPageProyectos);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    setOpen(false);
    dispatch(deleteProyectos(itemProy.idProyecto)).then((resultAction) => {
      if (deleteProyectos.fulfilled.match(resultAction))
        dispatch(showSnackbar(resultAction.payload.message));
    });

    setTimeout(() => {
      dispatch(getProyectos(page ? page : 1));
    }, 400);
  };

  return (
    <React.Fragment>
      <Tooltip title="Eliminar">
        <IconButton variant="outlined" onClick={handleClickOpen}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open}>
        <DialogTitle> Eliminar Proyecto </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Estás seguro que deseas eliminar el proyecto{" "}
            <b> "{itemProy.denominacion}" </b>?
            <p>
              (Tenga presente que esta acción también elimina las Actividades
              asociadas a este Proyecto.)
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}> Cancelar </Button>
          <Button onClick={handleSubmit} autoFocus>
            {" "}
            Aceptar{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
