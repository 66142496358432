// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datePicker{
    border-radius: 4px;
    border: 1px rgb(190,190,190) solid;
}`, "",{"version":3,"sources":["webpack://./src/views/NivelesEnsenanza/NivelesEnsenanzaCss.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kCAAkC;AACtC","sourcesContent":[".datePicker{\n    border-radius: 4px;\n    border: 1px rgb(190,190,190) solid;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
