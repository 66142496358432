import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import "dayjs/locale/es";
import Box from "@mui/material/Box";
import {
  CircularProgress,
  FormControl,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Formik } from "formik";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { vsResponsable } from "./YupResponsable";
import { SearchOutlined, CloseOutlined } from "@mui/icons-material";
import {
  clearPersonaSearchData,
  filterPersonas,
  selectErrorFilter,
  selectIsLoading,
  selectPersonasFilter,
} from "store/persona/persona.slice";
import ConfirmDialog from "views/Persona/Modal/Common/ConfirmDialog";
import { mensaje } from "./Config";
import {
  selectResponsabilidades,
  selectError,
  GetResponsabilidadesEndPoint,
} from "store/responsabilidades/responsabilidades.slice";
import {
  GetCausasBajaEndPoint,
  selectCausasBaja,
  selectError as selectErrorCausasBaja,
} from "store/causasBaja/causasBaja.slice";

export default function ResponsablesForm({ data, onSubmit, onClose }) {
  dayjs.extend(customParseFormat);
  const dispatch = useDispatch();
  const personaSearch = useSelector(selectPersonasFilter);
  const personaIsLoading = useSelector(selectIsLoading);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [nuevo, setNuevo] = React.useState(!data && true);
  const [nombreCompleto, setNombreCompleto] = React.useState("");
  const [cuilExistente, setCuilExistente] = React.useState(false);
  const formRef = useRef(null);
  const [edit] = React.useState(data ?? false);
  const error = useSelector(selectErrorFilter);
  const { data: tiposResponsabilidad } = useSelector(selectResponsabilidades);
  const errorTiposResponsabilidad = useSelector(selectError);
  const { data: CausasDeBajas } = useSelector(selectCausasBaja);
  const ErrorCausasDeBajas = useSelector(selectErrorCausasBaja);

  useEffect(() => {
    dispatch(clearPersonaSearchData());
    dispatch(GetResponsabilidadesEndPoint());
    dispatch(GetCausasBajaEndPoint());
  }, [dispatch]);

  useEffect(() => {
    const result = personaSearch?.data && personaSearch.data.length !== 0;
    if (result) {
      setNuevo(true);
      setCuilExistente(result);
      formRef.current.setFieldValue("cuit", personaSearch?.data[0].cuit);
      formRef.current.setFieldValue(
        "idPersona",
        personaSearch?.data[0].idPersona
      );
      setNombreCompleto(
        personaSearch?.data[0].nombres + " " + personaSearch?.data[0].apellidos
      );
    } else if (!result) {
      result === false && setOpenDialog(true);
      setNuevo(false);
      setNombreCompleto("");
      formRef.current.setFieldValue("cuit", "");
      formRef.current.setFieldValue("idPersona", null);
      setCuilExistente(false);
    }
  }, [personaSearch]);

  useEffect(() => {
    if (data?.persona?.cuit) {
      dispatch(filterPersonas(data.persona.cuit));
    }
  }, [dispatch, data]);

  const handleSubmit = (e) => {
    onSubmit(e);
  };

  const handleClose = (event, reason) => {
    onClose();
  };

  const findPersona = (value) => {
    dispatch(filterPersonas(value));
  };

  const resetPersona = (values) => {
    dispatch(clearPersonaSearchData());
  };

  const onCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <ConfirmDialog
        mensaje={mensaje}
        onClose={onCloseDialog}
        open={openDialog}
        title={"Atención"}
      />
      <Formik
        initialValues={{
          idCapacitador: data?.idCapacitador || "",
          idActividad: data?.idActividad || "",
          idProyecto: data?.idProyecto || "",
          idPersona: data?.persona?.idPersona || "",
          fechaInicioServ: data?.fechaInicio ? dayjs(data?.fechaInicio) : null,
          fechaFinServ: data?.fechaFin ? dayjs(data?.fechaFin) : null,
          idCausaBaja: data?.idCausaBaja || "",
          idResponsabilidad: data?.idResponsabilidad || "",
          cuit: data?.persona?.cuit ?? "",
        }}
        validationSchema={vsResponsable}
        onSubmit={(values) => {
          handleSubmit({
            ...values,
            idCapacitador: !!values.idCapacitador ? values.idCapacitador : 0,
            idCausaBaja: !!values.idCausaBaja ? values.idCausaBaja : 0,
            idActividad: !!values.idActividad ? values.idActividad : 0,
          });
        }}
        onReset={(value) => {
          setCuilExistente(false);
        }}
        innerRef={formRef}
      >
        {({
          values,
          errors,
          handleChange,
          touched,
          setFieldValue,
          submitForm,
          dirty,
        }) => (
          <Box component="form">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl>
                  <Typography variant="subtitle2" color="text.secondary">
                    Denominación de Proyecto
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {data?.denominacion ? data?.denominacion : ""}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    label="CUIL"
                    type="text"
                    name="cuit"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      const onlyNumbers = e.target.value.replace(/[^\d]/g, ""); // Eliminar todo excepto los números
                      setFieldValue("cuit", onlyNumbers);
                    }}
                    onPaste={handleChange}
                    value={values.cuit}
                    error={Boolean(errors.cuit && touched.cuit)}
                    onWheel={(e) => e.currentTarget.blur()} // Desenfocar el campo al girar la rueda del mouse
                    helperText={
                      Boolean(errors.cuit) && touched.cuit && errors.cuit
                    }
                    inputProps={{ maxLength: 11 }}
                    disabled={cuilExistente || personaIsLoading}
                    InputProps={{
                      endAdornment: (
                        <>
                          {personaIsLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : cuilExistente ? (
                            <Tooltip title="Borrar">
                              <IconButton
                                disabled={
                                  values.cuit == null ||
                                  String(values.cuit).length < 11
                                }
                                onClick={() => resetPersona(values)}
                              >
                                <CloseOutlined type="reset" />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Buscar CUIL">
                              <IconButton
                                disabled={
                                  values.cuit == null ||
                                  String(values.cuit).length < 11
                                }
                                onClick={() => findPersona(values.cuit)}
                              >
                                <SearchOutlined />
                              </IconButton>
                            </Tooltip>
                          )}
                        </>
                      ),
                    }}
                    onKeyDown={(event) => {
                      if (
                        values.cuit.length > 10 &&
                        (event.code === "Enter" || event.code === "NumpadEnter")
                      ) {
                        findPersona(values.cuit);
                      }
                    }}
                    autoComplete="off"
                  />
                  {error && (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      <span>
                        Ocurrió un problema al recuperar esta información
                      </span>
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {cuilExistente && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Typography variant="subtitle2" color="text.secondary">
                      Nombre y apellidos
                    </Typography>
                    <Typography variant="h8" gutterBottom>
                      {nombreCompleto}
                    </Typography>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    onChange={(e, value) => {
                      setFieldValue(
                        "idResponsabilidad",
                        value ? value.id : null
                      );
                    }}
                    options={
                      (tiposResponsabilidad &&
                        tiposResponsabilidad.map((option) => ({
                          id: option.idResponsabilidad,
                          label: option.descripcion,
                        }))) ||
                      []
                    }
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    name="idResponsabilidad"
                    value={
                      tiposResponsabilidad?.find(
                        (item) =>
                          item.idResponsabilidad === values.idResponsabilidad
                      )?.descripcion || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Tipo de Responsabilidad"
                        margin="normal"
                        variant="outlined"
                        autoComplete="off"
                        InputProps={{ ...params.InputProps, type: "search" }}
                        error={
                          Boolean(errors.idResponsabilidad) &&
                          touched.idResponsabilidad
                        }
                        style={{ marginTop: "0.5rem" }}
                        sx={{
                          "input[type='search']::-webkit-search-cancel-button":
                            {
                              display: "none",
                            },
                        }}
                        helperText={
                          Boolean(errors.idResponsabilidad) &&
                          touched.idResponsabilidad &&
                          errors.idResponsabilidad
                        }
                      />
                    )}
                  />
                  {errorTiposResponsabilidad && (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      Ocurrió un problema al recuperar este dato
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <DatePicker
                    label="Fecha de inicio"
                    name="fechaInicioServ"
                    value={values.fechaInicioServ}
                    onChange={(value) =>
                      setFieldValue("fechaInicioServ", value)
                    }
                    slotProps={{
                      textField: {
                        helperText:
                          Boolean(errors.fechaInicioServ) &&
                          touched.fechaInicioServ &&
                          errors.fechaInicioServ,
                        error:
                          Boolean(errors.fechaInicioServ) &&
                          Boolean(touched.fechaInicioServ),
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <DatePicker
                    label="Fecha de fin"
                    name="fechaFinServ"
                    value={values.fechaFinServ}
                    onChange={(value) => setFieldValue("fechaFinServ", value)}
                    slotProps={{
                      textField: {
                        helperText:
                          Boolean(errors.fechaFinServ) &&
                          touched.fechaFinServ &&
                          errors.fechaFinServ,
                        error:
                          Boolean(errors.fechaFinServ) &&
                          Boolean(touched.fechaFinServ),
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth style={{ marginTop: "-8px" }}>
                  <Autocomplete
                    onChange={(e, value) => {
                      setFieldValue("idCausaBaja", value ? value.id : null);
                    }}
                    options={
                      (CausasDeBajas &&
                        CausasDeBajas.map((option) => ({
                          id: option.idCausaBaja,
                          label: option.descripcion,
                        }))) ||
                      []
                    }
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    name="idCausaBaja"
                    value={
                      CausasDeBajas?.find(
                        (item) => item.idCausaBaja === values.idCausaBaja
                      )?.descripcion || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Causa de baja"
                        margin="normal"
                        variant="outlined"
                        autoComplete="off"
                        InputProps={{ ...params.InputProps, type: "search" }}
                        error={
                          Boolean(errors.idCausaBaja) && touched.idCausaBaja
                        }
                        sx={{
                          "input[type='search']::-webkit-search-cancel-button":
                            {
                              display: "none",
                            },
                        }}
                        helperText={
                          Boolean(errors.idCausaBaja) &&
                          touched.idCausaBaja &&
                          errors.idCausaBaja
                        }
                      />
                    )}
                  />
                  {ErrorCausasDeBajas && (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      Ocurrió un problema al recuperar este dato
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Stack
              spacing={2}
              direction="row"
              sx={{ display: "flex", justifyContent: "flex-end", mt: 3, mb: 2 }}
            >
              <Button
                variant="outlined"
                color="error"
                onClick={handleClose}
                spacing={2}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                onClick={() => submitForm()}
                disabled={!dirty && !cuilExistente}
              >
                Guardar
              </Button>
            </Stack>
          </Box>
        )}
      </Formik>
    </LocalizationProvider>
  );
}
