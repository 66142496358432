import React, { createContext, useState, useEffect } from 'react';
import {jwtDecode } from 'jwt-decode';

// Crea el contexto
export const TokenContext = createContext();

// Crea el proveedor del contexto
export const TokenProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [decodedToken, setDecodedToken] = useState(null);

  // Función para guardar el token en el estado y en el localStorage
  const saveToken = (newToken) => {
    console.log("saveToken")
    setToken(newToken);
    localStorage.setItem('token', newToken);

    // Decodifica el token y lo guarda en el estado
    const decoded = jwtDecode(newToken);
    setDecodedToken(decoded);
  };

  // Función para eliminar el token (por ejemplo, al cerrar sesión)
  const removeToken = () => {
    setToken(null);
    setDecodedToken(null);
    localStorage.removeItem('token');
  };

  // Cargar el token desde localStorage cuando se monta el componente
  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      saveToken(storedToken);
    }
  }, []);

  return (
    <TokenContext.Provider value={{ token, decodedToken, saveToken, removeToken }}>
      {children}
    </TokenContext.Provider>
  );
};
