import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  tableBodyCell,
  tableBodyCenterCell,
  tableHeadCell,
} from "components/customTable/StylesTable";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PutUnInscribirEndpoint,
  selectInscribir,
} from "store/Inscripcion/Inscripcion.slice";
import { showSnackbar } from "store/mensajeria/mensajeriaSlice";

export default function InscripcionTable({ preInscripcion, handleSubmit }) {
  const Dispatch = useDispatch();
  const preInscribir = useSelector(selectInscribir);

  const handleInscribir = async (idPreinscripcion) => {
    Dispatch(PutUnInscribirEndpoint({ idPreinscripcion, page: 1 })).then(
      (resultAction) => {
        if (PutUnInscribirEndpoint.fulfilled.match(resultAction))
          Dispatch(showSnackbar(resultAction.payload.message));
      }
    );
  };

  useEffect(() => {
    handleSubmit();
  }, [preInscribir]);

  return (
    <TableContainer sx={{ maxHeight: 470 }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead
          sx={{
            borderTop: "1px solid", // Agregar borde superior
          }}
        >
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Actividad</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>ApellidoyNombre</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>FechaYHora</TableCell>
            <TableCell sx={{ ...tableHeadCell }} style={{ textAlign: "center" }}>Inscriptos/ Desinscribir</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(preInscripcion) && preInscripcion?.length > 0 ? (
            preInscripcion?.map((preinscrip) => (
              <TableRow key={preinscrip.idInscripcion}>
                <TableCell sx={{ ...tableBodyCell }}>
                  {preinscrip.idInscripcion}
                </TableCell>
                <TableCell sx={{ ...tableBodyCell }}>
                  {preinscrip.actividad.descripcion}
                </TableCell>
                <TableCell sx={{ ...tableBodyCell }}>
                  {preinscrip.persona.fullName}
                </TableCell>
                <TableCell sx={{ ...tableBodyCell }}>
                  {preinscrip.fechaInscripcion}
                </TableCell>
                <TableCell sx={{ ...tableBodyCenterCell }}>
                  <Checkbox
                    name="inscribir"
                    checked={preinscrip.inscribir === 0 ? false : true}
                    onChange={(e) => {
                      handleInscribir(preinscrip.idPreinscripcion);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={15} sx={{ textAlign: "center" }}>
                No se encontraron resultados
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
