import React, { useEffect } from "react";
import { Paper, TableContainer } from "@mui/material";
import { tableHeightDefault, updateTableHeight } from './StylesTable'; // Asegúrate de que la ruta sea correcta

const CustomTableContainer = ({ children }) => {
  useEffect(() => {
    // Añadir el listener de resize para actualizar el maxHeight
    window.addEventListener("resize", updateTableHeight);

    // Limpiar el listener cuando el componente se desmonte
    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  }, []); // El arreglo vacío asegura que el efecto se ejecute solo una vez (al montar y desmontar el componente)

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: 0 }}>
      <TableContainer sx={{ maxHeight: tableHeightDefault.maxHeight }}>
        {children}
      </TableContainer>
    </Paper>
  );
};

export default CustomTableContainer;