export const tableHeadCell = {
  textAlign: "center",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontWeight: 600,
  borderTop: "1px solid rgba(224, 224, 224, 1)",
};

export const tableBodyCell = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export const tableBodyCenterCell = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textAlign: "center",
  verticalAlign: "middle",
};

export const tableHeightDefault = {
  maxHeight: 560,
};
