import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  fetchPeriodo_ByTipoPeriodo,
  fetchPeriodoById,
  fetchPeriodos,
  createPeriodo,
  updatePeriodo,
  disabledPeriodo,
  searchPeriodo,
} from "./periodos.api";

const initialState = {
  entities: [],
  entity: null,
  periodosxTipo: [],
  response: null,
  error: null,
  loading: false,
};

const errorMessage = {
  message: [
    {
      status: 500,
      description: "Se ha producido un error inesperado",
    },
  ],
};

export const GetPeriodo_ByTipoPeriodo = createAsyncThunk(
  "periodos/GetPeriodo_ByTipoPeriodo",
  async () => {
    const r = await fetchPeriodo_ByTipoPeriodo();
    return r;
  }
);

export const GetPeriodos = createAsyncThunk(
  "periodos/GetPeriodos",
  async (page, { dispatch }) => {
    const r = await fetchPeriodos(page);
    return r;
  }
);

export const GetPeriodo = createAsyncThunk("periodo/GetPeriodo", async (id) => {
  const r = await fetchPeriodoById(id);
  return r;
});

export const PostPeriodo = createAsyncThunk(
  "periodos/PostPeriodo",
  async ({ data, page = 1 }, { dispatch }) => {
    const r = await createPeriodo(data).then((data) => {
      dispatch(GetPeriodos(page));
      return data;
    });
    return r;
  }
);

export const PutPeriodo = createAsyncThunk(
  "periodos/PutPeriodo",
  async ({ data, page = 1 }, { dispatch }) => {
    const r = await updatePeriodo(data).then((data) => {
      dispatch(GetPeriodos(page));
      return data;
    });
    return r;
  }
);

export const GetSearchPeriodo = createAsyncThunk(
  "periodo/filterPeriodo",
  async ({ page = 1, data = "" }) => {
    const r = await searchPeriodo(page, data);
    return r;
  }
);

export const DisabledPeriodo = createAsyncThunk(
  "periodos/DisabledPeriodo",
  async ({ data, page = 1 }, { dispatch }) => {
    const r = await disabledPeriodo(data).then((data) => {
      dispatch(GetPeriodos(page));
      return data;
    });
    return r;
  }
);

export const PeriodosSlice = createSlice({
  name: "periodos",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //#region GetPeriodo_ByTipoPeriodo
      .addCase(GetPeriodo_ByTipoPeriodo.pending, (state) => {
        // pendiente.
        state.loading = true;
        state.response = null;
      })
      .addCase(GetPeriodo_ByTipoPeriodo.fulfilled, (state, action) => {
        // cumplida.
        state.loading = false;
        state.periodosxTipo = action.payload.data;
      })
      .addCase(GetPeriodo_ByTipoPeriodo.rejected, (state, action) => {
        // rechazado.
        state.loading = false;
        state.response = action.error.message;
      })
      //#endregion
      //#region GetPeriodos
      .addCase(GetPeriodos.pending, (state) => {
        // pendiente.
        state.loading = true;
        state.response = null;
      })
      .addCase(GetPeriodos.fulfilled, (state, action) => {
        // cumplida.
        state.entities = action.payload.data;
        state.loading = false;
      })
      .addCase(GetPeriodos.rejected, (state, action) => {
        // rechazado.
        state.loading = false;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })
      //#endregion
      //#region GetPeriodo
      .addCase(GetPeriodo.pending, (state) => {
        // pendiente.
        state.loading = true;
        state.response = null;
      })
      .addCase(GetPeriodo.fulfilled, (state, action) => {
        // cumplida.
        state.entity = action.payload.data;
        state.loading = false;
      })
      .addCase(GetPeriodo.rejected, (state, action) => {
        // rechazado.
        state.loading = false;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })
      //#endregion
      //#region PostPeriodo
      .addCase(PostPeriodo.pending, (state) => {
        // pendiente.
        state.loading = true;
        state.processing = true;
        state.response = null;
      })
      .addCase(PostPeriodo.fulfilled, (state, action) => {
        // cumplida.
        state.response = action.payload;
        state.processing = false;
      })
      .addCase(PostPeriodo.rejected, (state, action) => {
        // rechazado.
        state.loading = false;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
        state.error = true;
      })
      //#endregion
      //#region PutPeriodo
      .addCase(PutPeriodo.pending, (state) => {
        // pendiente.
        state.loading = true;
        state.error = null;
        state.response = null;
      })
      .addCase(PutPeriodo.fulfilled, (state, action) => {
        // cumplida.
        state.loading = false;
        state.response = action.payload;
      })
      .addCase(PutPeriodo.rejected, (state, action) => {
        // rechazado.
        state.loading = false;
        state.error = true;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })
      //#endregion
      //#region DeletePeriodo
      .addCase(DisabledPeriodo.pending, (state) => {
        // pendiente.
        state.loading = true;
        state.error = null;
        state.response = null;
      })
      .addCase(DisabledPeriodo.fulfilled, (state, action) => {
        // cumplida.
        state.loading = false;
        state.response = action.payload;
      })
      .addCase(DisabledPeriodo.rejected, (state, action) => {
        // rechazado.
        state.loading = false;
        state.error = true;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })
      //#endregion
      //#region GetSearchPeriodo
      .addCase(GetSearchPeriodo.pending, (state) => {
        // pendiente.
        state.loading = true;
        state.response = null;
      })
      .addCase(GetSearchPeriodo.fulfilled, (state, action) => {
        // cumplida.
        state.entities = action.payload.data;
        state.loading = false;
      })
      .addCase(GetSearchPeriodo.rejected, (state, action) => {
        // rechazado.
        state.loading = false;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      });
    //#endregion
  },
});

export const sPeriodos = (state) => state.periodos.entities;
export const sResponse = (state) => state.periodos.response;
export const sError = (state) => state.periodos.error;
export const sPeriodosxTipo = (state) => state.periodos.periodosxTipo;
export const sPeriodo = (state) => state.periodos.entity;
export const sIsLoading = (state) => state.periodos.loading;

export default PeriodosSlice.reducer;
