import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ErrorMessage, Formik } from "formik";
import {
  editInstituciones,
  submitInstitucion,
} from "utils/transformDataToSubmit/submitInstitucion";
import { selectInstitucionById } from "store/Institucion/institucion.slice";
import { vinstituciones } from "../../views/validaciones/validaciones";

export default function InstitucionesForm({ data, handleClose }) {
  const dispatch = useDispatch();
  let institucionToEdit = useSelector(selectInstitucionById);
  const isEditing = !!data;
  const formRef = useRef(null);

  const initialValues = {
    idInstitucion: institucionToEdit?.idInstitucion || "0",
    nombre: institucionToEdit?.nombre || "",
    idCaracter: institucionToEdit?.idCaracter || "",
    idDependencia: institucionToEdit?.idDependencia || "",
    domicilioLegal: institucionToEdit?.domicilioLegal || "",
    codigoPostal: institucionToEdit?.codigoPostal || "",
    telefono: institucionToEdit?.telefono || "",
    correoElectronico: institucionToEdit?.correoElectronico || "",
  };

  useEffect(() => {
    if (!isEditing) {
      formRef.current.resetForm({
        values: {
          idInstitucion: "0",
          nombre: "",
          idCaracter: "",
          idDependencia: "",
          domicilioLegal: "",
          codigoPostal: "",
          telefono: "",
          correoElectronico: "",
        },
      });
    } else {
      editInstituciones(data.ID, dispatch);
      formRef.current.resetForm({ values: initialValues });
    }
  }, [isEditing, dispatch]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={vinstituciones}
      onSubmit={(values) => {
        values.telefono = values.telefono.toString();
        values.nombre = values.nombre.toUpperCase();
        submitInstitucion(values, dispatch, institucionToEdit, handleClose);
      }}
      innerRef={formRef}
    >
      {({
        values,
        errors,
        handleChange,
        touched,
        setFieldValue,
        submitForm,
        dirty,
      }) => (
        <Box component="form">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={Boolean(errors.nombre) && touched.nombre}
              >
                <TextField
                  label="Nombre"
                  type="text"
                  name="nombre"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  autoComplete="off"
                  value={values.nombre}
                  error={Boolean(errors.nombre && touched.nombre)}
                  helperText={
                    Boolean(errors.nombre) && touched.nombre && errors.nombre
                  }
                  inputProps={{ maxLength: 50 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                error={Boolean(errors.idCaracter) && touched.idCaracter}
              >
                <InputLabel id="caracter">Carácter</InputLabel>
                <Select
                  id="idCaracter"
                  label="Carácter"
                  name="idCaracter"
                  fullWidth
                  value={values?.idCaracter}
                  onChange={handleChange}
                  error={Boolean(errors.idCaracter) && touched.idCaracter}
                >
                  <MenuItem key={1} value={1}>
                    PÚBLICA
                  </MenuItem>
                  <MenuItem key={2} value={2}>
                    PRIVADA
                  </MenuItem>
                </Select>
                {Boolean(errors.idCaracter) && touched.idCaracter && (
                  <FormHelperText
                    style={{
                      color: "#d32f2f",
                    }}
                  >
                    Este campo es obligatorio
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                error={Boolean(errors.idDependencia) && touched.idDependencia}
              >
                <InputLabel id="caracter">Dependencia</InputLabel>
                <Select
                  id="idDependencia"
                  label="Dependencia"
                  name="idDependencia"
                  fullWidth
                  value={values?.idDependencia}
                  onChange={handleChange}
                  error={Boolean(errors.idDependencia) && touched.idDependencia}
                >
                  <MenuItem key={1} value={1}>
                    PROVINCIAL
                  </MenuItem>
                  <MenuItem key={2} value={2}>
                    NACIONAL
                  </MenuItem>
                </Select>
                {Boolean(errors.idDependencia) && touched.idDependencia && (
                  <FormHelperText
                    style={{
                      color: "#d32f2f",
                    }}
                  >
                    Este campo es obligatorio
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={Boolean(errors.domicilioLegal) && touched.domicilioLegal}
              >
                <TextField
                  label="Dirección"
                  type="text"
                  name="domicilioLegal"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  autoComplete="off"
                  value={values.domicilioLegal}
                  error={Boolean(
                    errors.domicilioLegal && touched.domicilioLegal
                  )}
                  helperText={
                    Boolean(errors.domicilioLegal) &&
                    touched.domicilioLegal &&
                    errors.domicilioLegal
                  }
                  inputProps={{ maxLength: 100 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <TextField
                  label="Código Postal"
                  type="text"
                  name="codigoPostal"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => {
                    const onlyNumbers = e.target.value.replace(/[^\d]/g, ""); // Eliminar todo excepto los números
                    setFieldValue("codigoPostal", onlyNumbers);
                  }}
                  autoComplete="off"
                  value={values.codigoPostal}
                  error={Boolean(errors.codigoPostal && touched.codigoPostal)}
                  helperText={
                    Boolean(errors.codigoPostal) &&
                    touched.codigoPostal &&
                    errors.codigoPostal
                  }
                  inputProps={{ maxLength: 10 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <TextField
                  label="Teléfono"
                  type="text"
                  name="telefono"
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  onChange={handleChange}
                  value={values.telefono}
                  error={Boolean(errors.telefono && touched.telefono)}
                  helperText={
                    Boolean(errors.telefono) &&
                    touched.telefono &&
                    errors.telefono
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <TextField
                  label="Email"
                  type="text"
                  name="correoElectronico"
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  onChange={handleChange}
                  value={values.correoElectronico}
                  error={Boolean(
                    errors.correoElectronico && touched.correoElectronico
                  )}
                  helperText={
                    Boolean(errors.correoElectronico) &&
                    touched.correoElectronico &&
                    errors.correoElectronico
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Stack
            spacing={2}
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 3,
              mb: 2,
            }}
          >
            <Button
              variant="outlined"
              color="error"
              onClick={() => handleClose()}
              spacing={2}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={() => submitForm()}
              disabled={!dirty}
            >
              Guardar
            </Button>
          </Stack>
        </Box>
      )}
    </Formik>
  );
}
