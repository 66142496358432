import React, { useRef, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";
import "./PrintView.css";
import firmaUno from "../../../images/Firma 1 Certificado.jpg";
import firmaDos from "../../../images/Firma 2 Certificado.jpg";
import logoSello from "../../../images/Logo Planeamiento Educativo.png";
import logoInicial from "../../../images/Logo Inicial.jpg";
import { useDispatch, useSelector } from "react-redux";
import {
  getCertificate,
  selectCertificados,
} from "store/certificados/certificado.slice";
import { format } from 'date-fns';
import { selectActividadesXProyecto } from "store/actividades/actividades.slice";
import { formatDDMMYY_String } from "utils/helpers/dateFormatString";
import { getProyectosConNiveles, selectProyectosConNiveles } from "store/proyectos/proyectos.slice";

const PrintView = React.forwardRef(({ alumno }, ref) => {

  console.log('alumno en certificado', alumno)

  const dispatch = useDispatch();
  const { data } = useSelector(selectCertificados);
  const fetchActividad = useSelector(selectActividadesXProyecto);
  const findActividad = fetchActividad?.data?.find(
    (actividad) => actividad.idActividad === actividad.idActividad
  );
  const nivelesDelProyecto = useSelector(selectProyectosConNiveles);
  console.log('nivelesDelProyecto', nivelesDelProyecto)

  useEffect(() => {
    dispatch(getProyectosConNiveles(alumno?.proyecto?.idProyecto || 0));
  }, [dispatch, alumno])

  if (!data) return;
  const coso = `data:image/png;base64,${data.qr}`;
  const initialDate = new Date(data.fechaInicio);
  const finDate = new Date(data.fechaFin);
  const formattedDateI = format(initialDate, "dd/MM/yy");
  const formattedDateF = format(finDate, "dd/MM/yy");

  return (
    < div ref={ref} className="printable-content" >
      <div className="divImg" >
        <img src={logoInicial} alt="Firma 1 Certificado" />
      </div>
      <p style={{ fontStyle: "italic", fontSize: "28px", margin: "8px 0px 21px 0px" }}>CERTIFICA</p>
      <p>
        <span style={{ fontStyle: "italic" }}> {`Que el/la Profesor/a`} </span>
        <span style={{ fontStyle: "italic", fontWeight: "bold" }}> {`${data.nombres}`} </span>
        <span style={{ fontStyle: "italic" }}> {`D.N.I. N°`} </span>
        <span style={{ fontStyle: "italic", fontWeight: "bold" }}> {`${data.documento}`}</span>
      </p>
      <p style={{ fontStyle: "italic" }}> {`Participó y aprobó el Curso denominado`}</p>
      <p style={{ fontStyle: "italic", fontSize: "19px", fontWeight: "bold", textTransform: "uppercase" }} >
        {`"${data.proyecto}"`}
      </p>
      <p style={{ fontStyle: "italic" }}> {`Dictado de manera ${findActividad?.modalidadProyecto?.denominacion}, 
          desde ${formattedDateI} al ${formattedDateF}, en la Localidad de ${findActividad?.localidad}`} </p>
      <p style={{ fontStyle: "italic" }}> {`con una duracion de ${alumno.proyecto.horasCatedra} horas cátedra, y el correspondiente trabajo de evaluacion.`} </p>
      <p style={{ fontStyle: "italic" }}>
        {nivelesDelProyecto?.data && (nivelesDelProyecto?.data.length == 1) && "Para el nivel "}
        {nivelesDelProyecto?.data && (nivelesDelProyecto?.data.length >= 2) && "Para los niveles "}
        {
          nivelesDelProyecto?.data ? nivelesDelProyecto.data.map((x, index) =>
            <>
              {(nivelesDelProyecto.data.length == 1) &&                 // Cuando es para un solo nivel.
                <>
                  <span>{(x.nivelEnsenanza.charAt(0) + x.nivelEnsenanza.slice(1).toLowerCase() + ".")}</span>
                </>
              }
              {(nivelesDelProyecto.data.length == 2) &&                 // Cuando es para 2 niveles.
                <>
                  <span>{(index == 0) && (x.nivelEnsenanza.charAt(0) + x.nivelEnsenanza.slice(1).toLowerCase())}</span>
                  <span>{(index == 1) && (" y " + x.nivelEnsenanza.charAt(0) + x.nivelEnsenanza.slice(1).toLowerCase() + ".")}</span>
                </>
              }
              {(nivelesDelProyecto.data.length >= 3) &&                 // Cuando es para mas de 2 niveles.
                <>
                  <span>{(index <= nivelesDelProyecto.data.length - 3) && (x.nivelEnsenanza.charAt(0) + x.nivelEnsenanza.slice(1).toLowerCase() + ", ")}</span>
                  <span>{(index == nivelesDelProyecto.data.length - 2) && (x.nivelEnsenanza.charAt(0) + x.nivelEnsenanza.slice(1).toLowerCase() + " y ")}</span>
                  <span>{(index == nivelesDelProyecto.data.length - 1) && (x.nivelEnsenanza.charAt(0) + x.nivelEnsenanza.slice(1).toLowerCase() + ". ")}</span>
                </>
              }
            </>
          ) : <div style={{visibility: "hidden"}}>Sin niveles de enseñanza asociados</div>  // Evitamos que pierda la línea de escritura el diploma.
        }
      </p>
      <p style={{ fontStyle: "italic" }}> {`Este evento de capacitación, fortalecimiento y actualización docente fue auspiciado por`} </p>
      {
        (findActividad?.resolucion.slice(3, 15) === "S.P.C.E.(ME)") &&    // Desde "Sec. de Planificación y Calidad Educativa".
        <p style={{ fontStyle: "italic" }}> {` el Ministerio de Educación, a travéz de Resolución Ministerial
        ${findActividad?.resolucion.slice(3, 15)} 
        ${findActividad?.resolucion.slice(15)} `}
        </p>
      }
      {
        (findActividad?.resolucion.slice(3, 7) === "M.E.") &&       // Desde "Ministerio de Educación". 
        <p style={{ fontStyle: "italic" }}> {` el Ministerio de Educación, a travéz de Resolución Ministerial
        ${findActividad?.resolucion.slice(3, 7)} 
        ${findActividad?.resolucion.slice(7)} `}
        </p>
      }
      <p style={{ fontStyle: "italic" }}>
        {`El presente certificado se expide en San Fernando Del Valle 
        de Catamarca ${formatDDMMYY_String(alumno.proyecto.fechaFin)}.`}
      </p>
      {/* Falta poner esta fecha desde base.  */}
      <div className="Logos">
        <div className="logosDiv">
          <img style={{ marginLeft: "60px" }} src={logoSello} alt="Logo Sello" />
          <img src={firmaDos} alt="Firma 1 Certificado" />
          <img src={firmaUno} alt="Firma 2 Certificado" />
          <img style={{ marginRight: "60px" }} src={coso} alt="Identificador Único" />
        </div>
      </div>
      {/* Resto de los campos de datos aquí */}
    </div >
  );
});

export default function ImportAction({ data, certificate }) {

  const dispatch = useDispatch();
  const componentRef = useRef();
  const handleClick = () => {
    dispatch(getCertificate(certificate.idCertificacion));

    setTimeout(() => {
      handlePrint();
    }, 1000);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <IconButton variant="outlined" onClick={handleClick}>
        <PrintIcon />
      </IconButton>
      <div style={{ display: "none" }} className="printable-img">
        <PrintView alumno={data} ref={componentRef} certificate={certificate} />
      </div>
    </>
  );
}
