import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import PersonaFrom from "../Common/PersonaFrom";
import { IconButton, Paper, Tooltip } from "@mui/material";
import { PersonaContext } from "../Common/PersonaProvider";
import { ChevronLeft } from "@mui/icons-material";
import {
  clearPersonaSearchData,
  createNewPerson,
  editPerson,
} from "store/persona/persona.slice";
export default function EditarPersona({ onClose, persona }) {
  const dispatch = useDispatch();
  const { setPersona } = useContext(PersonaContext);

  const handleSubmit = (persona) => {
    if (persona?.idPersona) {
      dispatch(editPerson(persona));
    } else {
      dispatch(createNewPerson(persona));
    }
  };

  const handleClose = () => {
    setPersona(null);
    dispatch(clearPersonaSearchData());
    onClose(false);
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "left" }}>
        <Tooltip title="Volver atras">
          <IconButton
            style={{
              backgroundColor: "transparent",
              "&:hover": { backgroundColor: "transparent" },
              "&:focus": { backgroundColor: "transparent" },
            }}
            onClick={() => handleClose()}
          >
            <ChevronLeft style={{ fontSize: "32px" }} />
          </IconButton>
        </Tooltip>
        <h1 style={{ display: "flex", alignItems: "left", margin: "10px 0" }}>
          {persona ? "Editar" : "Agregar"} persona
        </h1>
      </div>
      <Paper style={{ padding: 20, backgroundColor: "#FFFFFF" }}>
        <PersonaFrom
          onSubmit={handleSubmit}
          onClose={handleClose}
          data={persona}
        />
      </Paper>
    </>
  );
}
