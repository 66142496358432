import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ProyectosIEliminar from "../ProyectosIEliminar/ProyectosIEliminar";
import ProyectoEditar from "../ProyectosIEditar/ProyectosIEditar";
import ProyectoDestinadoA from "../ProyectosIDestinadoA/ProyectosIDestinadoA";
import ResolucionesPage from "views/Resoluciones/ResolucionesPage/ResolucionesPage";
import ResponsablesPage from "views/Responsables/ResponsablesPage/ResponsablesPage";
import NivelesPage from "views/NivelesEnsenanza/NivelesEnsenanzaPage/NivelesEnsenanzaPage";
import { useIdRol } from "utils/roles/IdRolContext";
import {
  tableBodyCell,
  tableBodyCenterCell,
  tableHeadCell,
} from "components/customTable/StylesTable";
import CustomTableContainer from "components/customTable/CustomTableContainer";

const ProyectosITable = ({ datosProy }) => {

  //console.log('datosProy en tabla de Proyectos', datosProy)

  const { rolName } = useIdRol();

  function funcEva(sioNo) {
    switch (sioNo) {
      case "S":
        return "SI";
      case "N":
        return "NO";
      default:
        return "Sin Información";
    }
  }

  function cantNiveles(itemProy) {
    if (itemProy.cantNiveles === 0 || itemProy.cantNiveles == null) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <NivelesPage itemProy={itemProy} colorResp={"rgb(158, 56, 255)"} />
          <span style={{ color: "rgb(158, 56, 255)", fontWeight: "bold" }}>
            (
            {itemProy.cantNiveles === 0 ? itemProy.cantNiveles : "Sin Cantidad"}
            )
          </span>
        </div>
      );
    } else {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <NivelesPage itemProy={itemProy} />
          <span style={{ color: "rgb(117,117,117)", fontWeight: "bold" }}>
            ({itemProy.cantNiveles})
          </span>
        </div>
      );
    }
  }

  function renderResponsables(itemProy) {
    const colorStyle =
      itemProy.cantResponsables === 0
        ? { color: "rgb(158, 56, 255)", fontWeight: "bold" }
        : { color: "rgb(117,117,117)", fontWeight: "bold" };

    if (itemProy.cantResponsables == null) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <ResponsablesPage itemProy={itemProy} colorResp={colorStyle.color} />
          <span style={colorStyle}>(Sin Cantidad)</span>
        </div>
      );
    } else {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <ResponsablesPage itemProy={itemProy} colorResp={colorStyle.color} />
          <span style={colorStyle}>({itemProy.cantResponsables})</span>
        </div>
      );
    }
  }

  function renderResoluciones(itemProy) {
    const colorStyle =
      itemProy.cantResoluciones === 0
        ? { fontWeight: "bold", color: "rgb(158, 56, 255)" }
        : { fontWeight: "bold" };

    const descripcionResol = itemProy?.descripcionResol;

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <ResolucionesPage itemAct={itemProy} colorResol={colorStyle.color} />
        <div style={colorStyle}>
          {itemProy.cantResoluciones === 0 ? (
            <span style={{ color: "rgb(158, 56, 255)" }}>(S/R)</span>
          ) : descripcionResol ? (
            <span style={{ color: "rgb(117,117,117)" }}>
              {descripcionResol.slice(3)}
            </span>
          ) : (
            <span style={{ color: "rgb(117,117,117)" }}>(Sin Descripción)</span>
          )}
        </div>
      </div>
    );
  }

  function formatDate(fecha) {
    return fecha
      ? new Date(fecha).toLocaleDateString("es-ES", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
      : "";
  }

  return (
    <CustomTableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead
          sx={{
            borderTop: "1px solid", // Agregar borde superior
          }}
        >
          <TableRow>
            {[
              "ID",
              "Institucion",
              "Denominación",
              "Tipo de proyecto",
              "Modalidad del proyecto",
              "Estado del proyecto",
              "Resoluciones",
              "Equipo responsable",
              "Niveles de enseñanza",
              "Fecha de inicio",
              "Fecha de fin",
              "Destinado a",
              "Evaluación",
              "Horas cátedra",
              rolName !== "institucionOferente" &&
                rolName !== "capacitadores" &&
                rolName !== "docente" &&
                "Acciones",
            ].map((header, index) => (
              <TableCell key={index} sx={{ ...tableHeadCell }}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {datosProy?.records &&
          datosProy &&
          (datosProy?.records?.length > 0 || datosProy?.length > 0) ? (
            (datosProy?.records || datosProy)?.map((itemProy, index) => (
              <TableRow key={index}>
                {[
                  itemProy.idProyecto,
                  itemProy.nombreInstitucion,
                  itemProy.denominacion,
                  itemProy.tipoProyecto,
                  itemProy.modalidadProyecto,
                  itemProy.estadoProyecto,
                  renderResoluciones(itemProy),
                  renderResponsables(itemProy),
                  cantNiveles(itemProy),
                  formatDate(itemProy.fechaInicio),
                  formatDate(itemProy.fechaFin),
                  <ProyectoDestinadoA key="destinadoA" itemProy={itemProy} />,
                  funcEva(itemProy.conEvaluacion),
                  itemProy.horasCatedra,
                  rolName !== "institucionOferente" &&
                    rolName !== "capacitadores" &&
                    rolName !== "docente" && (
                      <div
                        key="acciones"
                        style={{ minWidth: "120px", textAlign: "center" }}
                      >
                        <ProyectoEditar itemProy={itemProy} />
                        <ProyectosIEliminar itemProy={itemProy} />
                      </div>
                    ),
                ].map((cell, index) => (
                  <TableCell
                    key={index}
                    sx={
                      index !== 11
                        ? { ...tableBodyCell }
                        : { ...tableBodyCenterCell }
                    }
                  >
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={15} sx={{ textAlign: "center" }}>
                No se encontraron resultados
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </CustomTableContainer>
  );
};

export default ProyectosITable;
