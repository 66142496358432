import {
  createNewInstitucion, 
  getInstitucionById, 
  editInstitucion,
  getInstituciones, 
} from "store/Institucion/institucion.slice";
import { showSnackbar } from "store/mensajeria/mensajeriaSlice";

export const submitInstitucion = (data, dispatch, institucionToEdit, handleClose) => {
  if (institucionToEdit.length !== 0) {
    dispatch(editInstitucion(data))
      .then((resultAction) => {
        if (editInstitucion.fulfilled.match(resultAction)) {
          dispatch(showSnackbar(resultAction.payload.message));
          dispatch(getInstituciones(1)); 
        }
      });
    handleClose();
    return;
  }

  const dataTransform = {
    ...data,
  };
  
  dispatch(createNewInstitucion(dataTransform))
    .then((resultAction) => {
      if (createNewInstitucion.fulfilled.match(resultAction)) {
        dispatch(showSnackbar(resultAction.payload.message));
        dispatch(getInstituciones(1)); 
      }
    });
  handleClose();
};


export const editInstituciones  = (idInstitucion, dispatch) => {
  dispatch(getInstitucionById(idInstitucion));
};
