import React from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import CapacitadorEliminar from "../CapacitadoresEliminar/CapacitadoresEliminar";
import CapacitadorEditar from "../CapacitadoresEditar/CapacitadoresEditar";
import { formatDDMMYY } from "utils/helpers/dateFormat";
import { useIdRol } from "utils/roles/IdRolContext";

export default function CapacitadoresTable({ itemAct, datosCap }) {

    const { rolName } = useIdRol();

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ fontWeight: "bold" }}>ID</TableCell>
                        <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Apellido y Nombre</TableCell>
                        {/* <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Responsabilidad</TableCell> */}
                        {rolName !== "institucionOferente" && rolName !== "capacitadores" && rolName !== "docente" &&
                            (<>
                                <TableCell style={{ fontWeight: "bold" }}>E-mail</TableCell>
                                <TableCell style={{ fontWeight: "bold" }}>Teléfono</TableCell>
                                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Inicio de Servicio</TableCell>
                                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Fin de Servicio</TableCell>
                                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Causa Baja</TableCell>
                                <TableCell style={{ fontWeight: "bold", textAlign: "center", minWidth: "120px" }}>
                                    Acciones
                                </TableCell>
                            </>)}
                    </TableRow>
                </TableHead>
                <TableBody>

                    {datosCap?.map(itemCap => (
                        <TableRow key={itemCap.idCapacitador}
                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                            <TableCell>{itemCap.idCapacitador}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{itemCap.persona.apellidoyNombre}</TableCell>
                            {/* <TableCell style={{ textAlign: "center" }}>{itemCap.responsabilidad.descripcion}</TableCell> */}
                            {rolName !== "institucionOferente" && rolName !== "capacitadores" && rolName !== "docente" &&
                                (<>
                                    <TableCell>{itemCap.persona.email}</TableCell>
                                    <TableCell>{itemCap.persona.telefono}</TableCell>
                                    <TableCell>{formatDDMMYY(itemCap.fechaInicioServ)}</TableCell>
                                    <TableCell>{formatDDMMYY(itemCap.fechaFinServ)}</TableCell>
                                    <TableCell style={{ textAlign: "center" }}>
                                        {itemCap?.causasBaja?.descripcion || "SIN BAJA"}
                                    </TableCell>
                                    <TableCell>
                                        <CapacitadorEditar itemAct={itemAct} itemCap={itemCap} />
                                        <CapacitadorEliminar itemAct={itemAct} itemCap={itemCap} />
                                    </TableCell>
                                </>)}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}