import React, { createContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {
  getAllCertificate,
  getAllTypeCertificate,
  selectAllCertificados,
  selectCreating,
  selectDeleting,
  selectTipeCert,
} from "store/certificados/certificado.slice";
import Loader from "components/Loader/Loader";
import { GenericTemplate } from "template/GenericTemplate";
import { Button, Checkbox } from "@mui/material";
import ImportAction from "./tableActions/ImportAction";
import {
  getAlumnosByACT,
  loadingAlumno,
  selectByAct,
} from "store/Alumnos/alumnos.slice";
import { GetActividadesXProyectoEndPoint } from "store/actividades/actividades.slice";
import {
  getProyectosConResol,
  selectProyectosConResol,
} from "store/proyectos/proyectos.slice";
import { FilterFields } from "./FilterFields";
import {
  confirmateCertificate,
  handleCertificar,
} from "./functions/handleCertificar";
import { useLocation } from "react-router-dom";
import { useIdRol } from "utils/roles/IdRolContext";
import "./certifiicado-styles.css";
import PreInsForm from "views/PrePreinsc/PreInsForm";

export const myCertificacionPersona = createContext(1);

export default function CertificadoPage() {
  const dispatch = useDispatch();
  let [page, setPage] = React.useState(1);
  const [cahngeCerto, setCahngeCerto] = useState(false);
  const [certificateState, setCertificateState] = useState([]);

  const [actividadData, setActividadData] = useState(null);
  const [convertCertificado, setConvertCertificado] = useState(null);
  const { rolName } = useIdRol();
  const [proyecto, setProyecto] = useState(null);
  const [actividad, setActividad] = useState(null);

  const location = useLocation();

  const path = location.pathname;

  const isAlumnno = path === "/certificados/certificados";

  //Loadings
  const creating = useSelector(selectCreating);
  const deleting = useSelector(selectDeleting);

  const loadding = deleting || deleting;

  //finLoadings
  // useEffect(() => {
  //   dispatch(getAlumnosByACT({ actividadData, page }));
  //   dispatch(getAllCertificate());
  // }, [page, dispatch, creating, deleting, actividadData]);

  useEffect(() => {
    // dispatch(getAllTypeCertificate());
    dispatch(getProyectosConResol());
    // dispatch(getAllCertificate());
  }, []);

  const handleChangeProyectos = (idProyecto) => {
    if (!idProyecto) return;
    dispatch(GetActividadesXProyectoEndPoint(idProyecto));
  };

  const handleGetAlumnos = (actividadData) => {
    setActividadData(actividadData);
    dispatch(getAlumnosByACT({ actividadData, page }));
  };

  //Trae alumnos por proyecto
  const fetchAlumnos = useSelector(selectByAct);

  const loadingAlumnos = useSelector(loadingAlumno);
  //Trae proyectos
  const fetchProyecto = useSelector(selectProyectosConResol);
  //trae tipo de certificado
  const fetchTypoCert = useSelector(selectTipeCert);
  //trae todos los certificados disponibles
  const fetchAllCertificate = useSelector(selectAllCertificados);
  const loadingCertificado = useSelector(loadingAlumno);
  const alumnos = fetchAlumnos.records ? fetchAlumnos.records : [];

  // useEffect(() => {
  //   if (alumnos.length !== 0) {
  //     const convertCertificad = alumnos.map((alumno) => {
  //       const findCertificado = fetchAllCertificate?.data?.find(
  //         (certificado) => certificado.idAlumno === alumno.idAlumno
  //       );

  //       const findUser = certificateState.find(
  //         (user) => user.alumno === alumno.idAlumno
  //       );

  //       if (!findUser) {
  //         certificateState.push({
  //           selectValue: findCertificado?.idTipoCertificado ?? 0,
  //           alumno: alumno.idAlumno,
  //         });
  //       }

  //       const dataAlumno = {
  //         ...alumno,
  //         proyecto: fetchProyecto.data.find(
  //           (proyecto) => proyecto.idProyecto === alumno.actividad.idProyecto
  //         ),
  //       };

  //       const findSelectValue = certificateState.find(
  //         (select) => select.alumno === alumno.idAlumno
  //       );

  //       let certificadoConvert = {
  //         ID: alumno.idAlumno ?? 0,
  //         NombreAlumno: alumno.persona.apellidoyNombre,
  //         CuitAlumno: alumno.persona.cuit,
  //         Actividad: alumno.actividad.descripcion,
  //       };

  //       if (!isAlumnno) {
  //         certificadoConvert.Certificado = (
  //           <>
  //             <Checkbox
  //               checked={findCertificado?.estadoCertificacion}
  //               disabled={
  //                 findSelectValue?.selectValue === 0 ||
  //                 findSelectValue === undefined
  //               }
  //               name="Aprobar/desaprobar"
  //               onChange={(e) => {
  //                 handleCertificar(
  //                   alumno,
  //                   findSelectValue,
  //                   dispatch,
  //                   findCertificado
  //                 );
  //               }}
  //               inputProps={{ "aria-label": "controlled" }}
  //             />
  //           </>
  //         );
  //       } else if (isAlumnno && findCertificado.confirmado) {
  //         certificadoConvert.Imprimir = (
  //           <div style={{ textAlign: "center" }}>
  //             {rolName !== "institucionOferente" &&
  //               rolName !== "capacitadores" && (
  //                 <ImportAction
  //                   data={dataAlumno}
  //                   certificate={findCertificado}
  //                 />
  //               )}
  //             <div style={{ fontWeight: "bold", color: "rgb(117,117,117)" }}>
  //               {"CON DIPLOMA"}
  //             </div>
  //           </div>
  //         );
  //       } else {
  //         certificadoConvert.Imprimir = <></>;
  //       }

  //       return certificadoConvert;
  //     });

  //     setConvertCertificado(convertCertificad);
  //   }
  // }, [alumnos, fetchAllCertificate, cahngeCerto, loadding]);

  if (loadingCertificado || loadingAlumnos) {
    return <Loader />;
  }

  const handleChange = (event, values) => {
    setPage(values);
  };

  return (
    <div className="container-cer">
      <myCertificacionPersona.Provider value={page}>
        <React.Fragment>
          <div style={{ display: "flex", alignItems: "left" }}>
            <h1 style={{ margin: "10px 0" }}>
              {"Realizar Pre-Inscripción"}
            </h1>
          </div>
          <div className="component-container-cer">
            <div>
              <FilterFields
                dataProyecto={fetchProyecto}
                handleChangeProyectos={handleChangeProyectos}
                handleGetAlumnos={handleGetAlumnos}
                proyecto={proyecto}
                actividad={actividad}
                setProyecto={setProyecto}
                setActividad={setActividad}
                setActividadData={setActividadData}
                setPage={setPage}
              />
            </div>
            <div>
              {actividad !== null &&
                <PreInsForm proyectoId={proyecto.idProyecto} actividadId={actividad.idActividad} />
              }
            </div>
          </div>         
        </React.Fragment>
      </myCertificacionPersona.Provider>
    </div>
  );
}
