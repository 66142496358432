import { request } from "utils/helpers/request.helpers";

const urlPreinscripcion = `${process.env.REACT_APP_API_URL_LOGIN_DEV}`;

export function GetPreInscripcion(page) {
  const url = `${urlPreinscripcion}/GetPreInscripcion?page=${page}`;
  return request(url, { method: 'GET' });
}

export function GetPreInscripcionById(page, idActividad) {
  const url = `${urlPreinscripcion}/GetPreInscripcionById?page=${page}&idActividad=${idActividad}`;
  return request(url, { method: 'GET' });
}

export function GetAllPreInscripcion() {
  const url = `${urlPreinscripcion}/GetAllPreInscripcion`;
  return request(url, { method: 'GET' });
}

export function PutPreInscribir(idPreinscripcion, page) {
  return request(`${urlPreinscripcion}/PutPreInscribir?idPreinscripcion=${idPreinscripcion}&page=${page}`, {
    method: 'PUT',
  });
}

export function PutPreInscribirAll(idPreinscripcion, page) {
  return request(`${urlPreinscripcion}/PutPreInscribirAll?idPreinscripcion=${idPreinscripcion}&page=${page}`, {
    method: 'PUT',
  });
}

export function PutPreInscribirRemoveAll(idPreinscripcion, page) {
  return request(`${urlPreinscripcion}/PutPreInscribirRemoveAll?idPreinscripcion=${idPreinscripcion}&page=${page}`, {
    method: 'PUT',
  });
}

export function SavePreInscripcion(data) {
  return request(`${urlPreinscripcion}/SavePreInscripcion`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function UpdatePreInscripcion(data) {
  return request(`${urlPreinscripcion}/UpdatePreInscripcion`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function RemovePreInscripcion(idPreInscripcion) {
  const url = `${urlPreinscripcion}/RemovePreInscripcion?idPreInscripcion=${idPreInscripcion}`;
  return request(url, { method: 'DELETE' });
}

export function getMisPreinscripciones() {
  const url = `${urlPreinscripcion}/Inscripcion/GetMisPreinscripciones`;
  return request(url, { method: 'GET' });
}

export function SavePreInscripcionExterna(data) {
  return request(`${urlPreinscripcion}/Inscripcion/PostPreInscripcionExterna`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}