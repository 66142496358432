import { request } from 'utils/helpers/request.helpers';

const resolucionUrl = `${process.env.REACT_APP_API_URL_DEV}/api/Resolucion`;

export function GetResolucion(id) {
  //Si no se envia ID entonces se obtendran todas las resoluciones
  const url = id !== undefined ? 
  `${resolucionUrl}/GetResolucion?idProyecto=${id}`
  :`${resolucionUrl}/GetResolucion`;
  return request(url, { method: 'GET' });
}

