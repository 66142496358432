import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SnackBarUtils from "utils/MUI/SnackbarUtils";
import GroupIcon from "@mui/icons-material/Group";
import IconButton from "@mui/material/IconButton";

import {
  GetResponsablesEndPoint,
  selectCapacitadoresResponse,
  selectIsLoading,
  selectResponsables,
} from "store/capacitadores/capacitadores.slice";
import ResponsablesNuevo from "../ResponsablesNuevo/ResponsablesNuevo";
import ResponsablesTable from "../ResponsablesTable/ResponsablesTable";
import ResponsablesTituloModal from "../ResponsablesTituloModal";
import { SnackBarMessageResponse } from "utils/Response/SnackBarMessageResponse";
import { CircularProgress, DialogContent, Tooltip } from "@mui/material";
import CustomModal from "components/customModal/CustomModal";

const ResponsablesPage = ({ itemProy, colorResp }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const GetResponsables = useSelector(selectResponsables);
  const [message, setMessage] = useState("");
  const [view, setView] = useState(false);
  const [viewMessage, setViewMessage] = useState(false);
  const capacitador = useSelector(selectCapacitadoresResponse);
  const capacitadoresIsLoading = useSelector(selectIsLoading);
  const handleClickOpen = () => {
    setOpen(true);
    dispatch(GetResponsablesEndPoint(itemProy?.idProyecto || 0)); // No lleva paginación.
  };

  const handleClose = () => {
    setOpen(false);
  };

  const muestroTabla = () => {
    if (GetResponsables?.data?.length !== 0) {
      // Capacitadores para una determinada Actividad.
      return (
        <ResponsablesTable
          itemProy={itemProy}
          datosResp={GetResponsables?.data || []}
        />
      );
    } else {
      return (
        <p
          style={{
            textAlign: "center",
          }}
        >
          {" "}
          Sin Integrantes asignados.{" "}
        </p>
      );
    }
  };

  useEffect(() => {
    if (Array.isArray(capacitador.message) && viewMessage) {
      setView(true);
      setMessage(SnackBarMessageResponse(capacitador));
    }

    setViewMessage(true);
  }, [capacitador, viewMessage]);
  return (
    <React.Fragment>
      <Tooltip title="Equipo Responsable">
        <IconButton variant="outlined" onClick={handleClickOpen}>
          <GroupIcon style={{ color: colorResp }} />
        </IconButton>
      </Tooltip>
      <CustomModal
        title={"Equipo Responsable"}
        open={open}
        setOpen={setOpen}
        maxWidth={GetResponsables?.data?.length > 0 ? "lg" : "md"}
      >
        <DialogContent>
          {capacitadoresIsLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress color="inherit" />
            </div>
          ) : (
            <>
              <ResponsablesTituloModal itemProy={itemProy || []} />
              {muestroTabla()}
              <ResponsablesNuevo
                itemProy={itemProy}
                handleConteinerClose={handleClose}
              />
            </>
          )}
        </DialogContent>
      </CustomModal>
      <SnackBarUtils
        closed={(value) => setView(value)}
        message={message}
        view={view}
      />
    </React.Fragment>
  );
};

export default ResponsablesPage;
