import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Navigate, useParams } from "react-router-dom";
import { login, logout } from "./store/auth/auth.slice";
import SignInSide from "views/Login/SignInSide";
import Dashboard from "components/Dashboard/Dashboard";
import ProyectosPage from "views/Proyectos/ProyectosPage/ProyectosPage";

import ActividadesPage from "views/Actividades/ActividadesPage/ActividadesPage";
import InstitucionPage from "views/Instituciones/InstitucionesPage";
import PreInscripcion from "views/PrePreinsc/PreInscripcion";
import ProyectosIPage from "views/ProyectosInicio/ProyectosIPage/ProyectosIPage";
import CursoPage from "views/Curso/CursoPage";
import CargaExternaPage from "views/CargaExterna/cargaExternaPage";
import Inscripcion from "views/Inscripcion/Inscripcion";
import { SnackBarMessageResponse } from "utils/Response/SnackBarMessageResponse";
import SnackBarUtils from "utils/MUI/SnackbarUtils";
import { hiddenSnackbar } from "store/mensajeria/mensajeriaSlice";
import PeriodosPage from "views/Periodos/Page/PeriodosPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import PersonaPage from "views/Persona/Page/PersonaPage";
import { PersonaProvider } from "views/Persona/Modal/Common/PersonaProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PreInsPage from "views/PrePreinsc/PreInsPage/PreInsPage";
import PreInsForm from "views/PrePreinsc/PreInsForm";
import PreInscripcionPage from "views/certificados/PreInscripcionPage";
import CertificadoPage from "views/certificados/CertificadoPage";

export default function App() {
  const isAuthenticated = localStorage.getItem("token");
  const dispatch = useDispatch();
  const Snackbar = useSelector((state) => state.mensajeria);
  const [message, setMessage] = useState("");
  const { code } = useParams();

  
  // useEffect(() => {
  //   // Recupera el estado de autenticación desde localStorage al cargar la página
  //   const savedAuth = localStorage.getItem("token");
  //   if (savedAuth) {
  //     dispatch(login());
  //   } else {
  //     dispatch(logout());
  //   }
  // }, [dispatch]);

  // useEffect(() => {
  //   console.log("en el useEffect del code")
  //   if (code) {
  //     console.log("code",code)
  //     alert("code", code)
  //   }
  // }, [])
  

  useEffect(() => {
    if (
      Array.isArray(Snackbar.message) &&
      Snackbar.view &&
      Snackbar.message.length > 0
    ) {
      setMessage(SnackBarMessageResponse(Snackbar));
    }
  }, [Snackbar, message]);

  let token = true;

  const queryClient = new QueryClient()

  return (
    <>
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
            <Route path="*" element={<Dashboard logout={isAuthenticated} />}>
              {/* <Route path="proyectos/proyectos" element={<ProyectosIPage />} />
              <Route path="proyectos/estado" element={<ProyectosPage />} />
              <Route
                path="actividades/actividades"
                element={<ActividadesPage />}
              />
              <Route
                path="entidades/personas"
                element={
                  <PersonaProvider>
                    <PersonaPage />
                  </PersonaProvider>
                }
              />
              <Route
                path="entidades/instituciones"
                element={<InstitucionPage />}
              />
              <Route path="periodos/periodos" element={<PeriodosPage />} /> */}
              <Route
                path="inscripcion/preinscripcion"
                element={<PreInscripcion />}
              />
              <Route
                path="inscripcion/preinscripcionlista"
                element={<PreInsPage />}
              />              
              {/* <Route path="inscripcion/inscripcion" element={<Inscripcion />} />
              <Route path="inscripcion/curso" element={<CursoPage />} />
              <Route
                path="certificados/cargaExterna"
                element={<CargaExternaPage />}
              />
              */}
              <Route   SE COMENTA HASTA QUE DEBA ESTAR HABILITADO
                path="certificados/certificaciones"
                element={<CertificadoPage />}
              />
              <Route
                path="preinscripcion/nueva"
                element={<PreInscripcionPage />}
              /> 
            </Route>
          
        </Routes>
        <SnackBarUtils
          closed={(value) => dispatch(hiddenSnackbar())}
          message={message}
          view={Snackbar.view}
        />
      </Router>
      <ToastContainer theme="colored" hideProgressBar />
      </QueryClientProvider>
    </>
  );
}
