import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import {
  GetAllPreInscripcion,
  GetPreInscripcion,
  GetPreInscripcionById,
  PutPreInscribir,
  PutPreInscribirAll,
  PutPreInscribirRemoveAll,
  RemovePreInscripcion,
  SavePreInscripcion,
  UpdatePreInscripcion,
} from "./preInscripcion.api";

const initialState = {
  entities: {},
  allEntities: {},
  response: {},
  preInscribir: {},
  loading: true,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
};

export const GetPreInscripcionEndpoint = createAsyncThunk(
  "preInscripcion/getPreInscripcion",
  async (page) => {
    const r = await GetPreInscripcion(page);
    return r;
  }
);

export const GetPreInscripcionByIdEndpoint = createAsyncThunk(
  "preInscripcion/GetPreInscripcionById",
  async ({ page, actividad }) => {
    const r = await GetPreInscripcionById(page, actividad);
    return r;
  }
);

export const GetAllPreInscripcionEndPoint = createAsyncThunk(
  "preInscripcion/GetAllPreInscripcion",
  async () => {
    const r = await GetAllPreInscripcion();
    return r;
  }
);

export const PutPreInscribirEndpoint = createAsyncThunk(
  "preInscripcion/putPreInscribir",
  async ({ idPreinscripcion, page }) => {
    const r = await PutPreInscribir(idPreinscripcion, page);
    return r;
  }
);

export const PutPreInscribirAllEndpoint = createAsyncThunk(
  "preInscripcion/putPreInscribirAll",
  async ({ idPreinscripcion, page }) => {
    const r = await PutPreInscribirAll(idPreinscripcion, page);
    return r;
  }
);

export const PutPreInscribirRemoveAllEndpoint = createAsyncThunk(
  "preInscripcion/PutPreInscribirRemoveAll",
  async ({ idPreinscripcion, page }) => {
    const r = await PutPreInscribirRemoveAll(idPreinscripcion, page);
    return r;
  }
);

export const SavePreInscripcionEndpoint = createAsyncThunk(
  "preInscripcion/SavePreInscripcion",
  async (data) => {
    const r = await SavePreInscripcion(data);
    return r;
  }
);

export const UpdatePreInscripcionEndpoint = createAsyncThunk(
  "preInscripcion/UpdatePreInscripcion",
  async (data) => {
    const r = await UpdatePreInscripcion(data);
    return r;
  }
);

export const RemovePreInscripcionEndpoint = createAsyncThunk(
  "preInscripcion/RemovePreInscripcion",
  async (idPreinscripcion) => {
    const r = await RemovePreInscripcion(idPreinscripcion);
    return r;
  }
);

export const clearPreInsc = createAction("entities/clearPreInsc");

export const preInscripcionSlice = createSlice({
  name: "preInscripcion",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // GetPreInscripcionEndpoint

      .addCase(GetPreInscripcionEndpoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetPreInscripcionEndpoint.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(GetPreInscripcionEndpoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // GetPreInscripcionByIdEndpoint

      .addCase(GetPreInscripcionByIdEndpoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetPreInscripcionByIdEndpoint.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(GetPreInscripcionByIdEndpoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // GetAllPreInscripcionEndPoint

      .addCase(GetAllPreInscripcionEndPoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetAllPreInscripcionEndPoint.fulfilled, (state, action) => {
        state.loading = false;
        state.allEntities = action.payload;
      })
      .addCase(GetAllPreInscripcionEndPoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // PutPreInscribirEndpoint

      .addCase(PutPreInscribirEndpoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(PutPreInscribirEndpoint.fulfilled, (state, action) => {
        state.loading = false;
        state.preInscribir = action.payload;
      })
      .addCase(PutPreInscribirEndpoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // PutPreInscribirAllEndpoint

      .addCase(PutPreInscribirAllEndpoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(PutPreInscribirAllEndpoint.fulfilled, (state, action) => {
        state.loading = false;
        state.preInscribir = action.payload;
      })
      .addCase(PutPreInscribirAllEndpoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // PutPreInscribirAllEndpoint

      .addCase(PutPreInscribirRemoveAllEndpoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(PutPreInscribirRemoveAllEndpoint.fulfilled, (state, action) => {
        state.loading = false;
        state.preInscribir = action.payload;
      })
      .addCase(PutPreInscribirRemoveAllEndpoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // SavePreInscripcionEndpoint

      .addCase(SavePreInscripcionEndpoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(SavePreInscripcionEndpoint.fulfilled, (state, action) => {
        state.loading = false;
        state.preInscribir = action.payload;
      })
      .addCase(SavePreInscripcionEndpoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // UpdatePreInscripcionEndpoint

      .addCase(UpdatePreInscripcionEndpoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UpdatePreInscripcionEndpoint.fulfilled, (state, action) => {
        state.loading = false;
        state.preInscribir = action.payload;
      })
      .addCase(UpdatePreInscripcionEndpoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // RemovePreInscripcionEndpoint

      .addCase(RemovePreInscripcionEndpoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(RemovePreInscripcionEndpoint.fulfilled, (state, action) => {
        state.loading = false;
        state.preInscribir = action.payload;
      })
      .addCase(RemovePreInscripcionEndpoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      //#region clearPreInsc
      .addCase(clearPreInsc, (state) => {
        state.entities = {}; // Vacía el array entities
      });
    //#endregion
  },
});

export const selectPreInscripcion = (state) => state.preInscripcion.entities;
export const selectAllPreInscripcion = (state) =>
  state.preInscripcion.allEntities;
export const selectPreInscribir = (state) => state.preInscripcion.preInscribir;
export const selectPreInscripcionResponse = (state) =>
  state.preInscripcion.response;
export const selectIsLoading = (state) => state.preInscripcion.loading;
export const selectIsCreating = (state) => state.preInscripcion.creating;
export const selectIsEditing = (state) => state.preInscripcion.editing;
export const selectIsDeleting = (state) => state.preInscripcion.deleting;
export const selectError = (state) => state.preInscripcion.error;

export default preInscripcionSlice.reducer;
