import { request } from "utils/helpers/request.helpers";

const proyectosUrl = `${process.env.REACT_APP_API_URL_LOGIN_DEV}`;

export function GetLocalidades() {
  const url = `${proyectosUrl}/GetLocalidades`;
  return request(url, { method: "GET" });
}

export function GetProvincias() {
  const url = `${proyectosUrl}/Inscripcion/GetProvincia`;
  return request(url, { method: "GET" });
}

export function GetDepartamento(idProvincia, departamento ) {
  const url = `${proyectosUrl}/Inscripcion/PostDepartamento`;
  const body = JSON.stringify({
    idProvincia: idProvincia,
    departamento: departamento,
  });

  return request(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: body,
  });
}

export function GetLocalidad(idDepartamento, localidad) {
  const url = `${proyectosUrl}/Inscripcion/PostLocalidadesByDpto`;
  const body = JSON.stringify({
    idDepartamento: idDepartamento,
    localidad: localidad,
  });

  return request(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: body,
  });
}
