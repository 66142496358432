import React from "react";
import { useDispatch } from "react-redux";
import { IconButton, Box, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PeriodoForm from "../Common/PeriodoForm";
import { PeriodosContext } from "../../Page/PeriodosPage";
import { PutPeriodo } from "store/Periodo/periodos.slice";
import CustomModal from "components/customModal/CustomModal";

export default function EditarPeriodo({ periodo }) {
  const [open, setOpen] = React.useState(false);
  const page = React.useContext(PeriodosContext);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick" && "escapeKeyDown") return;
    setOpen(false);
  };

  const handleSubmit = (periodo) => {
    dispatch(PutPeriodo({ data: periodo, page }));
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Tooltip title="Modificar">
        <IconButton variant="outlined" onClick={handleClickOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <CustomModal title={"Editar Periodo"} open={open} setOpen={handleClose}>
        <DialogContent>
          <PeriodoForm
            onSubmit={handleSubmit}
            onClose={handleClose}
            data={periodo}
          />
        </DialogContent>
      </CustomModal>
    </React.Fragment>
  );
}
