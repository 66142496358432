import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

const IdRolContext = createContext();

export const IdRolProvider = ({ children }) => {
  const [idRol, setIdRol] = useState(null);
  const [rolName, setRolName] = useState(null);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("usuario"));
    const idRolFromStorage = storedData ? storedData.idRol : null;
    setIdRol(idRolFromStorage);

    const rolNames = {
      122: "superAdmin",
      121: "planeamiento",
      120: "institucionOferente",
      119: "capacitadores",
      118: "docente"
    };

    const rolNameFromStorage = idRolFromStorage ? rolNames[idRolFromStorage] : null;
    setRolName(rolNameFromStorage);

    const handleStorageChange = (event) => {
      if (event.key === "token") {
        const decodedToken  = jwtDecode(event.newValue);
        const updatedIdRol = decodedToken ? decodedToken.idRol : null;
        const updatedRolName = updatedIdRol ? rolNames[updatedIdRol] : null;
        setIdRol(updatedIdRol);
        setRolName(updatedRolName);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [rolName]);

  return (
    <IdRolContext.Provider value={{ idRol, rolName }}>
      {children}
    </IdRolContext.Provider>
  );
};

export const useIdRol = () => {
  return useContext(IdRolContext);
};
