import { request } from "utils/helpers/request.helpers";

const actividadesUrl = `${process.env.REACT_APP_API_URL_DEV}/api/Certificacion`;
const urlPreinscripcion = `${process.env.REACT_APP_API_URL_LOGIN_DEV}`;

export function getCertificado(id) {
  const url = `${actividadesUrl}/obtenerCertificadoAlumno/${id}`;
  return request(url, { method: "GET" });
}

export function getAllTipoCertificados(id) {
  const url = `${actividadesUrl}/AllTipoCertificados`;
  return request(url, { method: "GET" });
}

export function getAllCertificateApi() {
  const url = `${actividadesUrl}/GetCertificaciones`;
  return request(url, { method: "GET" });
}

export function createCertificateApi(body) {
  return request(`${actividadesUrl}/InsertarCertificacion`, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(body),
  });
}
export function aprovateCertApi(body) {

  return request(`${actividadesUrl}/EditCertificacion`, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(body),
  });
}
export function confirmCertApi(body) {

  return request(`${actividadesUrl}/ConfirmarCertificado?idActividad=${body}`, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(body),
  });
}

export function UpdateActividad({ data }) {
  return request(`${actividadesUrl}/EditActividad`, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function DeleteCertificate(idCertificate) {
  return request(
    `${actividadesUrl}/Deshabilitar?pIdCertificacion=${idCertificate}`,
    {
      method: "DELETE",
    }
  );
}

export function getCertificadoByAlumnoAndAct(body) {
  const url = `${actividadesUrl}/GetCertificaciones_byAlumno_Act?pIdAlumno=${body.alumno}&pIdActividad=${body.actividad}`;
  return request(url, { method: "GET" });
}

export function getMisCertificaciones() {
  const url = `${urlPreinscripcion}/Inscripcion/GetCertificaciones`;
  return request(url, { method: 'GET' });
}

export function getMiCertificadoById(id) {
  const url = `${urlPreinscripcion}/Inscripcion/GetCertificado?idCertificado=${id}`;
  return request(url, { method: 'GET' });
}
