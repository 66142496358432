import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid } from '@mui/material';
import { selectActividadesAprobadasAll } from "store/actividades/actividades.slice";

export default function InscripcionSearch({ actividad, searchResponse }) {
  const [actividadFiltrada, setAcitividadFiltrada] = useState([]);
  const denominacion = useSelector(selectActividadesAprobadasAll);

  useEffect(() => {
    if (denominacion != null && denominacion.data?.length > 0) {
      let mapeo = denominacion.data?.map((x) => {
        return { id: x.idActividad, label: x.descripcion };
      });
      setAcitividadFiltrada(mapeo);
    }
  }, [denominacion]);

  const handleFilter = (actividad) => {
    searchResponse(actividad);
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{ display: "flex", flexDirection: "row", marginBottom: "25px" }}
    >
      <Grid item xs={12} md={1} style={{ alignItems: 'center', margin: "8px 0px 8px 20px", minWidth: "150px", fontWeight: "600" }}>
        <span for="denominacion">
          Elegir Actividad:
        </span>
      </Grid>
      <Grid item xs={12} md={7}>
        <Autocomplete
          size="small"
          disablePortal
          onChange={(event, newValue) => {
            handleFilter(newValue);
          }}
          id="combo-box-demo"
          options={actividadFiltrada}
          isOptionEqualToValue={(option, value) => option === value}
          name="denominacion"
          renderInput={(params) => <TextField {...params} label="Actividad" />}
        />
      </Grid>
    </Grid>
  );
}
