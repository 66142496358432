import React, { useContext } from "react";
import { useDispatch } from "react-redux";

import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { myPageProyectos } from "../../ProyectosInicio/ProyectosIPage/ProyectosIPage";
import {
  EditResolucionesEndPoint,
  GetIdResoluciones,
} from "store/Resoluciones/resoluciones.slice";
import { getProyectos } from "store/inicioProyectos/IProyectos.slice";
import { showSnackbar } from "store/mensajeria/mensajeriaSlice";
import ResolucionForm from "../ResolucionForm/ResolucionForm";
import CustomModal from "components/customModal/CustomModal";
export default function ResolucionesEditar({ itemAct, itemCap }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const page = useContext(myPageProyectos);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmitAct = (data) => {
    setOpen(false);
    dispatch(EditResolucionesEndPoint({ data })).then((resultAction) => {
      if (EditResolucionesEndPoint.fulfilled.match(resultAction))
        dispatch(showSnackbar(resultAction.payload.message));
    });

    setTimeout(() => {
      dispatch(GetIdResoluciones(itemAct?.idProyecto || 0)); //Actualizamos Tabla de Capacitadores.
    }, 400);

    setTimeout(() => {
      //Actualizamos Tabla de Actividades
      dispatch(getProyectos(page ? page : 1)); //Sabemos en tiempo real la cantidad de Capacitadores
    }, 400); //para una determinada Actividad.
  };

  return (
    <React.Fragment>
      <IconButton variant="outlined" onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <CustomModal
        title={"Editar Resolución"}
        open={open}
        setOpen={handleClose}
        maxWidth="sm"
      >
        <DialogContent>
          <ResolucionForm
            data={itemCap}
            onClose={handleClose}
            onSubmit={handleSubmitAct}
          />
        </DialogContent>
      </CustomModal>
    </React.Fragment>
  );
}
