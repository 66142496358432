import React, { createContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {
  getAllCertificate,
  getAllTypeCertificate,
  selectAllCertificados,
  selectCreating,
  selectDeleting,
  selectTipeCert,
} from "store/certificados/certificado.slice";
import Loader from "components/Loader/Loader";
import { GenericTemplate } from "template/GenericTemplate";
import { Button, Checkbox } from "@mui/material";
import ImportAction from "./tableActions/ImportAction";
import {
  getAlumnosByACT,
  loadingAlumno,
  selectByAct,
} from "store/Alumnos/alumnos.slice";
import { GetActividadesXProyectoEndPoint } from "store/actividades/actividades.slice";
import {
  getProyectosConResol,
  selectProyectosConResol,
} from "store/proyectos/proyectos.slice";
import { FilterFields } from "./FilterFields";
import {
  confirmateCertificate,
  handleCertificar,
} from "./functions/handleCertificar";
import { useLocation } from "react-router-dom";
import { useIdRol } from "utils/roles/IdRolContext";
import "./certifiicado-styles.css";
import { getMisCertificaciones } from "store/certificados/certificado.api";
import { useQuery } from "@tanstack/react-query";

export const myCertificacionPersona = createContext(1);

export default function CertificadoPage() {
  const dispatch = useDispatch();
  let [page, setPage] = React.useState(1);
  const [cahngeCerto, setCahngeCerto] = useState(false);
  const [certificateState, setCertificateState] = useState([]);

  const [actividadData, setActividadData] = useState(null);
  const [convertCertificado, setConvertCertificado] = useState(null);
  const { rolName } = useIdRol();
  const [proyecto, setProyecto] = useState(null);
  const [actividad, setActividad] = useState(null);

  const { data: certificaciones = [], isLoading } = useQuery({
    queryKey: ['certificaciones'], queryFn: () => getMisCertificaciones(),
    //enabled: !!dni, // Ejecuta la query solo si hay un departamento seleccionado
});

  
  const alumnos = certificaciones.data ? certificaciones.data : [];

  useEffect(() => {
    if (alumnos.length !== 0) {
      const convertCertificad = alumnos?.map((alumno) => {
        const findCertificado = {idCertificacion: alumno.idCertificacion}     

        const dataAlumno = {
                  ...alumno,
                  proyecto: {idProyecto: alumno.actividad.idProyecto,
                    fechaFin: alumno.actividad.fechaFin,
                  }
                };
        

        let certificadoConvert = {
          ID: alumno.idAlumno ?? 0,
          NombreAlumno: alumno.alumno.persona.apellidoyNombre,
          CuitAlumno: alumno.alumno.persona.cuit,
          Actividad: alumno.actividad.descripcion,
        };
        
          certificadoConvert.Imprimir = (
            <div style={{ textAlign: "center" }}>            
                  <ImportAction
                    data={dataAlumno}
                    certificate={findCertificado}
                  />
              <div style={{ fontWeight: "bold", color: "rgb(117,117,117)" }}>
                {"CON DIPLOMA"}
              </div>
            </div>
          );
      
          //certificadoConvert.Imprimir = <></>;
        
        return certificadoConvert;
      });

      setConvertCertificado(convertCertificad);
    }
  }, [alumnos]);

  if (isLoading) {
    return <Loader />;
  }

  const handleChange = (event, values) => {
    setPage(values);
  };

  return (
    <div className="container-cer">
      <myCertificacionPersona.Provider value={page}>
        <React.Fragment>        
          
          {alumnos.length !== 0 && convertCertificado && (
            <GenericTemplate
              titleName={ "Mis Certificados"}
              dataTable={convertCertificado}
              siEdit={false}
              siDelete={false}
              siTable={true}
            />
          )}         

         { alumnos.length === 0 && <div>No hay certificados actualmente</div>}

          {/* {actividad && alumnos.length !== 0 && proyecto && (
            <Stack spacing={2} style={{ margin: "20px 0px 30px 0px" }}>
              <Pagination
                count={fetchAlumnos?.pages}
                page={page}
                onChange={handleChange}
                color="primary"
              />
            </Stack>
          )} */}


        </React.Fragment>
      </myCertificacionPersona.Provider>
    </div>
  );
}
