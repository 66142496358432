import * as Yup from "yup";

export const iproyectos = Yup.object().shape({
  idInstitucion: Yup.number().required("Este campo es obligatorio"),
  idTipoProyecto: Yup.number().required("Este campo es obligatorio"),
  idModalidadProyecto: Yup.number().required("Este campo es obligatorio"),
  idEstadoProyecto: Yup.number().required("Este campo es obligatorio"),
  denominacion: Yup.string().required("Este campo es obligatorio"),
  horasDictado: Yup.number()
    .min(1, "Debe ser mayor a 0.")
    .required("Este campo es obligatorio"),
  lugar: Yup.string()
    .required("Este campo es obligatorio")
    //.min(5, 'El lugar debe tener al menos 5 caracteres')
    .max(150, "Este campo debe tener como máximo 150 caracteres"),
});

export const proyectoss = Yup.object().shape({
  fechaInicio: Yup.date()
    .typeError("Ingrese una fecha valida")
    .required("Este campo es obligatorio"),
  fechaFin: Yup.date()
    .typeError("Ingrese una fecha valida")
    .required("Este campo es obligatorio")
    .min(
      Yup.ref("fechaInicio"),
      "La fecha de fin no puede ser menor a la fecha de inicio"
    ),
  idInstitucion: Yup.number()
    .required("Este campo es obligatorio")
    .positive("Ingrese una Institucion valida")
    .integer("Ingrese una Institucion valida"),
  conEvaluacion: Yup.string().required("Este campo es obligatorio"),
  denominacion: Yup.string().required("Este campo es obligatorio"),
  //destinadoA: Yup.string().required("Este campo es obligatorio"),     // No Obligatorio por el momento.
  idModalidadProyecto: Yup.number()
    .required("Este campo es obligatorio")
    .positive("Ingrese una Modalidad de proyecto valida")
    .integer("Ingrese una Modalidad de proyecto valida"),
  idTipoProyecto: Yup.number()
    .required("Este campo es obligatorio")
    .positive("Ingrese un Tipo de proyecto valido")
    .integer("Ingrese un Tipo de proyecto valido"),
  horasCatedra: Yup.number()
    .positive()
    .integer()
    .required("Este campo es obligatorio")
    .min(1, "La duración de la cátedra debe ser mayor que 0"),
  //.max(100, 'La duración de la cátedra no puede ser mayor de 100 horas'), // Ajuste el valor máximo según sus necesidades
  /* horasReloj: Yup.number()
    .positive("Debe ser un numero positivo ")
    .min(1, "Debe ser mayor a 0.") // El número debe ser positivo
    .required("Este campo es obligatorio"), */
  //.min(5, 'El lugar debe tener al menos 5 caracteres')
  idEstadoProyecto: Yup.number()
    .required("No cumples con las especificaciones requeridas")
    .positive("Ingrese un Estado de proyecto valido")
    .integer("Debe seleccionar un Estado de proyecto válido"),
});

export const vpersonas = Yup.object().shape({
  tipoDocumento: Yup.number().required("Este campo es obligatorio"),
  documento: Yup.number()
    .required("Este campo es obligatorio")
    .integer("El DNI debe ser un número entero")
    .positive("El DNI debe ser un número positivo")
    .min(1000000, "El DNI debe tener al menos 7 dígitos")
    .max(99999999, "El DNI debe tener como máximo 8 dígitos"),
  cuit: Yup.number()
    .required("Este campo es obligatorio")
    .integer("El DNI debe ser un número entero")
    .positive("El DNI debe ser un número positivo")
    .min(1000000000, "El CUIL debe tener al menos 11 dígitos")
    .max(99999999999, "El CUIL debe tener como máximo 11 dígitos"),
  apellidos: Yup.string()
    .required("Este campo es obligatorio")
    .min(2, "El apellido debe tener al menos 2 caracteres")
    .max(50, "El apellido debe tener como máximo 50 caracteres"),
  //.matches(/^[a-zA-Z]+$/, "El apellido solo debe contener letras"),
  nombres: Yup.string()
    .min(2, "El nombre debe tener al menos 2 caracteres")
    .max(50, "El nombre debe tener como máximo 50 caracteres")
    //.matches(/^[a-zA-Z]+$/, "El nombre solo debe contener letras")
    .required("Este campo es obligatorio"),
  sexo: Yup.string().required("Este campo es obligatorio"),
  fechaNacimiento: Yup.date()
    .max(
      new Date(),
      "La fecha de nacimiento no puede ser posterior a la fecha actual"
    )
    .required("Este campo es obligatorio"),
  telefono: Yup.number().required("Este campo es obligatorio"),
  email: Yup.string()
    .email("Formato de Email inválido")
    .required("Este campo es obligatorio"),
});
export const vperiodos = Yup.object().shape({
  idInstitucion: Yup.number().required("Este campo es obligatorio"),
  idActividad: Yup.number().required("Este campo es obligatorio"),
  fechaInicio: Yup.date().required("Este campo es obligatorio"),
  fechaFin: Yup.date()
    .required("Este campo es obligatorio")
    .min(
      Yup.ref("fechaInicio"),
      "La fecha de fin debe ser posterior o igual a la fecha de inicio"
    ),
  idTipoPeriodo: Yup.number().required("Este campo es obligatorio"),
});

export const vinstituciones = Yup.object().shape({
  nombre: Yup.string()
    .required("Este campo es obligatorio")
    .min(2, "Este campo debe tener al menos 2 caracteres"),
  domicilioLegal: Yup.string()
    .required("Este campo es obligatorio")
    .min(5, "Este campo debe tener al menos 5 caracteres"),
  telefono: Yup.number()
    .typeError("El teléfono debe ser un número")
    .required("Este campo es obligatorio"),
  idCaracter: Yup.number().required("Este campo es obligatorio"),
  idDependencia: Yup.number().required("Este campo es obligatorio"),
  codigoPostal: Yup.number().required("Este campo es obligatorio"),
  correoElectronico: Yup.string()
    .test("Validate Email", "El formato de este campo es inválido", (value) => {
      // Si el valor está vacío, retornar true para indicar que no debe validar
      if (!value) {
        return true;
      }

      // Expresión regular para validar el formato del correo electrónico
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      // Testeamos el valor con la expresión regular
      return re.test(String(value).toLowerCase());
    })
    .required("Este campo es obligatorio"),
});

export const vresoluciones = Yup.object().shape({
  orgEmisor: Yup.string().required("Este campo es obligatorio").nullable(),

  nroResolucion: Yup.string()
    .required("Este campo es obligatorio")
    .matches(
      /^[0-9]{0,4}$/,
      "El código debe tener como máximo 4 dígitos numéricos"
    ),
  anio: Yup.string()
    .required("Este campo es obligatorio")
    .matches(
      /^[0-9]{4}$/,
      "El código debe tener exactamente 4 dígitos numéricos"
    ),
  supAutorizante: Yup.string()
    .required("Este campo es obligatorio")
    .matches(
      /^[0-9a-zA-Z.]{1,10}$/,
      "El código debe tener entre 1 y 10 caracteres alfanuméricos"
    ),
  elaboradorResol: Yup.string()
    .required("Este campo es obligatorio")
    .matches(
      /^[0-9a-zA-Z.]{1,10}$/,
      "El código debe tener entre 1 y 10 caracteres alfanuméricos"
    ),
});
