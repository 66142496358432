import React, { useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Tooltip } from "@mui/material";
import CustomModal from "components/customModal/CustomModal";

export default function GenericEdit({ data, title, Form }) {
  const [open, setOpen] = React.useState(false);
  const tituloOriginal = title; // Evitamos modificar la variable "title".

  const ultTitulo = tituloOriginal
    .split("")
    .reverse()
    .slice(0, 1)
    .join("")
    .toLowerCase();
  const dosUltTitulo = tituloOriginal
    .split("")
    .reverse()
    .slice(0, 2)
    .join("")
    .toLowerCase();
  const seisUltTitulo = tituloOriginal
    .split("")
    .reverse()
    .slice(0, 6)
    .join("")
    .toLowerCase();

  function singularTit(tituloOriginal) {
    if (seisUltTitulo == "senoic") {
      return tituloOriginal.substring(0, tituloOriginal.length - 6) + "ción";
    } else if (dosUltTitulo == "se") {
      return tituloOriginal.substring(0, tituloOriginal.length - 2);
    } else if (ultTitulo == "s") {
      return tituloOriginal.substring(0, tituloOriginal.length - 1);
    } else {
      return tituloOriginal;
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <>
        <Tooltip title="Modificar">
          <IconButton variant="outlined" onClick={handleClickOpen}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <CustomModal
          title={"Editar " + singularTit(tituloOriginal)}
          open={open}
          setOpen={handleClose}
        >
          <DialogContent>
            <Form data={data} handleClose={handleClose} />
          </DialogContent>
        </CustomModal>
      </>
    </>
  );
}
