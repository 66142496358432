import React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid
} from "@mui/material";
import { formatDDMMYY } from "utils/helpers/dateFormat";
export default function Proy_ResponsablesTable({ itemProy, datosResp }) {
  if (datosResp != 0) {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12} md={12} lg={12} xl={12} sm={12}>
          <TableContainer component={Paper} style={{ width: "100%", margin: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  {/*<TableCell style={{ fontWeight: "bold", textAlign: "center" }}>ID</TableCell> */}
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                    Apellido y Nombre
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                    CUIL
                  </TableCell>
                  {/*<TableCell style={{ fontWeight: "bold", textAlign: "center" }}>E-mail</TableCell> */}
                  {/*<TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Teléfono</TableCell> */}
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                    Responsabilidad
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                    Inicio de Servicio
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                    Fin de Servicio
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                    Causa Baja
                  </TableCell>
                  {/*<TableCell style={{ fontWeight: "bold", textAlign: "center", minWidth: "120px" }}>
                 Acciones
              </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {datosResp?.map((itemProy) => (
                  <TableRow
                    key={itemProy.idCapacitador}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {/*                             <TableCell>{itemProy.idCapacitador}</TableCell> */}
                    <TableCell style={{ textAlign: "center" }}>
                      {itemProy.persona.apellidoyNombre}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {itemProy.persona.cuit}
                    </TableCell>
                    {/* <TableCell>{itemProy.persona.email}</TableCell> */}
                    {/* <TableCell>{itemProy.persona.telefono}</TableCell> */}
                    <TableCell style={{ textAlign: "center" }}>
                      {itemProy.responsabilidad.descripcion}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {formatDDMMYY(itemProy.fechaInicioServ)}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {formatDDMMYY(itemProy.fechaFinServ)}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {itemProy?.causasBaja?.descripcion || "SIN BAJA"}
                    </TableCell>
                    {/*<TableCell>
                      <ResponsableEditar itemProy={itemProy} itemResp={itemResp} />
                      <ResponsableEliminar itemProy={itemProy} itemResp={itemResp} />
                     </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <div>
        <Box
          style={{ padding: "15px", marginTop: "10px", textAlign: "center" }}
        >
          <p>Sin Integrantes asignados.</p>
        </Box>
      </div>
    );
  }
}
