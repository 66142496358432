import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as Yup from "yup";
import DatePicker from "react-datepicker";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { ErrorMessage, Formik } from "formik";

import { GetCausasBajaEndPoint, selectCausasBaja } from "store/causasBaja/causasBaja.slice";
import { inputCAP } from "../CapacitadoresStyles";
import "../CapacitadoresCss.css";
import { GetAllPersonaEndpoint, selectPersonas } from "store/persona/persona.slice";


export default function CapacitadorForm({ itemAct, itemCap, onSubmitCap }) {     // Variables recién definidas, revisar su uso!.

  let formatoFInicioServ;
  let formatoFFinServ;
  const dispatch = useDispatch();
  const [causasBajaHook, setCausasBajaHook] = useState([]);
  const [valorCausasBaja, setValorCausasBaja] = useState(itemCap?.causasBaja?.descripcion || "SIN BAJA");
  const [responsabilidadesHook, setResponsabilidades] = useState([]);
  const [valorResponsabilidad, setValorResponsabilidad] = useState(itemCap?.responsabilidad?.descripcion || "");
  const Personas = useSelector(selectPersonas);
  const [persona, setPersona] = useState(null);
  const [cuitValid, setCuitValid] = useState(false);

  if (itemCap?.fechaInicioServ != null) {
    formatoFInicioServ = itemCap?.fechaInicioServ.substring(0, 10).split('-');
    formatoFInicioServ = formatoFInicioServ[1] + '/' + formatoFInicioServ[2] + '/' + formatoFInicioServ[0];
  } else {
    formatoFInicioServ = "";
  }

  if (itemCap?.fechaFinServ != null) {
    formatoFFinServ = itemCap?.fechaFinServ.substring(0, 10).split('-');
    formatoFFinServ = formatoFFinServ[1] + '/' + formatoFFinServ[2] + '/' + formatoFFinServ[0];
  } else {
    formatoFFinServ = "";
  }

  const CausasBajaEndPoint = useSelector(selectCausasBaja);

  useEffect(() => {
    let lista1 = CausasBajaEndPoint?.data?.map((x) => ({
      label: x.descripcion,
      id: x.idCausaBaja,
    }));
    setCausasBajaHook(lista1);
    let lista2 = [
      //{ label: "Director/a General", id: 1 },
      //{ label: "Supervidor/a", id: 2 },
      { label: "Capacitador/ Disertante", id: 3 },
      //{ label: "Otra", id: 4 },
    ]
    setResponsabilidades(lista2);
  }, [CausasBajaEndPoint]);

  useEffect(() => {
    dispatch(GetCausasBajaEndPoint());
    dispatch(GetAllPersonaEndpoint());
  }, []);
  useEffect(() =>{
    if(itemCap != null && Personas) handleCheckCuit(itemCap.persona.cuit)
  }, [itemCap, Personas])
  //Logica para enviar formulario al backend
  const handleSubmit = (value) => {
    value.idCapacitador = itemCap?.idCapacitador || 0;
    value.idPersona = persona.idPersona;                                                 // FALTA ESTO! !!!!.
    value.idActividad = itemAct?.idActividad || "";
    value.fechaInicioServ = value?.fechaInicioServ || "";
    value.fechaFinServ = value?.fechaFinServ || "";
    value.idCausaBaja = parseFloat(value.idCausaBaja);
    value.idResponsabilidad = 3;   // Responsabilidad de "Capacitador/ Disertante"

    onSubmitCap(value);                                 // Dato a enviar.
  };
  //Campos del formulario
  const initialValue = {
    idCapacitador: itemCap?.idCapacitador || "",
    idActividad: itemCap?.idActividad || "",
    idPersona: itemCap?.idPersona || "",
    fechaInicioServ: formatoFInicioServ ? new Date(formatoFInicioServ) : "",
    fechaFinServ: formatoFFinServ ? new Date(formatoFFinServ) : "",
    idCausaBaja: itemCap?.idCausaBaja || "",
    idResponsabilidad: 3,
    cuit: itemCap?.persona.cuit?? ""
  };

  const handleCheckCuit = (value) => {
    if (Personas.find((x) => x.cuit == value) != undefined) {
      setCuitValid(true);
      setPersona(Personas.find((x) => x.cuit == value));
      return true;
    } else {
      setPersona(null);
      setCuitValid(false);
      return false;
    }
  };

  //Validaciones del formulario (Requiere importar YUP)
  const validationSchema = Yup.object().shape({
    fechaInicioServ: Yup.date().required("Este campo es requerido"),
    fechaFinServ: Yup.date()
      .required("Este campo es requerido")
      .min(
        Yup.ref("fechaInicioServ"),
        "La fecha de fin debe ser mayor o igual a la fecha de inicio"
      ),
      cuit: Yup.number().test(
        "CuitValidation",
        'Cuit no registrado en base, consulta a "Secretaría de Planeamiento Educativo".',
        (value) => {
          return handleCheckCuit(value);
        }
      ),
      idResponsabilidad: Yup.number().required()
  });

  return (
    <>
      <Formik
        initialValues={initialValue}
        /* Aqui se obtienen los datos que se ingresó en el formulario */
        onSubmit={handleSubmit}
        /* Configuracion de Formik para agregar las validaciones */
        validationSchema={validationSchema}
      >
        {(formikProps) => (
          <Box style={{ borderRadius: "5px" }}>
            <div
              style={{
                marginBottom: "10px",
              }}
            >
              <Box>
                <TextField
                  disabled                                    // Se puede ver pero no editar la "Descripción de Actividad".                      
                  InputProps={{
                    value: itemAct?.descripcion
                      ? itemAct?.descripcion
                      : "",
                  }}
                  type="text"
                  variant="outlined"
                  //size="small"
                  label="Descripción de Actividad"
                  placeholder="Descripción de Actividad"
                  style={inputCAP}
                ></TextField>
              </Box>

              <Box>
                <TextField
                  type="number"
                  variant="outlined"
                  style={inputCAP}
                  label={`CUIL`}
                  placeholder="CUIL"
                  //size="small"
                  id="cuit"
                  name="cuit"
                  {...formikProps.getFieldProps("cuit")}
                ></TextField>
              </Box>
              <ErrorMessage name="cuit">
                {(mensaje) => <div style={{ color: "red" }}>{mensaje}</div>}
              </ErrorMessage>

              <Box>
                <TextField
                  type="text"
                  style={inputCAP}
                  variant="outlined"
                  label={`Nombre y Apellido`}
                  InputProps={{ readOnly: true }}
                  disabled={true}
                  value={
                    cuitValid ? `${persona.nombres} ${persona.apellidos}` : ""
                  }
                  id="personaInfo"
                  name="personaInfo"
                ></TextField>
              </Box>

              <Box>
                <Autocomplete
                  //size="small"
                  disablePortal
                  disabled
                  options={responsabilidadesHook || []}
                  //value={valorResponsabilidad ?? ""}
                  defaultValue={"Capacitador/ Disertante"}
                  onChange={(event, newValue) => {
                    formikProps.setFieldValue(
                      "idResponsabilidad",
                      newValue !== null ? newValue.id : ""
                    );
                    setValorResponsabilidad(newValue?.label)
                  }}
                  id="combo-box-demo"
                  name="idResponsabilidad"
                  style={inputCAP}
                  renderInput={(params) => (
                    <TextField {...params} label="Tipo de Responsabilidad" />
                  )}
                />
              </Box>
              <ErrorMessage name="idResponsabilidad">
                {(mensaje) => <div style={{ color: "red" }}>{mensaje}</div>}
              </ErrorMessage>

              <Box style={{
                marginTop: "30px",
                width: "81%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}>
                <span style={{ margin: "0px 10px", paddingBottom: "20px" }}>Inicio de Servicio:</span>
                <DatePicker
                  style={{ textAlign: "center", display: "flex", justifyContent: "space-between", borderRadius: "9px" }}
                  sx={{ borderRadius: "10px !important" }}
                  className="datePicker"
                  selected={formikProps.values.fechaInicioServ}
                  onChange={(date) => formikProps.setFieldValue("fechaInicioServ", date)}
                  dateFormat="dd/MM/yyyy"
                  placeholderText={"dd/mm/yyyy"}
                  showYearDropdown // year show and scrolldown alos
                  scrollableYearDropdown
                //name="fechaInicioServ"
                />
              </Box>
              <ErrorMessage name="fechaInicioServ">
                {(mensaje) => <div style={{ color: "red" }}>{mensaje}</div>}
              </ErrorMessage>

              <Box style={{
                width: "81%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}>
                <span style={{ margin: "0px 16px", paddingBottom: "20px" }}>Fin de Servicio:</span>
                <DatePicker
                  className="datePicker"
                  selected={formikProps.values.fechaFinServ}
                  onChange={(date) => formikProps.setFieldValue("fechaFinServ", date)}
                  dateFormat="dd/MM/yyyy"
                  placeholderText={"dd/mm/yyyy"}
                  showYearDropdown // year show and scrolldown alos
                  scrollableYearDropdown
                //name="fechaFinServ"
                />
              </Box>
              <ErrorMessage name="fechaFinServ">
                {(mensaje) => <div style={{ color: "red" }}>{mensaje}</div>}
              </ErrorMessage>

              <Box>
                <Autocomplete
                  //size="small"
                  disablePortal
                  options={causasBajaHook || []}
                  value={valorCausasBaja ?? "SIN BAJA"}
                  onChange={(event, newValue) => {
                    formikProps.setFieldValue(
                      "idCausaBaja",
                      newValue !== null ? newValue.id : ""
                    );
                    setValorCausasBaja(newValue?.label)
                  }}
                  id="combo-box-demo"
                  name="idCausaBaja"
                  style={inputCAP}
                  renderInput={(params) => (
                    <TextField {...params} label="Causa de Baja" />
                  )}
                />
              </Box>
              <ErrorMessage name="idCausaBaja">
                {(mensaje) => <div style={{ color: "red" }}>{mensaje}</div>}
              </ErrorMessage>
            </div>

            <Button
              onClick={() => formikProps.submitForm()}
              style={{
                marginTop: "80px",
                float: "right",
                backgroundColor: "#004D40",
                color: "white",
                textTransform: "uppercase",
              }}
            >
              Guardar
            </Button>
          </Box>
        )}
      </Formik>
    </>
  );
}

