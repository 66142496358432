import {
  aprovateCert,
  confirmCert,
} from "store/certificados/certificado.slice";
import { showSnackbar } from "store/mensajeria/mensajeriaSlice";

export const handleCertificar = (
  alumno,
  certificate,
  dispatch,
  findCertificado
) => {
  if (certificate.selectValue === 0) {
    return console.log("debe Seleccionar un Certificado");
  }

  dispatch(
    aprovateCert({
      idAlumno: alumno.idAlumno,
      idActividad: alumno.actividad.idActividad,
      estadoCertificacion: !findCertificado.estadoCertificacion,
      confirmado: false,
    })
  ).then((resultAction) => {
    if (aprovateCert.fulfilled.match(resultAction))
      dispatch(showSnackbar(resultAction.payload.message));
  });
};

export const confirmateCertificate = (actividad, dispatch) => {
  dispatch(confirmCert(actividad)).then((resultAction) => {
    if (confirmCert.fulfilled.match(resultAction))
      dispatch(showSnackbar(resultAction.payload.message));
  });
};
