import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetResponsablesEndPoint,
  selectResponsables,
} from "store/capacitadores/capacitadores.slice";
import Proy_ResponsablesTable from "../Proy_ResponsablesTable/Proy_ResponsablesTable";

const Proy_ResponsablesPage = ({ itemProy}) => {
  const dispatch = useDispatch();
  const GetCapacitadores = useSelector(selectResponsables);

  const handleClickOpen = () => {
    //setOpen(true);
    dispatch(GetResponsablesEndPoint(itemProy?.idProyecto || 0)); // No lleva paginación.
  };

  return (
    <React.Fragment>
      <Proy_ResponsablesTable
        itemProy={itemProy}
        datosResp={GetCapacitadores?.data || []}
      />
    </React.Fragment>
  );
};

export default Proy_ResponsablesPage;
