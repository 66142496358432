//import Toolbar from '@mui/material/Toolbar';
import Loader from "components/Loader/Loader";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProyectos,
  selectIsCreating,
  selectIsDeleting,
  selectIsEditing,
  selectIsLoading,
} from "store/proyectos/proyectos.slice";
import ProyectosTable from "../ProyectosTable/ProyectosTable";

export default function ProyectosPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProyectos());
  }, [dispatch]);

  const isLoading = useSelector(selectIsLoading);
  const isCreating = useSelector(selectIsCreating);
  const isEditing = useSelector(selectIsEditing);
  const isDeleting = useSelector(selectIsDeleting);

  const showLoader = isLoading || isCreating || isEditing || isDeleting;

  return (
    <React.Fragment>
      {showLoader && <Loader />}
      <div style={{ display: "flex", alignItems: "left" }}>
        <h1 style={{ margin: "10px 0" }}>Estado de Proyectos</h1>
      </div>
      <div>
        <ProyectosTable style={{ margin: "25px 0px" }} />
      </div>
    </React.Fragment>
  );
}
