import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchProyectos,
  createProyecto,
  updateProyecto,
  deleteProyecto,
  fetchProyectosConResol,
  fetchProyectosSinResol,
  fetchProyectosConNiveles,
} from "./proyectos.api";

const initialState = {
  entities: [],
  entitiesConResol: [],
  entitiesConNiveles: [],
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
};

export const getProyectos = createAsyncThunk(
  "proyectos/getProyectos",
  async () => {
    const r = await fetchProyectos();
    return r;
  }
);

export const getProyectosSinresol = createAsyncThunk(
  "proyectos/getProyectos",
  async () => {
    const r = await fetchProyectosSinResol();
    return r;
  }
);

export const getProyectosConResol = createAsyncThunk(
  "proyectos/getProyectosConResol",
  async () => {
    const r = await fetchProyectosConResol();
    return r;
  }
);

export const getProyectosConNiveles = createAsyncThunk(
  "proyectos/fetchProyectosConNiveles",
  async (idProyecto) => {
    const r = await fetchProyectosConNiveles(idProyecto);
    return r;
  }
);

export const newProyecto = createAsyncThunk(
  "proyectos/newProyecto",
  async (data) => {
    const r = await createProyecto(data);
    return r;
  }
);

export const editProyecto = createAsyncThunk(
  "proyectos/editProyecto",
  async ({ id, data }) => {
    const r = await updateProyecto({ id, data });
    return r;
  }
);

export const removeProyecto = createAsyncThunk(
  "proyectos/removeProyecto",
  async (id) => {
    const r = await deleteProyecto(id);
    return r;
  }
);

export const ProyectosSlice = createSlice({
  name: "proyectos",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      //Get Proyectos
      .addCase(getProyectos.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProyectos.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(getProyectos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Get Proyectos con Resolución.
      .addCase(getProyectosConResol.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProyectosConResol.fulfilled, (state, action) => {
        state.loading = false;
        state.entitiesConResol = action.payload;
      })
      .addCase(getProyectosConResol.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Get Proyectos con Niveles.
      .addCase(getProyectosConNiveles.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProyectosConNiveles.fulfilled, (state, action) => {
        state.loading = false;
        state.entitiesConNiveles = action.payload;
      })
      .addCase(getProyectosConNiveles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(newProyecto.pending, (state) => {
        state.creating = true;
        state.error = null;
      })
      .addCase(newProyecto.fulfilled, (state, action) => {
        state.creating = false;
        state.entities = action.payload;
      })
      .addCase(newProyecto.rejected, (state, action) => {
        state.creating = false;
        state.error = action.error.message;
      })

      .addCase(editProyecto.pending, (state) => {
        state.editing = true;
        state.error = null;
      })
      .addCase(editProyecto.fulfilled, (state, action) => {
        state.editing = false;
        state.entities = state.entities.map((proyecto) => {
          if (proyecto.id !== action.payload?.id) {
            return proyecto;
          }
          return {
            ...proyecto,
            ...action.payload,
          };
        });
      })
      .addCase(editProyecto.rejected, (state, action) => {
        state.editing = false;
        state.error = action.error.message;
      })

      .addCase(removeProyecto.pending, (state) => {
        state.deleting = true;
        state.error = null;
      })
      .addCase(removeProyecto.fulfilled, (state, action) => {
        state.deleting = false;
        state.entities = state.entities.filter(
          (proyecto) => proyecto.id !== action.payload?.id
        );
      })
      .addCase(removeProyecto.rejected, (state, action) => {
        state.deleting = false;
        state.error = action.error.message;
      });
  },
});

export const selectProyectos = (state) => state.proyectos.entities;
export const selectProyectosConResol = (state) =>
  state.proyectos.entitiesConResol;
export const selectProyectosConNiveles = (state) =>
  state.proyectos.entitiesConNiveles;
export const selectIsLoading = (state) => state.proyectos.loading;
export const selectIsCreating = (state) => state.proyectos.creating;
export const selectIsEditing = (state) => state.proyectos.editing;
export const selectIsDeleting = (state) => state.proyectos.deleting;
export const selectError = (state) => state.proyectos.error;

export default ProyectosSlice.reducer;
