import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ConfirmarInscriptos,
  GetAllInscripcion,
  GetInscripcion,
  GetInscripcionById,
  PutUnInscribir,
  SaveInscripcion,
} from "./Inscripcion.api";

const initialState = {
  entities: {},
  allEntities: {},
  inscribir: {},
  loading: true,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
};

export const GetInscripcionEndpoint = createAsyncThunk(
  "inscripcion/GetInscripcion",
  async (page) => {
    const r = await GetInscripcion(page);
    return r;
  }
);

export const PutUnInscribirEndpoint = createAsyncThunk(
  "inscripcion/PutUnInscribir",
  async ({ idPreinscripcion, page }) => {
    const r = await PutUnInscribir(idPreinscripcion, page);
    return r;
  }
);

export const GetInscripcionByIdEndpoint = createAsyncThunk(
  "inscripcion/GetInscripcionById",
  async ({ page, actividad }) => {
    const r = await GetInscripcionById(page, actividad);
    return r;
  }
);

export const GetAllInscripcionEndpoint = createAsyncThunk(
  "inscripcion/GetAllInscripcion",
  async () => {
    const r = await GetAllInscripcion();
    return r;
  }
);

export const SaveInscripcionEndpoint = createAsyncThunk(
  "SaveInscripcion/SaveInscripcion",
  async (data) => {
    const r = await SaveInscripcion(data);
    return r;
  }
);

export const ConfirmarInscriptosEndpoint = createAsyncThunk(
  "inscripcion/ConfirmarInscriptos",
  async (idActividad) => {
    const r = await ConfirmarInscriptos(idActividad);
    return r;
  }
);

export const InscripcionSlice = createSlice({
  name: "Inscripcion",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // GetPreInscripcionEndpoint

      .addCase(GetInscripcionEndpoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetInscripcionEndpoint.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(GetInscripcionEndpoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // PutUnInscribirEndpoint

      .addCase(PutUnInscribirEndpoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(PutUnInscribirEndpoint.fulfilled, (state, action) => {
        state.loading = false;
        state.inscribir = action.payload;
      })
      .addCase(PutUnInscribirEndpoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // GetInscripcionByIdEndpoint

      .addCase(GetInscripcionByIdEndpoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetInscripcionByIdEndpoint.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(GetInscripcionByIdEndpoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // GetAllInscripcionEndpoint

      .addCase(GetAllInscripcionEndpoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetAllInscripcionEndpoint.fulfilled, (state, action) => {
        state.loading = false;
        state.allEntities = action.payload;
      })
      .addCase(GetAllInscripcionEndpoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // SaveInscripcionEndpoint

      .addCase(SaveInscripcionEndpoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(SaveInscripcionEndpoint.fulfilled, (state, action) => {
        state.loading = false;
        state.inscribir = action.payload;
      })
      .addCase(SaveInscripcionEndpoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // ConfirmarInscriptosEndpoint

      .addCase(ConfirmarInscriptosEndpoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(ConfirmarInscriptosEndpoint.fulfilled, (state, action) => {
        state.loading = false;
        state.inscribir = action.payload;
      })
      .addCase(ConfirmarInscriptosEndpoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const selectInscripcion = (state) => state.Inscripcion.entities;
export const selectAllInscripcion = (state) => state.Inscripcion.allEntities;
export const selectInscribir = (state) => state.Inscripcion.inscribir;

export default InscripcionSlice.reducer;
