import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchModalidadesProyecto } from './modalidadesProyecto.api';

const initialState = {
  entities: [],
  loading: false,
  error: null,
};

export const getModalidadesProyecto = createAsyncThunk(
  'modalidadesProyecto/getModalidadesProyecto',
  async () => await fetchModalidadesProyecto()
);

export const ModalidadesProyectoSlice = createSlice({
  name: 'modalidadesProyecto',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    
    //getModalidadesProyecto
      .addCase(getModalidadesProyecto.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getModalidadesProyecto.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(getModalidadesProyecto.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const selectModalidadesProyecto = (state) =>
  state.modalidadesProyecto.entities;
export const selectModalidadesProyectoIsLoading = (state) =>
  state.modalidadesProyecto.loading;
export const selectModalidadesProyectoError = (state) =>
  state.modalidadesProyecto.error;

export default ModalidadesProyectoSlice.reducer;
