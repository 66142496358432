import {
  Autocomplete,
  Box,
  Button,
  DialogTitle,
  Modal,
  TextField,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "dayjs/locale/es";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import React from "react";

//import ActividadesTable from "./ActividadesTable";
import {
  GetAllPersonaEndpoint,
  selectPersonas,
} from "store/persona/persona.slice";
import {
  SavePreInscripcionEndpoint,
  UpdatePreInscripcionEndpoint,
} from "store/preInscripcion/preInscripcion.slice";
import { selectActividadesAprobadasAll } from "store/actividades/actividades.slice";
import { showSnackbar } from "store/mensajeria/mensajeriaSlice";

export default function PreInscripcionForm({
  onClose,
  preInscripciones,
  open,
  edit,
  idActividad,
  periodo
}) {

  dayjs.extend(customParseFormat);
  const dispatch = useDispatch();
  const Personas = useSelector(selectPersonas);
  const [selectPre, setSelectPre] = useState(null);
  const [persona, setPersona] = useState(null);
  const [abrir, setAbrir] = useState(false);
  const [cuitValid, setCuitValid] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const denominaciones = useSelector(selectActividadesAprobadasAll);
  const [idPre, setIdPre] = useState(); // Variable que almacena idPreInscripcion line 79;
  
  const formValues = {
    today: new Date(new Date().setHours(0, 0, 0, 0)),
    fechaIniPer: new Date(new Date(dayjs(periodo?.fechaInicio)).setHours(0, 0, 0, 0)) || null, 
    fechaFinPer: new Date(new Date(dayjs(periodo?.fechaFin)).setHours(0, 0, 0, 0)) || null,
    cuit: "",
    actividad: idActividad,
  };
  const [initialValue, setInitialValue] = useState({ ...formValues });

  useEffect(() => {
    dispatch(GetAllPersonaEndpoint());
  }, []);

  useEffect(() => {
    setInitialValue({
      today: new Date(new Date().setHours(0, 0, 0, 0)),
      fechaIniPer: new Date(new Date(dayjs(periodo?.fechaInicio)).setHours(0, 0, 0, 0)) || null,
      fechaFinPer: new Date(new Date(dayjs(periodo?.fechaFin)).setHours(0, 0, 0, 0)) || null,
      cuit: "",
      actividad: idActividad,
    });
  }, [periodo]);

  useEffect(() => {
    edit !== null && handleSetEditValue();
    edit !== null && setIsEditing(true);
  }, [edit]);

  useEffect(() => {
    idActividad != null && handleSetEditValue();
  }, [idActividad]);

  useEffect(() => {
    open && handleCloseOrOpen();
  }, [open]);

  useEffect(() => {
    if (denominaciones != null) {
      let mapeo = denominaciones.data?.map((x) => {
        {
          return { id: x.idActividad, label: x.descripcion };
        }
      });

      setSelectPre(mapeo);
    }
  }, [denominaciones]);

  const handleSetEditValue = () => {
    if (edit !== null) {
      const persona = preInscripciones.find((x) => x.idPreinscripcion == edit);

      setInitialValue({
        today: new Date(new Date().setHours(0, 0, 0, 0)),
        fechaIniPer: new Date(new Date(dayjs(periodo?.fechaInicio)).setHours(0, 0, 0, 0)) || null,
        fechaFinPer: new Date(new Date(dayjs(periodo?.fechaFin)).setHours(0, 0, 0, 0)) || null,
        actividad: persona.actividad.idActividad,
        cuit: persona.persona.cuit,
      });

      setIdPre(edit);
      handleCheckCuit(persona.persona.cuit);
    } else {
      setInitialValue({
        today: new Date(new Date().setHours(0, 0, 0, 0)),
        fechaIniPer: new Date(new Date(dayjs(periodo?.fechaInicio)).setHours(0, 0, 0, 0)) || null,
        fechaFinPer: new Date(new Date(dayjs(periodo?.fechaFin)).setHours(0, 0, 0, 0)) || null,
        cuit: "",
        actividad: idActividad,
      });
    }
  };

  const handleSubmit = (form) => {
    let request = {
      cuit: form.cuit.toString(),
      idActividad: Number(form.actividad),
    };

    if (isEditing) {
      request = { ...request, idPreInscripcion: idPre };
      dispatch(UpdatePreInscripcionEndpoint(request))
        .then((resultAction) => {
          if (UpdatePreInscripcionEndpoint.fulfilled.match(resultAction))
            dispatch(showSnackbar(resultAction.payload.message));
        });
      handleCloseOrOpen();
    } else {
      dispatch(SavePreInscripcionEndpoint(request))
        .then((resultAction) => {
          if (SavePreInscripcionEndpoint.fulfilled.match(resultAction))
            dispatch(showSnackbar(resultAction.payload.message));
        });
      handleCloseOrOpen();
    }
  };

  const handleCheckCuit = (value) => {
    if (Personas.find((x) => x.cuit == value) != undefined) {
      setCuitValid(true);
      setPersona(Personas.find((x) => x.cuit == value));
      return true;
    } else {
      setCuitValid(false);
      setPersona(null);
      return false;
    }
  };

  //Validaciones del formulario (Requiere importar YUP)
  const validationSchema = Yup.object().shape({
    today: Yup.date()
      .typeError("Ingrese una fecha válida")
      .min(Yup.ref("fechaIniPer"), "Te encuentras fuera de término para esta Pre-Inscripción.")
      .max(Yup.ref("fechaFinPer"), "Te encuentras fuera de término para esta Pre-Inscripción."),
    actividad: Yup.string().required("Este campo es requerido"),
    cuit: Yup.number().test(
      "CuitValidation",
      'Cuit no registrado en base, consulta a "Secretaría de Planeamiento Educativo".',
      (value) => {
        return handleCheckCuit(value);
      }
    ),
  });
  //Logica para cerrar o abrir el modal
  const handleCloseOrOpen = () => {
    setAbrir(!abrir);
    abrir && setCuitValid(false);
    onClose(false);
    abrir && setInitialValue(formValues);
    abrir && setIsEditing(false);
  };

  const stylePopapACT = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    color: "black",
    p: 4,
  };
  const inputACT = {
    width: "100%",
    marginTop: "10px",
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <Modal
        open={abrir}
        onClose={handleCloseOrOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Formik
            initialValues={initialValue}
            /* Aqui se obtienen los datos que se ingresó en el formulario */
            onSubmit={handleSubmit}
            /* Configuracion de Formik para agregar las validaciones */
            validationSchema={validationSchema}
          >
            {(formikProps) => (
              <Box sx={stylePopapACT} style={{ borderRadius: "5px" }}>
                <DialogTitle
                  sx={{ color: "rgb(56,56,56)", padding: "0px 0px 10px 0px" }}
                >
                  Agregar PreInscripción
                </DialogTitle>
                <div
                  style={{
                    width: "500px",
                  }}
                >
                  <Box>
                    <TextField
                      type="number"
                      variant="outlined"
                      label={`CUIL ${isEditing ? "(No editable)" : ""}`}
                      placeholder="CUIL"
                      style={inputACT}
                      //size="small"
                      id="cuit"
                      name="cuit"
                      disabled={isEditing ? true : false}
                      {...formikProps.getFieldProps("cuit")}
                    ></TextField>
                  </Box>
                  <ErrorMessage name="cuit">
                    {(mensaje) => <div style={{ color: "red" }}>{mensaje}</div>}
                  </ErrorMessage>

                  <Box style={{ display: "flex" }}>
                    <TextField
                      type="text"
                      style={inputACT}
                      variant="outlined"
                      label={`Nombre y Apellido ${isEditing ? "(No editable)" : ""
                        }`}
                      InputProps={{ readOnly: true }}
                      disabled={isEditing ? true : false}
                      value={
                        cuitValid
                          ? `${persona.nombres} ${persona.apellidos}`
                          : ""
                      }
                      id="personaInfo"
                      name="personaInfo"
                    ></TextField>
                  </Box>

                  <Box>
                    <Autocomplete
                      //size="small"
                      disablePortal
                      onChange={(event, newValue) => {
                        formikProps.setFieldValue(
                          "actividad",
                          newValue !== null ? newValue.id : ""
                        );
                      }}
                      id="combo-box-demo"
                      options={
                        selectPre != null
                          ? !isEditing
                            ? selectPre.filter((x) => x.id == idActividad)
                            : selectPre
                          : []
                      }
                      name="actividad"
                      value={selectPre.find((x) => x.id == idActividad)}
                      style={inputACT}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={selectPre.find((x) => x.id == idActividad)}
                          label={
                            isEditing
                              ? selectPre.find(
                                (x) => x.id == initialValue.actividad
                              )?.label ?? "Actividad"
                              : "Actividad"
                          }
                        />
                      )}
                    />
                  </Box>
                  <ErrorMessage name="actividad">
                    {(mensaje) => <div style={{ color: "red" }}>{mensaje}</div>}
                  </ErrorMessage>
                  <ErrorMessage name="today">
                    {(mensaje) => <div style={{ color: "red" }}>{mensaje}</div>}
                  </ErrorMessage>
                </div>

                <Button
                  onClick={() => formikProps.submitForm()}
                  style={{
                    marginTop: "40px",
                    float: "right",
                    backgroundColor: "#004D40",
                    color: "white",
                    textTransform: "uppercase",
                  }}
                >
                  {isEditing ? "Editar" : "Guardar"}
                </Button>
              </Box>
            )}
          </Formik>
        </>
      </Modal>
    </LocalizationProvider>
  );
}
