import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ConfirmarInscriptosEndpoint,
  selectAllInscripcion,
} from "store/Inscripcion/Inscripcion.slice";
import { Button, Checkbox, CircularProgress, Container } from "@mui/material";
import { showSnackbar } from "store/mensajeria/mensajeriaSlice";
import { GetActividadByIdEndpoint, selectActividades } from "store/actividades/actividades.slice";
import { useIdRol } from "utils/roles/IdRolContext";
import { GetPeriodoXIdPeriodoYIdActividadEndpoint } from "store/periodos/periodo.slice";

export default function InscripcionInfo({ preInscripciones, search }) {
  const [info, setInfo] = useState(null);
  const [checked, setChecked] = useState(false);
  const allPreInscripcion = useSelector(selectAllInscripcion);
  const Dispatch = useDispatch();
  const [idPreinscripcion, setIdPreInscripcion] = useState(null);
  const [loading, setLoading] = useState(true);
  const actividadById = useSelector(selectActividades);
  const { rolName } = useIdRol();

  /*   const handleInscribirRemoveAll = async (idPreinscripcion) => {
    Dispatch(PutPreInscribirRemoveAllEndpoint({ idPreinscripcion, page: 1 }));
    setLoading(true);
  };
  */
  const handleConfirmarInscripcion = async (idActividad) => {
    Dispatch(ConfirmarInscriptosEndpoint(idActividad))
      .then((resultAction) => {
        if (ConfirmarInscriptosEndpoint.fulfilled.match(resultAction))
          Dispatch(showSnackbar(resultAction.payload.message))
      });
    setLoading(true);
  };

  useEffect(() => {
    if (search)
      Dispatch(GetActividadByIdEndpoint(search.id))
  }, [search])

  useEffect(() => {
    if (allPreInscripcion && search && actividadById.data) {
      Dispatch(GetPeriodoXIdPeriodoYIdActividadEndpoint({ idTipoPeriodo: 1, idActividad: search.id }))
      let obj = allPreInscripcion?.data?.find(
        (x) => x.actividad.idActividad == search.id
      );
      setIdPreInscripcion(obj?.idPreinscripcion);
      let actividad = allPreInscripcion?.data?.filter(
        (x) => x.actividad.idActividad === obj?.actividad?.idActividad
      );

      let cupoMaximo = actividadById.data.cupo == 0 ? -1 : actividadById.data.cupo ?? 0;
      let inscriptos = actividad.length;

      setInfo({ cupoMaximo, inscriptos });
      setChecked(inscriptos >= cupoMaximo);
    }
    setLoading(false);
  }, [allPreInscripcion, search, actividadById]);

  const style1 = {
    whiteSpace: "noWrap",
    margin: "15px 20px 7px 20px",
    fontWeight: "600",
    fontSize: "18px"
  };

  const buttonStyle = {
    display: "inline-flex",
    padding: "6px 10px",
    justifyContent: "space-between",
    textAlign: "center",
    backgroundColor: "#004d40",
    borderRadius: "5px",
    cursor: "pointer",
    color: "white",
    marginBottom: "20px",
  };

  return (
    <div>
      {search && (
        <div>
          <div style={style1}>
            Cupo Máximo: {info?.cupoMaximo == -1 ? "Sin límites" : info?.cupoMaximo}
          </div>
          <div style={style1}>
            Cantidad Inscriptos: {info?.inscriptos}
          </div>
          {rolName !== "planeamiento" && rolName !== "docente" && rolName !== "capacitadores" &&
            (<span
              onClick={() => handleConfirmarInscripcion(search.id)}
              style={buttonStyle}
            >
              {loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                "CONFIRMAR INSCRIPTOS"
              )}
            </span>)}
        </div>
      )}
    </div>
  );
}
