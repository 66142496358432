import React, { useContext } from "react";
import { useDispatch } from "react-redux";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import NivelesEnsenanzaForm from "../NivelesEnsenanzaForm/NivelesEnsenanzaForm";
import {
  GetResponsablesEndPoint,
  SaveCapacitadorEndpoint,
} from "store/capacitadores/capacitadores.slice";
import {
  getProyectos,
  newNivelesProy,
} from "store/inicioProyectos/IProyectos.slice";
import { myPageProyectos } from "views/ProyectosInicio/ProyectosIPage/ProyectosIPage";
import { getProyectosConNiveles } from "store/proyectos/proyectos.slice";
import CustomModal from "components/customModal/CustomModal";
import { Stack } from "@mui/material";

export default function NivelesEnsenanzaNuevo({
  itemProy,
  handleConteinerClose,
}) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const page = useContext(myPageProyectos);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (itemProy) => {
    dispatch(newNivelesProy(itemProy));
    setOpen(false);

    setTimeout(() => {
      //Actualizamos Tabla de Actividades
      dispatch(getProyectos(page ? page : 1)); //Sabemos en tiempo real la cantidad de Capacitadores
    }, 400);

    setTimeout(() => {
      dispatch(getProyectosConNiveles(itemProy.idProyecto));
    }, 400);
  };

  return (
    <React.Fragment>
      <Stack
        spacing={2}
        direction="row"
        sx={{ display: "flex", justifyContent: "flex-end", mt: 3, mb: 2 }}
      >
        <Button
          variant="outlined"
          color="error"
          onClick={handleConteinerClose}
          spacing={2}
        >
          Cancelar
        </Button>
        <Button variant="contained" onClick={handleClickOpen}>
          Nuevo
        </Button>
      </Stack>
      <CustomModal
        title={"Agregar Niveles de Enseñanza"}
        open={open}
        setOpen={handleClose}
        maxWidth="sm"
      >
        <DialogContent>
          <NivelesEnsenanzaForm
            itemProy={itemProy}
            onSubmitResp={handleSubmit}
            handleClose={handleClose}
          />
        </DialogContent>
      </CustomModal>
    </React.Fragment>
  );
}
