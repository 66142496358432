export const formatDDMMYY_String = (date) => {
  date = new Date(date.toString())
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear());
  

  function mes(month) {
    switch (month) {
      case "01":
        return "enero"
      case "02":
        return "febrero"
      case "03":
        return "marzo"
      case "04":
        return "abril"
      case "05":
        return "mayo"
      case "06":
        return "junio"
      case "07":
        return "julio"
      case "08":
        return "agosto"
      case "09":
        return "septiembre"
      case "10":
        return "octubre"
      case "11":
        return "noviembre"
      case "12":
        return "diciembre"
      default:
        return "no encontrado"
    }
  }

  const formattedDate = `a los ${day} días del mes de ${mes(month)} de ${year}`;

  return formattedDate;
};