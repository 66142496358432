import { createTheme } from "@mui/material";

export const mdTheme = createTheme(
    {
        palette: {
          primary: {
            main: '#004d40',
          },
          secondary: {
            main: '#311b92',
          },
        },
      },
);


