import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import { configureAppStore } from 'store/configureStore';
import App from './Routes';
import { mdTheme } from './theme';
import { IdRolProvider } from 'utils/roles/IdRolContext';
import { TokenProvider } from 'context/TokenContext';

const store = configureAppStore();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <IdRolProvider>
      <ThemeProvider theme={mdTheme}>
        <CssBaseline />
        <React.StrictMode>
          <TokenProvider>
            <App />
          </TokenProvider>
        </React.StrictMode>
      </ThemeProvider>
    </IdRolProvider>
  </Provider>
);
