import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ActividadEliminar from "../ActividadesEliminar/ActividadesEliminar";
import ActividadEditar from "../ActividadesEditar/ActividadesEditar";
import CapacitadoresPage from "views/Capacitadores/CapacitadoresPage/CapacitadoresPage";
import ActividadesInfoAdicional from "../ActividadesInfoAdicional/ActividadesInfoAdicional";
import { useIdRol } from "utils/roles/IdRolContext";
import {
  tableBodyCell,
  tableBodyCenterCell,
  tableHeadCell,
} from "components/customTable/StylesTable";
import CustomTableContainer from "components/customTable/CustomTableContainer";

const renderCantCap = (itemAct) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <CapacitadoresPage
      itemAct={itemAct}
      colorCap={
        itemAct.cantCapacitadores === 0
          ? "rgb(158, 56, 255)"
          : "rgb(117,117,117)"
      }
    />
    <span
      style={{
        fontWeight: "bold",
        color:
          itemAct.cantCapacitadores === 0
            ? "rgb(158, 56, 255)"
            : "rgb(117,117,117)",
      }}
    >
      ({itemAct.cantCapacitadores ?? "Sin Cantidad"})
    </span>
  </div>
);

const renderTableCell = (
  value,
  minWidth = "auto",
  textAlign = "left",
  style = tableBodyCell
) => <TableCell sx={{ ...style }}>{value}</TableCell>;

const renderCupoCell = (cupo) => (
  <TableCell sx={{ ...tableBodyCell }}>
    {cupo > 0 ? (
      cupo
    ) : (
      <>
        <span>Sin cupo/Sin registro</span>
      </>
    )}
  </TableCell>
);

const ActividadesTable = ({ datosAct }) => {
  const { rolName } = useIdRol();

  return (
    <CustomTableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {[
              "ID",
              "Descripción",
              "Modalidad Actividad",
              "Estado Actividad",
              "Fecha Inicio",
              "Fecha Fin",
              "Cupo",
              "Localidad de Dictado",
              "Dirección de Dictado",
              "Capacitador",
              "Infor. Adicional",
              rolName !== "institucionOferente" &&
                rolName !== "capacitadores" &&
                rolName !== "docente" &&
                "Acciones",
            ].map((header, index) => (
              <TableCell key={index} sx={{ ...tableHeadCell }}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {(datosAct && datosAct?.records ? datosAct.records : datosAct)?.map(
            (itemAct) => (
              <TableRow key={itemAct.idActividad}>
                {renderTableCell(itemAct.idActividad)}
                {renderTableCell(itemAct.descripcion, "200px")}
                {renderTableCell(
                  itemAct.modalidadProyecto.denominacion,
                  undefined,
                  "center"
                )}
                {renderTableCell(
                  itemAct.estadoActividad.descripcion,
                  undefined,
                  "center"
                )}
                {renderTableCell(itemAct.fechaInicio, undefined, "center")}
                {renderTableCell(itemAct.fechaFin, undefined, "center")}
                {renderCupoCell(itemAct.cupo)}
                {renderTableCell(itemAct.localidad, "150px", "center")}
                {renderTableCell(itemAct.domicilio, undefined, "center")}
                {renderTableCell(renderCantCap(itemAct), undefined, "center")}
                {renderTableCell(
                  <ActividadesInfoAdicional itemAct={itemAct} />,
                  undefined,
                  "center",
                  tableBodyCenterCell
                )}
                {rolName !== "institucionOferente" &&
                  rolName !== "capacitadores" &&
                  rolName !== "docente" &&
                  renderTableCell(
                    <>
                      <ActividadEditar item={itemAct} />
                      <ActividadEliminar itemAct={itemAct} />
                    </>,
                    "120px",
                    "center"
                  )}
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </CustomTableContainer>
  );
};

export default ActividadesTable;
