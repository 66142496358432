import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  ListarTodastitucion,
  ListarProyecto,
  ListarActividad,
  PostCargaExtra
} from "./cargaExterna.api";

const initialState = {
  instituciones: [],
  proyectos: [],
  actividades: [],
  enviarExcel: [],
  loadingInstituciones: false,
  loadingProyectos: false,
  loadingActividades: false,
  loadingEnviarExcel: false,
  datosExcelFull: false,
  error: null,
};

export const fetchInstituciones = createAsyncThunk(
  "cargaExterna/fetchInstituciones",
  async () => {
    const response = await ListarTodastitucion();
    return response.data;
  }
);

export const fetchProyectos = createAsyncThunk(
  "cargaExterna/fetchProyectos",
  async (id) => {
    const response = await ListarProyecto(id);
    return response;
  }
);

export const fetchActividades = createAsyncThunk(
  "cargaExterna/fetchActividades",
  async (id) => {
    const response = await ListarActividad(id);
    return response.data;
  }
);

export const cargaExcel = createAsyncThunk(
  "cargaExterna/cargaExcel",
  async ({idProyecto, idActividad, archivoExcel}) => {
    const response = await PostCargaExtra({idProyecto, idActividad, archivoExcel});
    return response;
  }
);

const cargaExternaSlice = createSlice({
  name: "cargaExterna",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInstituciones.pending, (state) => {
        state.loadingInstituciones = true;
        state.error = null;
      })
      .addCase(fetchInstituciones.fulfilled, (state, action) => {
        state.loadingInstituciones = false;
        state.instituciones = action.payload;
      })
      .addCase(fetchInstituciones.rejected, (state, action) => {
        state.loadingInstituciones = false;
        state.error = action.error.message;
      })
      .addCase(fetchProyectos.pending, (state) => {
        state.loadingProyectos = true;
        state.error = null;
      })
      .addCase(fetchProyectos.fulfilled, (state, action) => {
        state.loadingProyectos = false;
        state.proyectos = action.payload;
      })
      .addCase(fetchProyectos.rejected, (state, action) => {
        state.loadingProyectos = false;
        state.error = action.error.message;
      })
      .addCase(fetchActividades.pending, (state) => {
        state.loadingActividades = true;
        state.error = null;
      })
      .addCase(fetchActividades.fulfilled, (state, action) => {
        state.loadingActividades = false;
        state.actividades = action.payload;
      })
      .addCase(fetchActividades.rejected, (state, action) => {
        state.loadingActividades = false;
        state.error = action.error.message;
      })
      .addCase(cargaExcel.pending, (state) => {
        state.loadingEnviarExcel = true;
        state.error = null;
      })
      .addCase(cargaExcel.fulfilled, (state, action) => {
        state.loadingEnviarExcel = false;
        state.datosExcelFull = true;
        state.enviarExcel = action;
        
        
      })
      .addCase(cargaExcel.rejected, (state, action) => {
        state.loadingEnviarExcel = false;
        state.error = action.error.message;
        toast.error("Sucedio un error al procesar el archivo, por favor revise los datos");
      });
  },
});

export const selectProyectos = (state) => state.cargaExterna.proyectos;
export const selectActividades = (state) => state.cargaExterna.actividades;
export const envioCargaExtra = (state) => state.cargaExterna.datosExcelFull;

export default cargaExternaSlice.reducer;
