import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import NivelesEnsenanzaEliminar from "../NivelesEnsenanzaEliminar/NivelesEnsenanzaEliminar";
import {
  tableBodyCell,
  tableBodyCenterCell,
  tableHeadCell,
} from "components/customTable/StylesTable";

export default function NivelesEnsenanzaTable({ itemProy, datosNiveles }) {
  return (
    <TableContainer sx={{ maxHeight: 350 }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead
          sx={{
            borderTop: "1px solid", // Agregar borde superior
          }}
        >
          <TableRow>
            <TableCell sx={{ ...tableHeadCell }}>
              Niveles de Enzeñansa
            </TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {datosNiveles?.map((itemResp) => (
            <TableRow key={itemResp.idNivelEnsenanza}>
              <TableCell sx={{ ...tableBodyCell }}>
                {itemResp.nivelEnsenanza}
              </TableCell>
              <TableCell sx={{ ...tableBodyCenterCell }}>
                <NivelesEnsenanzaEliminar
                  itemProy={itemProy}
                  itemResp={itemResp}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
