import { Button, Container, Pagination, Paper, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import PreInscripcionTable from "./PreInscripcionTable";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAllPreInscripcionEndPoint,
  GetPreInscripcionByIdEndpoint,
  selectAllPreInscripcion,
  selectPreInscribir,
  selectPreInscripcion,
} from "store/preInscripcion/preInscripcion.slice";
import PreInscripcionSearch from "./PreInscripcionSearch";
import PreInscripcionInfo from "./PreInscripcionInfo";
import PreInscripcionForm from "./PreInscripcionForm";
import { ActividadesAprobadasAll } from "store/actividades/actividades.slice";
import { selectPeriodos } from "store/periodos/periodo.slice";
import { useIdRol } from "utils/roles/IdRolContext";

const PreInscripcion = () => {
  const Dispatch = useDispatch();
  const preInscribir = useSelector(selectPreInscribir);
  const preInscripcion = useSelector(selectPreInscripcion);
  const allPreInscripcion = useSelector(selectAllPreInscripcion);
  const [search, setSearch] = useState(null);
  const [preInscripcionState, setPreInscripcionState] = useState(null);
  const [page, setPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [edit, setEdit] = useState(null);
  const periodo = useSelector(selectPeriodos);
  const { rolName } = useIdRol();

  useEffect(() => {
    Dispatch(ActividadesAprobadasAll());
  }, [Dispatch]);

  const handleCloseModal = () => {
    setOpenModal(false);
    setEdit(null);
  };

  const handleOpenModalEdit = (idPreInscripcion) => {
    setEdit(idPreInscripcion);
    setOpenModal(true);
  };

  useEffect(() => {
    setPreInscripcionState(preInscripcion.data?.records);
  }, [preInscripcion]);

  useEffect(() => {
    Dispatch(GetAllPreInscripcionEndPoint());
  }, [preInscribir]);

  const handleFilter = (actividad) => {
    setSearch(actividad);
    setPage(1);
    if (!(actividad?.id === null || actividad?.id === undefined)) {
      Dispatch(
        GetPreInscripcionByIdEndpoint({ page: page, actividad: actividad?.id })
      );
    }
  };

  useEffect(() => {
    if (search !== null)
      Dispatch(
        GetPreInscripcionByIdEndpoint({ page: page, actividad: search.id })
      );
  }, [page]);

  const handleChange = (event, values) => {
    setPage(values);
  };

  const handleChangeTable = (value) => {
    if (search !== null)
      Dispatch(
        GetPreInscripcionByIdEndpoint({ page: page, actividad: search.id })
      );
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex", alignItems: "left" }}>
        <h1 style={{ margin: "10px 0" }}>Pre-Inscripción</h1>
      </div>
      <Paper
        style={{
          paddingLeft: 10,
          paddingRight: 10,
          paddingBottom: 2,
          paddingTop: 20,
          backgroundColor: "#FFFFFF",
          borderRadius: 0,
        }}
      >
        <PreInscripcionSearch
          searchResponse={(search) => handleFilter(search)}
        />
        {search != null && (
          <Button
            disabled={periodo?.fechaFin === undefined}
            variant="contained"
            onClick={() => setOpenModal(true)}
            style={{ marginBottom: "10px" }}
          >
            Nuevo
          </Button>
        )}
      </Paper>

      <PreInscripcionInfo
        preInscripciones={allPreInscripcion}
        periodo={periodo}
        search={search}
        hayInsc={(preInscripcion?.data?.records?.length > 0)}
      />
      {rolName !== "docente" && (
        <>
          {search != null &&
            <PreInscripcionTable
              handleSubmit={(value) => handleChangeTable(value)}
              preInscripcion={preInscripcionState}
              openModalEdit={(idPreInscripcion) =>
                handleOpenModalEdit(idPreInscripcion)
              }
            />
          }
          {search != null && (preInscripcion?.data?.records?.length > 0) &&
            <Stack spacing={2} style={{ margin: "20px 0px 30px 0px" }}>
              <Pagination
                count={preInscripcion?.data?.pages}
                page={page}
                onChange={handleChange}
                color="primary"
              />
            </Stack>
          }
        </>
      )}

      <PreInscripcionForm
        idActividad={search?.id}
        preInscripciones={preInscripcionState}
        open={openModal}
        onClose={handleCloseModal}
        edit={edit}
        periodo={periodo}
      />
    </React.Fragment>
  );
};

export default PreInscripcion;
