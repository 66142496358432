// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.container-cer {
    display: flex;
    flex-direction: column;
    height: 100vh; 
  }
  
  .component-container-cer {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%; 
    text-align: center;
    padding: 20px;
    background-color: #f0f0f0; 
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); 
  }

  .text-placheolder {
    font-size: x-large;
    margin-top: 3%;
    text-align: justify;
  }

  .alert {
    padding: 10px;
    border-radius: 4px;
    margin-top: 10px;
  }
  
  .alert-success {
    background-color: green;
    color: white;
  }
  
  .alert-error {
    background-color: red;
    color: white;
  }
  
  `, "",{"version":3,"sources":["webpack://./src/views/certificados/certifiicado-styles.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;EACf;;EAEA;IACE,gBAAgB;IAChB,mBAAmB;IACnB,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,2CAA2C;EAC7C;;EAEA;IACE,kBAAkB;IAClB,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,uBAAuB;IACvB,YAAY;EACd;;EAEA;IACE,qBAAqB;IACrB,YAAY;EACd","sourcesContent":["\n.container-cer {\n    display: flex;\n    flex-direction: column;\n    height: 100vh; \n  }\n  \n  .component-container-cer {\n    margin-top: 1rem;\n    margin-bottom: 1rem;\n    width: 100%; \n    text-align: center;\n    padding: 20px;\n    background-color: #f0f0f0; \n    border-radius: 8px;\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); \n  }\n\n  .text-placheolder {\n    font-size: x-large;\n    margin-top: 3%;\n    text-align: justify;\n  }\n\n  .alert {\n    padding: 10px;\n    border-radius: 4px;\n    margin-top: 10px;\n  }\n  \n  .alert-success {\n    background-color: green;\n    color: white;\n  }\n  \n  .alert-error {\n    background-color: red;\n    color: white;\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
