import React, { createContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Loader from "components/Loader/Loader";
import { GenericTemplate } from "template/GenericTemplate";
import ImportAction from "./tableActions/ImportAction";
import { useIdRol } from "utils/roles/IdRolContext";
import "./certifiicado-styles.css";
import { getMisCertificaciones } from "store/certificados/certificado.api";
import { useQuery } from "@tanstack/react-query";

export const myCertificacionPersona = createContext(1);

export default function CertificadoPage() {
  const dispatch = useDispatch();
  let [page, setPage] = React.useState(1);
  const [cahngeCerto, setCahngeCerto] = useState(false);
  const [certificateState, setCertificateState] = useState([]);

  const [actividadData, setActividadData] = useState(null);
  const [convertCertificado, setConvertCertificado] = useState(null);
  const { rolName } = useIdRol();
  const [proyecto, setProyecto] = useState(null);
  const [printing, setPrinting] = useState(false);

  const { data: certificaciones = [], isLoading } = useQuery({
    queryKey: ['certificaciones'], queryFn: () => getMisCertificaciones(),
    //enabled: !!dni, // Ejecuta la query solo si hay un departamento seleccionado
});

  
  const alumnos = certificaciones.data ? certificaciones.data : [];

  useEffect(() => {
    if (alumnos.length !== 0) {
      const convertCertificad = alumnos?.map((alumno) => {
        const findCertificado = {idCertificacion: alumno.idCertificacion}     

        const dataAlumno = {
                  ...alumno,
                  proyecto: {idProyecto: alumno.actividad.idProyecto,
                    fechaFin: alumno.actividad.fechaFin,
                  }
                };
        

        let certificadoConvert = {
          ID: alumno.idAlumno ?? 0,
          NombreAlumno: alumno.alumno.persona.apellidoyNombre,
          CuitAlumno: alumno.alumno.persona.cuit,
          Actividad: alumno.actividad.descripcion,
        };
        
          certificadoConvert.Imprimir = (
            <div style={{ textAlign: "center" }}>            
                  <ImportAction
                    data={dataAlumno}
                    certificate={findCertificado}
                    setPrinting={setPrinting}
                  />
              <div style={{ fontWeight: "bold", color: "rgb(117,117,117)" }}>
                {"CON DIPLOMA"}
              </div>
            </div>
          );
      
          //certificadoConvert.Imprimir = <></>;
        
        return certificadoConvert;
      });

      setConvertCertificado(convertCertificad);
    }
  }, [alumnos]);

  if (isLoading) {
    return <Loader />;
  }

  const handleChange = (event, values) => {
    setPage(values);
  };

  return (
    <>
      {printing && <Loader />}
      <myCertificacionPersona.Provider value={page}>
        <React.Fragment>        
          
          {alumnos.length !== 0 && convertCertificado && (
            <GenericTemplate
              titleName={ "Mis Certificados"}
              dataTable={convertCertificado}
              siEdit={false}
              siDelete={false}
              siTable={true}
            />
          )}         

         { alumnos.length === 0 && <div>No hay certificados actualmente</div>}

        </React.Fragment>
      </myCertificacionPersona.Provider>
    </>
  );
}
