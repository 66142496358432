import { request } from "utils/helpers/request.helpers";

const institucionUrl = `${process.env.REACT_APP_API_URL_DEV}/api/Institucion`;

export function fetchInstitucion(page) {
  return request(`${institucionUrl}/Listar?page=${page}`, {     // Listar?page=1
    method: 'GET',
  });
}

export function fetchInstitucionTodas() {
  return request(`${institucionUrl}/ListarTodas`, {     // Listar TODAS!
    method: 'GET',
    headers: { "Content-Type": "application/json" },
  });
}

export function fetchInstitucionById(id) {
  return request(`${institucionUrl}/${id}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
}

export function searchInstitucion(pBusqueda) {
  return request(`${institucionUrl}/FiltrarInstituciones?pBusqueda=${pBusqueda}`, {
    method: 'GET',
  });
}

export function createInstitucion(data) {
  return request(`${institucionUrl}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function editInstituciones(data) {
  try {
    return request(`${institucionUrl}/ModificarInstitucion`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });
  } catch (error) {
    console.log("error", error.response);
  }
}

export function ListarCaracteres() {
  try {
    return request(`${institucionUrl}/ListarCaracteres`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(),
    });
  } catch (error) {
    console.log("error", error.response);
  }
}

export function ListarDependencias() {
  try {
    return request(`${institucionUrl}/ListarDependencias`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(),
    });
  } catch (error) {
    console.log("error", error.response);
  }
}

export async function deleteInstitucion(id) {
  try {
    return await request(`${institucionUrl}/EliminarInstitucion/${id}`, {
      method: "delete",
    });
  } catch (error) {
    console.log("error", error.response);
  }
}