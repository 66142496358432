import {
  Autocomplete,
  Box,
  Button,
  DialogTitle,
  Modal,
  TextField,
} from "@mui/material";
import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import React from "react";

//import ActividadesTable from "./ActividadesTable";
import {
  GetAllPersonaEndpoint,
  selectPersonas,
} from "store/persona/persona.slice";
import { selectActividadesAprobadasAll } from "store/actividades/actividades.slice";
import { SaveInscripcionEndpoint } from "store/Inscripcion/Inscripcion.slice";
import { showSnackbar } from "store/mensajeria/mensajeriaSlice";
import { selectPeriodos } from "store/periodos/periodo.slice";

export default function InscripcionForm({ onClose, open, idActividad }) {
  const dispatch = useDispatch();
  const Personas = useSelector(selectPersonas);
  const [selectPre, setSelectPre] = useState(null);
  const [persona, setPersona] = useState(null);
  const [abrir, setAbrir] = useState(false);
  const [cuitValid, setCuitValid] = useState(false);
  const periodo = useSelector(selectPeriodos);
  const denominaciones = useSelector(selectActividadesAprobadasAll);
  const formValues = {
    cuit: "",
    actividad: idActividad,
  };
  const [initialValue, setInitialValue] = useState({ ...formValues });

  useEffect(() => {
    dispatch(GetAllPersonaEndpoint());
    setInitialValue({ cuit: "", actividad: idActividad });
  }, []);

  useEffect(() => {
    open && handleCloseOrOpen();
  }, [open]);

  useEffect(() => {
    if (denominaciones != null) {
      let mapeo = denominaciones.data?.map((x) => {
        {
          return { id: x.idActividad, label: x.descripcion };
        }
      });

      setSelectPre(mapeo);
    }
  }, [denominaciones]);

  useEffect(() => {
    if (idActividad != null) {
      setInitialValue({ cuit: "", actividad: idActividad });
    }
  }, [idActividad]);

  const handleSubmit = (form) => {
    let request = {
      cuit: form.cuit.toString(),
      idActividad: Number(form.actividad),
    };

    dispatch(SaveInscripcionEndpoint(request))
    .then((resultAction) => {
      if (SaveInscripcionEndpoint.fulfilled.match(resultAction))
        dispatch(showSnackbar(resultAction.payload.message));
    });
    handleCloseOrOpen();
  };

  const handleCheckCuit = (value) => {
    if (Personas.find((x) => x.cuit == value) != undefined) {
      setCuitValid(true);
      setPersona(Personas.find((x) => x.cuit == value));
      return true;
    } else {
      setCuitValid(false);
      setPersona(null);
      return false;
    }
  };

  const handleCheckPeriodo = () => {
    if(periodo.fechaFin && periodo.fechaInicio){
      const fechaActual = new Date();
      const dia = fechaActual.getDate();
      const mes = fechaActual.getMonth() + 1;
      const anio = fechaActual.getFullYear();
  
      const partesFechaFin = periodo.fechaFin.split("/");
      const diaFin = parseInt(partesFechaFin[0], 10);
      const mesFin = parseInt(partesFechaFin[1], 10) - 1;
      const anioFin = parseInt(partesFechaFin[2], 10);
      const fechaFin = new Date(anioFin, mesFin, diaFin);
  
      const partesFechaInicio = periodo.fechaInicio.split("/");
      const diaInicio = parseInt(partesFechaInicio[0], 10);
      const mesInicio = parseInt(partesFechaInicio[1], 10) - 1; 
      const anioInicio = parseInt(partesFechaInicio[2], 10);
      const fechaInicio = new Date(anioInicio, mesInicio, diaInicio);
  
      fechaInicio.setHours(0, 0, 0, 0); 
      fechaFin.setHours(0, 0, 0, 0);
      fechaActual.setHours(0, 0, 0, 0);
         
    if (fechaActual > fechaFin) {
      return false;
    } else if (fechaInicio > fechaActual) {
      return false;
    }

    return true;
    }else{
      return false;
    }

  };


  //Validaciones del formulario (Requiere importar YUP)
  const validationSchema = Yup.object().shape({
    actividad: Yup.string().required("Este campo es requerido"),
    cuit: Yup.number().test(
      "CuitValidation",
      'Cuit no registrado en base, consulta a "Secretaría de Planeamiento Educativo".',
      (value) => {
        return handleCheckCuit(value);
      }
    ),
  });
  //Logica para cerrar o abrir el modal
  const handleCloseOrOpen = () => {
    setAbrir(!abrir);
    abrir && setCuitValid(false);
    onClose(false);
    abrir && setInitialValue(formValues);
  };

  const stylePopapACT = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    color: "black",
    p: 4,
  };
  const inputACT = {
    width: "100%",
    marginTop: "10px",
  };
  return (
    <>
      <Modal
        open={abrir}
        onClose={handleCloseOrOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Formik
            initialValues={initialValue}
            /* Aqui se obtienen los datos que se ingresó en el formulario */
            onSubmit={handleSubmit}
            /* Configuracion de Formik para agregar las validaciones */
            validationSchema={validationSchema}
          >
            {(formikProps) => (
              <Box sx={stylePopapACT} style={{ borderRadius: "5px" }}>
                <DialogTitle
                  sx={{ color: "rgb(56,56,56)", padding: "0px 0px 10px 0px" }}
                >
                  Agregar Inscripción
                </DialogTitle>
                <div
                  style={{
                    width: "500px",
                  }}
                >
                  <Box>
                    <TextField
                      type="number"
                      variant="outlined"
                      label="CUIL"
                      placeholder="CUIL"
                      style={inputACT}
                      //size="small"
                      id="cuit"
                      name="cuit"
                      {...formikProps.getFieldProps("cuit")}
                    ></TextField>
                  </Box>
                  <ErrorMessage name="cuit">
                    {(mensaje) => <div style={{ color: "red" }}>{mensaje}</div>}
                  </ErrorMessage>

                  <Box style={{ display: "flex" }}>
                    <TextField
                      type="text"
                      style={inputACT}
                      variant="outlined"
                      label="Nombre y Apellido"
                      InputProps={{ readOnly: true }}
                      value={
                        cuitValid
                          ? `${persona.nombres} ${persona.apellidos}`
                          : ""
                      }
                      id="personaInfo"
                      name="personaInfo"
                    ></TextField>
                  </Box>

                  <Box>
                    <Autocomplete
                      //size="small"
                      disablePortal
                      onChange={(event, newValue) => {
                        formikProps.setFieldValue(
                          "actividad",
                          newValue !== null ? newValue.id : ""
                        );
                      }}
                      id="combo-box-demo"
                      options={selectPre != null ? selectPre.filter(x => x.id == idActividad) : []}
                      name="actividad"
                      value={selectPre.find((x) => x.id == idActividad)}
                      style={inputACT}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={selectPre.find((x) => x.id == idActividad)}
                          label={"Actividad"}
                        />
                      )}
                    />
                  </Box>
                  <ErrorMessage name="actividad">
                    {(mensaje) => <div style={{ color: "red" }}>{mensaje}</div>}
                  </ErrorMessage>
                </div>

                <Button
                  onClick={() => formikProps.submitForm()}
                  style={{
                    marginTop: "100px",
                    float: "right",
                    backgroundColor: "#004D40",
                    color: "white",
                    textTransform: "uppercase",
                  }}
                >
                  {"Guardar"}
                </Button>
              </Box>
            )}
          </Formik>
        </>
      </Modal>
    </>
  );
}
