import React from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { useDispatch } from "react-redux";
import { showSnackbar } from "store/mensajeria/mensajeriaSlice";
import { Tooltip } from "@mui/material";

export default function GenericDelete({ idToDelete, deleteFuncion, title }) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const tituloOriginal = title; // Evitamos modificar la variable "title".

  const ultTitulo = tituloOriginal
    .split("")
    .reverse()
    .slice(0, 1)
    .join("")
    .toLowerCase();
  const dosUltTitulo = tituloOriginal
    .split("")
    .reverse()
    .slice(0, 2)
    .join("")
    .toLowerCase();
  const cuatroUltTitulo = tituloOriginal
    .split("")
    .reverse()
    .slice(0, 4)
    .join("")
    .toLowerCase();
  const seisUltTitulo = tituloOriginal
    .split("")
    .reverse()
    .slice(0, 6)
    .join("")
    .toLowerCase();

  function generoTit() {
    if (
      ultTitulo == "a" ||
      dosUltTitulo == "sa" ||
      cuatroUltTitulo == "noic" ||
      cuatroUltTitulo == "nóic" ||
      seisUltTitulo == "senoic"
    ) {
      return "la ";
    } else {
      return "el ";
    }
  }

  function singularTit(tituloOriginal) {
    if (seisUltTitulo == "senoic") {
      return tituloOriginal.substring(0, tituloOriginal.length - 6) + "ción";
    } else if (dosUltTitulo == "se") {
      return tituloOriginal.substring(0, tituloOriginal.length - 2);
    } else if (ultTitulo == "s") {
      return tituloOriginal.substring(0, tituloOriginal.length - 1);
    } else {
      return tituloOriginal;
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = () => {
    setOpen(false);
    //TODO: verificar que parametro enviamos como id
    dispatch(deleteFuncion(idToDelete.ID)).then((resultAction) => {
      if (deleteFuncion.fulfilled.match(resultAction))
        dispatch(showSnackbar(resultAction.payload.message));
    });
  };

  return (
    <React.Fragment>
      <Tooltip title="Eliminar">
        <IconButton variant="outlined" onClick={handleClickOpen}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Eliminar {singularTit(tituloOriginal)}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Estás seguro que deseas eliminar{" "}
            {generoTit() + singularTit(tituloOriginal)}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleSubmit} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
