import React, { createContext, useState } from "react";

// Componente Provider del contexto
export const PersonaProvider = ({ children }) => {
  const [page, setPage] = useState(1);
  const [persona, setPersona] = useState({});

  return (
    <PersonaContext.Provider value={{ page, persona, setPage, setPersona }}>
      {children}
    </PersonaContext.Provider>
  );
};

// Creamos el contexto
export const PersonaContext = createContext();
