import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetCausasBaja } from "./causasBaja.api";

const initialState = {
  entities: {},
  response: {},
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
};

export const GetCausasBajaEndPoint = createAsyncThunk(
  "causasBaja/GetCausasBaja",
  async () => {
    const r = await GetCausasBaja();
    return r;
  }
);

export const causasBajaSlice = createSlice({
  name: "causasBaja",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // GetActividadEndPoint
      .addCase(GetCausasBajaEndPoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetCausasBajaEndPoint.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(GetCausasBajaEndPoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});

export const selectCausasBaja = (state) => state.causasBaja.entities;
export const selectCausasBajaResponse = (state) => state.causasBaja.response;
export const selectIsLoading = (state) => state.causasBaja.loading;
export const selectIsCreating = (state) => state.causasBaja.creating;
export const selectIsEditing = (state) => state.causasBaja.editing;
export const selectIsDeleting = (state) => state.causasBaja.deleting;
export const selectError = (state) => state.causasBaja.error;

export default causasBajaSlice.reducer;
