import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  GetResponsablesXProyecto,
  GetCapacitadorXActividad,
  SaveCapacitador,
  UpdateCapacitador,
  DeleteCapacitador
} from "./capacitadores.api";

const initialState = {
  entitiesXProyecto: {},
  entities: {},
  response: {},
  message: {},
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
};

export const GetResponsablesEndPoint = createAsyncThunk(
  "capacitadores/GetResponsablesXProyecto",
  async (idProyecto) => {
    const r = await GetResponsablesXProyecto(idProyecto);
    return r;
  }
);

export const GetCapacitadorEndPoint = createAsyncThunk(
  "capacitadores/GetCapacitadorXActividad",
  async (idActividad) => {
    const r = await GetCapacitadorXActividad(idActividad);
    return r;
  }
);

export const SaveCapacitadorEndpoint = createAsyncThunk(
  "capacitadores/SaveCapacitador",
  async (body) => {
    const r = await SaveCapacitador(body);
    return r;
  }
);

export const EditCapacitadorEndPoint = createAsyncThunk(
  'capacitadores/UpdateCapacitador',
  async ({ data }) => {
    const r = await UpdateCapacitador({ data });
    return r;
  }
);

export const RemoveCapacitadorEndPoint = createAsyncThunk(
  'capacitadores/DeleteCapacitador',
  async (id) => {
    const r = await DeleteCapacitador(id);
    return r;
  }
);

export const capacitadoresSlice = createSlice({
  name: "capacitadores",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // GetResponsablesEndPoint

      .addCase(GetResponsablesEndPoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetResponsablesEndPoint.fulfilled, (state, action) => {
        state.loading = false;
        state.entitiesXProyecto = action.payload;
      })
      .addCase(GetResponsablesEndPoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // GetCapacitadorEndPoint

      .addCase(GetCapacitadorEndPoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetCapacitadorEndPoint.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(GetCapacitadorEndPoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // SaveCapacitadorEndpoint

      .addCase(SaveCapacitadorEndpoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(SaveCapacitadorEndpoint.fulfilled, (state, action) => {
        state.loading = false;
        state.response = action.payload;
      })
      .addCase(SaveCapacitadorEndpoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // EditCapacitadorEndPoint

      .addCase(EditCapacitadorEndPoint.pending, (state) => {
        state.editing = true;
        state.error = null;
      })
      .addCase(EditCapacitadorEndPoint.fulfilled, (state, action) => {
        state.editing = false;
        state.message = action.payload;
      })
      .addCase(EditCapacitadorEndPoint.rejected, (state, action) => {
        state.editing = false;
        state.error = action.error.message;
      })

      // RemoveActividadEndPoint

      .addCase(RemoveCapacitadorEndPoint.pending, (state) => {
        state.deleting = true;
        state.error = null;
      })
      .addCase(RemoveCapacitadorEndPoint.fulfilled, (state, action) => {
        state.deleting = false;
        state.message = action.payload;
      })
      .addCase(RemoveCapacitadorEndPoint.rejected, (state, action) => {
        state.deleting = false;
        state.error = action.error.message;
      })
  },
});

export const selectResponsables = (state) => state.capacitadores.entitiesXProyecto;
export const selectCapacitadores = (state) => state.capacitadores.entities;
export const selectCapacitadoresResponse = (state) => state.capacitadores.response;
export const selectIsLoading = (state) => state.capacitadores.loading;
export const selectIsCreating = (state) => state.capacitadores.creating;
export const selectIsEditing = (state) => state.capacitadores.editing;
export const selectIsDeleting = (state) => state.capacitadores.deleting;
export const selectError = (state) => state.capacitadores.error;

export default capacitadoresSlice.reducer;
