import { request } from 'utils/helpers/request.helpers';

const observacionesUrl = `${process.env.REACT_APP_API_URL_DEV}/api/Observacion`;

export function GetObservacion(proyElegido) { 
  const url = `${observacionesUrl}/BuscarObsxProyecto/${proyElegido}`;    
  return request(url, { method: 'GET' });
}

export function SaveObservacion(body) {
  return request(`${observacionesUrl}/SaveActividad`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(body),
  });
}

export async function DeleteObservacion(id) {
  try {
    return await request(`${observacionesUrl}/EliminarObservacion/${id}`, {
      method: "DELETE",
    });
  } catch (error) {
    console.log("error", error.response);
  }
}

export function EditObservacion(data) {
  try {
    return request(`${observacionesUrl}/ModificarObservacion`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });
  } catch (error) {
    console.log("error", error.response);
  }
}

export function PostObservacion(data) {
  try {
    return request(`${observacionesUrl}/InsertarObservacion`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });
  } catch (error) {
    console.log("error", error.response);
  }
}