import React, { useContext, useEffect, useState } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { fourthListItems } from "../listItems/listItems";
import { Button, Link, SwipeableDrawer, useMediaQuery } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import logo from "./Logo Edu.png";
import { useIdRol } from "utils/roles/IdRolContext";
import { TokenContext } from "context/TokenContext";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    height: "100%",
    overflowY: "auto",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme({
  palette: {
    primary: {
      main: "#aeaeae",
    },
    secondary: {
      main: "#311b92",
    },
  },
});

function DashboardContent({ rolName }) {
  const isAuthenticated = localStorage.getItem("token");
  const [open, setOpen] = React.useState(true);
  const [code, setCode] = useState(null);
  const location = useLocation();
  const redirectMiCatamarca = process.env.REACT_APP_API_URL_MI_CATAMARCA_LOGIN;
  const cerrarSesionMobile = process.env.REACT_APP_API_URL_CERRAR_SESION_MOBILE;
  const { token, decodedToken, saveToken, removeToken } =
    useContext(TokenContext);
  const isMobile = useMediaQuery("(max-width:600px)");
  // Verifica si estás en la página principal ("/")
  const isHomePage = location.pathname === "/";

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const codeParam = queryParams.get("code");

    if (!code && !token && codeParam) {
      setCode(codeParam);
      fetchToken(codeParam).then(() => {
        queryParams.delete("code");
        queryParams.delete("state");
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        window.history.replaceState({}, "", newUrl);
        // Elimina el signo de interrogación si no quedan parámetros
        if (!queryParams.toString()) {
          window.history.replaceState({}, "", window.location.pathname);
        }
      });
    } else if (!codeParam && !token) {
      window.location.href = redirectMiCatamarca;
    }
  }, [location.search, token, code]);

  const fetchToken = async (code) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL_LOGIN_DEV}/api/v1/Authentication/Token?authorizationCode=${code}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data?.value) {
        saveToken(data.value.access_token);
        localStorage.setItem("refresh_token", data.value.refresh_token);
        localStorage.setItem("id_token", data.value.id_token);
      }
    } catch (error) {
      console.error("Error al obtener el token:", error);
    }
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    const id_token = localStorage.getItem("id_token");
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("usuario");
    const url = `${cerrarSesionMobile}?id_token_hint=${id_token}&post_logout_redirect_uri=https://gestiondocente.catamarca.edu.ar/`;

    setCode(null);
    if (!isMobile) {
      window.location.href = url;
    } else {
      window.location.href = url;
    }
  };

  useEffect(() => {
    if (isMobile) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isMobile]);

  return (
    <ThemeProvider theme={mdTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          height: "100vh",
          overflow: "hidden",
          width: "100%",
        }}
      >
        <CssBaseline />
        <AppBar
          position={isMobile ? "static" : "fixed"}
          open={open}
          sx={{
            width: open
              ? !isMobile
                ? `calc(100% - ${240}px)`
                : "100%"
              : "100%",
            ml: isMobile ? 0 : `${open ? 240 : 57}px`,
            transition: mdTheme.transitions.create(["width", "margin"], {
              easing: mdTheme.transitions.easing.sharp,
              duration: mdTheme.transitions.duration.leavingScreen,
            }),
          }}
        >
          <Toolbar
            sx={{
              pr: "24px",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            {!isMobile && <img alt="logo" src={logo}></img>}
            <Typography
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
              style={{
                fontSize: "20px",
                textAlign: "center",
                marginRight: "40px",
              }}
            >
              INSCRIPCIÓN
            </Typography>
            <Button
              sx={{ ml: 1, display: "flex", alignItems: "center" }}
              color="inherit"
              onClick={handleLogout}
            >
              <span style={{ display: "inline-flex", alignItems: "center" }}>
                Salir
              </span>
              <ExitToAppIcon sx={{ fontSize: 40, ml: 1 }} />{" "}
              {/* Ajusta el tamaño según sea necesario */}
            </Button>
          </Toolbar>
        </AppBar>
        {isMobile ? (
          <SwipeableDrawer
            anchor={"left"}
            open={open}
            onClose={toggleDrawer}
            onOpen={toggleDrawer}
            sx={{ zIndex: 1300 }}
            aria-hidden={!open}
          >
            <List component="nav" onClick={toggleDrawer}>
              {fourthListItems({ rolName })}
            </List>
          </SwipeableDrawer>
        ) : (
          <Drawer variant="permanent" open={open}>
            <Toolbar
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                px: [1],
              }}
              style={{ margin: "12px 0px" }}
            >
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <Box
              sx={{
                height: "calc(100vh - 64px)",
                overflowY: "auto",
              }}
            >
              <List component="nav">{fourthListItems({ rolName })}</List>
            </Box>
          </Drawer>
        )}
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[300]
                : theme.palette.grey[900],
            flexGrow: 1,
            width: "auto",
            overflow: "auto",
            height: "calc(100vh)", // Subtract AppBar height
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Container
            maxWidth={false}
            sx={{
              mt: isMobile ? 0 : 12,
              display: "flex",
              flexDirection: "column",
              flex: 1, // Allow container to grow and fill available space              
            }}
          >
            {decodedToken && (
              <>
                <Typography
                  sx={{
                    mt: 2,
                    fontSize: "24px",
                    fontWeight: "bold",
                    textAlign: isHomePage ? "left" : "right",
                  }}
                  gutterBottom
                >
                  {isHomePage && (
                    <Typography
                      component="span"
                      sx={{
                        fontWeight: "normal",
                        fontSize: "28px",
                        display: isHomePage ? "block" : "inline", // Condicional para display
                      }}
                    >
                      Hola!
                    </Typography>
                  )}
                  {!isHomePage && " "}
                  {decodedToken.given_name} {decodedToken.family_name}
                </Typography>
              </>
            )}
            <Outlet />
          </Container>
          <Box
            component="footer"
            sx={{
              backgroundColor: (theme) => theme.palette.primary.main,
              color: "dimgray",
              py: 2,
              textAlign: "center",
              width: "auto",
              overflow: "auto",
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1200,
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              © {new Date().getFullYear()}
              {" | "}
              <Link
                href="https://www.catamarca.edu.ar/dit"
                target="_blank"
                color="inherit"
              >
                Dirección Provincial de Informática y Telecomunicaciones
              </Link>{" "}
              - Ministerio de Educación de Catamarca | Todos los derechos
              reservados
            </Typography>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function Dashboard() {
  const { rolName } = useIdRol();
  return <DashboardContent rolName={rolName} />;
}
