import { request } from "utils/helpers/request.helpers";

const proyectosUrl = `${process.env.REACT_APP_API_URL_LOGIN_DEV}`;

export function fetchProyectos() {
  const url = `${proyectosUrl}/Listar`;
  return request(url, { method: "GET" });
}

export function fetchProyectosSinResol() {
  const url = `${proyectosUrl}/ListarProyectos_ConResolusion'`;
  return request(url, { method: "GET" });
}

export function fetchProyectosConResol() {
  const url = `${proyectosUrl}/Inscripcion/GetListarProyectosConResolucion`;
  return request(url, { method: "GET" });
}
export function fetchProyectosConNiveles(idProyecto) {
  const url = `${proyectosUrl}/ListarNivelesxProyecto?idProyecto=${idProyecto}`;
  return request(url, { method: "GET" });
}

export function createProyecto(data) {
  return request(`${proyectosUrl}/`, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function createNivelesxProy(data) {
  return request(`${proyectosUrl}/`, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function updateProyecto({ id, data }) {
  return request(`${proyectosUrl}/${id}`, {
    method: "PUT",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function deleteProyecto(id) {
  return request(`${proyectosUrl}/${id}`, {
    method: "DELETE",
  });
}

export function createNivelesProy(data) {
  return request(`${proyectosUrl}/CreateRelProyectoNivel`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}
