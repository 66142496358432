import { request } from "utils/helpers/request.helpers";

const personasUrl = `${process.env.REACT_APP_API_URL_DEV}/api/Persona`;

export function fechPersona(page, nombres, apellidos, documento) {
  let nombresParam = nombres ? "&pnombres=" + nombres : "";
  if (!nombres) {
    nombresParam = "";
  }

  let apellidosParam = apellidos ? "&papellidos=" + apellidos : "";
  if (!apellidos) {
    apellidosParam = "";
  }

  let documentoParam = documento ? "&pdocumento=" + documento : "";
  if (documento === null) {
    documentoParam = "";
  }

  return request(
    `${personasUrl}/Listar?page=${page}${apellidosParam}${nombresParam}${documentoParam}`,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );
}
export function filterPerson(data) {
  return request(`${personasUrl}/FiltrarPersonas?pBusqueda=${data}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
}

export function fetchPersonaById(id) {
  return request(`${personasUrl}/${id}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
}

export function createPersona(data) {
  return request(`${personasUrl}/InsertarPersona`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function editPersona(data) {
  return request(`${personasUrl}/ModificarPersona`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

export async function deletePerson(id) {
  try {
    return await request(`${personasUrl}/${id}`, {
      method: "delete",
    });
  } catch (error) {
    console.log("cath persona.api");
    console.log("error", error.response);
  }
}

export function GetAllPersona() {
  return request(`${personasUrl}/GetAllPersona`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
}

export function GetEstadoCivil() {
  return request(`${personasUrl}/ListarEstadoCiviles`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
}

export async function GetAntecedentes() {
  try {
    const response = await request(`${personasUrl}/ListarAntecedentes`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    return response;
  } catch (error) {
    console.error("Error fetching antecedentes:", error);
    throw error;
  }
}
