import { configureStore } from "@reduxjs/toolkit";
import authReducer from "store/auth/auth.slice";
import proyectosReducer from "store/proyectos/proyectos.slice";
import PersonaSlice from "store/persona/persona.slice";
import tiposProyectosReducer from "store/tiposProyecto/tiposProyectos.slice";
import modalidadesProyectoReducer from "store/modalidadesProyecto/modalidadesProyecto.slice";
import estadosProyectoReducer from "store/estadosProyecto/estadosProyecto.slice";
import actividadesReducer from "store/actividades/actividades.slice";
import capacitadoresReducer from "store/capacitadores/capacitadores.slice";
import causasBajaReducer from "store/causasBaja/causasBaja.slice";
import localidadRecucer from "store/Localidad/Localidad.slice";
import resolucionReducer from "store/Resolucion/Resolucion.slice";
import IProyectosSlice from "store/inicioProyectos/IProyectos.slice";
import ListarProyectosSlice from "store/ListarProyectos/listarProyectos.slice";
import InstitucionSlice from "store/Institucion/institucion.slice";
import observacionesSlice from "store/observaciones/observaciones.slice";
import preInscripcion from "store/preInscripcion/preInscripcion.slice";
import Inscripcion from "store/Inscripcion/Inscripcion.slice";
import resolucionesSlice from "store/Resoluciones/resoluciones.slice";
import certificadoSlice from "store/certificados/certificado.slice";
import selectAlumnos from "store/Alumnos/alumnos.slice";
import cargaExternaReducer from "store/cargaExtra/cargaExterna.slice";
import mensajeriaReducer from "store/mensajeria/mensajeriaSlice";
import responsabilidadesSlice from "store/responsabilidades/responsabilidades.slice";
import nivelesEnsenanzaSlice from "store/nivelesEnsenanza/nivelesEnsenanza.slice";
import periodosSlice from "./Periodo/periodos.slice";
import periodoSlice from "./periodos/periodo.slice";
import tiposDePeriodoSlice from "./tiposDePeriodo/tiposDePeriodo.slice";

export function configureAppStore() {
  const store = configureStore({
    reducer: {
      auth: authReducer,
      proyectos: proyectosReducer,
      persona: PersonaSlice,
      tiposProyecto: tiposProyectosReducer,
      modalidadesProyecto: modalidadesProyectoReducer,
      estadosProyecto: estadosProyectoReducer,
      actividades: actividadesReducer,
      capacitadores: capacitadoresReducer,
      causasBaja: causasBajaReducer,
      localidad: localidadRecucer,
      resolucion: resolucionReducer,
      IProyectos: IProyectosSlice,
      ListarProyectos: ListarProyectosSlice,
      periodo: periodoSlice,
      periodos: periodosSlice,
      tiposDePeriodo: tiposDePeriodoSlice,
      institucion: InstitucionSlice,
      observaciones: observacionesSlice,
      preInscripcion,
      resoluciones: resolucionesSlice,
      certificado: certificadoSlice,
      cargaExterna: cargaExternaReducer,
      alumnos: selectAlumnos,
      Inscripcion,
      mensajeria: mensajeriaReducer,
      responsabilidades: responsabilidadesSlice,
      nivelesEnsenanza: nivelesEnsenanzaSlice,
    },
  });

  return store;
}
