import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import IconButton from "@mui/material/IconButton";
import ResolucionNuevo from "../ResolucionNuevo/ResolucionNuevo";
import {
  GetIdResoluciones,
  selectIsLoading,
  selectResolucionesXProyecto,
} from "store/Resoluciones/resoluciones.slice";
import ResolucionesTable from "../ResolucionesTable/ResolucionesTable";
import ResolucionesTituloModal from "../ResolucionesTituloModal";
import { CircularProgress, DialogContent, Tooltip } from "@mui/material";
import CustomModal from "components/customModal/CustomModal";

const ResolucionesPage = ({ itemAct, colorResol }) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = React.useState(false);
  const GetResoluciones = useSelector(selectResolucionesXProyecto);
  const resolucionesLoading = useSelector(selectIsLoading);

  const handleClickOpen = () => {
    setOpenModal(true);
    dispatch(GetIdResoluciones(itemAct.idProyecto)); // No lleva paginación.
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const muestroTabla = () => {
    if (GetResoluciones?.data !== null) {
      // Capacitadores para una determinada Actividad.
      return (
        <ResolucionesTable
          itemAct={itemAct}
          datosCap={GetResoluciones?.data || []}
        />
      );
    } else {
      return (
        <p
          style={{
            textAlign: "center",
            marginBottom: "30px",
          }}
        >
          {" "}
          Sin Resolución asignada.{" "}
        </p>
      );
    }
  };

  return (
    <React.Fragment>
      <Tooltip title="Resoluciones">
        <IconButton variant="outlined" onClick={handleClickOpen}>
          <ContentPasteIcon style={{ color: colorResol }} />
        </IconButton>
      </Tooltip>
      <CustomModal title={"Resolución"} open={openModal} setOpen={setOpenModal}>
        <DialogContent>
          {resolucionesLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress color="inherit" />
            </div>
          ) : (
            <>
              <ResolucionesTituloModal itemAct={itemAct || []} />
              {muestroTabla()}{" "}
              <ResolucionNuevo
                itemAct={itemAct}
                handleConteinerClose={handleClose}
                isNuevo={
                  GetResoluciones?.data?.length === 0 ||
                  GetResoluciones?.data === null
                }
              />
            </>
          )}
        </DialogContent>
      </CustomModal>
    </React.Fragment>
  );
};

export default ResolucionesPage;
