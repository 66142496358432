import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import Loader from "components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteObservacionEndpoint,
  selectIsLoading,
  EditObservacionEndpoint,
  GetObservacionEndPoint,
  selectObservaciones,
} from "store/observaciones/observaciones.slice";
import { useIdRol } from "utils/roles/IdRolContext";

export default function ObservacionesTable({IdProyecto}) {
  const [openModal, setOpenModal] = useState(false);
  const [isEditSuccess, setIsEditSuccess] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [observationToDeleteId, setObservationToDeleteId] = useState(null);
  const { rolName } = useIdRol();

  const [formData, setFormData] = useState({
    descripcion: "",
    idProyectoForm: "",
    fechaObservacionForm: "",
    fechaUltModForm: "",
    idObservacionToEdit: null,
  });

  const [lastFormData, setLastFormData] = useState({ ...formData });

  const dispatch = useDispatch();

  const { descripcion, idProyectoForm, fechaObservacionForm, fechaUltModForm, idObservacionToEdit } = formData;

  const {
    descripcion: lastDescripcion,
    idProyectoForm: lastIdProyectoForm,
    fechaObservacionForm: lastFechaObservacionForm,
    fechaUltModForm: lastFechaUltModForm,
  } = lastFormData;

  const GetObservaciones = useSelector(selectObservaciones);
  const loadingEddit = useSelector(selectIsLoading);

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const [year, month, day] = dateString.split("T")[0].split("-");
    return `${day}/${month}/${year}`;
  };

  const handleOpenModal = (idObservacion) => {
    setFormData((prevData) => ({ ...prevData, idObservacionToEdit: idObservacion }));
    setOpenModal(true);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setFormData(lastFormData);
  };

  const handleSaveChanges = () => {
    const dataToUpdate = { idObservacion: idObservacionToEdit, idProyecto: idProyectoForm, descripcion, fechaObservacion: fechaObservacionForm, fechaUltMod: fechaUltModForm };
  
    dispatch(EditObservacionEndpoint(dataToUpdate))
      .then(() => {
        setIsEditSuccess(true);
        dispatch(GetObservacionEndPoint(IdProyecto));
      })
      .catch((error) => console.error("Error en la edición: ", error));
  
    setLastFormData({ descripcion, idProyectoForm, fechaObservacionForm, fechaUltModForm });
    handleCloseModal();
  };

  const handleDeleteIconClick = (idObservacion) => {
    setObservationToDeleteId(idObservacion);
    setDeleteConfirmation(true);
    setIsEditSuccess(true)
  };

  const handleCloseDeleteConfirmation = () => {
    setDeleteConfirmation(false);
    setObservationToDeleteId(null);
  };

  const handleConfirmDelete = () => {
    if (observationToDeleteId) {
      dispatch(DeleteObservacionEndpoint(observationToDeleteId))
        .then(() => dispatch(GetObservacionEndPoint(IdProyecto)))
        .catch((error) => console.error("Error en la eliminación: ", error));
      handleCloseDeleteConfirmation();
    }
  };
  
  let numeroObs = 0;

  function numTabla() {
    numeroObs = numeroObs + 1;
    return numeroObs;
  }

  if (loadingEddit) return <Loader />;

  if (GetObservaciones.length === 0) {
    return <h4 style={{ textAlign: "center" }}>Sin observaciones hasta el momento.</h4>;
  } else {
    return (
      <div>
        <TableContainer component={Paper} style={{ marginBottom: "100px" }}>
          <Table>
            <TableHead style={{ backgroundColor: "rgb(240, 240, 240)" }}>
              <TableRow>
                <TableCell style={{ fontWeight: "bold", maxWidth: "50px", textAlign: "center" }}>Nº OBS</TableCell>
                <TableCell style={{ fontWeight: "bold", maxWidth: "40px", textAlign: "center" }}>ID</TableCell>
                <TableCell style={{ fontWeight: "bold", maxWidth: "80px", textAlign: "center" }}>Fecha Observación</TableCell>
                <TableCell style={{ fontWeight: "bold", maxWidth: "80px", textAlign: "center" }}>Fecha Última Modificación</TableCell>
                <TableCell style={{ fontWeight: "bold", maxWidth: "250px" }}>Descripción de Observación</TableCell>
                {rolName !== "institucionOferente" && rolName !== "capacitadores" && rolName !== "docente" &&
                <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Acciones</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {GetObservaciones?.map((item) => (
                <TableRow key={item.idObservacion} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell style={{ fontWeight: "bold", backgroundColor: "rgb(118, 207, 205)", padding: "0px", borderRadius: "7px", textAlign: "center" }}>
                    OBS_{numTabla()}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>{item.idObservacion}</TableCell>
                  <TableCell style={{ maxWidth: "80px", textAlign: "center" }}>{formatDate(item.fechaObservacion)}</TableCell>
                  <TableCell style={{ maxWidth: "80px", textAlign: "center" }}>{formatDate(item.fechaUltMod)}</TableCell>
                  <TableCell style={{ maxWidth: "250px", whiteSpace: "pre-wrap" }}>{item.descripcion}</TableCell>
                  {rolName !== "institucionOferente" && rolName !== "capacitadores" && rolName !== "docente" &&
                  (<TableCell style={{ textAlign: "center" }}>
                    <EditIcon
                      style={{ margin: "0px 8px", color: "rgb(117,117,117)" }}
                      onClick={() => {
                        handleOpenModal(item.idObservacion);
                        setFormData({
                          descripcion: item.descripcion,
                          idProyectoForm: item.idProyecto,
                          fechaObservacionForm: item.fechaObservacion,
                          fechaUltModForm: item.fechaUltMod,
                          idObservacionToEdit: item.idObservacion,
                        });
                      }}
                    />
                    {rolName !== "planeamiento" &&
                    <DeleteIcon style={{ margin: "0px 8px", color: "rgb(117,117,117)" }} onClick={() => handleDeleteIconClick(item.idObservacion)}/>}
                  </TableCell>)}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog open={openModal} onClose={handleCloseModal} maxWidth="md" fullWidth>
          <DialogTitle>Editar Observación</DialogTitle>
          <DialogContent>
            <TextField
              multiline
              fullWidth
              variant="outlined"
              style={{ marginTop: "10px" }}
              label="Descripción"
              rows={5}
              placeholder="Descripción"
              name="descripcion"
              value={descripcion}
              onChange={handleInputChange}
              inputProps={{ maxLength: 1000 }}
            />
            <div style={{ marginTop: "5px", marginLeft: "10px" }}>{descripcion.length} / 1000 caracteres</div>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleCloseModal} color="primary">
              Cancelar
            </Button>
            <Button variant="contained" onClick={handleSaveChanges} color="primary">
              Guardar Cambios
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={deleteConfirmation} onClose={handleCloseDeleteConfirmation}>
          <DialogTitle>Confirmar Eliminación</DialogTitle>
          <DialogContent>¿Está seguro de que desea eliminar esta observación?</DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleCloseDeleteConfirmation} color="primary">
              Cancelar
            </Button>
            <Button variant="contained" onClick={handleConfirmDelete} color="primary">
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
