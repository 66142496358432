  import { request } from "utils/helpers/request.helpers";

const periodosUrl = `${process.env.REACT_APP_API_URL_DEV}/api/Periodo`;

/* export function fetchTitulos(page) {
  const userGet = localStorage.getItem("token");
  const url = `${periodosUrl}/Listar?page=${page}&usuario=${userGet}`; // ABM Titulos.

  return request(url, { method: "GET" });
} */
export function fechPeriodo(page) {
  return request(`${periodosUrl}/Listar?page=${page}&tipoPeriodoIds=1`, {
    method: "GET",                                      // Solo obtenemos los Períodos de "Pre-Inscripción".
    headers: { "Content-Type": "application/json" },
  });
}

export function fechTipoPeriodo() {
  return request(`${periodosUrl}/ListarTiposPeriodo`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
}

export function fetchPeriodoById(id) {
  return request(`${periodosUrl}/GetPeriodo_ByID?pId=${id}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
}

export function createPeriodo(data) {
  return request(`${periodosUrl}/InsertarPeriodo`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function editPeriodo(data) {
  return request(`${periodosUrl}/ModificarPeriodo?pId=${data.id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data.data),
  });
}

export async function deletePeriodo(id) {
  return request(`${periodosUrl}/Deshabilitar?pIdPeriodo=${id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
  });
}

export function GetPeriodoXIdPeriodoYIdActividad(idTipoPeriodo, idActividad) {
  const url = `${periodosUrl}/GetPeriodoXIdPeriodoYIdActividad?idTipoPeriodo=${idTipoPeriodo}&idActividad=${idActividad}`;
  return request(url, { method: 'GET' });
}
/* 

export function searchTitle(value) {
  return request(`${periodosUrl}/FiltrarTitulo?pBusqueda=${value}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
}


 */
