import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PeriodoForm from "../Common/PeriodoForm";

import { PostPeriodo } from "store/Periodo/periodos.slice";
import { PeriodosContext } from "../../Page/PeriodosPage";
import { useMediaQuery, useTheme } from "@mui/material";
import CustomModal from "components/customModal/CustomModal";

export default function NuevoPeriodo() {
  const [open, setOpen] = React.useState(false);
  const page = React.useContext(PeriodosContext);
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    setOpen(false);
  };

  const handleSubmit = (periodo) => {
    dispatch(PostPeriodo({ data: periodo, page }));
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen}>
        Nuevo
      </Button>
      <CustomModal title={"Agregar Periodo"} open={open} setOpen={handleClose}>
        <DialogContent>
          <PeriodoForm
            onSubmit={handleSubmit}
            onClose={handleClose}
            page={page}
          />
        </DialogContent>
      </CustomModal>
    </React.Fragment>
  );
}
