import React, { createContext, useEffect, useState } from "react";
import ProyectosINuevo from "../ProyectosINuevo/ProyectosINuevo";
import ProyectosITable from "../ProyectosITable/ProyectosITable";
import { useDispatch, useSelector } from "react-redux";
import GenericSearch from "utils/search/GenericSearch";
import SnackBarUtils from "utils/MUI/SnackbarUtils";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { SnackBarMessageResponse } from "utils/Response/SnackBarMessageResponse";
import { Grid } from "@mui/material";
import {
  selectProyectos,
  getProyectos,
  selectIsLoadingProyecto as sIsLoading,
} from "store/inicioProyectos/IProyectos.slice";
import Loader from "components/Loader/Loader";

export const myPageProyectos = createContext(1);

const ProyectosIPage = () => {
  const dispatch = useDispatch();
  const [view, setView] = useState(false);
  const [message, setMessage] = useState("");
  const GetProyectos = useSelector(selectProyectos);
  const isLoading = useSelector(sIsLoading);
  const [currentSearch, setcurrentSearch] = React.useState("");
  let [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(getProyectos({ page, data: currentSearch }));
  }, [page, currentSearch, dispatch]);

  useEffect(() => {
    if (Array.isArray(GetProyectos.message)) {
      setView(true);
      setMessage(SnackBarMessageResponse(GetProyectos));
    }
  }, [GetProyectos]);

  const handleChange = (event, values) => {
    setPage(values);
  };

  const handleSearch = (e) => {
    if (e.code !== "Enter" && e.code !== "NumpadEnter") return;
    setPage(1);
    setcurrentSearch(e.target.value);
  };

  return (
    <myPageProyectos.Provider value={page}>
      <React.Fragment>
        {isLoading && <Loader />}
        <div style={{ display: "flex", alignItems: "left" }}>
          <h1 style={{ margin: "10px 0" }}>Proyectos</h1>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <ProyectosINuevo />
          </Grid>
          <Grid item xs={8}>
            <GenericSearch handleSearch={handleSearch} />
          </Grid>
        </Grid>
        <ProyectosITable datosProy={GetProyectos} />

        <SnackBarUtils
          closed={(value) => setView(value)}
          message={message}
          view={view}
        />

        <Stack spacing={2} style={{ margin: "20px 0px 30px 0px" }}>
          <Pagination
            count={GetProyectos?.pages}
            page={page}
            onChange={handleChange}
            color="primary"
          />
        </Stack>
      </React.Fragment>
    </myPageProyectos.Provider>
  );
};

export default ProyectosIPage;
