import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetObservacion, SaveObservacion, DeleteObservacion, EditObservacion, PostObservacion } from "./observaciones.api";
import { toast } from "react-toastify";

const initialState = {
  entities: [],
  response: {},
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
};

export const GetObservacionEndPoint = createAsyncThunk(
  "observaciones/getObservacion",
  async (proyElegido) => {
    const r = await GetObservacion(proyElegido);
    return r;
  }
);

export const SaveObservacionEndpoint = createAsyncThunk(
  "observaciones/SaveObservacion",
  async (body) => {
    const r = await SaveObservacion(body);
    return r;
  }
);

export const DeleteObservacionEndpoint = createAsyncThunk(
  "observaciones/DeleteObservacion",
  async(id) => {
    const r = await DeleteObservacion(id);
    return r;
  }
)

export const EditObservacionEndpoint = createAsyncThunk(
  "observaciones/EditObservacion",
  async(data) => {
    const r = await EditObservacion(data);
    return r;
  }
)

export const PostObservacionEndpoint = createAsyncThunk(
  "observaciones/PostObservacion",
  async(data) => {
    const r = await PostObservacion(data);
    return r;
  }
)

export const observacionesSlice = createSlice({
  name: "observaciones",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetObservacionEndPoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetObservacionEndPoint.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(GetObservacionEndPoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(SaveObservacionEndpoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(SaveObservacionEndpoint.fulfilled, (state, action) => {
        state.loading = false;
        state.response = action.payload;
      })
      .addCase(SaveObservacionEndpoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(DeleteObservacionEndpoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(DeleteObservacionEndpoint.fulfilled, (state, action) => {
        state.loading = false;
        state.response = action.payload;
        toast.success("Observacion eliminada con exito!");
      })
      .addCase(DeleteObservacionEndpoint.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
        toast.error("Ocurrio un error al agregar la observacion, intente nuevamente! "+action.error.message);
      })

      .addCase(EditObservacionEndpoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(EditObservacionEndpoint.fulfilled, (state, action) => {
        state.loading = false;
        state.response = action.payload;
        toast.success("Observacion editada con exito!");
      })
      .addCase(EditObservacionEndpoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        toast.error("Ocurrio un error al editar la observacion, intente nuevamente! "+action.error.message);
      })

      .addCase(PostObservacionEndpoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(PostObservacionEndpoint.fulfilled, (state, action) => {
        state.loading = false;
        state.response = action.payload;
        toast.success("Observacion agregada con exito!");
      })
      .addCase(PostObservacionEndpoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        toast.error("Ocurrio un error al agregar la observacion, intente nuevamente! "+ action.error.message);
      });
  },
});

export const selectObservaciones = (state) => state.observaciones.entities;
export const selectObservacionesResponse = (state) => state.observaciones.response;
export const selectIsLoading = (state) => state.observaciones.loading;
export const selectIsCreating = (state) => state.observaciones.creating;
export const selectIsEditing = (state) => state.observaciones.editing;
export const selectIsDeleting = (state) => state.observaciones.deleting;
export const selectError = (state) => state.observaciones.error;
export const selectPostObservacion = (state) => state.observaciones.response;

export default observacionesSlice.reducer;

