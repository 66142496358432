import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  createInstitucion,
  deleteInstitucion,
  editInstituciones,
  fetchInstitucion,
  fetchInstitucionById,
  fetchInstitucionTodas,
  searchInstitucion,
  ListarCaracteres,
  ListarDependencias,
} from "./institucion.api";

const initialState = {
  entities: [],
  messageResponse: null,
  entitiesFiltered: [],
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
  caracteres: [],
  dependencias: [],
  institucionById: [],
  entitiesTodas: [],
  searchResults: [],
  response: null,
};

const errorMessage = {
  message: [
    {
      status: 500,
      description: "Se ha producido un error inesperado",
    },
  ],
};

export const getInstituciones = createAsyncThunk(
  "institucion/getInstituciones",
  async (page) => {
    const r = await fetchInstitucion(page);
    return r;
  }
);

export const getInstitucionesTodas = createAsyncThunk(
  "institucion/getInstitucionesTodas",
  async () => {
    const r = await fetchInstitucionTodas();
    return r;
  }
);

export const getInstitucionById = createAsyncThunk(
  "institucion/getInstitucionById",
  async (id) => {
    const r = await fetchInstitucionById(id);
    return r;
  }
);

export const getSearchInstituciones = createAsyncThunk(
  "institucion/getSearchInstituciones",
  async (pBusqueda) => {
    const r = await searchInstitucion(pBusqueda);
    return r;
  }
);

export const createNewInstitucion = createAsyncThunk(
  "institucion/createNewInstitucion",
  async (data) => {
    const r = await createInstitucion(data);
    return r;
  }
);

export const editInstitucion = createAsyncThunk(
  "institucion/editInstitucion",
  async (data) => {
    const r = await editInstituciones(data);
    return r;
  }
);

export const getListarDependencias = createAsyncThunk(
  "institucion/dependencias",
  async () => {
    const r = await ListarDependencias();
    return r;
  }
);

export const getListarCaracteres = createAsyncThunk(
  "institucion/caracteres",
  async () => {
    const r = await ListarCaracteres();
    return r;
  }
);

export const removeInstitucion = createAsyncThunk(
  "institucion/removeInstitucion",
  async (id) => {
    const r = await deleteInstitucion(id);
    return r;
  }
);

export const InstitucionSlice = createSlice({
  name: "institucion",
  initialState,
  reducers: {
    setSearchResults: (state, action) => {
      state.searchResults = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //  getInstituciones (GET Paginado).
      .addCase(getInstituciones.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.response = null;
      })
      .addCase(getInstituciones.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
        state.response = null;
      })
      .addCase(getInstituciones.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })
      //  getInstitucionesTodas.
      .addCase(getInstitucionesTodas.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getInstitucionesTodas.fulfilled, (state, action) => {
        state.loading = false;
        state.entitiesTodas = action.payload.data;
      })
      .addCase(getInstitucionesTodas.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      //  getInstitucionById.
      .addCase(getInstitucionById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getInstitucionById.fulfilled, (state, action) => {
        state.loading = false;
        state.institucionById = action.payload;
      })
      .addCase(getInstitucionById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      //  getSearchInstituciones.
      .addCase(getSearchInstituciones.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSearchInstituciones.fulfilled, (state, action) => {
        state.loading = false;
        state.searchResults = action.payload.data;
      })

      .addCase(getSearchInstituciones.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })

      .addCase(getListarCaracteres.pending, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(getListarCaracteres.fulfilled, (state, action) => {
        state.loading = false;
        state.caracteres = action.payload;
      })
      .addCase(getListarCaracteres.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getListarDependencias.pending, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(getListarDependencias.fulfilled, (state, action) => {
        state.loading = false;
        state.dependencias = action.payload;
      })
      .addCase(getListarDependencias.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(createNewInstitucion.pending, (state) => {
        state.creating = true;
        state.loading = true;
        state.error = null;
      })
      .addCase(createNewInstitucion.fulfilled, (state, action) => {
        state.creating = false;
        state.loading = false;
        state.messageResponse = action.payload;
      })
      .addCase(createNewInstitucion.rejected, (state, action) => {
        state.creating = false;
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(editInstitucion.pending, (state) => {
        state.editing = true;
        state.error = null;
      })
      .addCase(editInstitucion.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(editInstitucion.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error.message;
      })

      .addCase(removeInstitucion.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.response = null;
      })
      .addCase(removeInstitucion.fulfilled, (state, action) => {
        state.loading = false;
        state.response = action.payload;
      })
      .addCase(removeInstitucion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      });
  },
});

export const selectInstituciones = (state) => state.institucion.entities;
export const selectInstitucionById = (state) =>
  state.institucion.institucionById;
export const selectInstitucionTodas = (state) =>
  state.institucion.entitiesTodas;
export const selectIsLoadingInstitucion = (state) => state.institucion.loading;
export const selectCaracteres = (state) => state.institucion.caracteres;
export const selectdependencias = (state) => state.institucion.dependencias;
export const selectSearchResults = (state) => state.institucion.searchResults;
export const { setSearchResults } = InstitucionSlice.actions;
export const selectResponse = (state) => state.institucion.response;

export default InstitucionSlice.reducer;
