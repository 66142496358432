import React, { useContext, useEffect, useState } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  firstListItems,
  secondListItems,
  thirdListItems,
  fourthListItems,
  fifthListItems,
} from "../listItems/listItems";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import { Button, Grid, SwipeableDrawer, useMediaQuery } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import logo from "./Logo Edu.png";
import logoCataGob from "./Ctca-Gobierno.png";
import { useIdRol } from "utils/roles/IdRolContext";
import { TokenContext } from "context/TokenContext";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    height: "100%",
    overflowY: "auto",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme({
  palette: {
    primary: {
      main: "#aeaeae",
    },
    secondary: {
      main: "#311b92",
    },
  },
});

function DashboardContent({ rolName }) {
  const isAuthenticated = localStorage.getItem("token");
  const [open, setOpen] = React.useState(true);
  const [code, setCode] = useState(null);
  const location = useLocation();
  const redirectMiCatamarca = process.env.REACT_APP_API_URL_MI_CATAMARCA_LOGIN
  const redirectPortal = process.env.REACT_APP_API_URL_PORTAL
  const cerrarSesionMobile = process.env.REACT_APP_API_URL_CERRAR_SESION_MOBILE
  const { token, decodedToken, saveToken, removeToken } = useContext(TokenContext);
  const isMobile = useMediaQuery('(max-width:600px)');


  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const codeParam = queryParams.get("code");

    if (!code && !token && codeParam) {
      setCode(codeParam);
      fetchToken(codeParam).then(() => {
        queryParams.delete("code");
        queryParams.delete("state");
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        window.history.replaceState({}, '', newUrl);
        // Elimina el signo de interrogación si no quedan parámetros
        if (!queryParams.toString()) {
          window.history.replaceState({}, '', window.location.pathname);
        }
      });
    } else if (!codeParam && !token) {
      window.location.href = redirectMiCatamarca;
    }
  }, [location.search, token, code]);

  const fetchToken = async (code) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL_LOGIN_DEV}/api/v1/Authentication/Token?authorizationCode=${code}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      saveToken(data.value.access_token);
      localStorage.setItem("refresh_token", data.value.refresh_token);
    } catch (error) {
      console.error('Error al obtener el token:', error);
    }
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("usuario");

    setCode(null);
    //window.location.href = "/";
    if(!isMobile) {
      window.location.href = redirectPortal;
    }else {
      window.location.href = cerrarSesionMobile;
    }
  };

  // if (!isAuthenticated) {
  //   window.location.href = redirectMiCatamarca;
  //   return null;
  // }

  useEffect(() => {
    if (isMobile) {
      setOpen(false);
    }else {
      setOpen(true);
    }
  }, [isMobile]);

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex", height: "100vh" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar
            sx={{
              pr: "24px",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            {!isMobile && (
              <img alt="logo" src={logo}></img>
            )}
            <Typography
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
              style={{
                fontSize: "20px",
                textAlign: "center",
                marginRight: "40px",
              }}
            >
              INSCRIPCIÓN
            </Typography>
            <Button sx={{ ml: 1 }} color="inherit" onClick={handleLogout}>
              Salir
              <DirectionsRunIcon />
            </Button>
          </Toolbar>
        </AppBar>
        {isMobile ? (
          <SwipeableDrawer
            anchor={'left'}
            open={open}
            onClose={toggleDrawer}
            onOpen={toggleDrawer}
            sx={{ zIndex: 1300 }}
          >
            <List component="nav" onClick={toggleDrawer}>
              {/* {firstListItems({ rolName })}
              {secondListItems({ rolName })}
              {thirdListItems({ rolName })} */}
              {fourthListItems({ rolName })}
              {/* {fifthListItems({ rolName })} */}
            </List>
          </SwipeableDrawer>
        ) : (
          <Drawer variant="permanent" open={open}>
            <Toolbar
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                px: [1],
              }}
              style={{ margin: "12px 0px" }}
            >
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <Box
              sx={{
                height: "calc(100vh - 64px)",
                overflowY: "auto",
              }}
            >
              <List component="nav">
                {/* {firstListItems({ rolName })}
              {secondListItems({ rolName })}
              {thirdListItems({ rolName })} */}
                {fourthListItems({ rolName })}
                {/* {fifthListItems({ rolName })} */}
              </List>
            </Box>
          </Drawer>

        )}
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[300]
                : theme.palette.grey[900],
            flexGrow: 1,
            width: "auto",
            overflow: "auto",
          }}
        >
          <Container
            maxWidth={false}
            sx={{
              mt: 12,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {decodedToken && (
              <>
                <p><strong>Hola, </strong> {decodedToken.given_name} {decodedToken.family_name}</p>
              </>
            )}
            {/* <Toolbar /> */}
            <Outlet />


            {/* <Toolbar style={{ backgroundColor: "#004d40", color: "white", width: "100%" }}>
            <Grid container spacing={3}>
              <Grid item xs>
                <span>
                  <img alt="log" src={logoCataGob}></img>
                </span>
              </Grid>
              <Grid item xs={6}>
                <Box
                  style={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  - MINISTERIO DE EDUCACIÓN DE LA PROVINCIA, DIRECCIÓN DE
                  INFORMÁTICA Y TELECOMUNICACIONES -
                </Box>
              </Grid>
              <Grid item xs>
                <Box
                  style={{
                    fontSize: "13px",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    height: "100%",
                  }}
                >
                  © 2024.v1.2
                </Box>
              </Grid>
            </Grid>
          </Toolbar> */}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function
  Dashboard() {
  const { rolName } = useIdRol();
  return <DashboardContent rolName={rolName} />;
}
