import React, { useContext } from "react";
import { useDispatch } from "react-redux";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import CapacitadoresForm from "../CapacitadoresForm/CapacitadoresForm";
import {
  GetCapacitadorEndPoint,
  SaveCapacitadorEndpoint,
} from "store/capacitadores/capacitadores.slice";
import { GetActividadEndPoint } from "store/actividades/actividades.slice";
import { ActividadesContext } from "views/Actividades/ActividadesPage/ActividadesPage";
import { estadoInicial } from "store/Resoluciones/resoluciones.slice";

export default function CapacitadorNuevo({ itemAct }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const page = useContext(ActividadesContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (body) => {
    setOpen(false);
    dispatch(SaveCapacitadorEndpoint(body));

    setTimeout(() => {
      dispatch(GetCapacitadorEndPoint(itemAct?.idActividad || 0)); //Actualizamos Tabla de Capacitadores.
    }, 400);

    setTimeout(() => {
      //Actualizamos Tabla de Actividades
      dispatch(GetActividadEndPoint(page ? page : 1)); //Sabemos en tiempo real la cantidad de Capacitadores
    }, 400); //para una determinada Actividad.
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        style={{ marginLeft: "30px" }}
      >
        Nuevo
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> Agregar Capacitador </DialogTitle>
        <DialogContent>
          <CapacitadoresForm itemAct={itemAct} onSubmitCap={handleSubmit} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
