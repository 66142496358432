import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import { GetResponsablesEndPoint, RemoveCapacitadorEndPoint } from 'store/capacitadores/capacitadores.slice';
import { myPageProyectos } from 'views/ProyectosInicio/ProyectosIPage/ProyectosIPage';
import { getProyectos } from 'store/inicioProyectos/IProyectos.slice';

export default function CapacitadorEliminar({ itemProy, itemResp }) {


  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const page = useContext(myPageProyectos);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);                                    
  };

  const handleSubmit = () => {
    setOpen(false);
    dispatch(RemoveCapacitadorEndPoint(itemResp.idCapacitador));   

    setTimeout(() => {                                                 //Actualizamos Tabla de Capacitadores, para    
      dispatch(GetResponsablesEndPoint(itemProy?.idProyecto || 0));     //una determinada Actividad.
    }, 400);

    setTimeout(() => {                                                 //Actualizamos Tabla de Proyectos
      dispatch(getProyectos(page? page : 1));                          //Sabemos en tiempo real la cantidad de Responsables              
    }, 400);                                                           //para un determinado Proyecto. 
  };

  return (
    <React.Fragment>
      <IconButton variant="outlined" onClick={handleClickOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog open={open}>
        <DialogTitle> Eliminar Integrante </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Estás seguro que deseas desestimar el integrante <b>"{itemResp?.persona?.apellidoyNombre}"</b> de este Proyecto?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}> Cancelar </Button>
          <Button onClick={handleSubmit} autoFocus> Aceptar </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
