import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  GetPeriodoXIdPeriodoYIdActividad,
  createPeriodo,
  deletePeriodo,
  editPeriodo,
  fechPeriodo,
  fechTipoPeriodo,
  fetchPeriodoById,
} from "./periodo.api";

const initialState = {
  entities: [],
  messageResponse: null,
  entitiesFiltered: null, // La entrada original es un objeto {}.
  tipoPeriodo: [],
  editLoading: false,
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
  personaById: [],
};

export const getPeriodos = createAsyncThunk(
  "periodo/getPeriodos",
  async (page) => {
    const r = await fechPeriodo(page);
    return r;
  }
);
export const getTipoPeriodos = createAsyncThunk(
  "periodo/getTipoPeriodos",
  async () => {
    const r = await fechTipoPeriodo();
    return r;
  }
);

export const createNewPeriodo = createAsyncThunk(
  "periodo/createNewPeriodo",
  async (data) => {
    const r = await createPeriodo(data);
    return r;
  }
);
export const editPeriod = createAsyncThunk(
  "periodo/editPeriod",
  async (data) => {
    const r = await editPeriodo(data);
    return r;
  }
);

export const getPeriodoById = createAsyncThunk(
  "periodo/getPeriodoByID",
  async (id) => {
    const r = await fetchPeriodoById(id);
    return r;
  }
);

export const removePeriodo = createAsyncThunk(
  "periodo/removePeriodo",
  async (id) => {
    const r = await deletePeriodo(id);
    return r;
  }
);

export const GetPeriodoXIdPeriodoYIdActividadEndpoint = createAsyncThunk(
  "periodo/GetPeriodoXIdPeriodoYIdActividad",
  async ({idTipoPeriodo, idActividad}) => {
    const r = await GetPeriodoXIdPeriodoYIdActividad(idTipoPeriodo, idActividad);
    return r;
  }
);
export const PeriodoSlice = createSlice({
  name: "periodos",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPeriodos.pending, (state) => {
        // pendiente.
        state.loading = true;
        state.error = null;
      })
      .addCase(getPeriodos.fulfilled, (state, action) => {
        // cumplida.
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addCase(getPeriodos.rejected, (state, action) => {
        // rechazado.
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getTipoPeriodos.pending, (state) => {
        // pendiente.
        state.loading = true;
        state.error = null;
      })
      .addCase(getTipoPeriodos.fulfilled, (state, action) => {
        // cumplida.
        state.loading = false;
        state.tipoPeriodo = action.payload;
      })
      .addCase(getTipoPeriodos.rejected, (state, action) => {
        // rechazado.
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getPeriodoById.pending, (state) => {
        // pendiente.
        state.editLoading = true;
        state.error = null;
      })
      .addCase(getPeriodoById.fulfilled, (state, action) => {
        // cumplida.
        state.editLoading = false;
        state.personaById = action.payload;
      })
      .addCase(getPeriodoById.rejected, (state, action) => {
        // rechazado.
        state.editLoading = false;
        state.error = action.error.message;
      })
      .addCase(createNewPeriodo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNewPeriodo.fulfilled, (state, action) => {
        state.loading = false;
        state.messageResponse = action.payload.message;
      })
      .addCase(createNewPeriodo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(editPeriod.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editPeriod.fulfilled, (state, action) => {
        state.loading = false;
        state.editPeriods = action.payload;
      })
      .addCase(editPeriod.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(removePeriodo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removePeriodo.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(removePeriodo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(GetPeriodoXIdPeriodoYIdActividadEndpoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetPeriodoXIdPeriodoYIdActividadEndpoint.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(GetPeriodoXIdPeriodoYIdActividadEndpoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const selectPeriodos = (state) => state.periodo.entities;
export const selectTipoPeriodos = (state) => state.periodo.tipoPeriodo;
export const selectPeriodoById = (state) => state.periodo.personaById;
export const selectIsLoadingPeriodo = (state) => state.periodo.loading;
export const selectIsLoadingEdit = (state) => state.periodo.editLoading;

export default PeriodoSlice.reducer;
