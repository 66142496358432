import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetResolucion } from './Resolucion.api';

const initialState = {
  entities: {},
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
};

export const GetResolucionEndpoint = createAsyncThunk(
  'resolucion/GetResolucion',
  async (id) => {
    const r = await GetResolucion(id);
    return r;
  }
);

export const resolucionSlice = createSlice({
  name: 'resolucion',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetResolucionEndpoint.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetResolucionEndpoint.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(GetResolucionEndpoint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});

export const selectResolucion = (state) => state.resolucion.entities;
export const selectIsLoading = (state) => state.resolucion.loading;
export const selectIsCreating = (state) => state.resolucion.creating;
export const selectIsEditing = (state) => state.resolucion.editing;
export const selectIsDeleting = (state) => state.resolucion.deleting;
export const selectError = (state) => state.resolucion.error;

export default resolucionSlice.reducer;
