import { request } from "utils/helpers/request.helpers";

const urlPreinscripcion = `${process.env.REACT_APP_API_URL_DEV}/api/inscripcion`;

export function GetInscripcion(page) {
  const url = `${urlPreinscripcion}/GetInscripcion?page=${page}`;
  return request(url, { method: 'GET' });
}

export function GetInscripcionById(page, idActividad) {
  const url = `${urlPreinscripcion}/GetInscripcionById?page=${page}&idActividad=${idActividad}`;
  return request(url, { method: 'GET' });
}

export function GetAllInscripcion() {
  const url = `${urlPreinscripcion}/GetAllInscripcion`;
  return request(url, { method: 'GET' });
}


export function PutUnInscribir(idPreinscripcion, page) {
  return request(`${urlPreinscripcion}/PutUnInscribir?idPreinscripcion=${idPreinscripcion}&page=${page}`, {
    method: 'PUT',
  });
}

export function SaveInscripcion(data) {
  return request(`${urlPreinscripcion}/SaveInscripcion`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function ConfirmarInscriptos(idActividad) {
  return request(`${urlPreinscripcion}/ConfirmarInscriptos?idActividad=${idActividad}`, {
    method: "POST",
  });
}