import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SnackBarUtils from "utils/MUI/SnackbarUtils";
import StairsIcon from "@mui/icons-material/Stairs";
import IconButton from "@mui/material/IconButton";

import NivelesEnsenanzaNuevo from "../NivelesEnsenanzaNuevo/NivelesEnsenanzaNuevo";
import NivelesEnsenanzaTable from "../NivelesEnsenanzaTable/NivelesEnsenanzaTable";
import NivelesEnsenanzaTituloModal from "../NivelesEnsenanzaTituloModal";
import {
  getProyectosConNiveles,
  selectIsLoading,
  selectProyectosConNiveles,
} from "store/proyectos/proyectos.slice";
import { CircularProgress, DialogContent, Tooltip } from "@mui/material";
import CustomModal from "components/customModal/CustomModal";

const NivelesEnsenanzaPage = ({ itemProy, colorResp }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const GetNiveles = useSelector(selectProyectosConNiveles);
  const nivelesIsLoading = useSelector(selectIsLoading);
  //const [message, setMessage] = useState("");
  const [view, setView] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    dispatch(getProyectosConNiveles(itemProy?.idProyecto || 0)); // No lleva paginación.
  };

  const handleClose = () => {
    setOpen(false);
  };

  const muestroTabla = () => {
    if (GetNiveles?.data?.length >= 0) {
      return (
        <NivelesEnsenanzaTable
          itemProy={itemProy}
          datosNiveles={GetNiveles?.data || []}
        />
      );
    } else {
      return (
        <p
          style={{
            textAlign: "center",
            marginBottom: "30px",
          }}
        >
          {" "}
          Sin Niveles de Enseñanza.{" "}
        </p>
      );
    }
  };

  return (
    <React.Fragment>
      <Tooltip title="Niveles de Enseñanza">
        <IconButton variant="outlined" onClick={handleClickOpen}>
          <StairsIcon style={{ color: colorResp }} />
        </IconButton>
      </Tooltip>
      <CustomModal title={"Niveles de Enseñanza"} open={open} setOpen={setOpen}>
        <DialogContent>
          {nivelesIsLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress color="inherit" />
            </div>
          ) : (
            <>
              <NivelesEnsenanzaTituloModal itemProy={itemProy || []} />
              {/* <div style={{ marginBottom: "15px" }} /> */}
              {muestroTabla()}
              <NivelesEnsenanzaNuevo
                itemProy={itemProy}
                handleConteinerClose={handleClose}
              />
            </>
          )}
        </DialogContent>
      </CustomModal>
      <SnackBarUtils
        closed={(value) => setView(value)}
        //message={message}
        view={view}
      />
    </React.Fragment>
  );
};

export default NivelesEnsenanzaPage;
