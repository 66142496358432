import { CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetActividadByIdEndpoint, selectActividades } from "store/actividades/actividades.slice";
import { GetPeriodoXIdPeriodoYIdActividadEndpoint, selectPeriodos } from "store/periodos/periodo.slice";
import {
  PutPreInscribirAllEndpoint,
  PutPreInscribirRemoveAllEndpoint,
  selectAllPreInscripcion,
} from "store/preInscripcion/preInscripcion.slice";
import { formatDDMMYY } from "utils/helpers/dateFormat";
import { useIdRol } from "utils/roles/IdRolContext";

export default function PreInscripcionInfo({ preInscripciones, search, hayInsc, periodo }) {

  //console.log('periodo', periodo)

  const [info, setInfo] = useState(null);
  const [checked, setChecked] = useState(false);
  const allPreInscripcion = useSelector(selectAllPreInscripcion);
  const Dispatch = useDispatch();
  const [idPreinscripcion, setIdPreInscripcion] = useState(null);
  const [loading, setLoading] = useState(true);
  const actividadById = useSelector(selectActividades);
  const handleInscribirRemoveAll = async (idPreinscripcion) => {
    Dispatch(PutPreInscribirRemoveAllEndpoint({ idPreinscripcion, page: 1 }));
    setLoading(true);
  };
  const handleInscribirAll = async (idPreinscripcion) => {
    Dispatch(PutPreInscribirAllEndpoint({ idPreinscripcion, page: 1 }));
    setLoading(true);
  };
  const { rolName } = useIdRol();

  useEffect(() => {
    if (search)
      Dispatch(GetActividadByIdEndpoint(search.id))
  }, [search])

  useEffect(() => {
    if (allPreInscripcion && search && actividadById.data) {
      Dispatch(GetPeriodoXIdPeriodoYIdActividadEndpoint({ idTipoPeriodo: 1, idActividad: search.id }))
      let obj = allPreInscripcion?.data?.find(
        (x) => x.actividad.idActividad == search.id
      );
      setIdPreInscripcion(obj?.idPreinscripcion);
      let actividad = allPreInscripcion?.data?.filter(
        (x) => x.actividad.idActividad === obj?.actividad?.idActividad
      );

      let preInscriptos = actividad.length;
      let cupoMaximo = actividadById.data.cupo == 0 ? -1 : actividadById.data.cupo ?? 0;
      let inscriptos = actividad.filter((x) => x.inscribir === 1).length;

      setInfo({ preInscriptos, cupoMaximo, inscriptos });
      setChecked(inscriptos >= cupoMaximo);
    }
    setLoading(false);
  }, [allPreInscripcion, search, actividadById]);

  const style1 = {
    whiteSpace: "noWrap",
    margin: "10px 20px 7px 20px",
    fontWeight: "600",
    fontSize: "18px"
  };

  const style2 = {
    margin: "0px 20px",
    fontWeight: "600",
    fontSize: "18px",
  };

  const buttonStyle = {
    //display: "inline-flex",
    justifyContent: "space-between",
    backgroundColor: "#004d40",
    borderRadius: "5px",
    cursor: "pointer",
    color: "white",
    margin: "0px 8px 10px 8px",
  };

  const recuadro = {
    backgroundColor: "lightgray",
    borderRadius: "5px"
  }
  return (
    <>
      {search && (
        <>
          {rolName !== "docente" &&
            <>
              <span style={style1}>Cantidad Pre-Inscriptos: {info?.preInscriptos}</span>
              <span style={style1}>Cupo Máximo: {info?.cupoMaximo == -1 ? "Sin límites" : info?.cupoMaximo}</span>
              <span style={style1}>Cantidad Inscriptos: {info?.inscriptos}</span>
              <div style={{ marginBottom: "10px" }}>
                <span onClick={() => handleInscribirAll(idPreinscripcion)}>
                  {loading ?
                    <CircularProgress size={20} color="inherit" />
                    :
                    <button style={{ ...buttonStyle, padding: "10px", border: 'none' }} disabled={!hayInsc}>
                      {info?.cupoMaximo == -1 ? "INSCRIBIR TODOS LOS PRE-INSCRIPTOS"
                        :
                        "INSCRIBIR LOS PRIMEROS " + info?.cupoMaximo + " PRE-INSCRIPTOS (Cupo Max.)"}
                    </button>
                    /* ${info?.inscriptos} / ${info?.cupoMaximo == -1 ? "∞" : info?.cupoMaximo} */
                  }
                </span>
                <button
                  disabled={!hayInsc}
                  style={{ ...buttonStyle, padding: "10px", backgroundColor: "rgb(130,130,130)", border: 'none' }}
                  onClick={() => handleInscribirRemoveAll(idPreinscripcion)}
                >
                  DESINCRIBIR TODOS
                </button>
              </div>
            </>
          }
          {periodo != null ?
            <div style={{ ...recuadro, textAlign: "center", marginTop: "10px" }}>
              <p style={{ ...style2, margin: "5px", display: "inline-block" }}>
                Fecha inicio Preinsc: {formatDDMMYY(periodo.fechaInicio)}
              </p>
              <span style={style2}>
                Fecha fin Preinsc: {formatDDMMYY(periodo.fechaFin)}
              </span>
            </div>
            :
            <p style={{ ...style2, margin: "10px 0px 0px 0px", padding: "5px", textAlign: "center", ...recuadro }}>
              {"Sin "}
              <span style={{ textDecoration: "underline" }}>
                Período de Pre-inscripción
              </span>
              {" asignado, consulta al Capacitador."}
            </p>
          }
        </>
      )}
    </>
  );
}
