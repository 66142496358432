import React, { createContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {
  getAllCertificate,
  getAllTypeCertificate,
  selectAllCertificados,
  selectCreating,
  selectDeleting,
  selectTipeCert,
} from "store/certificados/certificado.slice";
import Loader from "components/Loader/Loader";
import { GenericTemplate } from "template/GenericTemplate";
import { Button, Checkbox } from "@mui/material";
import ImportAction from "./tableActions/ImportAction";
import {
  getAlumnosByACT,
  loadingAlumno,
  selectByAct,
} from "store/Alumnos/alumnos.slice";
import { GetActividadesXProyectoEndPoint } from "store/actividades/actividades.slice";
import {
  getProyectosConResol,
  selectProyectosConResol,
} from "store/proyectos/proyectos.slice";
import { FilterFields } from "./FilterFields";
import {
  confirmateCertificate,
  handleCertificar,
} from "./functions/handleCertificar";
import { useLocation } from "react-router-dom";
import { useIdRol } from "utils/roles/IdRolContext";
import "./certifiicado-styles.css";
import PreInsForm from "views/PrePreinsc/PreInsForm";

export const myCertificacionPersona = createContext(1);

export default function PreInscripcionPage() {
  const dispatch = useDispatch();
  let [page, setPage] = React.useState(1);
  const [cahngeCerto, setCahngeCerto] = useState(false);
  const [certificateState, setCertificateState] = useState([]);

  const [actividadData, setActividadData] = useState(null);
  const [convertCertificado, setConvertCertificado] = useState(null);
  const { rolName } = useIdRol();
  const [proyecto, setProyecto] = useState(null);
  const [actividad, setActividad] = useState(null);

  const location = useLocation();

  const path = location.pathname;

  const isAlumnno = path === "/certificados/certificados";

  //Loadings
  const creating = useSelector(selectCreating);
  const deleting = useSelector(selectDeleting);

  const loadding = deleting || deleting;

  //finLoadings
  // useEffect(() => {
  //   dispatch(getAlumnosByACT({ actividadData, page }));
  //   dispatch(getAllCertificate());
  // }, [page, dispatch, creating, deleting, actividadData]);

  useEffect(() => {
    // dispatch(getAllTypeCertificate());
    dispatch(getProyectosConResol());
    // dispatch(getAllCertificate());
  }, []);

  const handleChangeProyectos = (idProyecto) => {
    if (!idProyecto) return;
    dispatch(GetActividadesXProyectoEndPoint(idProyecto));
  };

  const handleGetAlumnos = (actividadData) => {
    setActividadData(actividadData);
    setActividad(null);
    dispatch(getAlumnosByACT({ actividadData, page }));
  };

  //Trae alumnos por proyecto
  const fetchAlumnos = useSelector(selectByAct);

  const loadingAlumnos = useSelector(loadingAlumno);
  //Trae proyectos
  const fetchProyecto = useSelector(selectProyectosConResol);
  //trae tipo de certificado
  const fetchTypoCert = useSelector(selectTipeCert);
  //trae todos los certificados disponibles
  const fetchAllCertificate = useSelector(selectAllCertificados);
  const loadingCertificado = useSelector(loadingAlumno);
  const alumnos = fetchAlumnos.records ? fetchAlumnos.records : [];  

  if (loadingCertificado || loadingAlumnos) {
    return <Loader />;
  }

  const handleChange = (event, values) => {
    setPage(values);
  };

  return (
    <div className="container-cer">
      <myCertificacionPersona.Provider value={page}>
        <React.Fragment>
          <div style={{ display: "flex", alignItems: "left" }}>
            <h1 style={{ margin: "10px 0" }}>
              {"Realizar Pre-Inscripción"}
            </h1>
          </div>
          <div className="component-container-cer">
            <div>
              <FilterFields
                dataProyecto={fetchProyecto}
                handleChangeProyectos={handleChangeProyectos}
                handleGetAlumnos={handleGetAlumnos}
                proyecto={proyecto}
                actividad={actividad}
                setProyecto={setProyecto}
                setActividad={setActividad}
                setActividadData={setActividadData}
                setPage={setPage}
              />
            </div>
            <div>
              {actividad !== null &&
                <PreInsForm proyectoId={proyecto.idProyecto} actividadId={actividad.idActividad} />
              }
            </div>
          </div>         
        </React.Fragment>
      </myCertificacionPersona.Provider>
    </div>
  );
}
