// counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

const mensajeriaSlice = createSlice({
  name: 'mensajeria',
  initialState: {
    view: false,
    message: [],
  },
  reducers: {
    showSnackbar: (state, action) => {
      state.view = true;
      state.message = action.payload
    },
    hiddenSnackbar: (state) => {
      state.view = false;
      state.message = [];
    },
  },
});

export const { showSnackbar, hiddenSnackbar } = mensajeriaSlice.actions;
export default mensajeriaSlice.reducer;
