import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DialogContent from "@mui/material/DialogContent";

import {
  EditActividadEndPoint,
  FiltrarActividadEndPoint,
  selectActividadesResponse,
} from "store/actividades/actividades.slice";
import ActividadesForm from "../ActividadesNuevo/ActividadesForm";
import CustomModal from "components/customModal/CustomModal";
import { Tooltip } from "@mui/material";

export default function ActividadEditar({ item }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const sActividadesResponse = useSelector(selectActividadesResponse);

  useEffect(() => {
    if (sActividadesResponse?.data?.success) {
      setOpen(false);
      dispatch(FiltrarActividadEndPoint());
    }
  }, [dispatch, sActividadesResponse]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (data) => {
    dispatch(EditActividadEndPoint({ data }));
  };

  return (
    <React.Fragment>
      <Tooltip title="Modificar">
        <IconButton variant="outlined" onClick={handleClickOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <CustomModal
        title={"Editar Actividad"}
        open={open}
        setOpen={handleClose}
        maxWidth="sm"
      >
        <DialogContent>
          <ActividadesForm
            data={item}
            onSubmit={handleSubmit}
            onClose={handleClose}
          />
        </DialogContent>
      </CustomModal>
    </React.Fragment>
  );
}
