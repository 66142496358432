import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  DeleteCertificate,
  aprovateCertApi,
  confirmCertApi,
  createCertificateApi,
  getAllCertificateApi,
  getAllTipoCertificados,
  getCertificado,
  getCertificadoByAlumnoAndAct,
} from "./certificado.api";

const initialState = {
  entities: {},
  response: {},
  tipeCert: [],
  allCertificate: [],
  loadingCreate: false,
  loadingDelete: false,
  certificateByAlumno: null,
  idCertificate: null,
  loading: false,
  editing: false,
  deleting: false,
  error: null,
};

export const getCertificate = createAsyncThunk(
  "certificado/GetCertificado",
  async (Id) => {
    const r = await getCertificado(Id);
    return r;
  }
);

export const getAllTypeCertificate = createAsyncThunk(
  "certificado/AllTipoCertificados",
  async (Id) => {
    const r = await getAllTipoCertificados();
    return r;
  }
);
export const getCertificateByID = createAsyncThunk(
  "certificado/getCertificateByID",
  async (body) => {
    const r = await getCertificadoByAlumnoAndAct(body);
    return r;
  }
);

export const getAllCertificate = createAsyncThunk(
  "certificado/getAllCertificateApi",
  async () => {
    const r = await getAllCertificateApi();
    return r;
  }
);

export const createCertificate = createAsyncThunk(
  "actividades/createCertificate",
  async (body) => {
    const r = await createCertificateApi(body);
    return r;
  }
);
export const aprovateCert = createAsyncThunk(
  "actividades/aprovateCert",
  async (body) => {
    const r = await aprovateCertApi(body);
    return r;
  }
);

export const confirmCert = createAsyncThunk(
  "actividades/confirmCert",
  async (body) => {
    const r = await confirmCertApi(body);
    return r;
  }
);

export const handleDeleteCertificate = createAsyncThunk(
  "actividades/DeleteActividad",
  async (idCertificate) => {
    const r = await DeleteCertificate(idCertificate);
    return r;
  }
);

export const certificadosSlice = createSlice({
  name: "certificados",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // GetActividadEndPoint

      .addCase(getAllCertificate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllCertificate.fulfilled, (state, action) => {
        state.loading = false;
        state.allCertificate = action.payload;
      })
      .addCase(getAllCertificate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getCertificate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCertificate.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(getCertificate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getCertificateByID.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCertificateByID.fulfilled, (state, action) => {
        state.loading = false;
        state.certificateByAlumno = action.payload;
      })
      .addCase(getCertificateByID.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getAllTypeCertificate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllTypeCertificate.fulfilled, (state, action) => {
        state.loading = false;
        state.tipeCert = action.payload;
      })
      .addCase(getAllTypeCertificate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createCertificate.pending, (state) => {
        state.loadingCreate = true;
        state.error = null;
      })
      .addCase(createCertificate.fulfilled, (state, action) => {
        state.loadingCreate = false;
        state.idCertificate = action.payload;
      })
      .addCase(createCertificate.rejected, (state, action) => {
        state.loadingCreate = false;
        state.error = action.error.message;
      })
      .addCase(handleDeleteCertificate.pending, (state) => {
        state.loadingDelete = true;
        state.error = null;
      })
      .addCase(handleDeleteCertificate.fulfilled, (state, action) => {
        state.loadingDelete = false;
        state.idCertificate = action.payload;
      })
      .addCase(handleDeleteCertificate.rejected, (state, action) => {
        state.loadingDelete = false;
        state.error = action.error.message;
      })
      .addCase(aprovateCert.pending, (state) => {
        state.loadingCreate = true;
        state.error = null;
      })
      .addCase(aprovateCert.fulfilled, (state, action) => {
        state.loadingCreate = false;
      })
      .addCase(aprovateCert.rejected, (state, action) => {
        state.loadingCreate = false;
        state.error = action.error.message;
      })
      .addCase(confirmCert.pending, (state) => {
        state.loadingCreate = true;
        state.error = null;
      })
      .addCase(confirmCert.fulfilled, (state, action) => {
        state.loadingCreate = false;
      })
      .addCase(confirmCert.rejected, (state, action) => {
        state.loadingCreate = false;
        state.error = action.error.message;
      });
  },
});

export const selectCertificados = (state) => state.certificado.entities;
export const selectAllCertificados = (state) =>
  state.certificado.allCertificate;
export const selectActividadesResponse = (state) => state.certificado.response;
export const selectCertificateByAlumno = (state) =>
  state.certificado.certificateByAlumno;
export const selectTipeCert = (state) => state.certificado.tipeCert;
export const selectCreating = (state) => state.certificado.loadingCreate;
export const selectDeleting = (state) => state.certificado.loadingDelete;
export const selectIsLoading = (state) => state.certificado.loading;
export const selectIsEditing = (state) => state.certificado.editing;
export const selectIsDeleting = (state) => state.certificado.deleting;
export const selectError = (state) => state.certificado.error;

export default certificadosSlice.reducer;
