import { request } from 'utils/helpers/request.helpers';

const nivelesEnsenanzaUrl = `${process.env.REACT_APP_API_URL_DEV}/api/NivelesEnsenanza`;

//-------- Lista Completa de Niveles de Enseñanza

export function GetListaCompleta() {
  const url = `${nivelesEnsenanzaUrl}/ListaCompleta`;
  return request(url, { method: 'GET' });
}

//-------- Capacitadores de Actividad (Disertantes).

/* export function GetCapacitadorXActividad(idActividad) {
  const url = `${capacitadoresUrl}/GetCapacitadores_ByID_Actividad?pIdActividad=${idActividad}`;
  return request(url, { method: 'GET' });
}
 */
//-------- CREATE, UPDATE y DELETE para Proyectos y para Actividades.

/* export function SaveCapacitador(body) {
  return request(`${capacitadoresUrl}/InsertarCapacitador`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(body),
  });
}
 */
/* export function UpdateCapacitador({ data }) {
  return request(`${capacitadoresUrl}/ModificarCapacitador`, {
    method: 'PUT',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(data),
  });
}
 */
/*   export function DeleteCapacitador(id) {
    return request(`${capacitadoresUrl}/Deshabilitar?pIdCapacitador=${id}`, {
    method: 'PUT',
  });
}
 */