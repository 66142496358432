import React, { useContext } from "react";
import { useDispatch } from "react-redux";

import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";

import {
  GetIdResoluciones,
  SaveResolucionesEndpoint,
} from "store/Resoluciones/resoluciones.slice";
import { getProyectos } from "store/inicioProyectos/IProyectos.slice";
import { myPageProyectos } from "../../ProyectosInicio/ProyectosIPage/ProyectosIPage";
import { showSnackbar } from "store/mensajeria/mensajeriaSlice";
import CustomModal from "components/customModal/CustomModal";
import ResolucionForm from "../ResolucionForm/ResolucionForm";
import { Stack } from "@mui/material";

export default function ResolucionNuevo({
  itemAct,
  handleConteinerClose,
  isNuevo,
}) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const page = useContext(myPageProyectos);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (data) => {
    setOpen(false);
    dispatch(SaveResolucionesEndpoint(data)).then((resultAction) => {
      if (SaveResolucionesEndpoint.fulfilled.match(resultAction))
        dispatch(showSnackbar(resultAction.payload.message));
    });

    setTimeout(() => {
      dispatch(GetIdResoluciones(itemAct?.idProyecto || 0)); //Actualizamos Tabla de Capacitadores.
    }, 400);

    setTimeout(() => {
      //Actualizamos Tabla de Actividades
      dispatch(getProyectos(page ? page : 1)); //Sabemos en tiempo real la cantidad de Capacitadores
    }, 400); //para una determinada Actividad.
  };

  return (
    <React.Fragment>
      <Stack
        spacing={2}
        direction="row"
        sx={{ display: "flex", justifyContent: "flex-end", mt: 3, mb: 2 }}
      >
        <Button
          variant="outlined"
          color="error"
          onClick={handleConteinerClose}
          spacing={2}
        >
          Cancelar
        </Button>
        {isNuevo && (
          <Button variant="contained" onClick={handleClickOpen}>
            Nuevo
          </Button>
        )}
      </Stack>
      <CustomModal
        title={"Agregar Resolución"}
        open={open}
        setOpen={handleClose}
        maxWidth="sm"
      >
        <DialogContent>
          <ResolucionForm
            data={itemAct}
            onClose={handleClose}
            onSubmit={handleSubmit}
          />
        </DialogContent>
      </CustomModal>
    </React.Fragment>
  );
}
