import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  fetchTiposDePeriodo,
  fetchTiposDePeriodoById,
} from "./tiposDePeriodo.api";

import { any } from "prop-types";

const initialState = {
  entities: [],
  entity: null,  
  messageResponse: null,
  editLoading: false,
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,  
};

export const getTiposDePeriodo = createAsyncThunk(
  "tiposDePeriodo/GetTiposDePeriodo",
  async () => {
    const r = await fetchTiposDePeriodo();
    return r;
  }
);

export const getTiposDePeriodoById = createAsyncThunk(
  "tiposDePeriodo/getTiposDePeriodoById",
  async (id) => {
    const r = await fetchTiposDePeriodoById(id);
    return r;
  }
);

const TiposDePeriodoSlice = createSlice({
  name: "tiposDePeriodo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //#region GetTiposDePeriodo
      .addCase(getTiposDePeriodo.pending, (state) => {
        // pendiente.
        state.editLoading = true;
        state.error = null;
      })
      .addCase(getTiposDePeriodo.fulfilled, (state, action) => {
        // cumplida.
        state.entities = action.payload;
        state.editLoading = false;
      })
      .addCase(getTiposDePeriodo.rejected, (state, action) => {
        // rechazado.
        state.editLoading = false;
        state.error = action.error.message;
      })
      //#endregion
      //#region GetTiposDePeriodoById
      .addCase(getTiposDePeriodoById.pending, (state) => {
        // pendiente.
        state.editLoading = true;
        state.error = null;
      })
      .addCase(getTiposDePeriodoById.fulfilled, (state, action) => {
        // cumplida.
        state.entities = action.payload;
        state.editLoading = false;
      })
      .addCase(getTiposDePeriodoById.rejected, (state, action) => {
        // rechazado.
        state.editLoading = false;
        state.error = action.error.message;
      });
    //#endregion
  },
});

export const sTiposDePeriodo = (state) => state.tiposDePeriodo.entities;
export const stpIsLoading = (state) => state.tiposDePeriodo.loading;
export const stpIsEditLoading = (state) => state.tiposDePeriodo.editLoading;
export const stpError = (state) => state.tiposDePeriodo.error;

export default TiposDePeriodoSlice.reducer;
