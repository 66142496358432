import React from "react";
import { useDispatch } from "react-redux";
import { IconButton, Box, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import ProyectosIForm from "./ProyectosIForm";
import { getProyectoById } from "store/inicioProyectos/IProyectos.slice";
import CustomModal from "components/customModal/CustomModal";

export default function ProyectosIEditar({ itemProy }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
    dispatch(getProyectoById(itemProy.idProyecto));
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick" && "escapeKeyDown") return;
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Tooltip title="Modificar">
        <IconButton variant="outlined" onClick={handleClickOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <CustomModal title={"Editar Proyecto"} open={open} setOpen={handleClose}>
        <DialogContent>
          <ProyectosIForm itemProy={itemProy} onClose={handleClose} />
        </DialogContent>
      </CustomModal>
    </React.Fragment>
  );
}
