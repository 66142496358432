import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { EditCapacitadorEndPoint } from "store/capacitadores/capacitadores.slice";
import { GetResponsablesEndPoint } from "store/capacitadores/capacitadores.slice";
import { getProyectos } from "store/inicioProyectos/IProyectos.slice";
import { myPageProyectos } from "views/ProyectosInicio/ProyectosIPage/ProyectosIPage";
import { showSnackbar } from "store/mensajeria/mensajeriaSlice";
import ResponsablesForm from "../ResponsablesForm/ResponsablesForm";
import CustomModal from "components/customModal/CustomModal";

export default function CapacitadorEditar({ itemProy, itemResp }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const page = useContext(myPageProyectos);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmitAct = (data) => {
    setOpen(false);
    dispatch(EditCapacitadorEndPoint({ data })).then((resultAction) => {
      if (EditCapacitadorEndPoint.fulfilled.match(resultAction))
        dispatch(showSnackbar(resultAction.payload.message));
    });

    setTimeout(() => {
      dispatch(GetResponsablesEndPoint(itemProy?.idProyecto || 0)); //Actualizamos Tabla de Capacitadores.
    }, 400);

    setTimeout(() => {
      //Actualizamos Tabla de Actividades
      dispatch(getProyectos(page ? page : 1)); //Sabemos en tiempo real la cantidad de Capacitadores
    }, 400); //para una determinada Actividad.
  };

  return (
    <React.Fragment>
      <IconButton variant="outlined" onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <CustomModal
        title={"Editar Integrante"}
        open={open}
        setOpen={handleClose}
        maxWidth="sm"
      >
        <DialogContent>
          <ResponsablesForm
            data={{
              ...itemProy,
              idResponsabilidad: itemResp?.idResponsabilidad,
              idCapacitador: itemResp?.idCapacitador,
              idActividad: itemResp?.idActividad,
              persona: itemResp?.persona,
              idCausaBaja: itemResp?.idCausaBaja,
            }}
            onSubmit={handleSubmitAct}
            onClose={handleClose}
          />
        </DialogContent>
      </CustomModal>
    </React.Fragment>
  );
}
