import { Button, Container, Pagination, Paper, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAllPreInscripcionEndPoint,
  GetPreInscripcionEndpoint,
  selectAllPreInscripcion,
  selectPreInscribir,
  selectPreInscripcion,
} from "store/preInscripcion/preInscripcion.slice";
import SnackBarUtils from "utils/MUI/SnackbarUtils";
import { SnackBarMessageResponse } from "utils/Response/SnackBarMessageResponse";
import InscripcionForm from "./InscripcionForm";
import InscripcionInfo from "./InscripcionInfo";
import InscripcionSearch from "./InscripcionSearch";
import InscripcionTable from "./InscripcionTable";
import {
  GetAllInscripcionEndpoint,
  GetInscripcionByIdEndpoint,
  GetInscripcionEndpoint,
  selectAllInscripcion,
  selectInscribir,
  selectInscripcion,
} from "store/Inscripcion/Inscripcion.slice";
import { ActividadesAprobadasAll } from "store/actividades/actividades.slice";

const Inscripcion = () => {
  const [message, setMessage] = useState("");
  const [view, setView] = useState(false);
  const Dispatch = useDispatch();
  const preInscribir = useSelector(selectInscribir);
  const preInscripcion = useSelector(selectInscripcion);
  const allPreInscripcion = useSelector(selectAllInscripcion);
  const [actividades, setActividades] = useState([]);
  const [search, setSearch] = useState(null);
  const [lastSearch, setLastSearch] = useState(null);
  const [preInscripcionState, setPreInscripcionState] = useState(null);
  const [page, setPage] = useState(1);
  const [viewMessage, setViewMessage] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    Dispatch(GetInscripcionEndpoint(page));
    Dispatch(ActividadesAprobadasAll());
    setViewMessage(false);
  }, []);

  useEffect(() => {
    //Este hook se va para el search
    setPreInscripcionState(preInscripcion.data?.records);
  }, [preInscripcion]);

  useEffect(() => {
    Dispatch(GetAllInscripcionEndpoint());
  }, [preInscribir]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleFilter = (actividad) => {
    setSearch(actividad);
    setPage(1);
    //Si el filtro es null, entonces peticionamos al backend para obtener el listado nuevamente.
    if (actividad === null) Dispatch(GetInscripcionEndpoint(1));
    else
      Dispatch(
        GetInscripcionByIdEndpoint({ page: page, actividad: actividad.id })
      );
  };

  useEffect(() => {
    if (search !== null)
      Dispatch(
        GetInscripcionByIdEndpoint({ page: page, actividad: search.id })
      );
    else Dispatch(GetInscripcionEndpoint(page));
  }, [page]);

  const handleChange = (event, values) => {
    setPage(values);
  };

  const handleChangeTable = (value) => {
    if (search !== null)
      Dispatch(
        GetInscripcionByIdEndpoint({ page: page, actividad: search.id })
      );
    else Dispatch(GetInscripcionEndpoint(page));
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex", alignItems: "left" }}>
        <h1 style={{ margin: "10px 0" }}>Inscripción</h1>
      </div>
      <Paper
        style={{
          paddingLeft: 10,
          paddingRight: 10,
          paddingBottom: 2,
          paddingTop: 20,
          backgroundColor: "#FFFFFF",
          borderRadius: 0,
        }}
      >
        <InscripcionSearch
          actividad={actividades}
          searchResponse={(search) => handleFilter(search)}
        />
        {search != null && (
          <Button variant="contained" onClick={() => setOpenModal(true)} style={{ marginBottom: "10px" }}>
            Nuevo
          </Button>
        )}
        <InscripcionForm
          idActividad={search?.id}
          open={openModal}
          onClose={handleCloseModal}
        />
      </Paper>
      <InscripcionInfo preInscripciones={allPreInscripcion} search={search} />

      <InscripcionTable
        handleSubmit={(value) => handleChangeTable(value)}
        preInscripcion={preInscripcionState}
      />
      <Stack spacing={2} style={{ margin: "20px 0px 30px 0px" }}>
        <Pagination
          count={preInscripcion?.data?.pages}
          page={page}
          onChange={handleChange}
          color="primary"
        />
      </Stack>
    </React.Fragment>
  );
};

export default Inscripcion;
