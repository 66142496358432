import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import "dayjs/locale/en-gb";
import Box from "@mui/material/Box";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Stack,
  Typography,
  Alert,
} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { Autocomplete } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Formik } from "formik";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  getProyectosConResol,
  selectProyectosConResol,
  selectError as errorProyecto,
} from "store/proyectos/proyectos.slice";
import { formatDDMMYY } from "utils/helpers/dateFormat";
import {
  GetResolucionEndpoint,
  selectResolucion,
  selectError as errorResolucion,
} from "store/Resolucion/Resolucion.slice";
import { source_Actividades } from "./Config";
import {
  getModalidadesProyecto,
  selectModalidadesProyecto,
} from "store/modalidadesProyecto/modalidadesProyecto.slice";
import {
  GetLocalidadesEndpoint,
  selectErrorLocalidades,
  selectLocalidad,
} from "store/Localidad/Localidad.slice";
import { vsActividad } from "./YupActividad";
import { useTheme } from "@mui/material/styles";

export default function ActividadesForm({ data, onSubmit, onClose }) {

  console.log('data en ActividadForm ', data)

  dayjs.extend(customParseFormat);
  const dispatch = useDispatch();
  const denominaciones = useSelector(selectProyectosConResol);
  const proyectoError = useSelector(errorProyecto);
  const sResolucion = useSelector(selectResolucion);
  const resolucionError = useSelector(errorResolucion);
  const sModalidadActividad = useSelector(selectModalidadesProyecto);
  const [denominacionesSource, setDenominacionesSource] = useState([]);
  const [resolucion, setResolucion] = useState({});
  const [banderaNoAprobado, setBanderaNoAprobado] = useState(false)
  const sLocalidades = useSelector(selectLocalidad);
  const sErrorLocalidades = useSelector(selectErrorLocalidades);
  const theme = useTheme();

  useEffect(() => {
    setResolucion({});
    dispatch(getProyectosConResol());
    dispatch(getModalidadesProyecto());
    dispatch(GetLocalidadesEndpoint());
  }, [dispatch]);

  useEffect(() => {
    const denominacionesSource = denominaciones?.data?.map((denominacion) => ({
      idDenominacion: denominacion.idProyecto,
      descripcion:
        denominacion.denominacion +
        " | FIni: " +
        formatDDMMYY(denominacion.fechaInicio),
    }));
    setDenominacionesSource(denominacionesSource);
  }, [denominaciones]);

  useEffect(() => {
    const result = sResolucion?.data && sResolucion.data.length !== 0;
    if (result) {
      setResolucion({
        resolucion: sResolucion.data[0].descripcion,
        status: true,
        idResolucion: sResolucion.data[0].idResolucion,
      });
    }
  }, [sResolucion]);

  useEffect(() => {
    if (data?.idProyecto) {
      setResolucion({});
      dispatch(GetResolucionEndpoint(data.idProyecto));
    }
  }, [dispatch]);  

  const handleSubmit = (value) => {
    onSubmit({
      ...value,
      sinCupo: value.sinCupo ? 1 : 0,
      cupo: value.sinCupo ? 0 : value.cupo,
      resolucion: resolucion?.idResolucion,
      denominacion: value.idProyecto,
    });
  };

  const handleResolucion = (value) => {
    setResolucion({});
    value?.id && dispatch(GetResolucionEndpoint(value.id));
  };

  const handleClose = (event, reason) => {
    onClose();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <Formik
        initialValues={{
          idActividad: data?.idActividad || null,
          idProyecto: data?.idProyecto || "",

          fechaInicio: data?.fechaInicio
            ? dayjs(data?.fechaInicio, "DD/MM/YYYY")
            : null,
          fechaFin: data?.fechaFin ? dayjs(data?.fechaFin, "DD/MM/YYYY") : null,
          idLocalidad: data?.idLocalidad || "",
          domicilio: data?.domicilio || "",
          infoAdicional: data?.infoAdicional || "",
          resolucion: data?.idResolucion || "",
          sinCupo: data?.sinCupo === 0 ? false : true,
          cupo: data?.cupo || "",

          idModalidadActividad: data?.idModalidadActividad || "",
          idEstadoActividad: data?.idEstadoActividad || 2,   // Por defecto ingresa "EN ANÁLISIS/ TRÁMITE". 
        }}
        validationSchema={vsActividad}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          handleChange,
          touched,
          setFieldValue,
          submitForm,
          setFieldTouched,
          dirty,
        }) => (
          <Box component="form">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={Boolean(errors.idProyecto) && touched.idProyecto}
                >
                  <Autocomplete
                    onChange={(e, value) => {
                      setFieldValue("idProyecto", value?.id ? value.id : null);
                      value?.id && handleResolucion(value);
                    }}
                    options={
                      (denominacionesSource &&
                        denominacionesSource.map((option) => ({
                          id: option.idDenominacion,
                          label: option.descripcion,
                        }))) ||
                      []
                    }
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    name="idProyecto"
                    value={
                      denominacionesSource?.find(
                        (item) => item.idDenominacion === values.idProyecto
                      )?.descripcion || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Denominación del proyecto"
                        margin="normal"
                        variant="outlined"
                        autoComplete="off"
                        InputProps={{ ...params.InputProps, type: "search" }}
                        error={Boolean(errors.idProyecto) && touched.idProyecto}
                        style={{ marginBottom: "0px" }}
                        sx={{
                          "input[type='search']::-webkit-search-cancel-button":
                          {
                            display: "none",
                          },
                        }}
                        helperText={
                          Boolean(errors.idProyecto) &&
                          touched.idProyecto &&
                          errors.idProyecto
                        }
                      />
                    )}
                  />
                  {proyectoError && (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      Ocurrió un problema al recuperar este dato
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {values.idProyecto && resolucion?.idResolucion && (
                <Grid item xs={12} style={{ marginBottom: "0px" }}>
                  <FormControl fullWidth>
                    <Typography variant="subtitle2" color="text.secondary">
                      Resolución
                    </Typography>
                    <Typography variant="h8" gutterBottom>
                      {resolucion.resolucion}
                    </Typography>
                    {resolucionError && (
                      <FormHelperText
                        style={{
                          color: "#d32f2f",
                        }}
                      >
                        Ocurrió un problema al recuperar este dato
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={
                    Boolean(errors.idModalidadActividad) &&
                    touched.idModalidadActividad
                  }
                >
                  <Autocomplete
                    onChange={(e, value) => {
                      setFieldValue(
                        "idModalidadActividad",
                        value ? value.id : null
                      );
                    }}
                    options={
                      (sModalidadActividad &&
                        sModalidadActividad.map((option) => ({
                          id: option.idModalidadProyecto,
                          label: option.denominacion,
                        }))) ||
                      []
                    }
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    name="idModalidadActividad"
                    value={
                      sModalidadActividad?.find(
                        (item) =>
                          item.idModalidadProyecto ===
                          values.idModalidadActividad
                      )?.denominacion || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Modalidad actividad"
                        margin="normal"
                        variant="outlined"
                        autoComplete="off"
                        InputProps={{ ...params.InputProps, type: "search" }}
                        error={
                          Boolean(errors.idModalidadActividad) &&
                          touched.idModalidadActividad
                        }
                        style={{ marginTop: "0.1rem", marginBottom: "0px" }}
                        sx={{
                          "input[type='search']::-webkit-search-cancel-button":
                          {
                            display: "none",
                          },
                        }}
                        helperText={
                          Boolean(errors.idModalidadActividad) &&
                          touched.idModalidadActividad &&
                          errors.idModalidadActividad
                        }
                      />
                    )}
                  />
                  {proyectoError && (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      Ocurrió un problema al recuperar este dato
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  style={{ marginTop: "0.1rem" }}
                  error={
                    Boolean(errors.idEstadoActividad) &&
                    touched.idEstadoActividad
                  }
                >
                  <InputLabel id="idEstadoActividad">
                    Estado de Actividad
                  </InputLabel>
                  {data ?
                    <Select
                      label="Estado de actividad"
                      id="idEstadoActividad"
                      name="idEstadoActividad"
                      fullWidth
                      value={values?.idEstadoActividad}
                      //onChange={handleChange}
                      onChange={(event, newValue) => {
                        if (event?.target?.value !== 1) {
                          setFieldValue("idEstadoActividad", event?.target?.value);
                          setBanderaNoAprobado(false);
                        }
                        else {
                          if (data?.cantCapacitadores > 0) {
                            setFieldValue("idEstadoActividad", event?.target?.value);
                            setBanderaNoAprobado(false);
                          } else {
                            setBanderaNoAprobado(true);
                            setFieldValue("idEstadoActividad", "");
                          }
                        }
                      }}
                      onSelect={handleChange}
                      error={
                        Boolean(errors.idEstadoActividad) &&
                        touched.idEstadoActividad
                      }
                    >
                      {source_Actividades.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    :
                    <Select
                      disabled
                      label="Estado de actividad"
                      id="idEstadoActividad"
                      name="idEstadoActividad"
                      fullWidth
                      value={values?.idEstadoActividad}
                      onChange={handleChange}
                      onSelect={handleChange}
                      error={
                        Boolean(errors.idEstadoActividad) &&
                        touched.idEstadoActividad
                      }
                    >
                      {source_Actividades.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                  {Boolean(errors.idEstadoActividad) &&
                    touched.idEstadoActividad && (
                      <FormHelperText
                        style={{
                          color: "#d32f2f",
                        }}
                      >
                        Este campo es obligatorio
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>
              {/* {console.log("values", values)} */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <DatePicker
                    label="Fecha de inicio"
                    name="fechaInicio"
                    value={values.fechaInicio}
                    onChange={(value) => setFieldValue("fechaInicio", value)}
                    slotProps={{
                      textField: {
                        helperText:
                          Boolean(errors.fechaInicio) &&
                          touched.fechaInicio &&
                          errors.fechaInicio,
                        error:
                          Boolean(errors.fechaInicio) &&
                          Boolean(touched.fechaInicio),
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <DatePicker
                    label="Fecha de fin"
                    name="fechaFin"
                    value={values.fechaFin}
                    onChange={(value) => setFieldValue("fechaFin", value)}
                    slotProps={{
                      textField: {
                        helperText:
                          Boolean(errors.fechaFin) &&
                          touched.fechaFin &&
                          errors.fechaFin,
                        error:
                          Boolean(errors.fechaFin) && Boolean(touched.fechaFin),
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              {!values.sinCupo && (
                <Grid item xs={12} md={7}>
                  <FormControl fullWidth>
                    <TextField
                      label="Cupo de docentes"
                      type="text"
                      name="cupo"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => {
                        const onlyNumbers = e.target.value.replace(
                          /[^\d]/g,
                          ""
                        ); // Eliminar todo excepto los números
                        setFieldValue("cupo", onlyNumbers);
                      }}
                      autoComplete="off"
                      value={values.cupo}
                      error={Boolean(errors.cupo && touched.cupo)}
                      helperText={
                        Boolean(errors.cupo) && touched.cupo && errors.cupo
                      }
                      inputProps={{ maxLength: 4 }}
                      disabled={values.sinCupo}
                    />
                  </FormControl>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                md={5}
                sx={{
                  mt: -1,
                  mb: -1,
                  [theme.breakpoints.up("lg")]: {
                    mt: 0,
                    mb: 0,
                  },
                  [theme.breakpoints.up("md")]: {
                    mt: 0,
                    mb: 0,
                  },
                }}
              >
                <FormControl fullWidth>
                  <FormControlLabel
                    label="Sin Cupo/Registro"
                    labelPlacement="end"
                    sx={{
                      "& .MuiTypography-root": {
                        fontSize: "0.94rem", // Ajusta el tamaño de la fuente aquí
                        color: "#666", // Cambia el color del texto aquí
                      },
                    }}
                    control={
                      <Checkbox
                        name="sinCupo"
                        checked={values.sinCupo}
                        onChange={(e) => {
                          setFieldValue("cupo", "");
                          setFieldValue("sinCupo", e.target.checked);
                        }}
                      />
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={Boolean(errors.idLocalidad) && touched.idLocalidad}
                >
                  <Autocomplete
                    onChange={(e, value) => {
                      setFieldValue("idLocalidad", value ? value.id : null);
                    }}
                    options={
                      (sLocalidades?.data &&
                        sLocalidades?.data?.map((option) => ({
                          id: option.idLocalidad,
                          label: option.localidad,
                        }))) ||
                      []
                    }
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    name="idLocalidad"
                    value={
                      sLocalidades?.data?.find(
                        (item) => item.idLocalidad === values.idLocalidad
                      )?.localidad || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Localidad"
                        margin="normal"
                        variant="outlined"
                        autoComplete="off"
                        InputProps={{ ...params.InputProps, type: "search" }}
                        error={
                          Boolean(errors.idLocalidad) && touched.idLocalidad
                        }
                        style={{ marginTop: "0.1rem", marginBottom: "0px" }}
                        sx={{
                          "input[type='search']::-webkit-search-cancel-button":
                          {
                            display: "none",
                          },
                        }}
                        helperText={
                          Boolean(errors.idLocalidad) &&
                          touched.idLocalidad &&
                          errors.idLocalidad
                        }
                      />
                    )}
                  />
                  {sErrorLocalidades && values.idLocalidad && (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      Ocurrió un problema al recuperar este dato
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    label="Dirección de Dictado"
                    type="text"
                    name="domicilio"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    autoComplete="off"
                    value={values.domicilio}
                    error={Boolean(errors.domicilio && touched.domicilio)}
                    helperText={
                      Boolean(errors.domicilio) &&
                      touched.domicilio &&
                      errors.domicilio
                    }
                    inputProps={{ maxLength: 50 }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    label="Información Adicional"
                    type="text"
                    name="infoAdicional"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    autoComplete="off"
                    value={values.infoAdicional}
                    error={Boolean(
                      errors.infoAdicional && touched.infoAdicional
                    )}
                    multiline
                    rows={2}
                    helperText={
                      Boolean(errors.infoAdicional) &&
                      touched.infoAdicional &&
                      errors.infoAdicional
                    }
                    inputProps={{ maxLength: 50 }}
                  />
                </FormControl>
                {data && banderaNoAprobado &&
                  <Alert severity="warning" style={{ margin: "7px 0px" }}>
                    ¡No se puede aprobar una actividad sin Capacitadores!
                  </Alert>}
                {data && <Alert severity="info" style={{ marginTop: "7px" }}>
                  ¡Sólo si la actividad está "APROBADA" puede abrir un Período de Pre-Inscripción!
                </Alert>}
              </Grid>
            </Grid>

            <Stack
              spacing={2}
              direction="row"
              sx={{ display: "flex", justifyContent: "flex-end", mt: 3, mb: 2 }}
            >
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleClose()}
                spacing={2}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                onClick={() => submitForm()}
                disabled={
                  !dirty || !(values.idProyecto && resolucion?.idResolucion)
                }
              >
                Guardar
              </Button>
            </Stack>
          </Box>
        )}
      </Formik>
    </LocalizationProvider>
  );
}
